import { Info } from '@phosphor-icons/react';
import Popover from '../../ui/components/Popover/Popover';
import TooltipInfoBody from '../../ui/components/Popover/TooltipInfoBody';
import { FC } from 'react';

interface IWidgetTooltipProps {
  title: string;
  description: string;
  id?: string;
}

const WidgetTooltip: FC<IWidgetTooltipProps> = ({ title, description, id }) => {
  return (
    <Popover
      id={id}
      referenceContent={<Info size={20} className='text-gray-500' />}
      popoverContent={<TooltipInfoBody title={title} description={description} />}
      placement='bottom'
    />
  );
};

export default WidgetTooltip;
