import { FC } from 'react';
import { ITokenTransactionResult, ITransactionResponse } from '../../../api/dtos/transaction';
import TransactionEntityReport from './TransactionEntityReport';

interface TokenTransfersReportProps {
  tokenTransfers: ITokenTransactionResult[];
  transaction: ITransactionResponse;
  part: number;
}

const TokenTransfersReport: FC<TokenTransfersReportProps> = props => {
  const { tokenTransfers, transaction, part } = props;

  return (
    <>
      <div className='rounded-t-sm border border-gray-200 bg-gray-200 px-3 py-2.5 text-2xs font-bold'>
        Part {part}: Token Transfers
      </div>
      {tokenTransfers.map((t, i) => (
        <TransactionEntityReport
          key={i}
          input={t.input}
          output={t.output}
          transaction={transaction}
        />
      ))}
    </>
  );
};

export default TokenTransfersReport;
