import { FC } from 'react';
import {
  ITransactionEntity,
  ITransactionExternalResponse,
  ITransactionResponse,
} from '../../../api/dtos/transaction';
import TransactionEntityReport from './TransactionEntityReport';

interface ITransactionEntityReportProps {
  nonTokenInputs?: ITransactionEntity[];
  nonTokenOutputs?: ITransactionEntity[];
  externalTransactions?: ITransactionExternalResponse;
  transaction: ITransactionResponse;
  part: number;
}

const EntityDetailsReport: FC<ITransactionEntityReportProps> = props => {
  const { nonTokenInputs, nonTokenOutputs, externalTransactions, part, transaction } = props;
  // Find max length out of input/output arrays and pad the other array with empty objects
  const maxLength = Math.max(nonTokenInputs?.length || 0, nonTokenOutputs?.length || 0);

  return (
    <div className='mb-4 rounded-b-sm'>
      <div className='rounded-t-sm border border-gray-200 bg-gray-200 px-3 py-2.5 text-2xs font-bold'>
        Part {part}: {transaction?.currency_verbose} Transaction
      </div>
      {externalTransactions ? (
        <TransactionEntityReport
          input={externalTransactions?.input}
          output={externalTransactions?.output}
          transaction={transaction}
        />
      ) : (
        [...Array(maxLength)].map((_, index) => {
          return (
            <TransactionEntityReport
              key={index}
              input={nonTokenInputs?.[index]}
              output={nonTokenOutputs?.[index]}
              transaction={transaction}
            />
          );
        })
      )}
    </div>
  );
};

export default EntityDetailsReport;
