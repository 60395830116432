import classNames from 'classnames';
import { Check, CaretDown, X } from '@phosphor-icons/react';
import { FC, MutableRefObject, ReactNode, useEffect, useMemo, useRef, useState } from 'react';

import Select, {
  components,
  StylesConfig,
  MenuListProps,
  OptionProps,
  ControlProps,
  GroupBase,
  Props,
} from 'react-select';
import { BasicBadge, Button, Input } from '../../ui';
import useIntersectionObserver from '../../hooks/useInteractionObserver';
import Loader from '@/assets/loader.gif';
import { camelCase } from 'lodash';

declare module 'react-select/dist/declarations/src/Select' {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  export interface Props<Option, IsMulti extends boolean, Group extends GroupBase<Option>> {
    onToggleMenu?: (isOpen?: boolean) => void;
    getCustomLabel?: (option: IOption) => ReactNode;
    getCustomOptionLabel?: (option: IOption) => ReactNode;
    onSelectAll?: (type: 'all' | 'none') => void;
    setShowSelected?: (showSelected: boolean) => void;
    showSelected?: boolean;
    controlRef?: MutableRefObject<HTMLDivElement>;
    limit?: number;
    noBorder?: boolean;
    controlClassPaddingY?: string;
    customDropdownArrow?: ReactNode;
    disableSelectAll?: boolean;
    confirmationDropdown?: boolean;
    disableShowSelected?: boolean;
    temporaryIsSelected?: (value: IOption) => boolean;
    onConfirmation?: (toggle: (val: boolean) => void) => void;
    onCancel?: (toggle: (val: boolean) => void) => void;
    fetchNextPage?: () => void;
    hasNextPage?: boolean;
    isFetchingNextPage?: boolean;
    setSearch?: (value: string) => void;
    asyncPaginate?: boolean;
  }
}
export interface CustomSelectProps extends Props<IOption, true> {
  label?: string;
  options: IOption[];
  value?: IOption[];
  placeholder?: string;
  getCustomLabel?: (option: IOption) => ReactNode;
  getCustomOptionLabel?: (option: IOption) => ReactNode;
  handleChange: (value: IOption[]) => void;
  isLoading?: boolean;
  limit?: number;
  noBorder?: boolean;
  parentClassName?: string;
  controlClassPaddingY?: string;
  customDropdownArrow?: ReactNode;
  disableSelectAll?: boolean;
  moveDropdownToRight?: boolean;
  confirmationDropdown?: boolean;
  disableShowSelected?: boolean;
  temporaryIsSelected?: (value: IOption) => boolean;
  onConfirmation?: (toggle: (val: boolean) => void) => void;
  onCancel?: (toggle: (val: boolean) => void) => void;
  fetchNextPage?: () => void;
  hasNextPage?: boolean;
  isFetchingNextPage?: boolean;
  setSearch?: (value: string) => void;
  asyncPaginate?: boolean;
}
export interface IOption {
  value: string;
  label: string;
}

const MenuList = (props: MenuListProps<IOption[], true>) => {
  const menuListRef = useRef(null);
  const inputRef = useRef(null);
  const isSelectedAll = props.selectProps.value.length === props.options.length;

  const paginationID = useRef<HTMLDivElement>(null);

  useIntersectionObserver({
    target: paginationID,
    onIntersect: props.selectProps.fetchNextPage || null,
    enabled: !!props.selectProps.hasNextPage,
  });
  // Check if clicked outside of menu list
  useEffect(() => {
    const handleClickOutside = event => {
      if (
        menuListRef.current &&
        !menuListRef.current.contains(event.target) &&
        !props.selectProps.controlRef.current.contains(event.target)
      ) {
        props.selectProps.onToggleMenu(false);
        props.selectProps.onMenuClose();
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [menuListRef, props.selectProps]);
  const onChange = e => {
    if (props.selectProps.setSearch) {
      props.selectProps.setSearch(e.currentTarget.value);
    }
    props.selectProps.onInputChange(e.currentTarget.value, {
      action: 'set-value',
      prevInputValue: props.selectProps.inputValue,
    });
  };

  return (
    <components.MenuList {...props}>
      {/* Select all and clear */}
      <div ref={menuListRef}>
        <div className='sticky top-0 bg-white'>
          <Input
            ref={inputRef}
            type='text'
            className='w-full p-1'
            id='filter-search'
            data-testid='filter-search'
            value={props.selectProps.inputValue}
            onChange={onChange}
            placeholder='Search'
            onMouseDown={e => {
              e.stopPropagation();
              e.currentTarget.focus();
            }}
            onKeyDown={e => {
              e.stopPropagation();
              e.currentTarget.focus();
            }}
          />
          {(props.selectProps.disableSelectAll === false ||
            props.selectProps.disableShowSelected === false) && (
            <div className='flex flex-row items-center justify-between px-4 py-2 text-blue-500'>
              {props.selectProps.disableSelectAll === false &&
                (!isSelectedAll ? (
                  <div
                    className='cursor-pointer text-xs'
                    onClick={() => props.selectProps.onSelectAll('all')}
                  >
                    Select all
                  </div>
                ) : (
                  <div
                    className='cursor-pointer text-xs'
                    onClick={() => props.selectProps.onSelectAll('none')}
                  >
                    Clear all
                  </div>
                ))}
              {props.selectProps.disableShowSelected === false && (
                <div
                  className='cursor-pointer text-xs'
                  onClick={() => props.selectProps.setShowSelected(!props.selectProps.showSelected)}
                >
                  {props.selectProps.showSelected ? 'Show all' : 'Show selected'}
                </div>
              )}
            </div>
          )}
        </div>
        {props.children}
        {props.selectProps.asyncPaginate &&
          !props.selectProps.isFetchingNextPage &&
          !props.selectProps.isLoading && (
            <div
              ref={paginationID}
              className={`p-2 ${!props.selectProps.hasNextPage ? 'hidden' : ''}`}
            ></div>
          )}
        {props.selectProps.asyncPaginate && props.selectProps.isFetchingNextPage && (
          <img src={Loader} alt='Loader' className='mx-auto h-16' />
        )}
        {props.selectProps.confirmationDropdown && (
          <div className='sticky bottom-0 flex justify-between border-t bg-white p-1 pb-0'>
            <Button
              variant='tertiary'
              onClick={() => props.selectProps.onCancel(props.selectProps.onToggleMenu)}
            >
              Cancel
            </Button>
            <Button
              type='submit'
              variant='primary'
              onClick={() => props.selectProps.onConfirmation(props.selectProps.onToggleMenu)}
            >
              Done
            </Button>
          </div>
        )}
      </div>
    </components.MenuList>
  );
};

const Option = (props: OptionProps<IOption>) => {
  return (
    <components.Option {...props}>
      <div
        id={props.data.label + '-checkbox'}
        data-testid={props.data.label + '-checkbox'}
        className='flex flex-row items-center gap-2 p-3 text-gray-700'
      >
        <Check
          size={16}
          color='#fff'
          className={classNames('rounded border border-gray-300 p-px', {
            'bg-blue-600': props.selectProps.confirmationDropdown
              ? props.selectProps.temporaryIsSelected(props.data)
              : props.isSelected,
          })}
          weight='bold'
        />
        {props.selectProps.getCustomOptionLabel
          ? props.selectProps.getCustomOptionLabel(props.data)
          : props.data.label}
      </div>
    </components.Option>
  );
};

const Control = (props: ControlProps<IOption>) => {
  const values = props.getValue();
  const onClick = () => {
    props.selectProps.onToggleMenu();
  };
  const renderValue = () => {
    if (values.length === 0) {
      return (
        <div className='text-sm text-gray-500'>{props.selectProps.placeholder || 'Select'}</div>
      );
    }
    const limit = props.selectProps.limit;
    const el = values.slice(0, limit).map(item =>
      props.selectProps.getCustomLabel ? (
        <div key={item.label} className='mr-2'>
          {props.selectProps.getCustomLabel(item)}
        </div>
      ) : (
        <BasicBadge key={item.label} className='mr-2 bg-gray-100'>
          {item.label}
        </BasicBadge>
      )
    );

    if (values.length > limit) {
      el.push(
        <span className='ml-auto text-xs text-blue-600'>{`+${values.length - limit} more`}</span>
      );
    }
    return el;
  };
  return (
    <components.Control {...props}>
      <div
        id={props.selectProps.id}
        data-testid={props.selectProps.id}
        className={classNames(
          'flex h-full w-full cursor-pointer flex-row items-center justify-between rounded-md border-gray-300 pl-2 pr-3',
          { border: !props.selectProps.noBorder },
          props.selectProps.controlClassPaddingY || 'py-2'
        )}
        ref={props.selectProps.controlRef}
        onClick={onClick}
      >
        <div className='flex w-full flex-wrap items-center gap-y-1'>{renderValue()}</div>
        {values?.length > 0 && props.selectProps.isClearable !== false && (
          <X
            weight='bold'
            size={18}
            className={classNames(
              'ml-1 mr-0.5 cursor-pointer border-r pr-1 text-red-300 hover:text-red-400'
            )}
            onClick={e => {
              e.stopPropagation();
              props.selectProps.onChange([], null);
            }}
          />
        )}
        {props.selectProps.customDropdownArrow ? (
          props.selectProps.customDropdownArrow
        ) : (
          <CaretDown weight='bold' size={16} className={classNames('mx-0.5')} />
        )}
      </div>
    </components.Control>
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const styles: StylesConfig<any, false> = {
  control: css => ({
    ...css,
    border: 'none',
  }),
  menuList: css => ({
    ...css,
    maxHeight: '300px',
    paddingTop: 0,
  }),
  multiValue: css => ({
    ...css,
    backgroundColor: '#fff',
  }),
  multiValueRemove: css => ({
    ...css,
    display: 'none',
  }),
  option: css => ({
    ...css,
    padding: '0',
    backgroundColor: 'none',
    cursor: 'pointer',
    border: 0,
    outline: 'none',
    background: 'none',
    ':hover': {
      backgroundColor: '#F3F4F6',
    },
  }),
};
const CustomSelect: FC<CustomSelectProps> = props => {
  const {
    id,
    options,
    placeholder,
    isLoading = false,
    limit = 2,
    noBorder,
    parentClassName,
    disableShowSelected = false,
    disableSelectAll = false,
    moveDropdownToRight = false,
    confirmationDropdown = false,
    asyncPaginate = false,
  } = props;
  const [open, setOpen] = useState(false);
  const controlRef = useRef(null);
  const [showSelected, setShowSelected] = useState(false);

  const filteredOptions = useMemo(() => {
    if (showSelected) {
      return options.filter(item => props.value?.find(v => v.value === item.value));
    }
    return options;
  }, [options, props.value, showSelected]);

  return (
    <div className={parentClassName && classNames(parentClassName)}>
      {props.label && (
        <label data-testid={`${props.label}-label`} className='text-sm font-medium text-gray-500'>
          {props.label}
        </label>
      )}
      <Select
        id={id || camelCase(props?.label || '')}
        data-testid={id || camelCase(props?.label || '')}
        className='mt-1'
        styles={
          !moveDropdownToRight
            ? styles
            : {
                ...styles,
                menu: css => ({
                  ...css,
                  marginTop: '0px',
                  position: 'absolute',
                  left: 'max',
                  right: '-200px',
                  width: '300px',
                }),
              }
        }
        menuIsOpen={open}
        components={{ MenuList, Option, Control }}
        isMulti
        hideSelectedOptions={false}
        closeMenuOnSelect={true}
        placeholder={placeholder}
        onToggleMenu={isOpen => setOpen(isOpen || !open)}
        getCustomLabel={props.getCustomLabel}
        getCustomOptionLabel={props.getCustomOptionLabel}
        onChange={props.handleChange}
        value={props.value}
        menuPlacement='auto'
        showSelected={showSelected}
        disableSelectAll={disableSelectAll}
        disableShowSelected={disableShowSelected}
        setShowSelected={setShowSelected}
        isLoading={isLoading}
        onSelectAll={type => {
          if (type === 'all') {
            props.handleChange(options);
          } else {
            props.handleChange([]);
          }
        }}
        limit={limit}
        controlRef={controlRef}
        noBorder={noBorder}
        {...props}
        options={filteredOptions}
        controlClassPaddingY={props.controlClassPaddingY}
        customDropdownArrow={props.customDropdownArrow}
        confirmationDropdown={confirmationDropdown}
        hasNextPage={props?.hasNextPage}
        fetchNextPage={props?.fetchNextPage}
        isFetchingNextPage={props?.isFetchingNextPage}
        setSearch={props?.setSearch}
        asyncPaginate={asyncPaginate}
      />
    </div>
  );
};

export default CustomSelect;
