import { Api } from './api';
import { useQuery, UseQueryOptions } from 'react-query';
import { IStageListLiteResponse, IStageListResponse } from './dtos/stages';
import { convertBooleanToPy } from '../utils/helpers/apiHelpers';
import { useCachedQuery } from '../utils/helpers/react-query.helper';
export class StagesApi extends Api {
  readonly baseUrl: string = '/profile/api/v2/';

  readonly getStageListLite = (is_status_stage: boolean) => {
    return this.http.get<IStageListLiteResponse>(this.route('workflow-stages/lite/'), {
      params: { is_status_stage: convertBooleanToPy(is_status_stage) },
    });
  };

  readonly getStageList = (is_status_stage: boolean) => {
    return this.http.get<IStageListResponse>(this.route('workflow-stages/'), {
      params: { is_status_stage: convertBooleanToPy(is_status_stage) },
    });
  };
}

export const stagesApi = new StagesApi();

export const useStagesListLiteGet = (is_status_stage: boolean, options?: UseQueryOptions) => {
  const key = ['StagesApi.getStageListLite', is_status_stage];
  const result = useCachedQuery(key, () => stagesApi.getStageListLite(is_status_stage), {
    ...options,
  } as unknown);

  return {
    key,
    ...result,
  };
};

export const useStagesListGet = (is_status_stage: boolean) => {
  const key = ['StagesApi.getStageList', is_status_stage];
  const result = useQuery(key, () => stagesApi.getStageList(is_status_stage));

  return {
    key,
    ...result,
  };
};
