import * as React from 'react';
import Skeleton from 'react-loading-skeleton';

import classNames from 'classnames';

import { useRouter } from '../../modules/router/RouterProvider';
import { usePolicyGetDetails } from '../../api/policies';

import AddEditRule from './RuleDetails/AddEditRule';

const RuleCreate: React.FC = () => {
  const { getParams, navigate } = useRouter();
  const params = getParams();

  const policyId = params.policyId;

  const { isLoading, data: policyDetailsData } = usePolicyGetDetails(policyId);
  const policyDetails = policyDetailsData?.data;

  const [activeTab, setActiveTab] = React.useState(0);

  if (isLoading) {
    return <Skeleton count={3} height={30} />;
  } else {
    return (
      <>
        <div className='mb-4 flex gap-2 text-sm font-semibold shadow'>
          <div
            className='cursor-pointer border-b-4 border-white px-5 py-3.5'
            onClick={() => navigate('/settings/workspace/policies/')}
          >
            All Risk Policies
          </div>
          <div className='border-b-4 border-white py-3.5'>{'>'}</div>
          <div
            className='cursor-pointer border-b-4 border-white px-3 py-3.5'
            onClick={() => navigate(`/settings/workspace/policies/${params.policyId}/`)}
          >
            {policyDetails.name}
          </div>
          <div className='border-b-4 border-white py-3.5'>{'>'}</div>
          <div className='cursor-pointer border-b-4 border-blue-600 px-3 py-3.5'>Add Rule</div>
        </div>
        <div className='shadow'>
          <div className='flex gap-2 px-3 text-sm font-semibold shadow-sm'>
            <div
              onClick={() => setActiveTab(0)}
              className={classNames(
                activeTab === 0 ? 'border-blue-600' : 'border-white',
                'cursor-pointer border-b-4 px-3 py-4'
              )}
            >
              Rule Overview
            </div>
          </div>

          <AddEditRule policyDetails={policyDetails} />
        </div>
      </>
    );
  }
};

export default RuleCreate;
