import { AxiosError } from 'axios';
import classNames from 'classnames';
import { FC, useEffect } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { IErrorResponse } from '../../../api/dtos/common';
import {
  IUserNotificationSubscriptionResponse,
  IUserNotificationSubscriptionUpdateRequest,
} from '../../../api/dtos/profile';
import { profileApi, useUserNotificationSubscriptionsGet } from '../../../api/profile';
import { Button, RadioButton } from '../../../ui';
import CheckboxController from '../../../ui/components/Form/CheckboxController';
import RadioButtonsController from '../../../ui/components/Form/RadioButtonsController';
import ToggleController from '../../../ui/components/Form/ToggleController';
import CompassLoader from '../../ui/components/Loader/CompassLoader';

interface NotificationsFormProps {}

type SummaryNotificationTypes = 'monthly' | 'weekly';
type NotificationTypes =
  | 'summary'
  | 'file'
  | 'risk'
  | SummaryNotificationTypes
  | 'alert_item'
  | 'assignment'
  | 'resolution';

const initialData = {
  emailNotifications: false,
  summaryReport: false,
  summaryReportType: null as SummaryNotificationTypes,
  fileUploads: false,
  riskDetectionNotifications: false,
  alertItemNotifications: false,
  riskLevels: {
    '1': false,
    '2': false,
    '3': false,
    '4': false,
    '5': false,
  },
  assignmentNotifications: false,
  alertResolutionNotifications: false,
};

const NotificationsForm: FC<NotificationsFormProps> = () => {
  const queryClient = useQueryClient();
  const { data: response, isLoading, key: qKey } = useUserNotificationSubscriptionsGet();

  const subscriptions = response?.data;

  const {
    control,
    handleSubmit,
    setValue,
    formState: { isSubmitting, isDirty },
  } = useForm({
    defaultValues: initialData,
  });

  const emailNotifications = useWatch({ control, name: 'emailNotifications' });
  const summaryReport = useWatch({ control, name: 'summaryReport' });
  const riskDetectionNotifications = useWatch({ control, name: 'riskDetectionNotifications' });

  useEffect(() => {
    if (subscriptions == null) {
      return;
    }

    const notifications = toEmailNotificationTypes(subscriptions);

    setValue('emailNotifications', notifications.length > 0);
    setValue('summaryReport', notifications.includes('summary'));
    setValue('summaryReportType', notifications.includes('monthly') ? 'monthly' : 'weekly');
    setValue('fileUploads', notifications.includes('file'));
    setValue(
      'riskDetectionNotifications',
      notifications.includes('risk') || notifications.includes('alert_item')
    );
    setValue('riskLevels', subscriptions.risk_notifications);
    setValue('alertItemNotifications', notifications.includes('alert_item'));
    setValue('assignmentNotifications', notifications.includes('assignment'));
    setValue('alertResolutionNotifications', notifications.includes('resolution'));
  }, [subscriptions, setValue]);

  const { mutate } = useMutation(profileApi.updateNotificationSubscriptions, {
    // Always refetch after error or success:
    onSettled: (_data, error) => {
      if (error) {
        toast.error(error.response?.data?.detail || 'Failed to update notification settings');
      } else {
        toast.success('Notification settings updated');
      }
      queryClient.invalidateQueries(qKey);
    },
    onError: (error: AxiosError<IErrorResponse>) => {
      toast.error(error.response.data.detail);
    },
  });

  const onSubmit = (data: typeof initialData) => {
    const request: IUserNotificationSubscriptionUpdateRequest = {
      weekly_report:
        data.emailNotifications && data.summaryReport && data.summaryReportType === 'weekly',
      monthly_report:
        data.emailNotifications && data.summaryReport && data.summaryReportType === 'monthly',
      file_upload_status: data.emailNotifications && data.fileUploads,
      risk_notifications:
        data.emailNotifications && data.riskDetectionNotifications
          ? data.riskLevels
          : {
              ...initialData.riskLevels,
            },
      alert_item_notifications:
        data.emailNotifications && data.alertItemNotifications && data.riskDetectionNotifications,
      assignment_notifications: data.emailNotifications && data.assignmentNotifications,
      alert_resolution_notifications: data.emailNotifications && data.alertResolutionNotifications,
    };

    mutate(request);
  };

  if (isLoading) {
    return (
      <div>
        <CompassLoader />
      </div>
    );
  }

  return (
    <>
      {!isLoading && (
        <form
          onSubmit={handleSubmit(onSubmit)}
          className={classNames({
            '!text-gray-500': !emailNotifications,
          })}
        >
          <div className='py-2'>
            <ToggleController
              control={control}
              name='emailNotifications'
              labelText='Email Notifications'
              labelClass={classNames('text-sm', { '!text-gray-500': !emailNotifications })}
              // TODO:
              // disabled={graph?.permission !== 1}
            />
          </div>
          <div className='py-2'>
            <CheckboxController
              control={control}
              name='summaryReport'
              className='!items-start justify-start'
              inputClassName='mt-1'
              disabled={!emailNotifications}
              labelNode={
                <div className='flex w-full max-w-screen-sm flex-col pl-2'>
                  <div
                    className={classNames('text-sm font-medium', {
                      '!text-gray-500': !emailNotifications,
                    })}
                  >
                    Summary Reports
                  </div>
                  <div className='text-sm font-normal text-gray-500'>
                    Reports summarizing the activity in the past month. Includes case statistics,
                    risks detected and entities detected.
                  </div>
                </div>
              }
            />
            <div className={classNames('pl-6 pt-3', { hidden: !summaryReport })}>
              <RadioButtonsController control={control} name='summaryReportType'>
                <RadioButton labelText='Monthly' value='monthly' />
                <RadioButton labelText='Weekly' value='weekly' />
              </RadioButtonsController>
            </div>
          </div>
          <div className='py-2'>
            <CheckboxController
              control={control}
              name='fileUploads'
              className='!items-start justify-start'
              inputClassName='mt-1'
              disabled={!emailNotifications}
              labelNode={
                <div className='flex w-full max-w-screen-sm flex-col pl-2'>
                  <div
                    className={classNames('text-sm font-medium', {
                      '!text-gray-500': !emailNotifications,
                    })}
                  >
                    File Uploads
                  </div>
                  <div className='text-sm font-normal text-gray-500'>
                    Notification sent after an uploaded file has been successfully processed.
                  </div>
                </div>
              }
            />
          </div>
          <div className='py-2'>
            <CheckboxController
              control={control}
              name='riskDetectionNotifications'
              className='!items-start justify-start'
              inputClassName='mt-1'
              disabled={!emailNotifications}
              labelNode={
                <div className='flex w-full max-w-screen-sm flex-col pl-2'>
                  <div
                    className={classNames('text-sm font-medium', {
                      '!text-gray-500': !emailNotifications,
                    })}
                  >
                    Risk Detection Notifications
                  </div>
                  <div className='text-sm font-normal text-gray-500'>
                    Notification sent when any entity (address, transaction or customer) of the
                    following risks is detected by Merkle Science. The entity can be submitted
                    through APIs or through the manual upload.
                  </div>
                </div>
              }
            />
            <div
              className={classNames('flex gap-4 pl-6 pt-3', {
                hidden: !riskDetectionNotifications,
              })}
            >
              <CheckboxController control={control} name='riskLevels.5' labelText='Critical' />
              <CheckboxController control={control} name='riskLevels.4' labelText='High' />
              <CheckboxController control={control} name='riskLevels.3' labelText='Medium' />
              <CheckboxController control={control} name='riskLevels.2' labelText='Caution' />
              <CheckboxController control={control} name='riskLevels.1' labelText='Info' />
            </div>
            <div
              className={classNames('py-4 pl-6', {
                hidden: !riskDetectionNotifications,
              })}
            >
              <CheckboxController
                control={control}
                name='alertItemNotifications'
                className='!items-start justify-start'
                inputClassName='mt-1'
                disabled={!emailNotifications}
                labelNode={
                  <div className='flex w-full max-w-screen-sm flex-col pl-2'>
                    <div
                      className={classNames('text-sm font-medium', {
                        '!text-gray-500': !emailNotifications,
                      })}
                    >
                      Exposure change detection
                    </div>
                    <div className='text-sm font-normal text-gray-500'>
                      Notifies when there is a change in exposure values or new sub item in Alert is
                      created
                    </div>
                  </div>
                }
              />
            </div>
          </div>
          <div className='py-2'>
            <CheckboxController
              control={control}
              name='assignmentNotifications'
              className='!items-start justify-start'
              inputClassName='mt-1'
              disabled={!emailNotifications}
              labelNode={
                <div className='flex w-full max-w-screen-sm flex-col pl-2'>
                  <div
                    className={classNames('text-sm font-medium', {
                      '!text-gray-500': !emailNotifications,
                    })}
                  >
                    Assignment Notifications
                  </div>
                  <div className='text-sm font-normal text-gray-500'>
                    Notification sent when any entity (address, transaction or customer) is assigned
                    to you on Merkle Science. The entity can be submitted through APIs or through
                    the manual upload.
                  </div>
                </div>
              }
            />
          </div>
          <div className='py-2'>
            <CheckboxController
              control={control}
              name='alertResolutionNotifications'
              className='!items-start justify-start'
              inputClassName='mt-1'
              disabled={!emailNotifications}
              labelNode={
                <div className='flex w-full max-w-screen-sm flex-col pl-2'>
                  <div
                    className={classNames('text-sm font-medium', {
                      '!text-gray-500': !emailNotifications,
                    })}
                  >
                    Alert Resolution Notifications
                  </div>
                  <div className='text-sm font-normal text-gray-500'>
                    Notification sent when any alert is resolved.
                  </div>
                </div>
              }
            />
          </div>
          <div className='mt-4'>
            <Button type='submit' disabled={isSubmitting || !isDirty}>
              Update settings
            </Button>
          </div>
        </form>
      )}
    </>
  );
};

export const toEmailNotificationTypes = (data: IUserNotificationSubscriptionResponse) => {
  const notifications: NotificationTypes[] = [];
  const {
    file_upload_status,
    monthly_report,
    risk_notifications,
    weekly_report,
    alert_item_notifications,
    assignment_notifications,
    alert_resolution_notifications,
  } = data;

  if (monthly_report || weekly_report) {
    notifications.push('summary');
  }

  if (monthly_report) {
    notifications.push('monthly');
  }
  if (weekly_report) {
    notifications.push('weekly');
  }

  if (file_upload_status) {
    notifications.push('file');
  }
  if (alert_item_notifications) {
    notifications.push('alert_item');
  }
  for (const item in risk_notifications) {
    if (risk_notifications[item]) {
      notifications.push('risk');
      break;
    }
  }
  if (assignment_notifications) {
    notifications.push('assignment');
  }
  if (alert_resolution_notifications) {
    notifications.push('resolution');
  }

  return notifications;
};

export default NotificationsForm;
