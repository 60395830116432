import React, { FC, ReactNode } from 'react';
import { IAlert } from '../../../../api/dtos/alert';
import { CaseStatusBadge, Checkbox, ConfirmationModal, RiskBadge } from '../../../../ui';
import { Circle } from '@phosphor-icons/react';
import { formatDateTime } from '../../../../utils/helpers/date';
import { useAuth } from '../../../../modules/auth';
import AlertButtonPanel from './AlertButtonPanel';
import { startCase } from 'lodash';
import AlertInfo from './AlertInfo';
import { useRouter } from '../../../../modules/router/RouterProvider';
import { getTypeApi } from '../../../../utils/helpers/apiHelpers';
import { IStageOptions } from './AlertButtonPanel/AlertButtonPanel';
import { IOption } from '../../../../components/ui/components/Select/SingleSelect';

interface IAlertsProps {
  alert: IAlert;
  id: string | number;
  selectedAlerts: number[];
  type: 'address' | 'transaction' | 'customer';
  setAlertDetails?: (alert: number) => void;
  selectAlerts: (id: number) => void;
  setOpenModal: (val: string) => void;
  setIsBulkUpdate: (val: boolean) => void;
  setCurrentOption: (val: IStageOptions) => void;
  setSelectedAlertId: (val: string | number) => void;
  getCustomOptionLabel: (value: IStageOptions) => ReactNode;
  getCustomLabelAssignee: (value: IOption) => ReactNode;
  setNextStageOptions: (value: IStageOptions[]) => void;
}

const Alert: FC<IAlertsProps> = ({
  alert,
  type,
  id,
  selectedAlerts,
  setAlertDetails,
  selectAlerts,
  setOpenModal,
  setIsBulkUpdate,
  setCurrentOption,
  setSelectedAlertId,
  getCustomLabelAssignee,
  getCustomOptionLabel,
  setNextStageOptions,
}) => {
  const { state } = useAuth();
  const { navigate } = useRouter();
  const [alertInfo, setAlertInfo] = React.useState(false);
  const [redirectModal, setRedirectModal] = React.useState(false);

  const alertTags = [
    ...(type === 'customer' ? [startCase(alert.entity_type) + ' Risk'] : []),
    alert.rule_register.policy_category_verbose,
    ...alert.rule_register.risk_types.map(riskType => riskType.risk_type_verbose),
    formatDateTime(alert.updated_at, state.userProfile.timezone),
    ...(alert.status === 0 && alert.reviewed_by ? ['Reviewed by ' + alert.reviewed_by] : []),
    ...(alert.status === 1 && alert.resolved_by ? ['Resolved by ' + alert.resolved_by] : []),
    ...(alert.status === 2 && alert.escalated_by ? ['Escalated by ' + alert.escalated_by] : []),
  ];

  const onClickAlert = () => {
    if (type === 'address' || type === 'transaction') {
      setAlertDetails(alert?.id);
      navigate('', { alert: alert.id.toString() });
    } else if (type === 'customer' && ['address', 'transaction'].includes(alert.entity_type)) {
      setRedirectModal(true);
      navigate('', { alert: alert.id.toString() });
    } else {
      setAlertInfo(!alertInfo);
    }
  };

  const onRedirect = () =>
    navigate(`/${getTypeApi(alert.entity_type)}/${alert.object_id}`, {
      alert: alert.id.toString(),
    });

  return (
    <div className='my-3 rounded-lg border px-4 py-2 last:mb-0'>
      <div className='flex gap-6'>
        <Checkbox
          key={id}
          className='w-0 rounded'
          onChange={() => {
            selectAlerts(alert.id);
          }}
          checked={selectedAlerts.includes(alert.id)}
        />
        <div
          className='flex grow cursor-pointer justify-between'
          id='alertDetail-navigate'
          data-testid='alertDetail-navigate'
          onClick={onClickAlert}
        >
          <div className='flex-col'>
            <div className='mb-2 text-xs'>{alert.alert_body}</div>
            <div className='mb-2 flex flex-wrap text-xs text-gray-500'>
              {alertTags.map((tag, i) => (
                <div key={i} className='flex'>
                  {tag}
                  {<Circle size={5} weight='fill' className='mx-3 my-auto' />}
                </div>
              ))}
              <div className='text-xs text-gray-500'>Alert ID: {alert.id}</div>
            </div>
            <div className='flex items-start gap-2'>
              {alert.status === 2 && <CaseStatusBadge status={3} className='mr-2' />}
              <RiskBadge
                className='!py-[3px]'
                risk={alert.level}
                grayScale={[2, 3].includes(alert?.workflow_stage?.category)}
              />
              {getCustomOptionLabel({
                id: alert.workflow_stage?.id,
                label: alert.workflow_stage?.name,
                value: alert.workflow_stage?.name,
              })}
              {getCustomLabelAssignee({
                label: alert.assigned_to,
                value: alert.assigned_to,
              })}
            </div>
          </div>
          <div className='flex flex-col justify-center border-l' onClick={e => e.stopPropagation()}>
            <AlertButtonPanel
              alert={alert}
              type={type}
              setOpenModal={setOpenModal}
              setIsBulkUpdate={setIsBulkUpdate}
              setCurrentOption={setCurrentOption}
              setSelectedAlertId={setSelectedAlertId}
              setNextStageOptions={setNextStageOptions}
            />
          </div>
        </div>
      </div>
      <AlertInfo alert={alert} type={type} id={id} open={alertInfo} setOpen={setAlertInfo} />
      <ConfirmationModal
        onConfirm={onRedirect}
        open={!!redirectModal}
        data={redirectModal || null}
        onCancel={() => setRedirectModal(false)}
        title={'Redirect to ' + alert.entity_type}
        body={`This will redirect you to the ${alert.entity_type} page. Are you sure you want to proceed?`}
      />
    </div>
  );
};

export default Alert;
