import { FC, useState } from 'react';
import Sidebar from '../../ui/components/Sidebar';
import { Button } from '../../../ui';
import { ClockCounterClockwise } from '@phosphor-icons/react';
import { useInfiniteQuery } from 'react-query';
import FilterAuditTrail, {
  IFilters,
  defaultStateFilters,
} from '../../common/AuditTrail/FilterAuditTrail';
import AuditTrailAppliedFilters from '../../common/AuditTrail/AuditTrailAppliedFilters';
import Pagination from '../../../containers/Pagination';
import { caseApi } from '../../../api/case';
import { getNextPageParam } from '../../../utils/helpers/helperFunctions';
import CaseAuditTrailEntity from './CaseAuditTrailEntity';

interface ICaseAuditTrailProps {
  id: number;
}

const CaseAuditTrail: FC<ICaseAuditTrailProps> = props => {
  const { id } = props;
  const [open, setOpen] = useState(false);
  const [filters, setFilters] = useState<IFilters>(defaultStateFilters);
  const auditTrailQuery = useInfiniteQuery(
    ['caseApi', id, 'getComments', filters],
    ({ pageParam }) => caseApi.getAuditTrail({ caseId: id, offset: pageParam, filters }),
    {
      enabled: open,
      getNextPageParam,
    } as unknown
  );

  const onApplyFilter = (filters: IFilters) => {
    setFilters(filters);
  };

  return (
    <>
      <Button
        id='auditTrailOpen'
        variant='tertiary'
        className='my-auto ml-2 px-3'
        onClick={() => setOpen(true)}
      >
        <ClockCounterClockwise className='' size={16} />
      </Button>
      <Sidebar
        title='Case Timeline'
        filter={<FilterAuditTrail type='case' filters={filters} onApply={onApplyFilter} />}
        isOpen={open}
        onClose={() => setOpen(false)}
      >
        <div className='flex h-full flex-col justify-between'>
          <AuditTrailAppliedFilters filters={filters} setFilters={setFilters} />
          <Pagination
            query={auditTrailQuery}
            className='mb-auto flex flex-col-reverse'
            loader='compass'
          >
            {data => <CaseAuditTrailEntity entity={data} />}
          </Pagination>
        </div>
      </Sidebar>
    </>
  );
};

export default CaseAuditTrail;
