import classNames from 'classnames';
import { Dispatch, ReactNode, SetStateAction } from 'react';
import _ from './Input.module.scss';

export interface TextareaProps extends Omit<React.HTMLProps<HTMLTextAreaElement>, 'onChange'> {
  id: string;
  error?: string;
  labelText?: string;
  icon?: ReactNode;
  iconPos?: 'start' | 'end';
  onChange?: ((value: string) => void) | Dispatch<SetStateAction<string>>;
  inputClassNames?: string;
  enterPressed?: () => void;
}

const Textarea = (props: TextareaProps) => {
  const {
    id,
    error,
    icon,
    iconPos = 'start',
    labelText,
    placeholder,
    className,
    inputClassNames,
    onChange,
    enterPressed,
    ...otherProps
  } = props;

  return (
    <div className={className}>
      {labelText && (
        <label
          htmlFor={id}
          data-testid={`${id}-label`}
          className='block text-sm font-medium leading-default text-gray-800'
        >
          {labelText}
        </label>
      )}
      <div className='relative mt-1 rounded-md shadow-sm'>
        {icon && iconPos === 'start' ? (
          <div className='pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3'>
            {icon}
          </div>
        ) : (
          ''
        )}
        <textarea
          id={id}
          data-testid={id}
          className={classNames(
            error
              ? 'border-red-300 pr-10 text-red-800 placeholder-red-300 focus:border-red-500 focus:ring-red-500'
              : 'border-gray-300 text-gray-600 placeholder-gray-400 focus:border-blue-500 focus:ring-blue-500',
            icon ? (iconPos === 'start' ? 'pl-10' : 'pr-10') : '',
            'block w-full rounded-md border px-3 py-2 text-sm font-medium leading-default',
            _.inputField,
            'min-h-[100px]',
            inputClassNames
          )}
          placeholder={placeholder || 'Enter value'}
          onChange={event => onChange(event.target.value)}
          onKeyUp={event => {
            if (enterPressed && event.key === 'Enter') {
              event.preventDefault();
              enterPressed();
            }
          }}
          {...otherProps}
        />
        {!error && icon && iconPos === 'end' ? (
          <div className='pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3'>
            {icon}
          </div>
        ) : (
          ''
        )}
        {/* {error && (
          <div className='pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3'>
            <WarningCircle className='h-5 w-5 text-red-500' aria-hidden='true' />
          </div>
        )} */}
      </div>
      {error && (
        <p className='mt-2 text-sm text-red-600' id='email-error'>
          {error}
        </p>
      )}
    </div>
  );
};

export default Textarea;
