import { IFilters, policyTypeCategoryOptions } from './AlertListFilter';
import { BasicAppliedFilter } from '../AppliedFilters';
import { defaultAlertListFilters } from './AlertListFilter';
import DateAppliedFilter from '../AppliedFilters/DateAppliedFilter';
import RiskAppliedFilter from '../AppliedFilters/RiskAppliedFilter';
import { useMemo } from 'react';
import classNames from 'classnames';
import { Button } from '../../ui/components/Button';
import { UserPlus } from '@phosphor-icons/react';
import { IStageListLite } from '../../api/dtos/stages';
import AppliedFilters from '../../ui/components/Badges/AppliedFiltersBadge/AppliedFiltersBadge';

interface ICaseDistributionWidgetProps {
  filters: IFilters;
  setFilters: (filters: IFilters) => void;
  count: number;
  selectedAlerts: number[];
  setSelectedAlerts: (addresses: number[]) => void;
  isAllAlertsSelected: boolean;
  setIsAllAlertsSelected: (boolean) => void;
  selectedText: string;
  selectAllAlerts: (boolean) => void;
  setOpenModal?: (val: string) => void;
  stageOptions: IStageListLite[];
}

const AlertListAppliedFilter: React.FC<ICaseDistributionWidgetProps> = props => {
  const {
    count,
    filters,
    setFilters,
    selectAllAlerts,
    selectedAlerts,
    selectedText,
    setIsAllAlertsSelected,
    setSelectedAlerts,
    isAllAlertsSelected,
    setOpenModal,
    stageOptions,
  } = props;
  const isFilterApplied = useMemo(() => {
    if (filters.level?.length) return true;
    if (filters.policy_category) return true;
    if (filters.policy_type) return true;
    if (filters.rule_name?.length) return true;
    if (filters.start_time) return true;
    if (filters.end_time) return true;
    if (filters.status?.length) return true;
    if (filters.assigned_to?.length) return true;
  }, [filters]);

  return (
    <div
      className={classNames(
        'flex justify-stretch border-t-[1px] bg-gray-50',
        isFilterApplied || selectedAlerts?.length ? 'px-2 py-2.5' : ''
      )}
    >
      <div className='flex w-full items-center justify-between'>
        <div className='flex flex-wrap items-center'>
          <RiskAppliedFilter
            risks={filters.level}
            setRisks={r => setFilters({ ...filters, level: r })}
          />
          <DateAppliedFilter
            label='Created Date'
            from={filters.start_time}
            to={filters.end_time}
            onRemove={() => setFilters({ ...filters, start_time: null, end_time: null })}
          />
          {filters.status?.map((stage, i) => (
            <AppliedFilters
              key={i}
              onRemove={() => {
                setFilters({
                  ...filters,
                  status: filters.status.filter(r => r !== stage),
                });
              }}
            >
              Status:{' '}
              {stage === 'None' ? '-NA-' : stageOptions?.find(item => item.id === stage)?.name}
            </AppliedFilters>
          ))}
          <BasicAppliedFilter
            single
            setValue={() => setFilters({ ...filters, rule_id: null, rule_name: null })}
            label='Rule Name'
            value={[filters.rule_name]}
          />
          {filters.assigned_to_name?.map((assignee, i) => (
            <AppliedFilters
              key={i}
              onRemove={() => {
                setFilters({
                  ...filters,
                  assigned_to_name: filters.assigned_to_name.filter((_, index) => index !== i),
                  assigned_to: filters.assigned_to.filter((_, index) => index !== i),
                });
              }}
            >
              Assignee: {assignee}
            </AppliedFilters>
          ))}
          <BasicAppliedFilter
            label='Policy Type & Category'
            single
            value={[
              policyTypeCategoryOptions.find(
                o =>
                  o.value.split(',')[0] === filters.policy_category &&
                  Number(o.value.split(',')[1]) === filters.policy_type
              )?.label,
            ]}
            setValue={() => {
              setFilters({ ...filters, policy_category: null, policy_type: null });
            }}
          />
        </div>
        {isFilterApplied && (
          <div className='flex items-center'>
            <button
              id='alertList-reset'
              data-testid='alertList-reset'
              className='mr-2 text-2xs text-blue-600'
              onClick={() => setFilters(defaultAlertListFilters)}
            >
              Reset Filters
            </button>
          </div>
        )}
      </div>
      {selectedAlerts?.length > 0 && (
        <div className='flex min-w-[600px] items-center justify-end gap-3 pr-4 text-2xs'>
          <div>{selectedText}</div>
          <Button
            id='alertList-assignee'
            variant='secondary'
            iconStart={<UserPlus weight='fill' size={15} />}
            onClick={() => setOpenModal('assignee')}
          />
          <Button id='alertList-status' variant='secondary' onClick={() => setOpenModal('status')}>
            Update Status
          </Button>
          {!isAllAlertsSelected && count <= 100 && (
            <button
              id='alertList-selectAll'
              data-testid='alertList-selectAll'
              className='text-blue-500'
              onClick={() => {
                selectAllAlerts(true);
                setIsAllAlertsSelected(true);
              }}
            >
              Select all {count} alerts
            </button>
          )}
          <div
            id='alertList-clear'
            data-testid='alertList-clear'
            className='cursor-pointer text-blue-500'
            onClick={() => {
              setSelectedAlerts([]);
              setIsAllAlertsSelected(false);
            }}
          >
            Clear selection
          </div>
        </div>
      )}
    </div>
  );
};

export default AlertListAppliedFilter;
