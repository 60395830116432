/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { AxiosError } from 'axios';
import { TabHeader } from '../../../../components/ui/components/Header';
import RadioButton from '../../../../ui/components/RadioButtons/RadioButton';
import CheckboxController from '../../../../ui/components/Form/CheckboxController';
import CustomSelect, { IOption } from '../../../../components/Filters/CustomSelect';
import {
  useUserCaseAssignmentGet,
  useUserApprovalFlowGet,
  useUserCaseUserGet,
  useUserAutoCreateCasesGet,
  useUserCaseApproverAssignmentGet,
} from '../../../../api/profile';
import { RadioButtons } from '@ui/index';
import { useMutation, useQueryClient } from 'react-query';
import { profileApi } from '../../../../api/profile';
import { IErrorResponse } from '../../../../api/dtos/common';
import { toast } from 'react-toastify';
import {
  IUserApprovalFlowUpdateRequest,
  IUserAutoCreateCasesUpdateRequest,
  IUserCaseApproverAssignmentUpdateRequest,
  IUserCaseAssignmentUpdateRequest,
} from '../../../../api/dtos/profile';

interface CasePreferenceProps {}

const initialData = {
  riskLevels: {
    '1': false,
    '2': false,
    '3': false,
    '4': false,
    '5': false,
  },
};

const CasePreference: FC<CasePreferenceProps> = () => {
  const queryClient = useQueryClient();
  const { control } = useForm({
    defaultValues: initialData,
  });
  const [approvalFlowStatus, setApprovalFlowStatus] = useState<boolean>();
  const [approvalFlow, setApprovalFlow] = useState<string>();
  const [caseAssignment, setcaseAssignment] = useState<number>();
  const [caseAgentsSelected, setCaseAgentsSelected] = useState([]);
  const [caseApproverSelected, setCaseApproverSelected] = useState([]);
  const { key: qKey } = useUserCaseAssignmentGet({
    onSuccess: ({ data }) => {
      setcaseAssignment(data?.case_agent_assignment_type ?? 1);
    },
  });

  const { key: qKeyApprovalFlow } = useUserApprovalFlowGet({
    onSuccess: ({ data }) => {
      setApprovalFlowStatus(data?.is_case_mandatory_approval || false);
      setApprovalFlow(data?.is_case_mandatory_approval ? 'mandatoryApproval' : 'regularApproval');
    },
  });

  const { key: qKeyCaseApproverAssignment } = useUserCaseApproverAssignmentGet({
    onSuccess: ({ data }) => {
      setCaseApproverAssignment(data?.case_approver_assignment_type ?? 1);
    },
  });
  const [allUser, setUser] = useState();
  useUserCaseUserGet(null, {
    onSuccess: ({ data }) => {
      const modifiedResults = data?.results.map(resultItem => ({
        label: resultItem.full_name,
        value: resultItem.id,
      }));

      setUser(modifiedResults);
    },
  });
  const { data: responseAutoCreateCases, key: qKeyAutoCreateCases } = useUserAutoCreateCasesGet({
    onSuccess: ({ data }) => {
      setAutoResolveAlerts(data?.auto_create_case_alert_levels);
    },
  });
  const onSettleUpdateCaseAgentApprover = () => {
    queryClient.invalidateQueries(getCaseApproverQuery.key);
    queryClient.invalidateQueries(getCaseAgentQuery.key);
  };
  const getCaseApproverQuery = useUserCaseUserGet(
    { case_role: 1 },
    {
      onSuccess: ({ data }) => {
        const modifiedResults = data?.results.map(resultItem => ({
          label: resultItem.full_name,
          value: resultItem.id,
        }));
        setCaseApproverSelected(modifiedResults);
      },
    }
  );
  const getCaseAgentQuery = useUserCaseUserGet(
    {
      case_role: 2,
    },
    {
      onSuccess: ({ data }) => {
        const modifiedResults = data?.results.map(resultItem => ({
          label: resultItem.full_name,
          value: resultItem.id,
        }));
        setCaseAgentsSelected(modifiedResults);
      },
    }
  );

  const [caseApproverAssignment, setCaseApproverAssignment] = useState<number>();
  const [AutoResolveAlerts, setAutoResolveAlerts] = useState(
    responseAutoCreateCases?.data?.auto_create_case_alert_levels || [5, 3, 2, 1]
  );

  // Updating on Case Agent and Case Approver

  const showSuccessToast = () => {
    toast.success('Case preferences updated Successfully');
  };

  const updateAgent = useMutation(profileApi.updateCaseApprovers, {
    onSuccess: showSuccessToast,
    onSettled: onSettleUpdateCaseAgentApprover,
    onError: (error: AxiosError<IErrorResponse>) => {
      toast.error(error.response.data.detail);
    },
  });
  // Updating Case Assignment
  const mutateUpdateCase = useMutation(profileApi.updateCaseAssignment, {
    onSettled: () => {
      queryClient.invalidateQueries(qKey);
    },
    onSuccess: showSuccessToast,
    onError: (error: AxiosError<IErrorResponse>) => {
      toast.error(error.response.data.detail);
    },
  });
  // Updating Approval FLow
  const mutateUpdateApproval = useMutation(profileApi.updateApprovalFlow, {
    onSettled: () => {
      queryClient.invalidateQueries(qKeyApprovalFlow);
    },
    onSuccess: showSuccessToast,
    onError: (error: AxiosError<IErrorResponse>) => {
      toast.error(error.response.data.detail);
    },
  });
  // Updating CaseApproverAssignment
  const mutateUpdateCaseApproverAssignment = useMutation(profileApi.updateCaseApproverAssignment, {
    onSettled: () => {
      queryClient.invalidateQueries(qKeyCaseApproverAssignment);
    },
    onSuccess: showSuccessToast,
    onError: (error: AxiosError<IErrorResponse>) => {
      toast.error(error.response.data.detail);
    },
  });
  //Updating AutoCreateCases
  const mutateUpdateAutoCreateCases = useMutation(profileApi.updateAutoCreateCases, {
    onSettled: () => {
      queryClient.invalidateQueries(qKeyAutoCreateCases);
    },
    onSuccess: showSuccessToast,
    onError: (error: AxiosError<IErrorResponse>) => {
      toast.error(error.response.data.detail);
    },
  });

  const onChangeEventCaseAssignment = e => {
    setcaseAssignment(Number(e.target.value));
    const request: IUserCaseAssignmentUpdateRequest = {
      case_agent_assignment_type: Number(e.target.value),
    };
    mutateUpdateCase.mutate(request);
  };

  const onChangeEventapprovalFlow = e => {
    if (e.target.value === 'mandatoryApproval') {
      setApprovalFlowStatus(true);
      const request: IUserApprovalFlowUpdateRequest = {
        is_case_mandatory_approval: !approvalFlowStatus,
      };
      mutateUpdateApproval.mutate(request);
    }
    if (e.target.value === 'regularApproval') {
      setApprovalFlowStatus(false);
      const request: IUserApprovalFlowUpdateRequest = {
        is_case_mandatory_approval: !approvalFlowStatus,
      };
      mutateUpdateApproval.mutate(request);
    }
  };

  useEffect(() => {
    if (approvalFlowStatus) {
      setApprovalFlow('mandatoryApproval');
    } else {
      setApprovalFlow('regularApproval');
    }
  }, [approvalFlowStatus]);

  const onChangeEventCaseApproverAssignment = e => {
    setCaseApproverAssignment(Number(e.target.value));
    const request: IUserCaseApproverAssignmentUpdateRequest = {
      case_approver_assignment_type: Number(e.target.value),
    };
    mutateUpdateCaseApproverAssignment.mutate(request);
  };

  const toggleSelection = (data: number) => {
    const newAutoResolved = AutoResolveAlerts.includes(data)
      ? AutoResolveAlerts.filter(item => item !== data)
      : [...AutoResolveAlerts, data];
    setAutoResolveAlerts(newAutoResolved);
    const request: IUserAutoCreateCasesUpdateRequest = {
      auto_create_case_alert_levels: newAutoResolved,
    };
    mutateUpdateAutoCreateCases.mutate(request);
  };

  const handleCaseChange = (options, case_role: number) => {
    const handleUpdate = (selected, setSelected, caseRoleValue) => {
      if (selected?.length > options?.length) {
        const removedOption = selected.find(option => !options.includes(option));
        if (removedOption) {
          setSelected(prev => prev.filter(item => item.value !== removedOption.value));
          updateAgent.mutate({
            payload: {
              case_role: null,
            },
            id: removedOption.value,
          });
        }
      } else if (selected?.length < options?.length) {
        const addedOption = options.find(option => !selected.includes(option));
        if (addedOption) {
          setSelected(prev => [...prev, addedOption]);
          updateAgent.mutate({
            payload: {
              case_role: caseRoleValue,
            },
            id: addedOption.value,
          });
        }
      }
    };

    if (case_role === 1) {
      handleUpdate(caseApproverSelected, setCaseApproverSelected, 1);
    } else if (case_role === 2) {
      handleUpdate(caseAgentsSelected, setCaseAgentsSelected, 2);
    }
  };

  return (
    <div>
      <div className='mb-6'>
        <TabHeader heading='Case Preferences' />
      </div>
      <div className='w-1/2'>
        <h4 className='mt-5 text-base'>Auto Create Cases</h4>
        <TabHeader
          heading=''
          subheading='Cases of these alert levels will be opened automatically.'
        />

        <div className='flex gap-4 pt-3'>
          <CheckboxController
            control={control}
            name='riskLevels.1'
            checked={AutoResolveAlerts.includes(5)}
            onChange={() => toggleSelection(5)}
            labelNode={
              <div className='flex w-full max-w-screen-sm flex-col pl-3'>
                <div className='text-sm font-medium'>Critical</div>
              </div>
            }
          />
          <CheckboxController
            control={control}
            name='riskLevels.2'
            onChange={() => toggleSelection(4)}
            checked={AutoResolveAlerts.includes(4)}
            labelNode={
              <div className='flex w-full max-w-screen-sm flex-col pl-3'>
                <div className='text-sm font-medium'>High</div>
              </div>
            }
          />
          <CheckboxController
            control={control}
            name='riskLevels.3'
            checked={AutoResolveAlerts.includes(3)}
            onChange={() => toggleSelection(3)}
            labelNode={
              <div className='flex w-full max-w-screen-sm flex-col pl-3'>
                <div className='text-sm font-medium'>Medium</div>
              </div>
            }
          />
          <CheckboxController
            control={control}
            name='riskLevels.4'
            checked={AutoResolveAlerts.includes(2)}
            onChange={() => toggleSelection(2)}
            labelNode={
              <div className='flex w-full max-w-screen-sm flex-col pl-3'>
                <div className='text-sm font-medium'>Caution</div>
              </div>
            }
          />
          <CheckboxController
            control={control}
            name='riskLevels.5'
            onChange={() => toggleSelection(1)}
            checked={AutoResolveAlerts.includes(1)}
            labelNode={
              <div className='flex w-full max-w-screen-sm flex-col pl-3'>
                <div className='text-sm font-medium'>Info</div>
              </div>
            }
          />
        </div>
        <h4 className='mt-5 text-base'>Case Agents</h4>
        <TabHeader
          heading=''
          subheading='Add all of your agents below. You can only add people who have access to the Merkle Science Platform.'
        />
        <CustomSelect
          id='casePreference-caseAgent'
          value={caseAgentsSelected}
          isMulti
          options={allUser}
          isClearable={false}
          hideSelectedOptions={false}
          placeholder='Choose a Case Agent'
          disableSelectAll={true}
          handleChange={(options: IOption[]) => handleCaseChange(options, 2)}
          limit={3}
        />
      </div>
      <div className='mt-6 w-1/2'>
        <hr />
      </div>
      <h4 className='mt-3 text-base'>Case Assignment</h4>
      <TabHeader heading='' subheading='Define how new cases should be assigned.' />
      <div>
        <RadioButtons
          name='caseAssignment'
          onChange={onChangeEventCaseAssignment}
          selected={caseAssignment}
          className='flex flex-col !items-start'
        >
          <RadioButton
            labelText='Manual Assignment'
            value={0}
            name='manualAssignment'
            description='Cases get assigned only when you assign it manually.'
          />
          <RadioButton
            labelText='Round Robin Case Assignment'
            value={1}
            name='roundRobinAssignment'
            description='Cases get assigned in a rolling fashion to a selected pool of agents one by one.'
          />
          <RadioButton
            labelText='Least Outstanding Case Assignment'
            value={2}
            name='leastOutstandingAssignment'
            description='Cases get assigned to the agent with the least number of open cases.'
          />
        </RadioButtons>
      </div>
      <div className='mt-6 w-1/2 pt-4'>
        <hr />

        <h4 className='mt-3 text-base'>Approval Flow</h4>

        <div>
          <RadioButtons
            name='approvalFlow'
            className='mt-5 flex flex-col !items-start'
            onChange={onChangeEventapprovalFlow}
            selected={approvalFlow}
          >
            <RadioButton
              labelText='Regular Flow'
              value='regularApproval'
              name='regularApprovalFlow'
              description='Agents can change the case status from "Open" to "Closed" without any further evaluation. All events will be present in the Audit Trail.'
            />
            <RadioButton
              labelText='Mandatory Approval'
              value='mandatoryApproval'
              name='mendatoryApprovalFlow'
              description='When an agent marks the case as closed, the case will be sent to an Approver and they can approve the case closure or reject it with additional comments.'
            />
          </RadioButtons>
        </div>
        {approvalFlow === 'mandatoryApproval' ? (
          <div>
            <h4 className='mt-3 text-base'>Case Approver Assignment</h4>
            <TabHeader heading='' subheading='Define how case approver should be assigned' />
            <div>
              <RadioButtons
                name='caseApproverAssignment'
                className='mt-5 flex flex-col'
                onChange={onChangeEventCaseApproverAssignment}
                selected={caseApproverAssignment}
              >
                <RadioButton
                  labelText='Round Robin Approver Assignment'
                  value={0}
                  name='roundRobinApproval'
                  description='Case approvals get assigned in a rolling fashion to a selected pool of approvers one by one.'
                  selected={true}
                />
                <RadioButton
                  labelText='Least Outstanding Approver Assignment'
                  value={1}
                  name='leastOutstandingApproval'
                  description='Case approvals get assigned to the approver with the least number of approvals.'
                />
              </RadioButtons>
            </div>
          </div>
        ) : null}
        {approvalFlow === 'mandatoryApproval' ? (
          <div className='mt-6'>
            <h4 className='mt-5 text-base'>Case Approvers</h4>
            <TabHeader heading='' subheading='Add your agents who can approve case closures.' />
            <CustomSelect
              id='casePreference-approver'
              value={caseApproverSelected}
              isMulti
              options={allUser}
              isClearable={false}
              hideSelectedOptions={false}
              placeholder='Choose a Case Approver'
              disableSelectAll={true}
              handleChange={(options: IOption[]) => handleCaseChange(options, 1)}
              limit={3}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default CasePreference;
