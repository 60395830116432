/* eslint-disable @typescript-eslint/no-explicit-any */
import { Api } from './api';
import { getNextPageParam } from '../utils/helpers/helperFunctions';
import {
  IDownloadManagerRequest,
  IDownloadManagerResults,
  IDownloadManagerLinkResponse,
} from './dtos/downloadManager';
import { UseQueryOptions, useInfiniteQuery } from 'react-query';
import { IPaginatedResponse } from './dtos/common';

export class DownloadManagerApi extends Api {
  readonly baseUrl: string = '/utils/downloaded-assets';

  readonly getDownloads = (params?: IDownloadManagerRequest) => {
    return this.http.get<IPaginatedResponse<IDownloadManagerResults>>(this.route(''), {
      params,
    });
  };

  readonly getDownloadLink = (id: string) => {
    return this.http.get<IDownloadManagerLinkResponse>(this.route(`${id}/get-link/`));
  };
}

export const downloadManagerApi = new DownloadManagerApi();

export const useDownloadGetDownloads = (
  params: IDownloadManagerRequest,
  options?: UseQueryOptions
) => {
  const { limit, offset } = params;
  const key = ['downloadManagerApi.getDownloads', limit, offset];
  const result = useInfiniteQuery(
    key,
    ({ pageParam }) => downloadManagerApi.getDownloads({ offset: pageParam, limit: 20 }),
    {
      getNextPageParam,
      ...options,
    } as unknown
  );
  return {
    key,
    ...result,
  };
};
