import { FC } from 'react';
import { BasicModal } from '../../../ui';
import { useMutation, useQueryClient } from 'react-query';
import Quill from '../../ui/components/Quill';
import { toast } from '../../../ui/components/Toast';
import { commentApi } from '../../../api/comment';
import { AxiosError } from 'axios';
import { monitoringAddressesApi } from '../../../api/settings/monitoringAddresses';
import { getErrorMessage } from '../../../utils/helpers/helperFunctions';
import { IAddressResponse } from '../../../api/dtos/address';
import { useWorkspace } from '../../../utils/helpers/common';

interface IMonitoringModalProps {
  address: IAddressResponse;
  open: boolean;
  setOpen: (open: boolean) => void;
}

const MonitoringModal: FC<IMonitoringModalProps> = ({ open, setOpen, address }) => {
  const queryClient = useQueryClient();
  const isMonitoringActive = !address.is_muted;
  const workspace = useWorkspace();

  const { mutate: addAddress } = useMutation(monitoringAddressesApi.addMonitoredAddress, {
    onSuccess: () => {
      toast.success(`Monitoring has been ${isMonitoringActive ? 'Deactivated' : 'Enabled'}`);
      queryClient.invalidateQueries(['addressApi.getAddress', address.id]);
    },
    onError: (error: AxiosError) => {
      toast.error(getErrorMessage(error));
    },
  });

  const { mutate: archiveAddress } = useMutation(monitoringAddressesApi.archiveMonitoredAddress, {
    onSuccess: () => {
      toast.success('Monitoring has been Deactivated');
      queryClient.invalidateQueries(['addressApi.getAddress', address.id]);
    },
    onError: (error: AxiosError) => {
      toast.error(getErrorMessage(error));
    },
  });
  const { mutate: addComment } = useMutation(commentApi.addComment, {
    onSuccess: () => {
      toast.success('Comment added successfully');
    },
  });

  const onSubmit = (value: string, files: FileList) => {
    addComment({ id: address.id, type: 'address', body: value, files });
    if (workspace.continuous_monitoring_level === 1) {
      isMonitoringActive
        ? addAddress({
            identifier: address.identifier,
            currency: address.currency,
            is_ignored: true,
          })
        : archiveAddress({
            identifier: address.identifier,
            currency: address.currency,
            is_ignored: true,
          });
    } else {
      isMonitoringActive
        ? archiveAddress({
            identifier: address.identifier,
            currency: address.currency,
            is_ignored: false,
          })
        : addAddress({
            identifier: address.identifier,
            currency: address.currency,
            is_ignored: false,
          });
    }
    setOpen(false);
  };

  const ModalBody: FC = () => {
    return (
      <div>
        <Quill id={address.id} onSubmit={onSubmit} type='address' />
      </div>
    );
  };

  return (
    <BasicModal
      open={open}
      modalTitle={`Are you sure you want to ${
        isMonitoringActive ? 'Deactivate' : 'Enable'
      } Monitoring for this Address?`}
      modalBody={<ModalBody />}
      onClose={() => setOpen(false)}
    />
  );
};

export default MonitoringModal;
