import { useMutation, useQueryClient } from 'react-query';
import { integrationApi } from '../../../api/integration';
import { toast } from 'react-toastify';
import { AxiosError, AxiosResponse } from 'axios';
import { getErrorMessage } from '../../../utils/helpers/helperFunctions';
import { FC } from 'react';
import classNames from 'classnames';
import { ITransactionResponse } from '../../../api/dtos/transaction';

interface IFireblocksFrozenNoteProps {
  id: number;
}

const FireblocksFrozenNote: FC<IFireblocksFrozenNoteProps> = ({ id }) => {
  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation(integrationApi.unfreezeFireblocksTransaction, {
    onSuccess: () => {
      queryClient.setQueryData(
        ['transactionApi.getTransaction', id],
        (data: AxiosResponse<ITransactionResponse>) => {
          data.data.is_frozen_on_fireblocks = false;
          return data;
        }
      );

      toast.success('Transaction was successfully unfrozen on Fireblocks');
    },
    onError: (error: AxiosError) => {
      toast.error(getErrorMessage(error) || 'Failed to unfreeze transaction on Fireblocks');
    },
  });

  const onUnblock = () => {
    mutate(id);
  };

  return (
    <div className='flex justify-between rounded-md border bg-yellow-200 p-4 text-sm'>
      <div>
        Transaction was automatically frozen on Fireblocks due to a critical level risk being
        detected
      </div>
      <div
        id='unfreeze'
        data-testid='unfreeze'
        className={classNames('cursor-pointer text-blue-500 hover:text-blue-700', {
          'pointer-events-none opacity-50': isLoading,
        })}
        onClick={onUnblock}
      >
        Unfreeze now
      </div>
    </div>
  );
};

export default FireblocksFrozenNote;
