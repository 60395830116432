import { useAuth } from '../../../../src/modules/auth';
import { ICustomerResponse } from '../../../../src/api/dtos/customer';
import { Checkbox } from '../../../ui';
import CurrencyValue from '../../../components/ui/components/Currency/CurrencyValue';
import DigitalAssets from '../../../components/ui/components/Currency/DigitalAssets';
import { formatDateShort } from '../../../utils/helpers/date';
import IdentifierEllipsis from '../../../components/ui/components/Currency/IdentifierEllipsis';
import RisksDetected from '../../../components/common/RisksDetected';
import { useRouter } from '../../../modules/router/RouterProvider';

interface ICustomerData {
  customers: ICustomerResponse[];
  selectedCustomers?: number[];
  selectAllCustomers?: (force?: boolean) => void;
  selectCustomer?: (id: number) => void;
  type?: string;
}

export const useGetCustomerData = ({
  customers,
  selectedCustomers,
  selectAllCustomers,
  selectCustomer,
  type = 'page',
}: ICustomerData) => {
  const { state } = useAuth();
  const { navigate } = useRouter();

  return {
    headerData: [
      ...(type !== 'search'
        ? [
            <Checkbox
              name='checkbox-selectAll'
              key={0}
              className='w-0 rounded'
              onChange={() => selectAllCustomers()}
              checked={selectedCustomers.length === customers.length && customers.length !== 0}
            />,
          ]
        : []),
      'Customer ID',
      'Digital Assets',
      'Risks Detected',
      'Date Added',
      'Last Transaction',
      'Deposit Amount',
      <div key={8} id='open-alerts' data-testid='open-alerts' className='flex justify-center'>
        Open Alerts
      </div>,
    ],
    rowData: customers
      ? customers.map(customer => {
          return {
            id: customer.id,
            customerId: customer.customer_id,
            data: [
              ...(type !== 'search'
                ? [
                    <Checkbox
                      key={0}
                      className='w-0 rounded'
                      onChange={e => {
                        e.stopPropagation();
                        selectCustomer(customer.id);
                      }}
                      checked={selectedCustomers.includes(customer.id)}
                    />,
                  ]
                : []),
              <IdentifierEllipsis
                key={1}
                identifier={customer.customer_id}
                limit={32}
                className='text-blue-500'
                includedInCase={customer.is_included_in_case}
                clickable
                copyable
              />,
              <DigitalAssets key={2} digitalAssets={customer.digital_assets} />,
              <RisksDetected
                key={7}
                risk={customer.risk_level}
                risksDetected={customer.risks_detected}
              />,
              customer.created_at
                ? formatDateShort(customer.created_at, state.userProfile.timezone)
                : '',
              customer.latest_transaction_time
                ? formatDateShort(customer.latest_transaction_time, state.userProfile.timezone)
                : '-',
              <div key={9} className='flex'>
                <CurrencyValue
                  key={7}
                  value={customer.total_incoming_value_usd}
                  currency={'USD'}
                  dualTone
                />
              </div>,
              <div
                key={10}
                id='open-alerts'
                data-testid='open-alerts'
                className='flex justify-center'
              >
                {customer.open_alerts}
              </div>,
            ],
            onClick: () => {
              navigate(`/customers/${encodeURIComponent(customer.customer_id)}`);
            },
          };
        })
      : [],
  };
};
