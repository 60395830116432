import React, { FC, HTMLAttributeAnchorTarget } from 'react';
import { useRouter } from '../../../modules/router/RouterProvider';

interface LinkClassProps {
  isActive: boolean;
}

interface CustomLinkProps {
  id?: string;
  to: string;
  params?: Record<string, string>;
  className?: string | ((props: LinkClassProps) => string);
  target?: HTMLAttributeAnchorTarget;
  children: React.ReactNode;
  onClickHandle?: () => void;
}

const CustomLink: FC<CustomLinkProps> = ({
  to = '',
  params,
  className,
  children,
  target,
  onClickHandle,
  id,
}) => {
  const { navigate } = useRouter();
  const isActive = window.location.pathname === to;
  const handleClick = event => {
    onClickHandle?.();
    event.preventDefault();
    navigate(to, params);
  };

  const href = to + (params ? '?' + new URLSearchParams(params).toString() : '');

  const finalClassName = typeof className === 'function' ? className({ isActive }) : className;

  if (!href) return <>{children}</>;

  return (
    <a
      id={id}
      data-testid={id}
      href={href}
      target={target}
      className={finalClassName}
      onClick={target ? null : handleClick}
    >
      {children}
    </a>
  );
};

export default CustomLink;
