import { FC } from 'react';
import { isEmpty, every } from 'lodash';

import AppliedFilters from '../../AppliedFilters';
import RiskAppliedFilter from '../../AppliedFilters/RiskAppliedFilter';
import { AppliedFiltersBadge } from '../../../ui';
import { riskTypesOptions } from '../../../components/Filters/RiskTypeFilter';

import { IFilters, ruleStatusOptions } from './PolicyRuleListFilter';

interface PolicyRuleListAppliedFiltersProps {
  filters: IFilters;
  setFilters: (filters: IFilters) => void;
}

const PolicyRuleListAppliedFilters: FC<PolicyRuleListAppliedFiltersProps> = props => {
  const { filters, setFilters } = props;
  if (every(filters, v => isEmpty(v))) return null;
  return (
    <AppliedFilters
      onResetAll={() => {
        setFilters({
          risk_type: [],
          alert_level: [],
          status: [],
        });
      }}
    >
      {filters.status.map((r, i) => (
        <AppliedFiltersBadge
          key={i}
          onRemove={() => setFilters({ ...filters, status: filters.status.filter(v => v !== r) })}
        >
          Status: {ruleStatusOptions.find(s => s.value === String(r)).label}
        </AppliedFiltersBadge>
      ))}
      <RiskAppliedFilter
        risks={filters.alert_level}
        setRisks={r => {
          setFilters({ ...filters, alert_level: r });
        }}
      />
      {filters.risk_type.map((riskType, i) => (
        <AppliedFiltersBadge
          key={i}
          onRemove={() =>
            setFilters({ ...filters, risk_type: filters.risk_type.filter(v => v !== riskType) })
          }
        >
          Type: {riskTypesOptions.find(rT => rT.value === String(riskType)).label}
        </AppliedFiltersBadge>
      ))}
    </AppliedFilters>
  );
};

export default PolicyRuleListAppliedFilters;
