import { FC } from 'react';
import AppliedFilters, { BasicAppliedFilter } from '../../../AppliedFilters';
import { IFilters, defaultStateFilters } from './CounterpartyFilter';
import { EntityAppliedFilters } from '../../../AppliedFilters';
import { every, isEmpty } from 'lodash';
import { getEntityTypeBySubType } from '../../../../utils/helpers/entity';
import classNames from 'classnames';

interface CounterpartyAppliedFiltersProps {
  filters: IFilters;
  setFilters: (filters: IFilters) => void;
  className?: string;
}

const CounterpartyAppliedFilters: FC<CounterpartyAppliedFiltersProps> = props => {
  const { filters, setFilters, className } = props;
  if (every(filters, v => isEmpty(v))) return null;
  return (
    <AppliedFilters
      onResetAll={() => {
        setFilters(defaultStateFilters);
      }}
      className={classNames('mt-2', className)}
    >
      <EntityAppliedFilters
        isCounterparty
        entity={filters.entities}
        setEntity={e => {
          setFilters({ ...filters, entities: e });
        }}
      />
      <EntityAppliedFilters
        isCounterparty
        entity={filters.entitySubtypes.map(e => `${getEntityTypeBySubType(e)} -> ${e}`)}
        setEntity={e => {
          setFilters({ ...filters, entitySubtypes: e });
        }}
      />
      <BasicAppliedFilter
        label='Entity Name'
        single
        value={[filters.entityName]}
        setValue={v => {
          setFilters({ ...filters, entityName: v[0] });
        }}
      />
    </AppliedFilters>
  );
};

export default CounterpartyAppliedFilters;
