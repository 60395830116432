import { FC } from 'react';
import { ArrowsClockwise } from '@phosphor-icons/react';
import Popover from '../Popover/Popover';

interface RefreshProps {
  onRefresh: () => void;
  tooltipText?: string;
}

const Refresh: FC<RefreshProps> = ({ onRefresh, tooltipText = 'Refresh now' }) => {
  return (
    <Popover
      data-testid='table-refresh'
      id='table-refresh'
      referenceContent={
        <ArrowsClockwise
          onClick={onRefresh}
          size={20}
          weight='bold'
          className='mx-2 cursor-pointer transition hover:rotate-180 hover:text-blue-600'
        />
      }
      isBasic
      className='text-2xs'
      popoverContent={tooltipText}
    />
  );
};

export default Refresh;
