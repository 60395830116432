import { XCircle, CheckCircle } from '@phosphor-icons/react';
import { FC } from 'react';
import BasicBadge from '../BasicBadge/BasicBadge';
import classNames from 'classnames';

export interface StatusBadgeProps {
  isActive: boolean;
  className?: string;
}

const PolicyStatusBadge: FC<StatusBadgeProps> = props => {
  const { isActive, className } = props;
  // text-gray-500 text-green-500
  return (
    <BasicBadge
      iconStart={
        isActive ? (
          <CheckCircle className='mr-2 text-green-500' size={12} weight='fill' />
        ) : (
          <XCircle className='mr-2 text-gray-500' size={12} weight='fill' />
        )
      }
      className={classNames(isActive ? 'bg-green-100' : 'bg-gray-200', 'text-gray-900', className)}
    >
      {props.isActive ? 'Live' : 'Deactivated'}
    </BasicBadge>
  );
};

export default PolicyStatusBadge;
