import { Plus } from '@phosphor-icons/react';
import { ICaseResponse } from '../../../api/dtos/case';
import { Button } from '../../../ui';
import AddressesTable from './AddressesTable';
import CustomersTable from './CustomersTable';
import TransactionsTable from './TransactionsTable';
import EmptyState from '@/assets/empty.svg';
import {
  useCaseGetAddresses,
  useCaseGetCustomers,
  useCaseGetTransactions,
} from '../../../api/case';
import { flattenInfiniteQueryResult } from '../../../utils/helpers/react-query.helper';
import CaseAddEntitiesModal from '../CaseAddEntitiesModal';
import { useState } from 'react';
import CompassLoader from '../../ui/components/Loader/CompassLoader';

interface CaseEntitiesProps {
  caseData: ICaseResponse;
}

const CaseEntities: React.FC<CaseEntitiesProps> = ({ caseData }) => {
  const customersQuery = useCaseGetCustomers(caseData.id);
  const transactionsQuery = useCaseGetTransactions(caseData.id);
  const addressesQuery = useCaseGetAddresses(caseData.id);

  const [customersCount] = flattenInfiniteQueryResult(customersQuery?.data);
  const [transactionsCount] = flattenInfiniteQueryResult(transactionsQuery?.data);
  const [addressesCount] = flattenInfiniteQueryResult(addressesQuery?.data);

  const isLoading =
    customersQuery.isLoading || transactionsQuery.isLoading || addressesQuery.isLoading;

  const [modal, setModal] = useState(false);

  if (isLoading) {
    return <CompassLoader />;
  }

  if (!customersCount && !transactionsCount && !addressesCount) {
    return (
      <>
        <div className='m-auto flex'>
          <img alt='Empty state' src={EmptyState} width='140' className='mr-6' />
          <div className='my-auto text-xs'>
            <div className='font-bold'>No Entities added yet!</div>
            <div className='mt-1 text-gray-500'>Entities added to the case will show up here.</div>
            <Button className='mt-4' variant='tertiary' onClick={() => setModal(true)}>
              <Plus className='mr-2' size={16} /> Add Entity
            </Button>
          </div>
        </div>
        <CaseAddEntitiesModal caseId={caseData.id} isOpen={modal} onClose={() => setModal(false)} />
      </>
    );
  }

  return (
    <div className='w-2/3 p-4'>
      <AddressesTable caseData={caseData} addressesQuery={addressesQuery} />
      <CustomersTable caseData={caseData} customersQuery={customersQuery} />
      <TransactionsTable caseData={caseData} transactionsQuery={transactionsQuery} />
    </div>
  );
};

export default CaseEntities;
