import { Button, Input } from '@ui/index';
import { MagnifyingGlass, X } from '@phosphor-icons/react';
import * as React from 'react';
import { debounce } from 'lodash';
import { Transition } from '@headlessui/react';

interface SearchProps {
  id?: string;
  value?: string;
  setValue?: React.Dispatch<React.SetStateAction<string>>;
  placeholder?: string;
  type?: 'input' | 'button';
  className?: string;
  minChars?: number;
  label?: string;
  autoComplete?: 'on' | 'off';
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>;
  showXButton?: boolean;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
}

const Search: React.FC<SearchProps> = props => {
  const {
    value,
    setValue,
    placeholder = 'Search',
    type = 'input',
    className,
    minChars = 0,
    showXButton = true,
    autoComplete = 'on',
    id,
  } = props;
  const [isInput, setIsInput] = React.useState<boolean>(type === 'input');
  const [inputValue, setInputValue] = React.useState<string>(value || '');
  const [inputFocused, setInputFocused] = React.useState<boolean>(false);

  const debouncedSearch = React.useMemo(
    () =>
      debounce((searchValue: string) => {
        if (setValue) {
          setValue(searchValue);
        }
      }, 300),
    [setValue]
  );

  React.useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);

  const handleChange = React.useCallback(
    (value: string) => {
      const trimmedValue = value.trim();
      setInputValue(value);
      if (trimmedValue.length < minChars) {
        debouncedSearch('');
      } else {
        debouncedSearch(trimmedValue);
      }
    },
    [debouncedSearch, minChars]
  );

  const handleInputChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const searchValue = e.target.value;
      handleChange(searchValue);
    },
    [handleChange]
  );

  return (
    <div className='relative'>
      <Transition
        as='div'
        show={isInput}
        enter='transition-opacity duration-300'
        enterFrom='opacity-0'
        enterTo='opacity-100'
        leave='transition-opacity duration-300'
        leaveFrom='opacity-100'
        leaveTo='opacity-0'
      >
        {isInput && (
          <>
            <Input
              labelText={props.label}
              labelClassNames='text-gray-500'
              id='search-input'
              type='text'
              className={`text-gray-400 focus-within:text-gray-400 ${className}`}
              onChange={handleInputChange}
              autoComplete={autoComplete}
              value={inputValue}
              placeholder={placeholder}
              iconStart={<MagnifyingGlass size={18} />}
              iconEnd={
                inputValue &&
                showXButton && (
                  <X size={20} className='cursor-pointer' onClick={() => handleChange('')} />
                )
              }
              onKeyDown={props.onKeyDown}
              onBlur={e => {
                setInputFocused(false);
                if (props.onBlur) {
                  props.onBlur(e);
                }
              }}
              onFocus={() => setInputFocused(true)}
            />
            {inputFocused && inputValue.length < minChars && (
              <div
                className={
                  'left-30 absolute z-20 mt-2 rounded-lg border border-solid border-gray-200 bg-white p-2 text-2xs font-medium text-gray-800 shadow-2xl'
                }
              >
                {`Please enter at least ${minChars} characters`}
              </div>
            )}
          </>
        )}
      </Transition>

      <Transition
        as='div'
        show={!isInput}
        enter='transition-opacity duration-300'
        enterFrom='opacity-0'
        enterTo='opacity-100'
        leave='transition-opacity duration-300'
        leaveFrom='opacity-100'
        leaveTo='opacity-0'
      >
        {!isInput && (
          <Button
            id={id}
            variant='tertiary'
            onClick={() => {
              setIsInput(true);
            }}
            className='py-2.5'
            iconStart={<MagnifyingGlass size={16} />}
          ></Button>
        )}
      </Transition>
    </div>
  );
};

export default Search;
