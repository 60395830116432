import { FlagBanner, X } from '@phosphor-icons/react';
import { BasicBadge } from '../../../../ui';
import { ellipsis } from '../../../../utils/helpers/helperFunctions';
import Popover from '../../../../ui/components/Popover/Popover';

interface CustomTagBadgeProps {
  type: number;
  label?: string;
  onRemove?: () => void;
  limit?: number;
}

const CustomTagBadge: React.FC<CustomTagBadgeProps> = ({ type, label, onRemove, limit }) => {
  const getBadgeLabel = () => {
    if (type === 0) return limit ? ellipsis(label, limit, true) : label;
    if (type === 1) return 'Whitelist';
    if (type === 2) return 'Blacklist';
  };
  const getBadgeClassName = () => {
    if (type === 0) return 'text-purple-800 bg-purple-100';
    if (type === 1) return 'text-gray-700 bg-gray-50 border border border-gray-700';
    if (type === 2) return 'text-gray-50 bg-gray-700';
  };

  const getBadgeIcon = () => {
    if (type === 0) return null;
    if (type === 1) return <FlagBanner className='mr-1' size={15} />;
    // mirror FlagBanner
    if (type === 2)
      return <FlagBanner className='mr-1' size={15} style={{ transform: 'scaleX(-1)' }} />;
  };

  return (
    <Popover
      className='rounded-md border bg-white p-2'
      referenceContent={
        <BasicBadge
          className={getBadgeClassName()}
          iconStart={getBadgeIcon()}
          iconEnd={
            type === 0 && onRemove && <X onClick={onRemove} className='ml-1 cursor-pointer' />
          }
        >
          {getBadgeLabel()}
        </BasicBadge>
      }
      popoverContent={label}
    />
  );
};

export default CustomTagBadge;
