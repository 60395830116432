import { AxiosError } from 'axios';
import { FC, useState } from 'react';
import { useInfiniteQuery, useMutation, useQueryClient } from 'react-query';
import { IErrorResponse } from '../../../api/dtos/common';
import { IUserApiKeyUpdateRequest } from '../../../api/dtos/profile';
import { profileApi } from '../../../api/profile';
import { Button } from '../../../ui';
import ConfirmationModal from '../../../ui/components/Modals/ConfirmationModal/ConfirmationModal';
import { TableCopyCell, TableDateTimeCell } from '../../../ui/components/Table/Cells';
import Table from '../../../ui/components/Table/Table';
import { toast } from '../../../ui/components/Toast';
import {
  buildInfiniteQueryTableProps,
  flattenInfiniteQueryResult,
  getNextPageParamByOffset,
} from '../../../utils/helpers/react-query.helper';

interface ApiKeysTableProps {}

const headerData = ['API Key', 'Created at', 'Updated at', 'Actions'];

const getApiQueryKey = 'ApiKeysTable.ProfileApi.getApiKeys';

const ApiKeysTable: FC<ApiKeysTableProps> = () => {
  const [openRevoke, setOpenRevoke] = useState<number>();
  const [openEnable, setOpenEnable] = useState<number>();

  const queryClient = useQueryClient();
  const queryResult = useInfiniteQuery(
    [getApiQueryKey],
    ({ pageParam }) => profileApi.getApiKeys({ offset: pageParam }),
    {
      getNextPageParam: getNextPageParamByOffset,
    } as unknown
  );

  const { mutate: generateApiKey, isLoading: isMutationLoading } = useMutation(
    profileApi.generateApiKey,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(getApiQueryKey);
        toast.success('New API key created');
      },
      onError: (error: AxiosError<IErrorResponse>) => {
        toast.error(error.response?.data?.detail);
      },
    }
  );

  const { mutate: updateApiKey } = useMutation(
    (args: [number, IUserApiKeyUpdateRequest]) => profileApi.updateApiKey(...args),
    {
      onMutate: async ([id, request]) => {
        await queryClient.cancelQueries(getApiQueryKey);
        const oldData = queryClient.getQueriesData(getApiQueryKey);

        queryClient.setQueryData(getApiQueryKey, (old: typeof queryResult.data) => {
          old?.pages?.forEach(
            p =>
              (p.data.results = p.data.results.map(r => {
                if (r.id === id) {
                  return {
                    ...r,
                    ...request,
                  };
                }

                return r;
              }))
          );
          return { ...old };
        });

        return { oldData };
      },
      onSuccess: () => {
        // queryResult.refetch();
        toast.success('API key is updated');
      },
      onError: (error: AxiosError<IErrorResponse>, newData, context) => {
        queryClient.setQueryData(getApiQueryKey, context?.oldData);
        toast.error(error.response?.data?.detail);
      },
      onSettled: () => {
        queryClient.invalidateQueries(getApiQueryKey);
      },
    }
  );

  const [count, results] = flattenInfiniteQueryResult(queryResult.data);

  const rows = count
    ? results?.map(i => {
        return {
          id: i.id,
          data: [
            <TableCopyCell key={i.key} value={i.key} />,
            <TableDateTimeCell key={i.key} value={i.created_at} />,
            <TableDateTimeCell key={i.key} value={i.updated_at} />,
            <span key={i.key}>
              {i.is_active ? (
                <Button
                  id='apikeys-revoke'
                  variant='irreversible'
                  className='!px-2 !py-0 leading-5'
                  onClick={() => setOpenRevoke(i.id)}
                >
                  Revoke
                </Button>
              ) : (
                <Button
                  id='apikeys-enable'
                  variant='primary'
                  className='!px-2 !py-0 leading-5'
                  onClick={() => setOpenEnable(i.id)}
                >
                  Enable
                </Button>
              )}
            </span>,
          ],
        };
      })
    : [];

  return (
    <>
      <ConfirmationModal
        open={openRevoke != null}
        data={openRevoke}
        onCancel={() => setOpenRevoke(null)}
        onConfirm={id => {
          setOpenRevoke(null);
          updateApiKey([id as number, { is_active: false }]);
        }}
        title={'Revoke API Key'}
        body={
          <>
            <span>Are you sure you want to revoke this API Key?</span>
            <br />
            <br />
            <span>
              Revoking this API key will mean that you can no longer use this key in any production
              or development environment
            </span>
          </>
        }
      />

      <ConfirmationModal
        open={openEnable != null}
        data={openEnable}
        onCancel={() => setOpenEnable(null)}
        onConfirm={id => {
          setOpenEnable(null);
          updateApiKey([id as number, { is_active: true }]);
        }}
        title={'Enable API Key'}
        body={
          <>
            <p>Are you sure you want to enable this API Key?</p>
          </>
        }
      />

      <div className='rounded-lg border'>
        <Table
          title='ACTIVE API KEYS'
          headerData={headerData}
          rows={rows}
          isLoading={queryResult.isLoading}
          {...buildInfiniteQueryTableProps(queryResult)}
          headerActions={
            <Button
              id='apikeys-generateNew'
              className='!px-2 !py-1'
              onClick={() => generateApiKey()}
              disabled={isMutationLoading}
            >
              Generate new API key
            </Button>
          }
        />
      </div>
    </>
  );
};

export default ApiKeysTable;
