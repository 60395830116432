import { Dialog, DialogPanel } from '@headlessui/react';
import { FC, Fragment, useState } from 'react';
import Tab from '../../ui/components/Tab/Tab';
import DropZone from './DropZone';
import { useUpload } from '../../../api/upload';
import {
  buildInfiniteQueryTableProps,
  flattenInfiniteQueryResult,
} from '../../../utils/helpers/react-query.helper';
import Table from '../../../ui/components/Table/Table';
import { BasicBadge, Button } from '../../../ui';
import { useQueryClient } from 'react-query';
import { useGetUploadHistoryRowData } from './UploadHistoryData';
import {
  CaretDown,
  CaretUp,
  ClockCounterClockwise,
  FileArrowUp,
  UploadSimple,
} from '@phosphor-icons/react';
import { Currencies } from '../../../utils/constants/constants';
import { useTheme } from '../../../utils/helpers/theme';

interface FileUploadsProps {}

const FileUploads: FC<FileUploadsProps> = () => {
  const { themeColor } = useTheme();
  const [tab, setTab] = useState<number>(0);
  const [insideTab, setInsideTab] = useState<number>(0);
  const [open, setOpen] = useState<boolean>(false);
  const [isGuidelinesVisible, setIsGuidelinesVisible] = useState<boolean>(false);
  const queryClient = useQueryClient();

  const filesQuery = useUpload({ query: insideTab }, { enabled: open });
  const [, files] = flattenInfiniteQueryResult(filesQuery?.data);

  const onSuccess = () => {
    queryClient.resetQueries(['UploadApi.getFiles', insideTab]);
    setTab(1);
  };

  // const tabs: ITabValue[] = [
  //   {
  //     label: (
  //       <div className='flex'>
  //         <FileArrowUp size={18} color='#6b7280' />
  //         <p className='ml-2 items-center'>Upload a File</p>
  //       </div>
  //     ),
  //     hidden: false,
  //   },
  //   {
  //     label: (
  //       <div className='flex'>
  //         <ClockCounterClockwise size={18} color='#6b7280' />
  //         <p className='ml-2 items-center'>Upload History</p>
  //       </div>
  //     ),
  //     hidden: false,
  //   },
  // ];

  const insideTabs = [
    { label: 'Address', hidden: false },
    { label: 'Transaction', hidden: false },
  ];

  const headerData = ['File Name', 'Screened', 'Status', 'Risk Detected', 'Date', ''];

  const rows = useGetUploadHistoryRowData({ files });

  const getActiveCurrencies = () => {
    return Currencies.filter(currency => !currency.deprecated);
  };

  const guidelinesRowData = insideTab => {
    const tab = insideTab === 0 ? 'address' : 'transaction';
    return [
      {
        id: 1,
        data: [<div key={1}>identifier</div>, <div key={1}> The {tab} to be screened</div>],
      },
      {
        id: 1,
        data: [
          <div key={1}>currency</div>,
          <div key={1}>
            {' '}
            The blockchain the {tab} belongs to
            <p>
              Supported :{' '}
              {getActiveCurrencies().map(c => (
                <BasicBadge
                  key={c.currency_short}
                  className='mx-1 my-0.5 rounded-full bg-gray-100 px-[8px] py-0.5 !text-2xs !font-normal text-gray-800'
                >
                  {c.label}
                </BasicBadge>
              ))}
            </p>
          </div>,
        ],
      },
      {
        id: 1,
        data: [
          <div key={1}>customer_id</div>,
          <div key={1}> The customer ID this {tab} belongs to (optional)</div>,
        ],
      },
      {
        id: 1,
        data: [
          <div key={1}>type</div>,
          <div key={1}>
            <p>Address type of the customer</p>
            <p>(required only if customer ID is provided)</p>
            <div>
              Options:{' '}
              <BasicBadge
                key={1}
                className='mx-1 my-0.5 rounded-full bg-gray-100 px-[8px] py-0 !text-2xs text-gray-800'
              >
                Deposit
              </BasicBadge>
              <BasicBadge
                key={2}
                className='mx-1 my-0.5 rounded-full bg-gray-100 px-[8px] py-0 !text-2xs text-gray-800'
              >
                Withdrawal
              </BasicBadge>
            </div>
          </div>,
        ],
      },
      ...(insideTab === 1
        ? [
            {
              id: 1,
              data: [
                <div key={1}>address</div>,
                <div key={1}>
                  <div>Deposit or Withdrawal Address</div>
                  <div>(required only if customer ID is provided)</div>
                </div>,
              ],
            },
          ]
        : []),
      {
        id: 1,
        data: [
          <div key={1}>method</div>,
          <div key={1}>
            {' '}
            This field must be set to ARCHIVE for {tab === 'address'
              ? 'addresses'
              : 'transactions'}{' '}
            you wish to archive. Else this value can be empty.
          </div>,
        ],
      },
    ];
  };

  return (
    <div
      id='header-fileUploads'
      data-testid='header-fileUploads'
      style={{ backgroundColor: themeColor().secondary }}
      className='my-px mr-3 flex cursor-pointer items-center rounded-lg px-3 text-white outline-1 hover:outline hover:outline-white focus:outline-none'
      onClick={() => {
        setOpen(true);
        setTab(0);
      }}
    >
      <div>
        <UploadSimple size={20} color='#fff' />
      </div>
      <Dialog open={open} onClose={() => setOpen(false)} className='relative z-50'>
        <div className='fixed inset-0 bg-black/50' aria-hidden='true' />
        <div className='fixed right-[20px] top-[40px] flex w-[800px] rounded-xl pt-2'>
          <DialogPanel
            className={`max-h-[calc(100vh-55px)] w-full ${tab === 1 ? 'overflow-auto' : ''} ${isGuidelinesVisible && tab === 0 ? 'overflow-y-auto' : ''} rounded-xl bg-white`}
          >
            <div className='flex justify-around py-4'>
              <div
                id='fileUploads-tab1'
                data-testid='fileUploads-tab1'
                className={`mx-auto flex w-1/2 cursor-pointer justify-center ${
                  tab === 0 ? 'border-b-2 border-blue-500 text-blue-500' : ''
                }`}
                onClick={() => setTab(0)}
              >
                <FileArrowUp size={18} weight='bold' color={tab === 0 ? '#2563eb' : '#6b7280'} />
                <p className='mb-3 ml-2 items-center text-sm font-medium'>Upload a File</p>
              </div>

              <div
                id='fileUploads-tab2'
                data-testid='fileUploads-tab2'
                className={`flex w-1/2 cursor-pointer justify-center ${
                  tab === 1 ? 'border-b-2 border-blue-500 text-blue-500' : ''
                }`}
                onClick={() => setTab(1)}
              >
                <ClockCounterClockwise
                  size={18}
                  weight='bold'
                  color={tab === 1 ? '#2563eb' : '#6b7280'}
                />
                <p className='ml-2 items-center text-sm font-medium'>Upload History</p>
              </div>
            </div>
            <div className='flex justify-between'>
              <Tab
                values={insideTabs}
                type={'primary'}
                onChange={setInsideTab}
                listClassName=' p-3 rounded-md'
              ></Tab>
              <Button
                id='fileUploads-refresh'
                data-testid='fileUploads-refresh'
                variant='tertiary'
                onClick={() => {
                  queryClient.resetQueries(['UploadApi.getFiles', insideTab]);
                }}
                className='my-auto mr-5 focus:outline-0'
              >
                Refresh
              </Button>
            </div>
            {tab === 0 ? (
              <>
                <DropZone file_type={insideTab} onSuccess={onSuccess} />
                {!isGuidelinesVisible ? (
                  <div
                    className='mb-4 ml-4 flex cursor-pointer'
                    id='fileUploads-viewGuidelines'
                    data-testid='fileUploads-viewGuidelines'
                    onClick={() => setIsGuidelinesVisible(true)}
                  >
                    <CaretDown size={15} color='#D1D5DB' className='mr-2' />
                    <p className='text-xs text-gray-500'>View Upload Guidelines</p>
                  </div>
                ) : (
                  <div className='mx-4 leading-6'>
                    <div
                      className='flex cursor-pointer'
                      id='fileUploads-hideGuidelines'
                      data-testid='fileUploads-hideGuidelines'
                      onClick={() => setIsGuidelinesVisible(false)}
                    >
                      <CaretUp size={15} color='#D1D5DB' className='mr-2' />
                      <p className='text-xs text-gray-500'>Hide Upload Guidelines</p>
                    </div>
                    <Table
                      className='mb-3'
                      headerData={['Column', 'Description']}
                      rows={guidelinesRowData(insideTab)}
                      // height={500}
                      isSelectable
                      headerWidths={[20, 80]}
                      fontClass='text-2xs text-gray-500 border border-gray-200'
                    />
                  </div>
                )}
              </>
            ) : (
              <div>
                <Table
                  headerData={headerData}
                  rows={rows}
                  height={450}
                  isSelectable
                  headerWidths={[25, 10, 15, 25, 15, 10]}
                  fontClass='text-[11px]'
                  {...buildInfiniteQueryTableProps(filesQuery)}
                />
              </div>
            )}
          </DialogPanel>
        </div>
      </Dialog>
    </div>
  );
};

export default FileUploads;
