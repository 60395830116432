import { FC } from 'react';
import MetaData from '../../../layouts/MetaData';
import { RiskBadge } from '../../../ui';
// import { useSearchParams } from 'react-router-dom';
import Breadcrumb from '../../ui/components/BreadCrumb';
import { useRouter } from '../../../modules/router/RouterProvider';
import CustomerMetadataHeader from './CustomerMetadataHeader';
import { ICustomerResponse } from '../../../api/dtos/customer';
import { formatDateShort } from '../../../utils/helpers/date';
import { useAuth } from '../../../modules/auth';

interface CustomerMetadataProps {
  customer: ICustomerResponse;
  alerts?: number;
}

const CustomerMetadata: FC<CustomerMetadataProps> = props => {
  const { customer, alerts } = props;

  const { getQueryParams } = useRouter();
  const {
    state: {
      userProfile: { timezone },
    },
  } = useAuth();
  const { caseId, entityId, refCase } = getQueryParams();

  const isCaseReferenced = caseId && refCase && entityId;

  const metaDetails = [
    { label: 'Risk Level', value: <RiskBadge risk={customer.risk_level} /> },
    { label: 'Open Alerts', value: alerts || 0 },
    { label: 'First Txn.', value: formatDateShort(customer.earliest_transaction_time, timezone) },
    { label: 'Last Txn.', value: formatDateShort(customer.latest_transaction_time, timezone) },
    { label: 'Added On', value: formatDateShort(customer.created_at, timezone) },
  ];

  return (
    <MetaData
      title='CUSTOMER'
      preHeader={
        isCaseReferenced && (
          <Breadcrumb
            items={[{ label: caseId, to: `/cases/${refCase}` }, { label: customer.customer_id }]}
            className='mb-2'
          />
        )
      }
      header={<CustomerMetadataHeader customer={customer} />}
      metaDetails={metaDetails}
    />
  );
};

export default CustomerMetadata;
