import * as React from 'react';

import { getErrorMessage } from '../../../utils/helpers/helperFunctions';

import { caseApi } from '../../../api/case';

import { ICaseBulkActionFilters } from '../../../views/dashboard/cases/CaseList';

import { BasicModal, Button, Dropdown, DropdownOption } from '../../../ui';
import { toast } from '../../../ui/components/Toast';

interface Props {
  isOpen: boolean;
  onClose: () => void;

  selectedCases: number[];
  isAllCaseSelected: boolean;

  bulkActionFilter: ICaseBulkActionFilters;

  caseUsers: DropdownOption[];

  refetchList: () => void;
}

const AssignAgentModal: React.FC<Props> = ({
  isOpen,
  onClose,
  selectedCases,
  isAllCaseSelected,
  bulkActionFilter,
  caseUsers,
  refetchList,
}) => {
  const [agent, setAgent] = React.useState<DropdownOption>(null);

  const assignAgent = async () => {
    try {
      const res = await caseApi.assignAgent(
        {
          cases: isAllCaseSelected ? [] : selectedCases,
          assigned_to: Number(agent.value),
        },
        bulkActionFilter
      );

      if (res.status === 200) {
        toast.success('Cases Updated Successfully');

        refetchList();
        onClose();
      }
    } catch (error) {
      if (getErrorMessage(error)?.length) {
        getErrorMessage(error).forEach(err => toast.error(err));
      } else {
        toast.error(getErrorMessage(error));
      }
    }
  };

  return (
    <BasicModal
      size='sm'
      open={isOpen}
      onClose={onClose}
      modalTitle='Assign To'
      modalBody={
        <div className='z-[100000]'>
          <form
            className='flex flex-col'
            onSubmit={e => {
              e.preventDefault();
              assignAgent();
            }}
          >
            <Dropdown
              labelText='Assign To'
              value={agent}
              onChange={setAgent}
              placeholder='Choose a case agent'
              options={caseUsers}
            />
            <div className='mt-4 flex justify-end gap-2'>
              <Button variant='tertiary' onClick={onClose}>
                Cancel
              </Button>
              <Button type='submit'>Yes</Button>
            </div>
          </form>
        </div>
      }
    />
  );
};

export default AssignAgentModal;
