/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { FilterPanel } from '../FilterPanel';
import RiskFilter from '../Filters/RiskFilter';
import CalendarFilter, { IDateRangeString } from '../Filters/CalendarFilter';
import SingleSelect from '../ui/components/Select/SingleSelect';
import { IClassifierResponse } from '../../api/dtos/classifier';
import { AxiosResponse } from 'axios';
import RuleIdFilter from '../Filters/RuleIdFilter';
import { UseInfiniteQueryResult, UseQueryResult } from 'react-query';
import StageNameFilter from '../Filters/StageFilter';
import AssignedToFilter from '../Filters/AssignedToFilter';
import { IStageListLiteResponse } from 'src/api/dtos/stages';

export interface IFilters {
  level: number[];
  start_time: string;
  end_time: string;
  rule_id: string;
  rule_name: string;
  policy_category: string;
  policy_type: number;
  status: any;
  assigned_to: any;
  assigned_to_name: any;
}

export const defaultAlertListFilters: IFilters = {
  level: [],
  status: [],
  assigned_to: [],
  assigned_to_name: [],
  start_time: null,
  end_time: null,
  rule_id: null,
  rule_name: null,
  policy_category: null,
  policy_type: null,
};

export const policyTypeCategoryOptions = [
  { label: 'Address > Source of Funds', value: '1,1' },
  { label: 'Address > Behaviour', value: '2,1' },
  { label: 'Address > Source of Funds Advanced', value: '3,1' },
  { label: 'Transaction > Source of Funds', value: '1,0' },
  { label: 'Customer > Source of Funds', value: '1,2' },
  { label: 'Customer > Behaviour', value: '2,2' },
  { label: 'Customer > Source of Funds Advanced', value: '3,2' },
];

interface Props {
  filters: IFilters;
  onApply: (filters: IFilters) => void;
  onReset: () => void;
  disabled?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ruleNamesQuery: UseInfiniteQueryResult<AxiosResponse<IClassifierResponse, any>, unknown>;
  stagesListLiteQuery: UseQueryResult<AxiosResponse<IStageListLiteResponse>>;
}

const AlertListFilter: React.FC<Props> = ({
  filters,
  onApply,
  onReset,
  disabled,
  ruleNamesQuery,
  stagesListLiteQuery,
}) => {
  const [level, setLevel] = React.useState<number[]>();
  const [transactionDate, setTransactionDate] = React.useState<IDateRangeString>();
  const [ruleId, setRuleId] = React.useState<string>();
  const [ruleName, setRuleName] = React.useState<string>();
  const [category, setCategory] = React.useState<string>();
  const [policyType, setPolicyType] = React.useState<number>();
  const [workflow_stage, setStage] = React.useState<(number | string)[]>([]);
  const [assignedTo, setAssignedTo] = React.useState<(number | string)[]>([]);
  const [assigneesList, setAssignees] = React.useState<string[]>([]);

  const handleApply = () => {
    onApply({
      ...filters,
      level,
      start_time: transactionDate?.from,
      end_time: transactionDate?.to,
      rule_id: ruleId,
      rule_name: ruleName,
      policy_category: category,
      policy_type: policyType,
      status: workflow_stage,
      assigned_to: assignedTo,
      assigned_to_name: assigneesList,
    });
  };

  const handleReset = () => {
    setLevel([]);
    setTransactionDate(null);
    onReset();
    setRuleId(null);
    setRuleName(null);
    setCategory(null);
    setPolicyType(null);
    setStage([]);
    setAssignedTo([]);
    setAssignees([]);
  };

  React.useEffect(() => {
    setLevel(filters.level);
    setTransactionDate({
      from: filters.start_time,
      to: filters.end_time,
    });
    setRuleId(filters.rule_id);
    setRuleName(filters.rule_name);
    setCategory(filters.policy_category);
    setPolicyType(filters.policy_type);
    setStage(filters.status);
    setAssignedTo(filters.assigned_to);
    setAssignees(filters.assigned_to_name);
  }, [filters]);

  return (
    <FilterPanel
      id='alertList-filter'
      onApply={handleApply}
      onReset={handleReset}
      disabled={disabled}
    >
      <RiskFilter value={level} setValue={setLevel} />
      <StageNameFilter
        is_status_stage
        value={workflow_stage}
        setValue={setStage}
        stagesListLiteQuery={stagesListLiteQuery}
      />
      <AssignedToFilter
        assigneesList={assigneesList}
        setAssignees={setAssignees}
        value={assignedTo}
        setValue={setAssignedTo}
      />
      <CalendarFilter label='Created At' value={transactionDate} setValue={setTransactionDate} />
      <RuleIdFilter
        value={ruleId}
        ruleName={ruleName}
        setValue={setRuleId}
        setName={setRuleName}
        ruleNamesQuery={ruleNamesQuery}
      />
      <SingleSelect
        options={policyTypeCategoryOptions}
        handleChange={(value: (typeof policyTypeCategoryOptions)[0]) => {
          setCategory(value?.value.split(',')[0]);
          setPolicyType(Number(value?.value.split(',')[1]));
        }}
        value={policyTypeCategoryOptions.find(
          o => o.value.split(',')[0] === category && Number(o.value.split(',')[1]) === policyType
        )}
        label='Policy Type & Category'
        labelClassName='text-sm'
      />
    </FilterPanel>
  );
};

export default AlertListFilter;
