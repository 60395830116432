import { FC } from 'react';
import { Info, UserCircle } from '@phosphor-icons/react';

import { getEntityDescription } from '../../utils/helpers/entity';
import { ITransactionResponse } from '../../api/dtos/transaction';
import { ICustomerAddress, ICustomerDepositWithdrawalResult } from '../../api/dtos/customer';

import IdentifierEllipsis from '../ui/components/Currency/IdentifierEllipsis';
import Popover from '../../ui/components/Popover/Popover';
import EntityBadge from '../ui/components/Badge/EntityBadge';
import EntityWithSubtypeBadge from '../ui/components/EntityTypeBadge';
import { BasicBadge } from '../../ui';

interface IOriginatorBeneficiaryProps {
  transaction: ICustomerDepositWithdrawalResult | ITransactionResponse;
  type: 'originator' | 'beneficiary';
  isInCustomerDetails?: boolean;
}

const OriginatorBeneficiary: FC<IOriginatorBeneficiaryProps> = ({
  transaction,
  type,
  isInCustomerDetails = false,
}) => {
  const isCustomer = type === 'originator' ? transaction.type === 2 : transaction.type === 1;
  const currentAddresses =
    transaction[type === 'originator' ? 'from_addresses' : 'to_addresses'] || [];
  const addresses = currentAddresses.filter(address => {
    return (
      address.exposure_type !== 'indirect' &&
      ((address as ICustomerAddress).address || address.tag_name_verbose)
    );
  });
  const showAddress = (addresses[0] as ICustomerAddress)?.address && !isInCustomerDetails;

  const Entity = (entity: ICustomerAddress) => {
    if (!entity) return null;
    if (!entity.tag_name_verbose) return isInCustomerDetails ? 'No entities detected' : 'Unknown';
    const entityDescription = getEntityDescription(
      entity.tag_type_verbose,
      entity.tag_subtype_verbose
    );

    return (
      <div className='my-auto'>
        {showAddress ? (
          <IdentifierEllipsis identifier={(addresses[0] as ICustomerAddress)?.address} />
        ) : (
          <div>
            <Popover
              placement='bottom'
              referenceContent={<EntityBadge entity={entity} dot fixedWidth />}
              popoverContent={
                <div
                  className={`w-[400px] whitespace-normal bg-white p-2 shadow ${
                    entityDescription ? '' : 'hidden'
                  }`}
                >
                  <div className='flex'>
                    <div className='mb-auto mr-2 mt-2 rounded-full bg-blue-100 p-2'>
                      <Info size={25} className='my-auto rounded-full bg-blue-100 text-blue-600' />
                    </div>
                    <div>
                      <div className='my-1 text-sm font-medium'>{entity.tag_name_verbose}</div>
                      <EntityWithSubtypeBadge
                        type={entity.tag_type_verbose}
                        subtype={entity.tag_subtype_verbose}
                        isPopoverHidden
                      />
                      <div className='text-sm text-gray-500'>{entityDescription}</div>
                    </div>
                  </div>
                </div>
              }
            />
          </div>
        )}
      </div>
    );
  };

  const Entities: FC<{ entities: ICustomerAddress[] }> = ({ entities }) => {
    return (
      <div className='flex'>
        <Entity {...entities[0]} />
        {entities.length > 1 && (
          <Popover
            referenceContent={
              <BasicBadge className='ml-1 bg-gray-100'>
                <span className='text-3xs'>+{entities.length - 1}</span>
              </BasicBadge>
            }
            containerClassName='my-auto'
            popoverContent={
              <div className='grid max-w-[200px] grid-cols-1 gap-1 border bg-white p-2 shadow-md'>
                {entities.slice(1).map((entity, i) => (
                  <Entity key={i} {...entity} />
                ))}
              </div>
            }
          />
        )}
      </div>
    );
  };

  return (
    <div className='flex gap-1'>
      {isCustomer ? (
        <div title={transaction.customer_id} className={`${transaction.customer_id} flex`}>
          <UserCircle size={16} className='mr-1' />
          <span>
            {transaction.customer_id.length > 8
              ? `${transaction.customer_id.substring(0, 8)}...`
              : transaction.customer_id}
          </span>
        </div>
      ) : !addresses.length ? (
        isInCustomerDetails ? (
          'No entities detected'
        ) : (
          'Unknown'
        )
      ) : (
        <Entities entities={addresses} />
      )}
    </div>
  );
};

export default OriginatorBeneficiary;
