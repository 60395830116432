import CustomSelect, { IOption } from './CustomSelect';

import { FC, useState } from 'react';
import { useWorkSpaceGetWorkFlowMembers } from '../../api/workspaces';
import {
  buildInfiniteQueryTableProps,
  flattenInfiniteQueryResult,
} from '../../utils/helpers/react-query.helper';
import { useDebounce } from 'use-debounce';
import UserBadge from '../../ui/components/Badges/UserBadge/UserBadge';

interface AssignedToFilterProps {
  value: (number | string)[];
  assigneesList?: string[];
  setAssignees?: (assignees: string[]) => void;
  setValue: (assignees: (number | string)[]) => void;
}

const AssignedToFilter: FC<AssignedToFilterProps> = props => {
  const { value, assigneesList, setAssignees, setValue } = props;
  const [search, setSearch] = useState<string>('');
  const [debouncedSearch] = useDebounce(search, 500);
  const assignedToOptionsQuery = useWorkSpaceGetWorkFlowMembers({
    q: debouncedSearch,
    is_active: true,
    id: 2,
  });

  const getAssignedToDataOptions = assigneeData => {
    const assigneeOptionsData = assigneeData?.map(r => {
      return {
        id: r.user_id,
        value: r.email,
        label: r.email,
      };
    });
    return assigneeOptionsData;
  };
  const [, assigneeData] = flattenInfiniteQueryResult(assignedToOptionsQuery.data);

  const assigneesOption = getAssignedToDataOptions(assigneeData);
  const [assignee, setAssignee] = useState<IOption[]>(
    value && assigneesList
      ? assigneesList.map((item, index) => ({ label: item, value: item, id: value[index] }))
      : value && value.length
        ? assigneesOption?.filter(r => value?.includes(r.id))
        : []
  );
  const handleChange = assignees => {
    setValue(assignees.map(r => r.id));
    setAssignees && setAssignees(assignees.map(r => r.label));
    setAssignee(assignees);
  };

  const getCustomLabelAssignee = (option: IOption) => {
    return <UserBadge className='bg-blue-100 text-blue-800' label={option?.label} />;
  };

  return (
    <>
      <CustomSelect
        label='Assigned To'
        placeholder='Select Assignees'
        options={assigneesOption}
        handleChange={handleChange}
        value={assignee}
        getCustomLabel={getCustomLabelAssignee}
        getCustomOptionLabel={getCustomLabelAssignee}
        asyncPaginate
        {...buildInfiniteQueryTableProps(assignedToOptionsQuery)}
        isSearchable
        setSearch={setSearch}
      />
    </>
  );
};

export default AssignedToFilter;
