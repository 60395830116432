import { FC, useMemo } from 'react';
import AppliedFilters, { BasicAppliedFilter } from '../../../AppliedFilters';
import { defaultStateFilters } from './AlertFilter';
import RiskAppliedFilter from '../../../AppliedFilters/RiskAppliedFilter';
import { IAlertFiltersUpdate } from '../../../../api/dtos/alert';
import DateAppliedFilter from '../../../AppliedFilters/DateAppliedFilter';
import RiskTypeAppliedFilter from '../../../AppliedFilters/RiskTypeAppliedFilter';
import classNames from 'classnames';
import { Button } from '../../../../ui/components/Button';
import { UserPlus } from '@phosphor-icons/react';
import AppliedFiltersBadge from '../../../../ui/components/Badges/AppliedFiltersBadge/AppliedFiltersBadge';
import { IStageOptions } from './AlertButtonPanel/AlertButtonPanel';

interface AlertAppliedFiltersProps {
  count: number;
  filters: IAlertFiltersUpdate;
  selectedAlerts: number[];
  selectedText: string;
  stageOptions: IStageOptions[];
  isAllAlertsSelected: boolean;
  setFilters: (filters: IAlertFiltersUpdate) => void;
  setSelectedAlerts: (addresses: number[]) => void;
  setIsAllAlertsSelected: (boolean) => void;
  selectAllAlerts: (boolean) => void;
  setOpenModal: (val: string) => void;
  setIsBulkUpdate: (val: boolean) => void;
}

const AlertAppliedFilters: FC<AlertAppliedFiltersProps> = props => {
  const {
    count,
    filters,
    selectedAlerts,
    selectedText,
    stageOptions,
    isAllAlertsSelected,
    setFilters,
    setSelectedAlerts,
    setIsAllAlertsSelected,
    selectAllAlerts,
    setOpenModal,
    setIsBulkUpdate,
  } = props;

  const isFilterApplied = useMemo(() => {
    if (filters.level?.length) return true;
    if (filters.rule_id?.length) return true;
    if (filters.rule_name?.length) return true;
    if (filters.risk_type?.length) return true;
    if (filters.rule_condition_type?.length) return true;
    if (filters.status?.length) return true;
    if (filters.assigned_to?.length) return true;
    if (filters.transaction_end_date) return true;
    if (filters.transaction_start_date) return true;
    return false;
  }, [filters]);
  return (
    <div
      className={classNames({
        '-mx-4 -mb-4 mt-2 flex justify-end border-t bg-gray-50':
          selectedAlerts?.length > 0 || isFilterApplied,
      })}
    >
      {isFilterApplied && (
        <AppliedFilters
          className='grow py-2'
          onResetAll={() => {
            setFilters(defaultStateFilters);
          }}
        >
          <RiskAppliedFilter
            risks={filters.level}
            setRisks={r => setFilters({ ...filters, level: r })}
          />
          <BasicAppliedFilter
            label='Rule Name'
            value={filters.rule_name}
            setValue={v => {
              setFilters({ ...filters, rule_name: v });
            }}
          />
          {filters.status?.map((stage, i) => (
            <AppliedFiltersBadge
              key={i}
              onRemove={() => {
                setFilters({
                  ...filters,
                  status: filters.status.filter(r => r !== stage),
                });
              }}
            >
              Status:{' '}
              {stage === 'None' ? '-NA-' : stageOptions?.find(item => item.id === stage)?.label}
            </AppliedFiltersBadge>
          ))}
          {filters.assigned_to_name?.map((assignee, i) => (
            <AppliedFiltersBadge
              key={i}
              onRemove={() => {
                setFilters({
                  ...filters,
                  assigned_to_name: filters.assigned_to_name.filter((_, index) => index !== i),
                  assigned_to: filters.assigned_to.filter((_, index) => index !== i),
                });
              }}
            >
              Assignee: {assignee}
            </AppliedFiltersBadge>
          ))}
          <RiskTypeAppliedFilter
            riskTypes={filters.risk_type}
            setRiskTypes={r => setFilters({ ...filters, risk_type: r })}
          />
          <BasicAppliedFilter
            label='Exposure Type'
            value={filters.rule_condition_type}
            setValue={v => {
              setFilters({ ...filters, rule_condition_type: v });
            }}
          />
          <DateAppliedFilter
            label='Transaction Date'
            from={filters.transaction_start_date}
            to={filters.transaction_end_date}
            onRemove={() =>
              setFilters({ ...filters, transaction_start_date: '', transaction_end_date: '' })
            }
          />
        </AppliedFilters>
      )}
      {selectedAlerts?.length > 0 && (
        <div className='flex min-w-[600px] items-center justify-end gap-3 py-2 pr-4 text-2xs'>
          <div>{selectedText}</div>
          <Button
            id='alert-assignee'
            variant='secondary'
            buttonSize='md'
            iconStart={<UserPlus weight='fill' size={15} />}
            onClick={() => {
              setIsBulkUpdate(true);
              setOpenModal('assignee');
            }}
          />
          <Button
            id='alert-status'
            variant='secondary'
            buttonSize='md'
            onClick={() => {
              setIsBulkUpdate(true);
              setOpenModal('status');
            }}
          >
            Update Status
          </Button>
          {!isAllAlertsSelected && count <= 100 && (
            <button
              id='alert-selectAll'
              data-testid='alert-selectAll'
              className='text-blue-500'
              onClick={() => {
                selectAllAlerts(true);
                setIsAllAlertsSelected(true);
              }}
            >
              Select all {count} alerts
            </button>
          )}
          <div
            id='alert-clear'
            data-testid='alert-clear'
            className='cursor-pointer text-blue-500'
            onClick={() => {
              setSelectedAlerts([]);
              setIsAllAlertsSelected(false);
            }}
          >
            Clear selection
          </div>
        </div>
      )}
    </div>
  );
};

export default AlertAppliedFilters;
