import CustomSelect, { IOption } from './CustomSelect';
import { FC, useState } from 'react';
import CurrencyBadge from '../ui/components/Badge/CurrencyBadge';
import { getCurrencyFromCode } from '../../utils/helpers/currency';
import { Currencies } from '../../utils/constants/constants';

interface CurrencyFilterProps {
  value: number[];
  setValue: (risks: number[]) => void;
}

const CurrencyFilter: FC<CurrencyFilterProps> = props => {
  const { value, setValue } = props;

  const getLabel = (option: IOption) => {
    const label = getCurrencyFromCode(Number(option.value)).currency_short;
    return <CurrencyBadge currency={Number(option.value)} label={label} />;
  };

  const getOptionLabel = (option: IOption) => {
    const currentCurrency = getCurrencyFromCode(Number(option.value));
    return (
      <CurrencyBadge
        currency={Number(option.value)}
        label={`${currentCurrency.currency_short} • ${currentCurrency.label}`}
        transparent
      />
    );
  };

  const currencyOptions = Currencies.map(c => {
    return {
      value: c.value.toString(),
      label: c.currency_short + c.label,
    };
  });

  let selectedCurrencies = [];
  if (value && value.length) {
    selectedCurrencies = currencyOptions.filter(c => value.includes(Number(c.value)));
  }
  const [currencies, setCurrencies] = useState<IOption[]>(selectedCurrencies);

  const handleChange = (currencies: IOption[]) => {
    setValue(currencies.map(r => Number(r.value)));
    setCurrencies(currencies);
  };

  return (
    <>
      <CustomSelect
        label='Blockchain'
        placeholder='Select Blockchain'
        options={currencyOptions}
        getCustomLabel={getLabel}
        getCustomOptionLabel={getOptionLabel}
        handleChange={handleChange}
        value={currencies}
      />
    </>
  );
};

export default CurrencyFilter;
