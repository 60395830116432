/* eslint-disable react/jsx-pascal-case */
import './integrations/instrument.js';
import { FC } from 'react';
import SPA from './containers/SPA';
import './styles/index.scss';
import { BrowserAgent } from '@newrelic/browser-agent/loaders/browser-agent';

import NERUM from './integrations/newrelic';
new BrowserAgent(NERUM);

const App: FC = () => {
  return <SPA />;
};

export default App;
