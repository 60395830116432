import { FC, useState } from 'react';
import { ITransactionEntity, ITransactionResponse } from '../../../../api/dtos/transaction';
import IdentifierEllipsis from '../../../ui/components/Currency/IdentifierEllipsis';
import CurrencyValue from '../../../ui/components/Currency/CurrencyValue';
import EntityWithSubtypeBadge from '../../../ui/components/EntityTypeBadge';
import { isAddressScreeningDisabled } from '../../../../utils/helpers/currency';
import classNames from 'classnames';
import AddressPreview from '../../../Address/AddressPreview';

interface TransactionEntityProps {
  entity: ITransactionEntity;
  transaction: ITransactionResponse;
  className?: string;
  isReport?: boolean;
}

const TransactionEntity: FC<TransactionEntityProps> = props => {
  const { entity, transaction, className, isReport = false } = props;
  const [addressPreview, setAddressPreview] = useState(false);

  const multilineAddress = entity.address.includes(',');
  let isClickable = true;

  const formatAddress = () => {
    let address = multilineAddress ? entity.address.replace(/,/g, '\n') : entity.address;
    // Disable screening for applicable addresses
    if (isAddressScreeningDisabled(address, transaction.currency)) {
      address = address.split('-')[0];
      isClickable = false;
    }
    return address;
  };
  return (
    <div className={classNames(`mb-2 ${className}`, isReport ? 'text-3xs' : 'text-xs')}>
      <div
        onClick={() => {
          if (isClickable) setAddressPreview(true);
        }}
      >
        <IdentifierEllipsis
          identifier={formatAddress()}
          limit={50}
          type='addresses'
          currency={transaction.currency}
          copyable={!isReport}
          className={isClickable && 'cursor-pointer'}
        />
      </div>
      <div className='mb-2 flex text-gray-500'>
        <CurrencyValue
          value={entity.value}
          currency={entity.token_symbol || entity.currency_short}
          className='border-r-2 pr-3'
        />
        <CurrencyValue value={entity.value_usd} currency='USD' className='pl-3' />
      </div>
      {entity.tag_name_verbose && (
        <div className='flex flex-wrap text-2xs'>
          <span className='my-auto mb-2 mr-3 flex'>{entity.tag_name_verbose}</span>
          <EntityWithSubtypeBadge
            type={entity.tag_type_verbose}
            subtype={entity.tag_subtype_verbose}
          />
        </div>
      )}
      <AddressPreview
        id={entity?.address}
        currency={transaction?.currency}
        open={addressPreview}
        setOpen={setAddressPreview}
      />
    </div>
  );
};

export default TransactionEntity;
