import React, { useMemo, useState } from 'react';
import { useEffect, useRef } from 'react';
import { MagnifyingGlass, XCircle } from '@phosphor-icons/react';
import { useRouter } from '../../modules/router/RouterProvider';
import { useTheme } from '../../utils/helpers/theme';
import SingleSelect, { IOption } from '../ui/components/Select/SingleSelect';
import { useAuth } from '../../modules/auth/AuthProvider';

interface Props {}

const SearchComponent: React.FC<Props> = () => {
  const { setTabState } = useAuth();
  const { getQueryParams, pathname } = useRouter();
  const { themeColor } = useTheme();
  const { q, search_entity_type } = getQueryParams();
  const filterOptions = [
    { label: 'All', value: 'all' },
    { label: 'Address', value: 'address' },
    { label: 'Transaction', value: 'transaction' },
    { label: 'Customer', value: 'customer' },
    { label: 'Case', value: 'case' },
  ];
  const [searchFocus, setSearchFocus] = useState(false);
  const [searchTerm, setSearchTerm] = useState(q ? q : '');
  const [selectedSearchEntity, setSelectedSearchEntity] = useState<IOption>(filterOptions[0]);
  const searchLimit = useMemo(() => {
    if (['customer', 'case'].includes(selectedSearchEntity.value)) return 3;
    return 5;
  }, [selectedSearchEntity.value]);
  const menuListRef = useRef(null);
  const { navigate } = useRouter();

  const onClickEnter = (event, entityValue = null) => {
    if (
      entityValue &&
      ['customer', 'case'].includes(selectedSearchEntity.value) &&
      !['customer', 'case'].includes(entityValue) &&
      searchTerm.length < 5
    ) {
      setSearchFocus(true);
      return;
    }
    if (searchTerm.length < searchLimit) return;
    if (event.key === 'Enter') {
      setTabState({ isTabVisible: true });
      setSearchFocus(false);
      document.getElementById('header-searchBar').blur();
      navigate('/search', {
        q: searchTerm,
        search_entity_type: entityValue ? entityValue : selectedSearchEntity.value,
      });
    } else return;
  };

  const onRemoveQuery = () => {
    setSearchTerm('');
    setSelectedSearchEntity(filterOptions[0]);
    navigate('/dashboard');
  };

  const controlRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = event => {
      if (
        menuListRef.current &&
        !menuListRef.current.contains(event.target) &&
        !controlRef.current.contains(event.target)
      ) {
        setSearchFocus(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [menuListRef]);

  useEffect(() => {
    if (q && search_entity_type) {
      setTabState({ isTabVisible: true });
      setSearchTerm(q);
      setSelectedSearchEntity(filterOptions.find(item => item.value === search_entity_type));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [q, search_entity_type]);

  useEffect(() => {
    if (!pathname.startsWith('/search')) {
      setSearchTerm('');
      setSearchFocus(false);
    }
  }, [pathname]);

  return (
    <div ref={controlRef}>
      <div className='z-998 relative flex items-center justify-center'>
        <div
          style={{
            backgroundColor:
              searchFocus || searchTerm ? themeColor().focused : themeColor().secondary,
          }}
          className={'my-2 flex h-[38px] w-[561px] items-center rounded-md p-[9px]'}
        >
          <MagnifyingGlass
            size={20}
            className={`${searchFocus || searchTerm ? 'text-gray-500' : 'text-white'}`}
          />
          <input
            value={searchTerm}
            type='text'
            id='header-searchBar'
            data-testid='header-searchBar'
            onChange={e => setSearchTerm(e.target.value)}
            className={`size-full border-none bg-transparent text-sm font-normal outline-none focus:border-transparent focus:ring-transparent ${
              searchFocus || searchTerm
                ? 'text-gray-800 placeholder:text-gray-800'
                : 'text-white placeholder:text-white'
            } `}
            placeholder='Search for Address, Transaction, Customer or Case'
            onFocus={() => setSearchFocus(true)}
            onKeyDown={e => onClickEnter(e)}
            autoComplete='off'
            autoCorrect='off'
          />
          {searchTerm && (
            <XCircle
              id='header-removeSearch'
              data-testid='header-removeSearch'
              size={25}
              className={`${searchFocus || searchTerm ? 'text-gray-500' : 'text-white'} cursor-pointer`}
              onClick={onRemoveQuery}
              weight='bold'
            />
          )}
          {(searchFocus || searchTerm) && (
            <div className='ml-1 border-l'>
              <SingleSelect
                id='header-searchDropdown'
                value={selectedSearchEntity}
                options={filterOptions}
                handleChange={option => {
                  setSelectedSearchEntity(option);
                  if (pathname.startsWith('/search')) {
                    onClickEnter({ key: 'Enter' }, option.value);
                  }
                }}
                className='-mr-2 mt-0 w-32 rounded-none border-gray-300'
                isCloseHidden
                noBorder
              />
            </div>
          )}
        </div>
      </div>
      {searchFocus && (
        <div
          ref={menuListRef}
          className={
            searchFocus
              ? 'top-15 left-30 absolute max-h-[90vh] w-[561px] overflow-y-auto rounded-lg border border-solid border-gray-200 bg-white p-2 text-xs shadow-2xl'
              : 'text-xs'
          }
        >
          <div className='p-2 text-2xs font-medium text-gray-800'>
            {searchTerm.trim().length < searchLimit
              ? `Please enter at least ${searchLimit} characters`
              : 'Press enter to search'}
          </div>
        </div>
      )}
    </div>
  );
};

export default SearchComponent;
