import { FC } from 'react';
import CurrencyValue from '../../ui/components/Currency/CurrencyValue';
import { formatDateShort } from '../../../utils/helpers/date';
import ReportTable from '../ReportTable';
import { ICustomerAddressesResult } from '../../../api/dtos/customer';
import CurrencyBadge from '../../ui/components/Badge/CurrencyBadge';
import { useRouter } from '../../../modules/router/RouterProvider';
import DigitalAssets from '../../ui/components/Currency/DigitalAssets';
import { RiskBadge } from '../../../ui';
import { getCurrencyById } from '../../../utils/helpers/currency';
import { startCase } from 'lodash';

interface ICustomerAddressesReportProps {
  customerAddresses: ICustomerAddressesResult[];
  part?: number;
  type: string;
}

const CustomerAddressesReport: FC<ICustomerAddressesReportProps> = ({
  customerAddresses,
  part,
  type,
}) => {
  const { getQueryParams } = useRouter();
  const timezone = getQueryParams().tz;

  if (!customerAddresses) return null;

  const tableData = customerAddresses.map(address => {
    const currencyShort = getCurrencyById(address.currency)?.currency_short;
    return {
      label: <CurrencyBadge currency={address.currency} label={currencyShort} transparent />,
      value: [
        {
          label: 'Address',
          value: <div className='break-words'>{address.identifier}</div>,
        },
        {
          label: 'Digital Assets',
          value: <DigitalAssets digitalAssets={address.digital_assets} showAll />,
        },
        {
          label: 'Risk Level',
          value: <RiskBadge risk={address.risk_level} />,
        },
        {
          label: 'Balance',
          value: <CurrencyValue value={address.balance} currency={currencyShort} />,
        },
        {
          label: 'Date Added',
          value: formatDateShort(address.created_at, timezone),
        },
      ],
    };
  });

  return (
    <ReportTable tableData={tableData} title={`Part ${part}: ${startCase(type)} Address Summary`} />
  );
};

export default CustomerAddressesReport;
