import * as React from 'react';
import { StylesConfig } from 'react-select';
import AsyncSelect from 'react-select/async';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const styles: StylesConfig<any, false> = {
  control: css => ({
    ...css,
    borderColor: '#d1d5db',
    borderRadius: '6px',
    fontSize: '14px',
  }),
  indicatorsContainer: css => ({ ...css, display: 'visible' }),
  input: css => ({
    ...css,
    fontSize: '14px',
    paddingTop: '2px',
    paddingBottom: '3px',
  }),
  placeholder: css => ({ ...css, fontSize: '14px' }),
  menuList: css => ({
    ...css,
    maxHeight: '300px',
    paddingTop: 0,
  }),
  option: css => ({
    ...css,
    padding: '0',
    backgroundColor: 'none',
    cursor: 'pointer',
    border: 0,
    outline: 'none',
    background: 'none',
    fontSize: '14px',
    paddingLeft: '10px',
    paddingRight: '10px',
    paddingTop: '8px',
    paddingBottom: '8px',
    color: '#374151',
    ':hover': {
      backgroundColor: '#F3F4F6',
    },
  }),
};

export interface SelectOption {
  label: string;
  value: number;
}

interface Props {
  id: string;
  label: string;
  placeholder: string;
  value: SelectOption;
  onChange: (value: SelectOption) => void;
  loadOptions: (query: string) => Promise<SelectOption[]>;
}

const CustomAsyncSelect: React.FC<Props> = ({
  id,
  label,
  placeholder,
  value,
  loadOptions,
  onChange,
}) => {
  return (
    <>
      <label
        htmlFor={id}
        data-testid={`${id}-label`}
        className='mb-1 block text-sm font-medium leading-default text-gray-500'
      >
        {label}
      </label>
      <AsyncSelect
        id={id}
        data-testid={id}
        value={value}
        defaultValue={value}
        classNames={{
          control: () => 'border border-gray-200',
        }}
        placeholder={placeholder}
        loadOptions={loadOptions}
        onChange={onChange}
        styles={styles}
        cacheOptions
      />
    </>
  );
};

export default CustomAsyncSelect;
