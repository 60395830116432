import * as React from 'react';
import { AxiosResponse } from 'axios';
import { ArrowCircleRight, CheckCircle, Plus, UserPlus } from '@phosphor-icons/react';

import {
  buildInfiniteQueryTableProps,
  flattenInfiniteQueryResult,
} from '../../../utils/helpers/react-query.helper';
import { caseApi, useCaseGetCaseList } from '../../../api/case';
import { useRouter } from '../../../modules/router/RouterProvider';
import { useAuth } from '../../../modules/auth';

import { useUserCaseUserGet } from '../../../api/profile';

import { Button } from '../../../ui';
import { toast } from '../../../ui/components/Toast';
import Table, { ITableRow } from '../../../ui/components/Table/Table';
import Tab from '../../../components/ui/components/Tab/Tab';

import AddCaseModal from '../../../components/Case/CaseList/AddCaseModal';
import AssignAgentModal from '../../../components/Case/CaseList/AssignAgentModal';
import ChangeCaseStatusModal from '../../../components/Case/CaseList/ChangeCaseStatusModal';
import DeleteCasesModal from '../../../components/Case/CaseList/DeleteCasesModal';
import CaseListFilter, {
  IFilters,
  defaultCaseListFilters,
} from '../../../components/Case/CaseList/CaseListFilter';
import CaseListAppliedFilter from '../../../components/Case/CaseList/CaseListAppliedFilter';
import { InfiniteData } from 'react-query';
import { ICaseResult } from '../../../api/dtos/case';
import { useGetCaseData } from './CaseData';
import ResolveMultipleAlertsModal from '../../../components/common/ResolveMultipleAlertsModal';

interface ICaseData {
  headerData: (string | JSX.Element)[];
  rowData: ITableRow[];
}

const getBulkActionFilter = (activeTab, filters, user_id) => {
  const bulkActionFilter = { ...filters };
  switch (activeTab) {
    case 0:
      bulkActionFilter.assigned_to = user_id;
      break;
    case 1:
      bulkActionFilter.assigned_to = 'None';
      break;
    case 2:
      bulkActionFilter.status = [2];
      break;
  }
  return bulkActionFilter;
};

export interface ICaseBulkActionFilters {
  assigned_to?: string;
  status?: number[];
}

const CaseList: React.FC = () => {
  const { state } = useAuth();

  const { data } = useUserCaseUserGet();

  const caseUsers = React.useMemo(() => {
    if (data?.data?.results) {
      return data.data.results.map(user => ({ label: user.full_name, value: `${user.id}` }));
    } else {
      return [];
    }
  }, [data]);

  const { getQueryParams, navigate } = useRouter();
  const { address, customer_id, status, risk_level, assigned_to, start_date, end_date } =
    getQueryParams();

  const [activeTab, setActiveTab] = React.useState(0);

  const [filters, setFilters] = React.useState<IFilters>({
    address: address && address !== 'null' ? address : null,
    customer_id: customer_id && customer_id !== 'null' ? customer_id : null,
    status: status ? status.split(',').map(Number) : [],
    risk_level: risk_level ? risk_level.split(',').map(Number) : [],
    assigned_to: assigned_to ? assigned_to.split(',').map(Number) : [],
    start_date: start_date && start_date !== 'null' ? start_date : null,
    end_date: end_date && end_date !== 'null' ? end_date : null,
  });

  type QueryFilters = {
    assigned_to?: number[] | 'None';
    status?: number[];
  };

  const useCreateCaseQuery = (queryFilters: QueryFilters, tab: number) => {
    return useCaseGetCaseList(
      { offset: 0, filters: { ...filters, ...queryFilters } },
      {
        onSuccess: (data: InfiniteData<AxiosResponse<ICaseResult>>) => {
          if (data.pages[0].data.count === 0 && activeTab === tab && tab !== 3) {
            setActiveTab(3);
          }
        },
      }
    );
  };

  const assignedCaseQuery = useCreateCaseQuery({ assigned_to: [state.userProfile?.id] }, 0);
  const unassignedCaseQuery = useCreateCaseQuery({ assigned_to: 'None' }, 1);
  const closedCaseQuery = useCreateCaseQuery({ status: [2] }, 2);
  const allCaseQuery = useCreateCaseQuery({}, 3);

  const isLoading =
    assignedCaseQuery.isLoading ||
    unassignedCaseQuery.isLoading ||
    closedCaseQuery.isLoading ||
    allCaseQuery.isLoading;

  const refetchLists = () => {
    assignedCaseQuery.refetch();
    unassignedCaseQuery.refetch();
    closedCaseQuery.refetch();
    allCaseQuery.refetch();
  };

  const caseQuery = React.useMemo(() => {
    switch (activeTab) {
      case 0:
        return assignedCaseQuery;
      case 1:
        return unassignedCaseQuery;
      case 2:
        return closedCaseQuery;
      case 3:
        return allCaseQuery;
    }
  }, [activeTab, assignedCaseQuery, unassignedCaseQuery, closedCaseQuery, allCaseQuery]);

  React.useEffect(() => {
    setSelectedCases([]);
    setIsAllCaseSelected(false);
  }, [activeTab]);

  const [count, cases] = flattenInfiniteQueryResult(caseQuery?.data);

  const syncFilters = React.useCallback(
    (newFilters: IFilters) => {
      const newSearchParams = new URLSearchParams();
      Object.keys(newFilters).forEach(key => {
        if (newFilters[key]) {
          newSearchParams.set(key, newFilters[key]);
        }
      });
      navigate('/cases', Object.fromEntries(newSearchParams));
    },
    [navigate]
  );

  React.useEffect(() => {
    if (JSON.stringify(filters) === JSON.stringify(defaultCaseListFilters)) {
      setFilters(defaultCaseListFilters);
      syncFilters(defaultCaseListFilters);
    }
  }, [filters, syncFilters]);

  const handleApply = (newFilters: IFilters) => {
    setFilters(newFilters);
    syncFilters(newFilters);
  };

  const onReset = () => {
    setFilters(defaultCaseListFilters);
    syncFilters(defaultCaseListFilters);
  };

  const [isAllCaseSelected, setIsAllCaseSelected] = React.useState(false);
  const [selectedCases, setSelectedCases] = React.useState<number[]>([]);

  const selectCase = (id: number) => {
    if (selectedCases.includes(id)) {
      setSelectedCases(selectedCases.filter(i => i !== id));
    } else {
      setSelectedCases([...selectedCases, id]);
    }
  };

  const selectAllCases = (force = false) => {
    if (force) {
      setSelectedCases(cases.map(caseItem => caseItem.id));
    } else {
      if (selectedCases.length === cases.length) {
        setSelectedCases([]);
        setIsAllCaseSelected(false);
      } else {
        setSelectedCases(cases.map(caseItem => caseItem.id));
      }
    }
  };

  const [isAddCaseModalOpen, setIsAddCaseModalOpen] = React.useState(false);
  const [isAssignAgentModalOpen, setIsAssignAgentModalOpen] = React.useState(false);
  const [isChangeCaseStatusModal, setIsChangeCaseStatusModal] = React.useState(false);
  const [isResolveAlertsModalOpen, setIsResolveAlertsModalOpen] = React.useState(false);
  const [isDeleteCasesModalOpen, setIsDeleteCasesModalOpen] = React.useState(false);
  const downloadSelectedCases = async () => {
    if (!isAllCaseSelected) {
      const res = await caseApi.exportCaseList(selectedCases);
      toast.success(res.data.message);
    } else {
      const res = await caseApi.exportCaseList(
        [],
        getBulkActionFilter(activeTab, filters, state.userProfile?.id)
      );
      toast.success(res.data.message);
    }
  };

  const tabs = [
    { label: 'Assigned to Me', count: flattenInfiniteQueryResult(assignedCaseQuery?.data)[0] },
    {
      label: 'Unassigned',
      count: flattenInfiniteQueryResult(unassignedCaseQuery?.data)[0],
    },
    {
      label: 'Closed',
      count: flattenInfiniteQueryResult(closedCaseQuery?.data)[0],
    },
    {
      label: 'All Cases',
      count: flattenInfiniteQueryResult(allCaseQuery?.data)[0],
    },
  ];

  const caseData: ICaseData = useGetCaseData({ cases, selectAllCases, selectedCases, selectCase });

  const headerData: (string | JSX.Element)[] = caseData.headerData;
  const rows: ITableRow[] = caseData.rowData;

  return (
    <>
      <Table
        title='Cases'
        headerData={headerData}
        rows={rows}
        tab={activeTab}
        headerActionsLeft={
          <Tab
            values={tabs}
            type='primary'
            inactiveClassName='text-gray-800/30 border-gray-200 cursor-not-allowed'
            panelClassName='px-4'
            changeTab={activeTab || null}
            onChange={setActiveTab}
            isLoading={isLoading}
          />
        }
        isLoading={caseQuery.isLoading}
        filterComponent={
          <div className='flex gap-2'>
            {selectedCases.length > 0 && (
              <>
                <Button
                  id='caseList-assignee'
                  variant='tertiary'
                  iconStart={<UserPlus size={17} />}
                  onClick={() => setIsAssignAgentModalOpen(true)}
                >
                  Assign Agent
                </Button>
                <Button
                  id='caseList-openAlerts'
                  variant='tertiary'
                  iconStart={<CheckCircle size={17} />}
                  onClick={() => setIsResolveAlertsModalOpen(true)}
                >
                  Update Open Alerts
                </Button>
                <Button
                  id='caseList-status'
                  variant='tertiary'
                  iconStart={<ArrowCircleRight size={17} />}
                  onClick={() => setIsChangeCaseStatusModal(true)}
                >
                  Change Status
                </Button>
              </>
            )}

            <CaseListFilter
              filters={filters}
              onApply={handleApply}
              onReset={onReset}
              disabled={selectedCases.length > 0}
              caseUsers={caseUsers}
              activeTab={activeTab}
            />
            <Button
              id='caseList-addCase'
              iconStart={<Plus size={17} />}
              onClick={() => setIsAddCaseModalOpen(true)}
              disabled={selectedCases.length > 0}
            >
              Add Case
            </Button>
          </div>
        }
        appliedFilters={
          <CaseListAppliedFilter
            count={count}
            filters={filters}
            setFilters={handleApply}
            selectedCases={selectedCases}
            setSelectedCases={setSelectedCases}
            isAllCaseSelected={isAllCaseSelected}
            setIsAllCaseSelected={setIsAllCaseSelected}
            selectAllCases={selectAllCases}
            downloadSelectedCases={downloadSelectedCases}
            deleteSelectedCases={() => setIsDeleteCasesModalOpen(true)}
            caseUsers={caseUsers}
            selectedText={
              isAllCaseSelected
                ? `${count} cases selected.`
                : selectedCases.length === cases.length
                  ? `All ${selectedCases.length} cases in this page are selected.`
                  : `${selectedCases.length} ${selectedCases.length > 1 ? 'cases' : 'case'} selected.`
            }
          />
        }
        heightOffset={25.5}
        count={count}
        isCountVerbose
        isSelectable
        {...buildInfiniteQueryTableProps(caseQuery)}
      />
      <ChangeCaseStatusModal
        isOpen={isChangeCaseStatusModal}
        onClose={() => setIsChangeCaseStatusModal(false)}
        bulkActionFilter={getBulkActionFilter(activeTab, filters, state.userProfile?.id)}
        selectedCases={selectedCases}
        isAllCaseSelected={isAllCaseSelected}
        refetchList={() => refetchLists()}
      />
      <ResolveMultipleAlertsModal
        type='case'
        isOpen={isResolveAlertsModalOpen}
        onClose={() => setIsResolveAlertsModalOpen(false)}
        selectedEntities={selectedCases}
        isAllEntitiesSelected={isAllCaseSelected}
        refetchList={() => refetchLists()}
        filters={filters}
        selectedTab={activeTab}
        setSelectedEntities={setSelectedCases}
        setIsAllEntitiesSelected={setIsAllCaseSelected}
      />
      <DeleteCasesModal
        isOpen={isDeleteCasesModalOpen}
        onClose={() => setIsDeleteCasesModalOpen(false)}
        bulkActionFilter={getBulkActionFilter(activeTab, filters, state.userProfile?.id)}
        selectedCases={selectedCases}
        isAllCaseSelected={isAllCaseSelected}
        refetchList={() => refetchLists()}
      />
      <AssignAgentModal
        isOpen={isAssignAgentModalOpen}
        onClose={() => setIsAssignAgentModalOpen(false)}
        bulkActionFilter={getBulkActionFilter(activeTab, filters, state.userProfile?.id)}
        caseUsers={caseUsers}
        selectedCases={selectedCases}
        isAllCaseSelected={isAllCaseSelected}
        refetchList={() => refetchLists()}
      />
      <AddCaseModal
        isOpen={isAddCaseModalOpen}
        onClose={() => setIsAddCaseModalOpen(false)}
        caseUsers={caseUsers}
      />
    </>
  );
};

export default CaseList;
