import { IRuleCondition } from '../../api/dtos/policies';

export const mapRuleConditions = (ruleConditions: IRuleCondition[]) => {
  const ruleConditionsCopy = ruleConditions.map(rule => {
    const updatedRule = {} as IRuleCondition;
    const ignoreIfEmptyList = [
      'upper_threshold_amount_usd',
      'taint_percent_upper_threshold',
      'sentiments',
    ];
    for (const key in rule) {
      if (key === 'lower_transaction_count_threshold') {
        updatedRule.transaction_count_threshold = rule.lower_transaction_count_threshold;
      } else if (key === 'transaction_count_threshold_2') {
        updatedRule.transaction_count_threshold = rule.transaction_count_threshold_2;
      } else if (key === 'analysis_window_2') {
        updatedRule.analysis_window = rule.analysis_window_2;
      } else if (
        ignoreIfEmptyList.includes(key) &&
        (rule[key] === null || rule[key]?.length === 0)
      ) {
        // Ignore and don't add the keys if its empty or null
        continue;
      } else {
        updatedRule[key] = rule[key];
      }
    }
    return updatedRule;
  });

  return ruleConditionsCopy;
};

export const reverseMapRuleConditions = (rule: IRuleCondition) => {
  if (rule.transaction_count_threshold) {
    rule.lower_transaction_count_threshold = rule.transaction_count_threshold;
  }
  if (rule.transaction_count_threshold) {
    rule.transaction_count_threshold_2 = rule.transaction_count_threshold;
  }
  if (rule.analysis_window) {
    rule.analysis_window_2 = rule.analysis_window;
  }
  return rule;
};
