import * as React from 'react';
import { useMutation } from 'react-query';
import { useAuth } from '../../modules/auth';

import { caseApi } from '../../api/case';
import { ICreateCaseRequest } from '../../api/dtos/case';

import { useRouter } from '../../modules/router/RouterProvider';

import { BasicModal, Button, Input, RadioButton, RadioButtons } from '../../ui';
import { AsyncSelect, SelectOption } from '../../ui/components/AsyncSelect';
import { toast } from '../../ui/components/Toast';
import { IStageListLite } from '../../api/dtos/stages';

interface IEntityDetailsForCase {
  currency: number;
  identifier: string;
}

interface ICustomerDetailsForCase {
  customer_id: string;
}

interface Props {
  type: 'address' | 'transaction' | 'customer';
  isOpen: boolean;
  isAllEntitiesSelected: boolean;
  onClose: () => void;
  entities: IEntityDetailsForCase[] | ICustomerDetailsForCase[];
  selectedTab?: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  filters?: any;
  closedStage?: IStageListLite;
}

const getLabel = (type: 'address' | 'transaction' | 'customer') => {
  switch (type) {
    case 'address':
      return 'Addresses';
    case 'transaction':
      return 'Transactions';
    case 'customer':
      return 'Customers';
  }
};

const AddToCaseModal: React.FC<Props> = ({
  type = 'address',
  isOpen,
  onClose,
  isAllEntitiesSelected,
  entities,
  selectedTab = -1,
  ...props
}) => {
  const { navigate } = useRouter();
  const { state } = useAuth();

  const [caseAssignmentType, setCaseAssignmentType] = React.useState(0);
  const [newCaseName, setNewCaseName] = React.useState('');
  const [selectedCase, setSelectedCase] = React.useState<SelectOption>(null);

  const { mutateAsync: searchCase } = useMutation(caseApi.getCaseList);
  const { mutateAsync: addAddressToCase } = useMutation(caseApi.addAddress);
  const { mutateAsync: addTransactionToCase } = useMutation(caseApi.addTransaction);
  const { mutateAsync: addCustomerToCase } = useMutation(caseApi.addCustomer);

  const searchCases = async (query: string): Promise<SelectOption[]> => {
    if (query.length === 0 || query.length < 3) {
      return [];
    }
    const res = await searchCase({ q: query });
    return res.data.results.map(option => ({
      label: option.name,
      value: option.id,
    }));
  };

  const addToExistingCase = async () => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const params: any =
      selectedTab === 0 && isAllEntitiesSelected
        ? { filters: props.filters, assigned_to: state.userProfile?.id }
        : selectedTab === 1 && isAllEntitiesSelected
          ? { filters: props.filters, assigned_to: 'None' }
          : selectedTab === 2 && isAllEntitiesSelected
            ? { filters: props.filters, workflow_stage: props.closedStage?.id, closed: true }
            : selectedTab === 3 && isAllEntitiesSelected
              ? { filters: props.filters }
              : {};
    params.case_id = selectedCase.value;
    let res;
    switch (type) {
      case 'address':
        params.addresses = isAllEntitiesSelected ? [] : (entities as IEntityDetailsForCase[]);
        params.entity_type = isAllEntitiesSelected ? 'addresses' : '';
        res = await addAddressToCase(params);
        break;
      case 'transaction':
        params.transactions = isAllEntitiesSelected ? [] : (entities as IEntityDetailsForCase[]);
        params.entity_type = isAllEntitiesSelected ? 'transactions' : '';
        res = await addTransactionToCase(params);
        break;
      case 'customer':
        params.customers = isAllEntitiesSelected ? [] : (entities as IEntityDetailsForCase[]);
        params.entity_type = isAllEntitiesSelected ? 'customers' : '';
        res = await addCustomerToCase(params);
        break;
      default:
        break;
    }

    if (res.status === 200) {
      setSelectedCase(null);
      setCaseAssignmentType(0);

      toast.success(`Added ${getLabel(type)} to Case`);

      navigate(`/cases/${selectedCase.value}`);
    }
  };

  const getParams = (name: string, type: 'address' | 'transaction' | 'customer') => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const params: any =
      selectedTab === 0 && isAllEntitiesSelected
        ? { filters: props.filters, assigned_to: state.userProfile?.id }
        : selectedTab === 1 && isAllEntitiesSelected
          ? { filters: props.filters, assigned_to: 'None' }
          : selectedTab === 2 && isAllEntitiesSelected
            ? { filters: props.filters, workflow_stage: props.closedStage?.id, closed: true }
            : selectedTab === 3 && isAllEntitiesSelected
              ? { filters: props.filters }
              : {};
    params.name = name;
    if (isAllEntitiesSelected) {
      params.entity_type =
        type === 'address' ? 'addresses' : type === 'customer' ? 'customers' : 'transactions';
    }
    switch (type) {
      case 'address':
        params.addresses = isAllEntitiesSelected ? [] : (entities as IEntityDetailsForCase[]);
        return params;
      case 'transaction':
        params.transactions = isAllEntitiesSelected ? [] : (entities as IEntityDetailsForCase[]);
        return params;
      case 'customer':
        params.customers = isAllEntitiesSelected ? [] : (entities as IEntityDetailsForCase[]);
        return params;
    }
  };

  const addToNewCase = async () => {
    try {
      const res = await caseApi.createCase(getParams(newCaseName, type) as ICreateCaseRequest);

      if (res.status === 201) {
        toast.success(`Added ${getLabel(type)} to Case`);

        setNewCaseName('');
        setCaseAssignmentType(0);

        navigate(`/cases/${res.data.id}`);
      }
    } catch (err) {
      Object.keys(err.response.data).forEach(key => {
        toast.error(err.response.data[key]);
      });
    }
  };

  const handleAddToCase = () => {
    if (caseAssignmentType === 0) {
      addToNewCase();
    } else {
      addToExistingCase();
    }
  };

  return (
    <BasicModal
      size='sm'
      open={isOpen}
      onClose={onClose}
      modalTitle={`Add ${getLabel(type)} to Case`}
      modalBody={
        <div className='z-[100000]'>
          <form
            className='flex flex-col'
            onSubmit={e => {
              e.preventDefault();
              handleAddToCase();
            }}
          >
            <div className='mb-5 flex gap-4'>
              <RadioButtons
                name='caseAssignmentType'
                onChange={e => setCaseAssignmentType(Number((e.target as HTMLInputElement).value))}
                selected={caseAssignmentType}
                className='flex'
              >
                <RadioButton labelText='New Case' value={0} name='newCase' />
                <RadioButton labelText='Existing Case' value={1} name='existingCase' />
              </RadioButtons>
            </div>
            <label htmlFor='case-name' data-testid='case-name'>
              {caseAssignmentType === 0 ? (
                <>
                  <Input
                    labelText='Enter Case Name'
                    labelClassNames='text-gray-500'
                    type='text'
                    name='case-name'
                    id='case-name'
                    placeholder='Case Name'
                    value={newCaseName}
                    onChange={e => setNewCaseName((e.target as HTMLInputElement).value)}
                    required
                  />
                </>
              ) : (
                <>
                  <AsyncSelect
                    id='select-case'
                    label='Enter Case ID or Case Name'
                    placeholder='Type to search'
                    value={selectedCase}
                    onChange={value => setSelectedCase(value)}
                    loadOptions={searchCases}
                  />
                </>
              )}
            </label>
            <div className='mt-6 flex justify-end gap-2'>
              <Button variant='tertiary' onClick={onClose}>
                Cancel
              </Button>
              <Button type='submit'>
                {caseAssignmentType === 0 ? 'Create Case' : 'Add to Case'}
              </Button>
            </div>
          </form>
        </div>
      }
    />
  );
};

export default AddToCaseModal;
