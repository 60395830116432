import React, { useEffect, useRef, useState } from 'react';
import EmptyState from '../../ui/components/States/Empty';
import classNames from 'classnames';
import * as d3 from 'd3';
import { IAggregatedResult } from '../../api/dtos/address';
import { colorsDark, colorDarkDefault } from '../../utils/helpers/drawCanvasElements';

interface DonutChartProps {
  inputs: Array<IAggregatedResult>;
  outputs: Array<IAggregatedResult>;
  group: string;
  id: string;
}
const nf = Intl.NumberFormat('en-US', {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

const DonutChartReport: React.FC<DonutChartProps> = props => {
  const { inputs, outputs, group = 'type', id = 'sankey' } = props;
  const [show, setShow] = useState(false);
  const [noInput, setNoInput] = useState(true);
  const [noOutput, setNoOutput] = useState(true);
  const [rowInputTotal, setRowInputTotal] = useState(null);
  const [rowOutputTotal, setRowOutputTotal] = useState(null);
  const [inputData, setInputData] = useState(null);
  const [outputData, setOutputData] = useState(null);

  useEffect(() => {
    setShow(inputs?.length > 0 || outputs?.length > 0);
    if (!inputs?.length && !outputs?.length) return;
    setNoInput(!inputs?.length || !inputs.some(i => Number(i.total_value_usd) > 0));
    setNoOutput(!outputs?.length || !outputs.some(i => Number(i.total_value_usd) > 0));

    // Recalculate rowInputTotal
    const getRowTotal = (list: IAggregatedResult[]) => {
      if (!list) return null;
      const data = list.filter(item => {
        return item.exposure_type === 'direct';
      });
      let total = 0;
      data.forEach(item => {
        total += Number(item.total_value_usd) || 0;
      });
      return total;
    };
    setRowInputTotal(getRowTotal(inputs));
    setRowOutputTotal(getRowTotal(outputs));
    // Recalculate inputData and outputData
    const parseData = (list: IAggregatedResult[], totalValue) => {
      list = list.sort(function (a, b) {
        if (a.tag_type_verbose > b.tag_type_verbose) {
          return -1;
        }
        if (a.tag_type_verbose < b.tag_type_verbose) {
          return 1;
        }
        return 0;
      });
      const keysData = {};
      const unknown = [];
      const genData = [];
      let unknownValue = 0;
      let indirectValue = 0;
      let directValue = 0;

      let nameProp;
      if (group === 'type') {
        nameProp = 'tag_type_verbose';
      } else if (group === 'name') {
        nameProp = 'tag_name_verbose';
      } else {
        nameProp = 'tag_subtype_verbose';
      }

      list.forEach(item => {
        let tooltip;
        if (group === 'type') {
          tooltip = item.tag_type_verbose || 'Unknown';
        } else if (group === 'name') {
          tooltip = item.tag_name_verbose || 'Unknown';
        } else {
          tooltip =
            item.tag_type_verbose + item.tag_subtype_verbose
              ? `${item.tag_type_verbose || 'Unknown'}: ${item.tag_subtype_verbose || 'Unknown'}`
              : 'Unknown';
        }
        if (item[nameProp]) {
          const key = `${item[nameProp]} - ${item.exposure_type}`;
          let dataItem = keysData[key];
          if (!dataItem) {
            const tagType = item.tag_type_verbose;
            keysData[key] = dataItem = {
              name: item[nameProp],
              value: Number(item.total_value_usd),
              tooltip,
              color: colorsDark[tagType] ? colorsDark[tagType] : colorDarkDefault,
            };
            if (item.exposure_type === 'direct') {
              directValue += parseFloat(dataItem.value);
              genData.push(dataItem);
            } else {
              indirectValue += parseFloat(dataItem.value);
              unknown.push(dataItem);
            }
          } else {
            dataItem.value += Number(item.total_value_usd);
            if (item.exposure_type === 'direct') {
              directValue += Number(item.total_value_usd);
            } else {
              indirectValue += Number(item.total_value_usd);
            }
          }
        } else if (item.exposure_type === 'direct') {
          unknownValue += Number(item.total_value_usd);
        }
      });

      if (unknown.length) {
        genData.push({
          tooltip: 'Unknown',
          name: 'Unknown',
          children: unknown,
          color: colorDarkDefault,
        });
      } else if (unknownValue > 0) {
        genData.push({
          tooltip: 'Unknown',
          name: 'Unknown',
          value: unknownValue,
          color: colorDarkDefault,
        });
      }

      return {
        name: 'Root',
        children: genData,
        indirectValue: indirectValue,
        directValue: directValue,
        totalValue: parseFloat(totalValue),
        indirectValuePercentage: totalValue ? (100 / totalValue) * indirectValue : 0,
        directValuePercentage: totalValue ? (100 / totalValue) * directValue : 0,
      };
    };
    !noInput && setInputData(parseData(inputs, rowInputTotal));
    !noOutput && setOutputData(parseData(outputs, rowOutputTotal));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [group, inputs, outputs, rowInputTotal, rowOutputTotal]);

  const chartIncoming = useRef<HTMLDivElement>(null);
  const chartOutgoing = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!show) return;
    const div = d3
      .select('body')
      .append('div')
      .attr(
        'class',
        'absolute bg-white z-50 w-auto pointer-events-none rounded-xl shadow-md p-4 font-semibold border border-gray-300'
      )
      .style('opacity', '0')
      .style('display', 'none');

    const drawChart = (id, data, tooltipType) => {
      if (!data) return;
      const { totalValue, directValue, indirectValue } = data;
      const value = parseFloat(totalValue) - parseFloat(directValue) - parseFloat(indirectValue);
      data.children = data.children.map(item => {
        if (item.name === 'Unknown' && item.children?.length > 0 && value > 0) {
          item.children.unshift({
            color: 'transparent',
            name: 'Unknown',
            tooltip: 'Unknown',
            value,
          });
        }
        return item;
      });

      const partition = data => {
        const root = d3.hierarchy(data).sum(d => d.value);
        return d3.partition().size([2 * Math.PI, root.height + 1])(root);
      };

      const width = 450;
      const radius = width / 6;
      const arc = d3
        .arc()
        .startAngle(d => d.x0)
        .endAngle(d => d.x1)
        .padAngle(d => Math.min((d.x1 - d.x0) / 2, 0.01))
        .padRadius(radius * 1.5)
        .innerRadius(d => d.y0 * radius)
        .outerRadius(d => Math.max(d.y0 * radius, d.y1 * radius - 4));

      const root = partition(data);

      root.each(d => (d.current = d));

      const svg = d3.select(id).append('svg');
      svg.attr('viewBox', [0, 0, width, width]).style('font', '10px sans-serif');

      const g = svg.append('g').attr('transform', `translate(${width / 2},${width / 2})`);
      const path = g
        .append('g')
        .selectAll('path')
        .data(root.descendants().slice(1))
        .join('path')
        .attr('fill', d => {
          return d.data.color;
        })
        .attr('fill-opacity', () => 1)
        .attr('d', d => arc(d.current));

      path
        .style('cursor', 'pointer')
        .on('mouseover', d => {
          const node = d3.select(d.srcElement).data()[0];
          const tooltip = `${tooltipType === 'sent' ? 'Sent' : 'Received'} US$${nf.format(
            Number(node.value)
          )} to ${node.data.tooltip}`;

          div
            .style('opacity', 1)
            .style('display', 'block')
            .style('position', 'absolute')
            .style('left', d.pageX + 20 + 'px')
            .style('top', d.pageY + 20 + 'px')
            .html(tooltip);
        })
        .on('mouseout', function () {
          div.style('opacity', 0).style('display', 'none');
        })
        .on('mousemove', function (d) {
          div.style('left', d.pageX + 20 + 'px').style('top', d.pageY + 20 + 'px');
        });
    };
    if (chartIncoming?.current) chartIncoming.current.innerHTML = '';
    if (chartOutgoing?.current) chartOutgoing.current.innerHTML = '';
    drawChart(`#${id}Incoming`, inputData, 'received');
    drawChart(`#${id}Outgoing`, outputData, 'sent');
  }, [id, inputData, outputData, show]);
  if (!show) return null;

  return (
    <div>
      <div
        className={classNames(
          'border-gray relative my-16 flex w-full border-r',
          'incoming',
          noInput ? 'noData' : 'donut'
        )}
      >
        {noInput ? (
          <div className='mx-auto flex'>
            <EmptyState />
          </div>
        ) : (
          <>
            <div
              ref={chartIncoming}
              className='z-10 ml-8'
              style={{
                width: '300px',
                height: '300px',
              }}
              id={`${id}Incoming`}
              data-testid={`${id}Incoming`}
            ></div>
            <img
              className={classNames('absolute left-[188px] top-[115px] -ml-6 h-12 w-12')}
              src='/incoming.svg'
              title='Incoming'
            />
            <div className='m-auto w-72'>
              <div className='mb-4 text-lg font-extrabold'>Incoming Funds</div>
              <div className='mb-auto ml-auto grid h-32 grid-cols-2 text-xs'>
                <p className='text-gray-700'>Total Incoming</p>
                <p className='font-semibold'>${nf.format(rowInputTotal)}</p>
                <p className='text-gray-700'>Direct Exposure</p>
                <p className='font-semibold'>
                  ${nf.format(inputData?.directValue)}
                  <span className='ml-1 text-sm font-normal text-gray-500'>
                    ({nf.format(inputData?.directValuePercentage)}%)
                  </span>
                </p>
                <p className='text-gray-700'>Indirect Exposure</p>
                <p className='font-semibold'>
                  ${nf.format(inputData?.indirectValue)}
                  <span className='ml-1 text-sm font-normal text-gray-500'>
                    ({nf.format(inputData?.indirectValuePercentage)}
                    %)
                  </span>
                </p>
              </div>
            </div>
          </>
        )}
      </div>

      <div
        className={classNames(
          'border-gray relative flex w-full border-r',
          'outgoing',
          noOutput ? 'noData' : 'donut'
        )}
      >
        {noOutput ? (
          <div className='mx-auto flex'>
            <EmptyState />
          </div>
        ) : (
          <>
            <div
              ref={chartOutgoing}
              className='z-10 ml-8'
              style={{
                width: '300px',
                height: '300px',
              }}
              id={`${id}Outgoing`}
              data-testid={`${id}Outgoing`}
            ></div>
            <img
              className={classNames('absolute left-[188px] top-[115px] -ml-6 h-12 w-12')}
              src='/outgoing.svg'
              title='Outgoing'
            />
            <div className='m-auto w-72'>
              <div className='mb-4 text-lg font-extrabold'>Outgoing Funds</div>
              <div className='mb-auto ml-auto grid h-32 grid-cols-2 text-xs'>
                <p className='text-gray-700'>Total Outgoing</p>
                <p className='font-semibold'>${nf.format(rowOutputTotal)}</p>
                <p className='text-gray-700'>Direct Exposure</p>
                <p className='font-semibold'>
                  ${nf.format(outputData?.directValue)}
                  <span className='ml-1 text-sm font-normal text-gray-500'>
                    ({nf.format(outputData?.directValuePercentage)}%)
                  </span>
                </p>
                <p className='text-gray-700'>Indirect Exposure</p>
                <p className='font-semibold'>
                  ${nf.format(outputData?.indirectValue)}
                  <span className='ml-1 text-sm font-normal text-gray-500'>
                    ({nf.format(outputData?.indirectValuePercentage)}
                    %)
                  </span>
                </p>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default DonutChartReport;
