import React, { FC, useState, useEffect } from 'react';
import { usePopper } from 'react-popper';
import classNames from 'classnames';
import { Placement } from '@popperjs/core';
import { Transition } from '@headlessui/react';

interface PopoverProps {
  referenceContent: React.ReactNode;
  popoverContent: React.ReactNode;
  placement?: Placement;
  initialVisibility?: boolean;
  className?: string;
  containerClassName?: string;
  delay?: number;
  isBasic?: boolean;
  id?: string;
}

const Popover: FC<PopoverProps> = ({
  referenceContent,
  popoverContent,
  placement = 'bottom-end',
  initialVisibility = false,
  className,
  containerClassName,
  isBasic,
  delay = 100,
  id,
}) => {
  const [referenceElement, setReferenceElement] = useState<HTMLSpanElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);
  const [popoverVisible, setPopoverVisible] = useState<boolean>(initialVisibility);
  let showTimer: NodeJS.Timeout | undefined;
  let hideTimer: NodeJS.Timeout | undefined;

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement,
  });

  const handleMouseEnter = () => {
    if (hideTimer) {
      clearTimeout(hideTimer);
    }
    showTimer = setTimeout(() => {
      setPopoverVisible(true);
    }, delay);
  };

  const handleMouseLeave = () => {
    if (showTimer) {
      clearTimeout(showTimer);
    }
    hideTimer = setTimeout(() => {
      setPopoverVisible(false);
    }, delay);
  };

  useEffect(() => {
    return () => {
      if (showTimer) {
        clearTimeout(showTimer);
      }
      if (hideTimer) {
        clearTimeout(hideTimer);
      }
    };
  }, [hideTimer, showTimer]);

  return (
    <div className={containerClassName} id={id} data-testid={id}>
      <div className='relative' onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        <span ref={setReferenceElement}>{referenceContent}</span>
      </div>
      <Transition
        show={popoverVisible}
        as={React.Fragment}
        enter='transition-opacity duration-300'
        enterFrom='opacity-0'
        enterTo='opacity-100'
        leave='transition-opacity duration-300'
        leaveFrom='opacity-100'
        leaveTo='opacity-0'
      >
        <div
          ref={setPopperElement}
          style={styles.popper}
          {...attributes.popper}
          className={classNames(
            'z-999',
            { 'rounded-md border bg-white p-2 shadow-md': isBasic },
            className
          )}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          onClick={e => e.stopPropagation()}
        >
          {popoverContent}
        </div>
      </Transition>
    </div>
  );
};

export default Popover;
