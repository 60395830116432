import { List } from '@phosphor-icons/react';
import Logo from '@/assets/logo-with-text.svg';
import { FC, Fragment, useState } from 'react';
import { useAuth } from '../../modules/auth';
import HeaderMenu from './HeaderMenu';
import HeaderMenuLinks from './HeaderMenuLinks';
import SearchComponent from '../../components/UniversalSearch/UniversalSearchBar';
import { useTheme } from '../../utils/helpers/theme';
import DashboardTabs from '../DashboardTabs/DashboardTabs';
import { Menu, MenuButton, MenuItem, MenuItems, Transition } from '@headlessui/react';
import { CaretDown } from '@phosphor-icons/react';
import classNames from 'classnames';
import Kybb from '@/assets/images/dashboard/kybb.svg';
import Tracker from '@/assets/images/dashboard/tracker.svg';
import FileUploads from './FileUpload/FileUploads';
import DownloadManager from './DownloadManager';
import CustomLink from '../ui/components/CustomLink';
import { useEffectOnce } from 'react-use';
import { useRouter } from '../../modules/router/RouterProvider';
import { removeOrganizationInfo } from '../../utils/helpers/cookies';
import React from 'react';
import { getAppUrl } from '../../utils/helpers/common';
export interface HeaderProps {
  compassStatus?: string;
  onSupport?: () => void;
  onLogout?: () => void;
}

//TODO: not show header when permissions api fails
const Header: FC<HeaderProps> = ({ onLogout, compassStatus, onSupport }) => {
  const { state, setTabState } = useAuth();
  const [width, setWidth] = React.useState(0);
  const { navigate } = useRouter();

  const [compassChannel, setCompassChannel] = useState<BroadcastChannel>(null);

  const createBroadcastChannel = () => {
    const newCompassChannel = new BroadcastChannel('compass-channel');
    setCompassChannel(newCompassChannel);
    newCompassChannel.addEventListener(
      'message',
      ev => {
        if (ev.data === 'logoutUser') {
          logoutUser();
        }
      },
      { passive: true }
    );
  };

  const logoutUser = (clearData = false) => {
    if (clearData) removeOrganizationInfo();
    navigate('/accounts/logout', { clear: clearData.toString() });
  };

  useEffectOnce(() => {
    createBroadcastChannel();
  });

  const { themeColor } = useTheme();

  const handleLogout = () => {
    compassChannel.postMessage('logoutUser');
    logoutUser(true);
  };

  const handleSupport = () => {
    window.zE('webWidget', 'show');
    window.zE('webWidget', 'open');
  };

  const handleListClick = () => {
    if (location.pathname !== '/search') {
      setTabState({ isTabVisible: !state.isTabVisible });
    }
  };

  React.useEffect(() => {
    if (state?.userProfile?.organization_brand_logo) {
      const img = new Image();
      img.src = state?.userProfile?.organization_brand_logo;

      img.onload = () => {
        const aspectRatio = img.width / img.height;
        const calculatedWidth = 32 * aspectRatio;
        setWidth(calculatedWidth);
      };
    }
  }, [state?.userProfile?.organization_brand_logo]);

  if (!state.access) return null;

  return (
    <div className='flex flex-col'>
      <header
        className={
          'sticky inset-x-0 top-0 z-40 flex flex-none items-center justify-between border-b border-gray-200 p-4 py-1 pr-8'
        }
        style={{ backgroundColor: themeColor().primary }}
      >
        <div className='flex flex-row'>
          <div className='relative'>
            {state.access !== '' && (
              <>
                <div className='mx-1'>
                  <List
                    id='header-tabs'
                    data-testid='header-tabs'
                    size={36}
                    onClick={handleListClick}
                    className={`gray-800 inline-flex w-10 cursor-pointer justify-center rounded border-2 border-transparent p-2 text-white hover:bg-[linear-gradient(rgb(0_0_0/20%)_0_0)] ${state.isTabVisible ? 'border-2 border-solid border-white' : ''} ${state.isTabVisible ? 'hover:border-transparent' : ''}`}
                  />
                </div>
              </>
            )}
          </div>
          <div className='ml-4 flex shrink-0 items-center text-white'>
            <CustomLink id='header-logo' to='/dashboard' className='flex'>
              <img src={Logo} alt='merklescience-logo' />
              {state?.userProfile?.organization_brand_logo && (
                <img
                  src={state?.userProfile?.organization_brand_logo}
                  alt='Organization logo'
                  className='ml-2 border-l'
                  height={32}
                  width={width}
                />
              )}
            </CustomLink>
          </div>
          <div>
            <Menu as='div' className='inline-block pl-3 pt-2 text-left marker:relative'>
              <div>
                <MenuButton
                  id='header-productsList'
                  className={classNames('text-sm font-medium text-white')}
                >
                  <div className='flex'>
                    <CaretDown size={16} weight='fill' className='my-auto text-white' />
                  </div>
                </MenuButton>
              </div>

              <Transition
                unmount={false}
                as={Fragment}
                enter='transition ease-out duration-100'
                enterFrom='transform opacity-0 scale-95'
                enterTo='transform opacity-100 scale-100'
                leave='transition ease-in duration-75'
                leaveFrom='transform opacity-100 scale-100'
                leaveTo='transform opacity-0 scale-95'
              >
                <MenuItems
                  static
                  className='left-70 absolute top-8 z-999 mt-2 rounded-2xl border bg-white p-1 shadow-lg focus:outline-none'
                >
                  <MenuItem>
                    {({ close }) => (
                      <div
                        id='header-tracker'
                        className='cursor-pointer rounded-2xl p-2 pr-4 text-gray-700 hover:bg-[#dbeafe] hover:text-blue-900'
                        onClick={() => {
                          close();
                          window.open(getAppUrl('tracker'), '_blank');
                        }}
                      >
                        <img alt='tracker' src={Tracker} className='inline-block px-2' />
                        <span className='text-sm font-medium tracking-[0.2em]'>TRACKER</span>
                      </div>
                    )}
                  </MenuItem>
                  <MenuItem>
                    {({ close }) => (
                      <div
                        id='header-kybb'
                        className='cursor-pointer rounded-2xl p-2 pr-4 text-gray-700 hover:bg-[#dbeafe] hover:text-blue-900'
                        onClick={() => {
                          close();
                          window.open(getAppUrl('kybb'), '_blank');
                        }}
                      >
                        <img alt='kybb' src={Kybb} className='inline-block px-2' />
                        <span className='text-sm font-medium tracking-[0.2em]'>KYBB</span>
                      </div>
                    )}
                  </MenuItem>
                </MenuItems>
              </Transition>
            </Menu>
          </div>
        </div>
        <SearchComponent />
        {/* {state.userProfile?.first_name != null && (
        <HeaderMenu
          userDetails={{
            email: `${state.userProfile?.first_name} ${state.userProfile?.last_name}`,
            name: `${state.userProfile?.first_name} ${state.userProfile?.last_name}`,
          }}
          logout={onLogout}
        />
      )} */}
        <div className='flex bg-transparent'>
          <DownloadManager />
          <FileUploads />
          <HeaderMenuLinks
            compassStatus={compassStatus}
            onSupport={onSupport ? onSupport : handleSupport}
          />
          <HeaderMenu onLogout={onLogout ? onLogout : handleLogout} />
        </div>
      </header>
      {location.pathname !== '/search' && <DashboardTabs />}
    </div>
  );
};

export default Header;
