import * as React from 'react';
import CompassLoader from '../ui/components/Loader/CompassLoader';
import classNames from 'classnames';

import { useRouter } from '../../modules/router/RouterProvider';
import {
  usePolicyGetDetails,
  usePolicyRuleGetDetails,
  usePolicyRuleGetCryptoObjectsFlagged,
  usePolicyRuleGetAlerts,
} from '../../api/policies';

import AddEditRule from './RuleDetails/AddEditRule';
import RuleAnalytics from './RuleDetails/RuleAnalytics';
import RuleAuditTrail from './RuleDetails/RuleAuditTrail';

const RuleDetails: React.FC = () => {
  const { getParams, navigate } = useRouter();
  const params = getParams();

  const policyId = params.policyId;
  const ruleId = params.ruleId;

  const { isLoading: isPolicyDetailsLoading, data: policyDetailsData } = usePolicyGetDetails(
    policyId,
    {
      useErrorBoundary: true,
    }
  );
  const policyDetails = policyDetailsData?.data;

  const {
    isLoading: isRuleDetailsLoading,
    data: ruleDetailsData,
    isFetching,
  } = usePolicyRuleGetDetails(policyId, ruleId, { useErrorBoundary: true });
  const ruleDetails = ruleDetailsData?.data;

  const { isLoading: isLoadingRuleCryptoObjects, data: ruleCryptoObjects } =
    usePolicyRuleGetCryptoObjectsFlagged(policyId, ruleId);
  const ruleCryptoObjectsFlagged = ruleCryptoObjects?.data;

  const { isLoading: isLoadingRuleAlert, data: ruleAlertData } = usePolicyRuleGetAlerts(
    policyId,
    ruleId
  );
  const ruleAlerts = ruleAlertData?.data;

  const [activeTab, setActiveTab] = React.useState(0);

  const renderActiveTab = () => {
    switch (activeTab) {
      case 0:
        return <AddEditRule policyDetails={policyDetails} ruleDetails={ruleDetails} />;
      case 1:
        return (
          <RuleAnalytics
            isLoading={isLoadingRuleCryptoObjects || isLoadingRuleAlert}
            flaggedCount={ruleCryptoObjectsFlagged.flagged_count}
            hitRate={ruleCryptoObjectsFlagged.hit_rate}
            totalCount={ruleCryptoObjectsFlagged.total_count}
            ruleAlerts={ruleAlerts}
            type={policyDetails.type_verbose}
          />
        );
      case 2:
        return <RuleAuditTrail policyType={policyDetails.type_verbose} />;
    }
  };

  if (isPolicyDetailsLoading || isRuleDetailsLoading || isFetching) {
    return <CompassLoader />;
  } else {
    return (
      <>
        <div className='mb-4 flex gap-2 text-sm font-semibold shadow'>
          <div
            className='cursor-pointer border-b-4 border-white px-5 py-3.5'
            onClick={() => navigate('/settings/workspace/policies/')}
          >
            All Risk Policies
          </div>
          <div className='border-b-4 border-white py-3.5'>{'>'}</div>
          <div
            className='cursor-pointer border-b-4 border-white px-3 py-3.5'
            onClick={() => navigate(`/settings/workspace/policies/${params.policyId}/`)}
          >
            {policyDetails?.name}
          </div>
          <div className='border-b-4 border-white py-3.5'>{'>'}</div>
          <div className='cursor-pointer border-b-4 border-blue-600 px-3 py-3.5'>Edit Rule</div>
        </div>
        <div className='shadow'>
          <div className='flex gap-2 px-3 text-sm font-semibold shadow-sm'>
            <div
              onClick={() => setActiveTab(0)}
              className={classNames(
                activeTab === 0 ? 'border-blue-600' : 'border-white',
                'cursor-pointer border-b-4 px-3 py-4'
              )}
            >
              Rule Overview
            </div>
            <div
              onClick={() => setActiveTab(1)}
              className={classNames(
                activeTab === 1 ? 'border-blue-600' : 'border-white',
                'cursor-pointer border-b-4 px-3 py-4'
              )}
            >
              Rule Analytics
            </div>
            <div
              onClick={() => setActiveTab(2)}
              className={classNames(
                activeTab === 2 ? 'border-blue-600' : 'border-white',
                'cursor-pointer border-b-4 px-3 py-4'
              )}
            >
              Audit Trail
            </div>
          </div>

          {renderActiveTab()}
        </div>
      </>
    );
  }
};

export default RuleDetails;
