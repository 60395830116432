import { FC } from 'react';
import ApiKeysTable from '../../../../components/Settings/ApiKeys/ApiKeysTable';
import { TabHeader } from '../../../../components/ui/components/Header';

interface ApiKeysProps {}

const ApiKeys: FC<ApiKeysProps> = () => {
  return (
    <div className='h-full'>
      <TabHeader
        heading='API Keys'
        subheading={
          <>
            You need an API access token to communicate with Merkle Science&apos;s APIs. <br /> Read
            more about API access tokens and how to use them in our in our documentation.
          </>
        }
      />
      <a
        id='api-documentation'
        data-testid='api-documentation'
        href='/docs/api'
        className='text-sm font-semibold text-blue-600 hover:text-blue-900'
        target='_blank'
      >
        API Documentation →
      </a>
      <div className='mt-4'>
        <ApiKeysTable />
      </div>
    </div>
  );
};

export default ApiKeys;
