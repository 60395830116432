/* eslint-disable no-console */
import * as Sentry from '@sentry/react';
import React from 'react';
import {
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from 'react-router-dom';
import { getErrorMessage } from '../utils/helpers/helperFunctions';

Sentry.init({
  enabled: !!import.meta.env.VITE_SENTRY_ENABLED || false,
  dsn: import.meta.env.VITE_SENTRY_DSN,
  environment: import.meta.env.VITE_SENTRY_ENV,
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.extraErrorDataIntegration(),
  ],
  tracesSampleRate: 1.0,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  beforeSend(event, hint: any) {
    const errorMessage = getErrorMessage(hint.captureContext?.extra?.originalError)?.toLowerCase();
    if (
      hint.originalException === 'Timeout' ||
      errorMessage?.includes('ultrahub') ||
      errorMessage?.includes('megahub')
    )
      return null;
    if (
      event?.exception?.values?.[0]?.type === 'UnhandledRejection' &&
      event.exception.values.length === 1 &&
      event.exception.values[0].value === 'Non-Error promise rejection captured with value: '
    ) {
      return null;
    }
    return event;
  },
});
