import { unescape } from 'lodash';
import { usePolicyGetRuleList } from '../../api/policies';
import Table from '../../ui/components/Table/Table';
import {
  buildInfiniteQueryTableProps,
  flattenInfiniteQueryResult,
} from '../../utils/helpers/react-query.helper';
import PolicyStatusBadge from '../../ui/components/Badges/StatusBadge/PolicyStatusBadge';
import { Button, RiskBadge } from '../../ui';
import { IClassifierResult } from '../../api/dtos/classifier';
import { useRouter } from '../../modules/router/RouterProvider';
import RuleListFilter, { IFilters, defaultStateFilters } from './RuleListFilter';
import { useState } from 'react';
import RuleListAppliedFilters from './RuleListAppliedFilters';
import { convertPyBooleanToBoolean } from '../../utils/helpers/apiHelpers';
import Rule from '../Risks/Rule';
import { useCurrentRole } from '../../utils/helpers/common';
import { Search } from '../ui/components/Search';
import SortPanel from '../SortPanel/SortPanel';

interface IRuleListProps {}

const RuleList: React.FC<IRuleListProps> = () => {
  const [filters, setFilters] = useState(defaultStateFilters as IFilters);
  const [search, setSearch] = useState('');
  const [ordering, setOrdering] = useState('');

  const [isExpanded, setIsExpanded] = useState<string[]>([]);

  const rulesQueryFilters = {
    is_enabled:
      filters.ruleStatus === 'live' ? true : filters.ruleStatus === 'deactivated' ? false : null,
    policy_enabled:
      filters.policyStatus === 'live'
        ? true
        : filters.policyStatus === 'deactivated'
          ? false
          : null,
    policy_type: filters.policyType || null,
    alert_level: filters.riskLevel.map(r => r),
    // BE Filter rule list starts with 0 Info
  };

  const rulesQuery = usePolicyGetRuleList({ ...rulesQueryFilters, q: search, ordering });
  const headerData = [
    'Policy Name',
    'Policy Status',
    'Rule Name',
    'Rule Status',
    'Risk Level',
    'Custom Tag',
    '',
  ];

  const sortingOptions = [
    {
      sort_by: 'rule_name',
      label: ['A->Z', 'Z->A'],
    },
    {
      sort_by: 'created_at',
      label: ['Latest', 'Oldest'],
    },
    {
      sort_by: 'updated_at',
      label: ['Latest', 'Oldest'],
    },
  ];

  const [count, data] = flattenInfiniteQueryResult(rulesQuery?.data);

  const { navigate } = useRouter();
  const { isAdmin } = useCurrentRole();

  const onClickRule = (rule: IClassifierResult) => {
    navigate(`/settings/workspace/policies/${rule.policy}/rules/${rule.uuid}`);
  };

  const rows = data?.map(rule => ({
    id: rule.uuid,
    data: [
      <div
        id='rule-name'
        data-testid='rule-name'
        key={rule.uuid}
        onClick={e => {
          e.stopPropagation();
          onClickRule(rule);
        }}
        className='text-blue-500'
      >
        {rule.policy_name}
      </div>,
      <PolicyStatusBadge
        key={rule.uuid}
        isActive={convertPyBooleanToBoolean(rule.policy_enabled)}
      />,
      unescape(rule.rule_name),
      <PolicyStatusBadge key={rule.uuid} isActive={rule.is_enabled} />,
      <RiskBadge key={rule.uuid} risk={rule.alert_level} className='mt-1' />,
      rule.custom_tag_name,
      isAdmin ? (
        <Button id='rule-edit' key={rule.uuid} onClick={() => onClickRule(rule)} variant='tertiary'>
          Edit
        </Button>
      ) : null,
    ],
    extraRowContent: isExpanded.includes(rule.uuid) && (
      <div>
        {rule.rule_conditions.map((r, i) => (
          <div key={i} className='my-2 text-2xs leading-6 text-gray-500'>
            <Rule
              ruleType={r.rule_condition_type}
              type={(rule.policy_type_verbose.toLowerCase() as 'address') || 'transaction'}
              {...r}
            />
            {i !== rule.rule_conditions.length - 1 && (
              <div className='text-gray-500'>{rule.is_only_or_conditions ? 'OR' : 'AND'}</div>
            )}
          </div>
        ))}
      </div>
    ),
    className: 'hover:bg-gray-100 break-all',
  }));

  const onClickRow = (id: string) => {
    const index = isExpanded.findIndex(i => i === id);
    if (index === -1) {
      setIsExpanded([...isExpanded, id]);
    } else {
      const newExpanded = [...isExpanded];
      newExpanded.splice(index, 1);
      setIsExpanded(newExpanded);
    }
  };

  return (
    <Table
      border
      title='Rule List'
      headerData={headerData}
      rows={rows}
      count={count}
      isCountVerbose
      heightOffset={120}
      className='mt-2'
      onClickRow={onClickRow}
      headerWidths={[22, 13, 28, 10, 10, 10, 7]}
      filterComponent={
        <div className='flex'>
          <Search id='rule-search' value={search} setValue={setSearch} className='mr-2' />
          <SortPanel
            onApply={setOrdering}
            onReset={() => setOrdering('')}
            value={ordering}
            options={sortingOptions}
          />
          <RuleListFilter filters={filters} onApply={setFilters} />
        </div>
      }
      appliedFilters={
        <RuleListAppliedFilters
          ordering={ordering}
          setOrdering={setOrdering}
          filters={filters}
          setFilters={setFilters}
        />
      }
      {...buildInfiniteQueryTableProps(rulesQuery)}
    />
  );
};

export default RuleList;
