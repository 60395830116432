import * as React from 'react';
import classNames from 'classnames';
import { formatDateShort } from '../../../../utils/helpers/date';
import AppliedFilters from '../../../../ui/components/Badges/AppliedFiltersBadge/AppliedFiltersBadge';

import { IFilters, defaultBillingFilters } from './BillingFilter';

interface Props {
  filters: IFilters;
  setFilters: (filters: IFilters) => void;
}

const BillingAppliedFilter: React.FC<Props> = ({ filters, setFilters }) => {
  const isFilterApplied = React.useMemo(() => {
    if (filters.start_date) return true;
    if (filters.end_date) return true;
  }, [filters]);

  const resetFilter = () => {
    setFilters(defaultBillingFilters);
  };

  return (
    <div
      className={classNames(
        'flex justify-stretch border-t-[1px] bg-gray-50',
        isFilterApplied ? 'px-2 py-2.5' : ''
      )}
    >
      <div className='flex w-full items-center justify-between'>
        <div className='flex flex-wrap items-center'>
          {filters.start_date && (
            <AppliedFilters
              onRemove={() => {
                setFilters({
                  ...filters,
                  start_date: null,
                  end_date: null,
                });
              }}
            >
              Date: {formatDateShort(filters.start_date)} - {formatDateShort(filters.end_date)}
            </AppliedFilters>
          )}
        </div>
        {isFilterApplied && (
          <div className='flex items-center'>
            <button
              id='billing-reset'
              data-testid='billing-reset'
              className='mr-2 text-2xs text-blue-600'
              onClick={() => resetFilter()}
            >
              Reset Filters
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default BillingAppliedFilter;
