import { colorDarkDefault, colorsDark } from '../../../utils/helpers/drawCanvasElements';
import {
  IInvestigationTreeNode,
  IInvestigationTreeResponse,
} from '../../../api/dtos/investigationTree';
import { StylesConfig } from 'react-select';
import { EntityOption } from './types';

export const getAvgLinkVal = (investigationTree: IInvestigationTreeResponse): number => {
  const inMax = investigationTree.incoming_edges.reduce((acc, curr) => {
    return acc < curr.value ? acc : curr.value;
  }, NaN);

  const outMax = investigationTree.outgoing_edges.reduce(function (acc, curr) {
    return acc < curr.value ? acc : curr.value;
  }, NaN);

  const inMin = investigationTree.incoming_edges.reduce(function (acc, curr) {
    return acc > curr.value ? acc : curr.value;
  }, NaN);

  const outMin = investigationTree.outgoing_edges.reduce(function (acc, curr) {
    return acc > curr.value ? acc : curr.value;
  }, NaN);

  const minLinkVal = inMin < outMin ? inMin : outMin;
  const maxLinkVal = inMax > outMax ? inMax : outMax;

  const avgLinkVal = (minLinkVal + maxLinkVal) / 2;

  return avgLinkVal;
};

export const truncateAddress = (address: string, maxChars = 13, trailingCharCount = 5): string => {
  if (!address) {
    return '';
  }
  address = address.toString();
  let result = address;
  if (address.length > maxChars) {
    const front = address.substr(0, maxChars - trailingCharCount - 3);
    const mid = '...';
    const end = address.substr(-trailingCharCount);

    result = front + mid + end;
  }
  return result;
};

export const truncateName = (name: string, maxChars = 13): string => {
  if (!name) {
    return '';
  }
  name = name.toString();
  let result = name;
  if (name.length > maxChars) {
    const front = name.substr(0, maxChars - 3);

    result = front + '...';
  }
  return result;
};

export const formatAmountAsString = (amt: number | string, fixedPoint = 2) => {
  if (!amt) {
    return 0;
  }
  const nf = Intl.NumberFormat();
  return nf.format(Number(Number(amt).toFixed(fixedPoint)));
};

export const createNode = (item: IInvestigationTreeNode, id: string, txId: string) => {
  return {
    ...item,
    id,
    name: id?.toLowerCase() === txId?.toLowerCase() ? 'Origin' : id.slice(1),
    addresses: item.addresses?.length
      ? item.addresses
      : id?.toLowerCase() === txId?.toLowerCase()
        ? [id]
        : [id.slice(1)],
    nodeColor: item.tag_type_verbose ? colorsDark[item.tag_type_verbose] : colorDarkDefault,
    processed: false,
  };
};

export const createLink = (item, source, target, linkColor, linkWidth = 1) => {
  return {
    id: source + '_' + target,
    source,
    target,
    value: formatAmountAsString(item.value, 2),
    numberedValue: item.value,
    color: linkColor,
    width: linkWidth,
  };
};

export const formatSenderReceiver = (address: string, minTime: number, maxTime: number) => {
  return {
    address,
    min_transaction_time_epoch: minTime,
    max_transaction_time_epoch: maxTime,
  };
};

const dot = (color = 'transparent') => ({
  alignItems: 'center',
  display: 'flex',

  ':before': {
    backgroundColor: color,
    borderRadius: 10,
    content: '" "',
    display: 'block',
    marginRight: 5,
    marginLeft: 5,
    height: 10,
    width: 10,
  },
});

export const colorStyles: StylesConfig<EntityOption> = {
  control: styles => ({
    ...styles,
    backgroundColor: 'white',
    borderColor: 'rgb(229, 231, 235)',
    borderRadius: 6,
    fontSize: 14,
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    const color = data.color;
    const hoverColor = data.color + '80';
    return {
      ...styles,
      backgroundColor: isDisabled
        ? undefined
        : isSelected
          ? data.color
          : isFocused
            ? hoverColor
            : undefined,
      color: isDisabled ? '#ccc' : '#1f2937',
      cursor: isDisabled ? 'not-allowed' : 'default',
      ...dot(data.color),
      ':active': {
        ...styles[':active'],
        backgroundColor: !isDisabled ? (isSelected ? data.color : color) : undefined,
      },
    };
  },
  multiValue: (styles, { data }) => {
    const bckgColor = data.color + '20';
    return {
      ...styles,
      backgroundColor: bckgColor,
      ...dot(data.color),
    };
  },
  multiValueLabel: styles => ({
    ...styles,
    color: '#1f2937',
    paddingLeft: 0,
  }),
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    color: data.color,
    ':hover': {
      backgroundColor: data.color,
      color: 'white',
    },
  }),
};
