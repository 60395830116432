import { useLocalStorage } from 'react-use';
import Popover from '../../../ui/components/Popover/Popover';
import {
  formatOnlyDate,
  formatDateTime,
  formatDateShort,
  formatDateAgo,
} from '../../../utils/helpers/date';

export interface IDateProps {
  date: string | number;
  type: 'date' | 'datetime' | 'short' | 'duration';
  tz?: string;
}

export const Date = (props: IDateProps) => {
  const { date, type, tz } = props;
  const [tzLocalStorage] = useLocalStorage<string>('tz');
  const tzValue = tz || tzLocalStorage;

  if (!date) return null;
  let dateStr = '';
  if (type === 'date') {
    dateStr = formatOnlyDate(date, tzValue);
  } else if (type === 'datetime') {
    dateStr = formatDateTime(date, tzValue);
  } else if (type === 'short') {
    dateStr = formatDateShort(date, tzValue);
  } else if (type === 'duration') {
    dateStr = formatDateAgo(date);
  } else {
    dateStr = formatDateTime(date, tzValue);
  }

  return (
    <Popover
      referenceContent={dateStr}
      popoverContent={
        <span>
          {formatDateTime(date, tzValue)} ({tzValue})
        </span>
      }
      isBasic
    />
  );
};
