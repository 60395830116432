import * as React from 'react';

import { BasicModal, Button } from '../../../ui';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  downloadSelectedCustomers: () => void;
}

const CustomerListExportModal: React.FC<Props> = ({
  isOpen,
  onClose,
  downloadSelectedCustomers,
}) => {
  return (
    <BasicModal
      size='sm'
      open={isOpen}
      onClose={onClose}
      modalTitle='Export'
      modalBody={
        <div className='z-[100000]'>
          <p className='text-sm'>
            You have selected to download Customers list as a CSV file . Would you like to proceed?
          </p>
          <div className='mt-4 flex justify-end gap-2'>
            <Button variant='tertiary' onClick={onClose}>
              Cancel
            </Button>
            <Button
              onClick={() => {
                downloadSelectedCustomers();
                onClose();
              }}
            >
              Confirm
            </Button>
          </div>
        </div>
      }
    />
  );
};

export default CustomerListExportModal;
