import { IAddressResponse } from '../../../api/dtos/address';
import { useAuth } from '../../../modules/auth';
import { useWorkspace } from '../../../utils/helpers/common';
import WidgetTooltip from '../../Dashboard/WidgetTooltip';
import MonitoringBadge from '../../ui/components/Badge/MonitoringBadge';

export const MonitoringLabel = () => (
  <div className='flex items-center'>
    <span className='mr-1'>Monitoring</span>
    <div className='normal-case'>
      <WidgetTooltip
        title={'Monitoring'}
        description={
          'If enabled, risk checks are performed on this address every time it receives a new transaction'
        }
      />
    </div>
  </div>
);

export const MonitoringValue = ({ address }: { address: IAddressResponse }) => {
  const { state } = useAuth();
  const workspace = useWorkspace();
  const getMonitoringMessage = () => {
    if (!state.userProfile.can_enable_continuous_monitoring) {
      return 'Continuous Monitoring feature is currently unavailable on your account. Please reach out to support.';
    }
    if (workspace.continuous_monitoring_level === 0) {
      if (address.is_muted && address.is_custom_ignored) {
        return 'Continuous Monitoring feature is disabled in Settings. Go to Setting & Product Configurations & Monitoring config to enable it. And also remove this wallet from <Monitoring deactivated Addresses>.';
      }
      return 'Continuous Monitoring feature is disabled in Settings. Go to Setting & Product Configurations & Monitoring config to enable it.';
    }
    if (
      workspace.continuous_monitoring_level === 1 &&
      address.is_muted &&
      address.is_custom_ignored
    ) {
      return 'Wallet is part of Monitoring deactivated list. Enable monitoring to start monitoring.';
    }
    if (
      workspace.continuous_monitoring_level === 2 &&
      address.is_muted &&
      !address.is_custom_monitored
    ) {
      return 'Enable monitoring to start monitoring this wallet.';
    }
    if (workspace.continuous_monitoring_level === 3 && address.is_muted) {
      return 'Entity based monitoring is enabled and it monitors only entity tagged wallets';
    }
    if (address.is_muted) {
      return 'Monitoring deactivated for this wallet.';
    }
    return '';
  };
  return (
    <div className='flex items-center'>
      <span className='ml-2'>
        <MonitoringBadge isEnabled={!getMonitoringMessage()} inactiveText='inactive' />
      </span>
      {getMonitoringMessage() && (
        <span className='mx-1 flex font-normal'>
          <WidgetTooltip title={'Continous Monitoring'} description={getMonitoringMessage()} />
        </span>
      )}
    </div>
  );
};
