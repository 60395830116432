import ReactDOMServer from 'react-dom/server';
import { Tooltip } from 'react-tooltip';

import { getCurrencyShortValue } from '../../../utils/helpers/currency';
import { formatDateTime } from '../../../utils/helpers/date';

import CurrencyValue from '../../../components/ui/components/Currency/CurrencyValue';
import BasicBadge from '../../../ui/components/Badges/BasicBadge/BasicBadge';

export const Fee = ({ transaction }) => (
  <div className='flex'>
    <CurrencyValue
      value={transaction.fee}
      currency={getCurrencyShortValue(transaction.currency_short)}
    />
    <span className='mx-3 border border-r-2 border-gray-200' />
    <CurrencyValue value={transaction.fee_usd} currency='USD' />
  </div>
);

export const TransactionTime = ({ transaction, state }) => {
  if (transaction?.block_date_time) {
    return <>{formatDateTime(transaction?.block_date_time, state.userProfile.timezone)}</>;
  }
  return (
    <>
      <span
        className='items-center justify-center rounded-full bg-gray-100 text-center text-gray-900'
        data-tooltip-id='unconfirmed-transaction'
        data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
          <p className='w-36 text-xs'>
            This transaction has been discovered on the mempool but has not been added to the
            blockchain.
          </p>
        )}
      >
        <BasicBadge>
          Unconfirmed <span className='my-auto ml-1 size-3 rounded-full bg-gray-300'>?</span>
        </BasicBadge>
      </span>
      <Tooltip place='bottom' id='unconfirmed-transaction' data-testid='unconfirmed-transaction' />
    </>
  );
};
