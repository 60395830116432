import React, { useRef } from 'react';
import { Paperclip, File, X } from '@phosphor-icons/react';
import { Button } from '../../../ui';

type FileAttachProps = {
  onUpload: (files: FileList) => void;
  files?: FileList;
};

const FileAttach: React.FC<FileAttachProps> = ({ onUpload, files }) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const selectedFileArray = files || [];

  const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    onUpload(files);
  };

  const handleFileAttach = () => {
    fileInputRef.current?.click();
  };

  const getSelectedFilesText = () => {
    if (selectedFileArray.length === 1) {
      return selectedFileArray[0].name;
    } else {
      return `${selectedFileArray[0].name} +${selectedFileArray.length - 1}`;
    }
  };

  return (
    <div className='my-auto flex'>
      {selectedFileArray.length > 0 && (
        <div className='group relative ml-3 rounded-lg border border-gray-200 bg-white'>
          <label data-testid='file-upload' htmlFor='file-upload' className='mr-1 flex'>
            <File className='mx-1 my-auto text-gray-500' weight='fill' />
            {getSelectedFilesText()}
          </label>
          <div
            id='removeUploads'
            data-testid='removeUploads'
            className='absolute right-0 top-1 hidden cursor-pointer bg-white px-1 group-hover:flex'
            onClick={() => onUpload(null)}
          >
            <X weight='bold' color='#000' className='rounded-full bg-gray-300 p-0.5' />
          </div>
        </div>
      )}
      <input
        id='file-upload'
        data-testid='file-upload'
        type='file'
        className='hidden'
        multiple
        onChange={handleFileSelect}
        ref={fileInputRef}
      />
      <Button
        id='attachFile'
        variant='tertiary'
        className='ml-3 p-1 px-2'
        onClick={handleFileAttach}
      >
        <Paperclip size={16} />
      </Button>
    </div>
  );
};

export default FileAttach;
