import * as React from 'react';

import { BasicModal, Button } from '../../ui';
import { addressApi } from '../../api/address';
import { transactionApi } from '../../api/transaction';
import { customerApi } from '../../api/customer';
import { caseApi } from '../../api/case';
import { useAuth } from '../../modules/auth';
import { toast } from '../../ui/components/Toast';
import { IStageListLite } from '../../api/dtos/stages';
import SingleSelect, { IOption } from '../ui/components/Select/SingleSelect';
import { IStageOptions } from './Alerts/Alert/AlertButtonPanel/AlertButtonPanel';
import { getColorMappingStageOptions } from '../../utils/helpers/stageOptionsColor';
import { useStagesListLiteGet } from '../../api/stages';
import StageBadge from '../../ui/components/Badges/StageBadge/StageBadge';

interface Props {
  type: 'address' | 'transaction' | 'customer' | 'case';
  isOpen: boolean;
  isAllEntitiesSelected: boolean;
  onClose: () => void;
  selectedEntities: number[];
  refetchList: () => void;
  selectedTab?: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  filters?: any;
  closedStage?: IStageListLite;
  setSelectedEntities: (addresses: number[]) => void;
  setIsAllEntitiesSelected: (boolean) => void;
}

const ResolveMultipleAlertsModal: React.FC<Props> = ({
  type,
  isOpen,
  onClose,
  selectedEntities,
  isAllEntitiesSelected,
  refetchList,
  selectedTab = -1,
  setIsAllEntitiesSelected,
  setSelectedEntities,
  ...props
}) => {
  const [alertComment, setAlertComment] = React.useState('');
  const [selectedOption, setSelectedOption] = React.useState<IStageOptions>(null);
  const { state } = useAuth();

  const statusesListLiteQuery = useStagesListLiteGet(true);

  const getStageOptions = stageData => {
    return stageData
      ?.filter(r => r?.category === 2)
      .map(r => ({
        id: r.id,
        value: r.name,
        label: r.name,
      }));
  };
  const stageOptionsData = getStageOptions(statusesListLiteQuery?.data?.data?.results);
  const stageColorMap = getColorMappingStageOptions(statusesListLiteQuery?.data?.data?.results);

  const resolveOpenAlerts = async () => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const params: any =
      selectedTab === 0 && isAllEntitiesSelected
        ? { filters: props.filters, assigned_to: state.userProfile?.id }
        : selectedTab === 1 && isAllEntitiesSelected
          ? { filters: props.filters, assigned_to: 'None' }
          : selectedTab === 2 && isAllEntitiesSelected
            ? { filters: props.filters, workflow_stage: props?.closedStage?.id, closed: true }
            : selectedTab === 3 && isAllEntitiesSelected
              ? { filters: props.filters }
              : {};
    params.ids = isAllEntitiesSelected ? [] : selectedEntities;
    params.comment = alertComment;
    params.updated_status = selectedOption.id;
    let res;
    let case_params;
    const filters = { ...props.filters };
    switch (type) {
      case 'address':
        res = await addressApi.updateAddressAlerts(params);
        break;
      case 'transaction':
        res = await transactionApi.updateTransactionAlerts(params);
        break;
      case 'customer':
        res = await customerApi.updateCustomerAlerts(params);
        break;
      case 'case':
        if (selectedTab === 0) {
          filters.assigned_to = [state?.userProfile?.id];
        } else if (selectedTab === 1) {
          filters.assigned_to = ['None'];
        }
        case_params =
          selectedTab === 0 && isAllEntitiesSelected
            ? { filters }
            : selectedTab === 1 && isAllEntitiesSelected
              ? { filters }
              : selectedTab === 2 && isAllEntitiesSelected
                ? { status: 2, closed: true, filters }
                : selectedTab === 3 && isAllEntitiesSelected
                  ? { filters }
                  : {};
        case_params.ids = isAllEntitiesSelected ? [] : selectedEntities;
        case_params.comment = alertComment;
        case_params.updated_status = selectedOption.id;
        res = await caseApi.updateCaseAlerts(case_params);
        break;
    }

    toast.success(res.data.message);
    setAlertComment('');
    setSelectedEntities([]);
    setIsAllEntitiesSelected(false);
    refetchList();
    onClose();
  };

  const handleChangeStage = (stage: IOption) => {
    setSelectedOption(stage);
  };

  const getCustomOptionLabel = (option: IStageOptions) => {
    if (option.label === undefined) {
      return <StageBadge role={8} label='-NA-' />;
    }
    return <StageBadge role={stageColorMap[option?.id]} label={option?.label} />;
  };

  return (
    <BasicModal
      size='sm'
      open={isOpen}
      onClose={onClose}
      modalTitle='Update Open Alerts'
      modalBody={
        <div className='flex flex-col gap-5 px-4 py-3 text-sm'>
          <div className='w-full gap-6'>
            <div className='flex w-full items-center gap-6'>
              <p className='w-1/5 text-gray-500'>Updated Status</p>
              <SingleSelect
                options={stageOptionsData}
                handleChange={handleChangeStage}
                value={selectedOption}
                isCloseHidden={true}
                showRadioButton={true}
                getCustomLabel={getCustomOptionLabel}
                getCustomOptionLabel={getCustomOptionLabel}
                parentClassName='flex-grow'
                filterOptions={() => true}
              />
            </div>
          </div>
          <div className='flex flex-col gap-2'>
            <p className='text-sm font-medium leading-5 text-gray-500'>Comment</p>
            <textarea
              name='alert-update'
              id='alert-update'
              data-testid='alert-update'
              placeholder='Enter your comment here'
              className='w-full rounded-md border-gray-400 text-sm text-gray-600'
              value={alertComment}
              onChange={e => setAlertComment(e.target.value)}
              required
              rows={5}
            />
          </div>
        </div>
      }
      modalFooter={
        <div className='flex justify-end gap-4'>
          <Button variant='tertiary' onClick={onClose}>
            Cancel
          </Button>
          <Button
            disabled={alertComment?.length === 0 || selectedOption === null}
            onClick={resolveOpenAlerts}
          >
            Confirm
          </Button>
        </div>
      }
    />
  );
};

export default ResolveMultipleAlertsModal;
