import { FC } from 'react';
import { ConfirmationModal } from '../../../ui';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { useRouter } from '../../../modules/router/RouterProvider';
import { caseApi } from '../../../api/case';
import { ICaseResponse } from '../../../api/dtos/case';

interface ICaseDeleteModalProps {
  caseData: ICaseResponse;
  open: boolean;
  setOpen: (open: boolean) => void;
}

const CaseDeleteModal: FC<ICaseDeleteModalProps> = props => {
  const { caseData, open, setOpen } = props;

  const { navigate } = useRouter();

  const onSuccess = () => {
    toast.success('Case Deleted');
    navigate('/cases');
  };

  const { mutate } = useMutation(caseApi.deleteCase, { onSuccess });

  const ModalBody: FC = () => {
    return (
      <div>
        Are you sure you want to delete the case <strong>{caseData?.case_id}</strong>?
      </div>
    );
  };

  const onConfirm = () => {
    mutate(caseData?.id.toString());
    setOpen?.(false);
  };

  const onCancel = () => {
    setOpen?.(false);
  };

  return (
    <>
      <ConfirmationModal
        open={open}
        data={open || null}
        onCancel={onCancel}
        onConfirm={onConfirm}
        title={'Delete this Case'}
        body={<ModalBody />}
      />
    </>
  );
};

export default CaseDeleteModal;
