import { FC, useState } from 'react';
import { BasicModal, Button, Textarea } from '../../../ui';
import { useMutation } from 'react-query';
import { IAddressResponse } from '../../../api/dtos/address';
import { getCurrencyImageById } from '../../../utils/helpers/currency';
import FileAttach from '../../common/AuditTrail/FileAttach';
import { addressApi } from '../../../api/address';
import { toast } from '../../../ui/components/Toast';

interface IIKnowThisAddressProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  address: IAddressResponse;
}

const IKnowThisAddress: FC<IIKnowThisAddressProps> = ({ address, open, setOpen }) => {
  const { mutate } = useMutation(addressApi.addKnowThisAddressComment, {
    onSuccess: () => {
      toast.success('Comment added successfully');
      setOpen(false);
    },
  });

  const ModalBody: FC = () => {
    const [file, setFile] = useState<FileList>();
    const [comment, setComment] = useState('');

    const onConfirm = () => {
      mutate({ identifier: address.id, currency: address.currency, comment, support_file: file });
    };
    return (
      <div>
        <p className='text-gray-500'>
          Tell us about it and we will add it to our database so that next time, we can identify it
          for you. Thank you for helping us improve :)
        </p>
        <div className='text-medium mt-2 text-base'>Address</div>
        <div className='my-2 flex'>
          <img
            className='mr-1'
            width={16}
            src={getCurrencyImageById(address.currency)}
            alt={address.currency_short}
          />
          {address.identifier}
        </div>
        <div className='text-medium mb-2 mt-4 text-base'>How do you know this address?</div>
        <Textarea
          id='comment'
          data-testid='comment'
          value={comment}
          onChange={setComment}
          placeholder=' '
        />
        <div className='mt-2'>
          <FileAttach files={file} onUpload={setFile} />
        </div>
        <div className='mt-4 flex justify-end'>
          <Button variant='tertiary' onClick={() => setOpen(false)} className='mr-2'>
            Cancel
          </Button>
          <Button onClick={onConfirm} variant='primary'>
            Submit
          </Button>
        </div>
      </div>
    );
  };

  return (
    <BasicModal
      open={open}
      onClose={() => setOpen(false)}
      modalTitle='Do you know this address?'
      modalBody={<ModalBody />}
    />
  );
};

export default IKnowThisAddress;
