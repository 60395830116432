import * as React from 'react';

import { getErrorMessage } from '../../../utils/helpers/helperFunctions';
import { CaseStatus } from '../../../utils/constants/constants';

import { caseApi } from '../../../api/case';

import { ICaseBulkActionFilters } from '../../../views/dashboard/cases/CaseList';

import { BasicModal, Button, Dropdown, DropdownOption, Textarea } from '../../../ui';
import { toast } from '../../../ui/components/Toast';

interface Props {
  isOpen: boolean;
  onClose: () => void;

  selectedCases: number[];
  isAllCaseSelected: boolean;

  bulkActionFilter: ICaseBulkActionFilters;

  refetchList: () => void;
}

const ChangeCaseStatusModal: React.FC<Props> = ({
  isOpen,
  onClose,
  selectedCases,
  isAllCaseSelected,
  bulkActionFilter,
  refetchList,
}) => {
  const options: DropdownOption[] = CaseStatus;

  const [status, setStatus] = React.useState<DropdownOption>(null);
  const [comment, setComment] = React.useState('');

  const changeCaseStatus = async () => {
    try {
      const res = await caseApi.updateCaseStatus(
        {
          cases: isAllCaseSelected ? [] : selectedCases,
          status: Number(status.value),
          comment,
        },
        bulkActionFilter
      );

      if (res.status === 200) {
        setStatus(null);
        setComment('');
        toast.success('Cases Updated Successfully');

        refetchList();
        onClose();
      }
    } catch (error) {
      if (Array.isArray(getErrorMessage(error))) {
        getErrorMessage(error).forEach(err => toast.error(err));
      } else {
        toast.error(getErrorMessage(error));
      }
    }
  };

  return (
    <BasicModal
      size='sm'
      open={isOpen}
      onClose={onClose}
      modalTitle='Change Status'
      modalBody={
        <div className='z-[100000]'>
          <form
            className='flex flex-col'
            onSubmit={e => {
              e.preventDefault();
              changeCaseStatus();
            }}
          >
            <Dropdown
              labelText='Status'
              value={status}
              onChange={val => setStatus(val)}
              placeholder='Change Status'
              options={options}
            />
            <div className='my-2.5' />
            <Textarea
              id='comment'
              type='text'
              value={comment}
              labelText='Add Comment'
              placeholder='Add your comment here...'
              onChange={setComment}
              required
            />
            <div className='mt-4 flex justify-end gap-2'>
              <Button variant='tertiary' onClick={onClose}>
                Cancel
              </Button>
              <Button type='submit'>Yes</Button>
            </div>
          </form>
        </div>
      }
    />
  );
};

export default ChangeCaseStatusModal;
