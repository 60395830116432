import { FC } from 'react';
import { transactionTypesOptions } from '../../utils/helpers/helperFunctions';
import SingleSelect from '../ui/components/Select/SingleSelect';

interface TransactionTypeFilterProps {
  value: string;
  setValue: (type: string) => void;
}

const TransactionTypeFilter: FC<TransactionTypeFilterProps> = props => {
  const { value, setValue } = props;

  const currentOption = transactionTypesOptions.find(option => option.value === value);

  return (
    <>
      <SingleSelect
        options={transactionTypesOptions}
        handleChange={value => setValue(value?.value)}
        value={currentOption}
        label='Transaction Type'
        labelClassName='text-sm'
      />
    </>
  );
};

export default TransactionTypeFilter;
