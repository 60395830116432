import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { authApi } from '../../api/auth';
import CompassLoader from '../../components/ui/components/Loader/CompassLoader';
import { useAuth } from '../../modules/auth';
import { useEffectOnce } from 'react-use';
import { removeOrganizationInfo, removeUserEmail } from '../../utils/helpers/cookies';
import { useRouter } from '../../modules/router/RouterProvider';

const Logout = () => {
  const { state, logout } = useAuth();
  const { getQueryParams } = useRouter();
  const clearTokenAndCookies = () => {
    const { clear } = getQueryParams();
    if (clear === 'true') {
      removeOrganizationInfo();
    }
    removeUserEmail();
  };
  const { mutate } = useMutation(authApi.logout, {
    onError: (err: Error) => {
      toast.error(err.message);
    },
    onSettled: () => {
      clearTokenAndCookies();
    },
  });
  const logoutUser = () => {
    mutate({ token: state.access });
    logout({});
  };

  useEffectOnce(() => {
    logoutUser();
  });

  return (
    <div>
      <div className='text-xl mb-6 text-center font-medium uppercase text-gray-800'>
        Logging out
      </div>
      <CompassLoader />
    </div>
  );
};

export default Logout;
