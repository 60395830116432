import { FC, useEffect, useState } from 'react';
import { BasicModal, Button, RoleBadge } from '../../../ui';
import { useMutation } from 'react-query';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import InputController from '../../../ui/components/Form/InputController';
import { toast } from '../../../ui/components/Toast';
import { teamMembersApi } from '../../../api/settings/teamMembers';
import { ITeamMember } from '../../../api/dtos/settings/teamMembers';
import ToggleController from '../../../ui/components/Form/ToggleController';
import { IWorkspace, IWorkspaceMembersResult } from '../../../api/dtos/workspaces';
import { startCase } from 'lodash';
import WorkspaceBadge from '../../ui/components/Badge/WorkspaceBadge';
import SingleSelectController from '../../ui/components/Select/SingleSelectController';
import { Role, Roles } from '../../../ui/utils/constants';
import { workspacesApi } from '../../../api/workspaces';

export interface TeamMemberEditModalProps {
  onClose?: (refetch?: boolean) => void;
  open: boolean;
  teamMember: ITeamMember | IWorkspaceMembersResult;
  workspace?: IWorkspace;
  type: 'organisation' | 'workspace';
}

const validations = Yup.object({
  email: Yup.string().email().required().label('Email Address'),
  is_active: Yup.boolean().label('Enable this User'),
  role: Yup.object().label('Role'),
});

const initialData = {
  email: '',
  is_active: true,
  role: {} as (typeof Roles)[0],
};

const TeamMemberEditModal: FC<TeamMemberEditModalProps> = props => {
  const { open, onClose, teamMember, workspace, type } = props;

  const { mutate: updateTeamMember } = useMutation(teamMembersApi.updateTeamMember);
  const { mutate: updateWorkspaceMember } = useMutation(workspacesApi.updateWorkspaceMember);

  const [adminWorkspaces, setAdminWorkspaces] = useState<string[]>([]);
  const [agentWorkspaces, setAgentWorkspaces] = useState<string[]>([]);

  const {
    control,
    handleSubmit,
    setValue,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: initialData,
    resolver: yupResolver(validations),
  });

  const isFormDisable = isSubmitting;

  useEffect(() => {
    if (teamMember) {
      if ('memberships' in teamMember) {
        setAdminWorkspaces(
          teamMember.memberships.filter(m => m.role === 1).map(m => m.workspace) ?? []
        );
        setAgentWorkspaces(
          teamMember.memberships.filter(m => m.role === 2).map(m => m.workspace) ?? []
        );
      } else {
        setValue(
          'role',
          Roles.find(r => r.value === teamMember.role)
        );
        setAdminWorkspaces([workspace?.name ?? '']);
      }
      setValue('email', teamMember?.email ?? '');
      setValue('is_active', teamMember?.is_active ?? true);
    }
  }, [setValue, teamMember, workspace?.name]);
  const onSubmit = (req: typeof initialData) => {
    const is_active = req.is_active ?? true;
    const role = req.role?.value;
    const id = teamMember?.id;
    type === 'organisation'
      ? updateTeamMember(
          { id, is_active },
          {
            onSuccess: () => {
              toast.success('Team member status changed successfully!');
            },
            onError: () => {
              toast.error('Failed to update member, Please try again.');
            },
            onSettled: () => {
              onClose(true);
            },
          }
        )
      : updateWorkspaceMember(
          { id, is_active, role },
          {
            onSuccess: () => {
              toast.success('Workspace member status changed successfully!');
            },
            onError: () => {
              toast.error('Failed to update member, Please try again.');
            },
            onSettled: () => {
              onClose(true);
            },
          }
        );
  };
  const getCustomRoleLabel = option => <RoleBadge role={Number(option.value) as Role} />;

  return (
    <BasicModal
      open={open}
      onClose={() => onClose?.()}
      modalTitle={`Change Status of ${startCase(type)} Member`}
      modalBody={
        <div className='pb-0 text-sm font-normal leading-default'>
          {
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className='mb-4'>
                <InputController
                  id='email'
                  name='email'
                  type='email'
                  labelText='Email Address'
                  placeholder='Email Address'
                  required={true}
                  control={control}
                  disabled
                />
              </div>
              <div className='grid grid-cols-2 gap-2'>
                <div className='text-gray-500'>Role</div>
                <div className='text-gray-500'>Workspace</div>
                {type === 'workspace' ? (
                  <>
                    <SingleSelectController
                      name='role'
                      options={Roles.filter((r, index) => ![2].includes(index) && index <= 4).map(
                        r => ({
                          label: r.label,
                          value: r.value.toString(),
                        })
                      )}
                      getCustomLabel={getCustomRoleLabel}
                      getCustomOptionLabel={getCustomRoleLabel}
                      control={control}
                      isCloseHidden
                    />
                    <div className='mt-1 rounded-md border border-gray-200 bg-gray-50 p-2'>
                      {adminWorkspaces.map((a, i) => (
                        <WorkspaceBadge key={a} i={i} label={a} />
                      ))}
                    </div>
                  </>
                ) : (
                  <>
                    {!!adminWorkspaces?.length && (
                      <>
                        <div className='rounded-md border border-gray-200 bg-gray-50 p-2'>
                          <RoleBadge role={1} />
                        </div>
                        <div className='rounded-md border border-gray-200 bg-gray-50 p-2'>
                          {adminWorkspaces.map((a, i) => (
                            <WorkspaceBadge key={a} i={i} label={a} />
                          ))}
                        </div>
                      </>
                    )}
                    {!!agentWorkspaces?.length && (
                      <>
                        <div className='rounded-md border border-gray-200 bg-gray-50 p-2'>
                          <RoleBadge role={2} />
                        </div>
                        <div className='rounded-md border border-gray-200 bg-gray-50 p-2'>
                          {agentWorkspaces.map((a, i) => (
                            <WorkspaceBadge key={a} i={i} label={a} />
                          ))}
                        </div>
                      </>
                    )}
                  </>
                )}
              </div>

              <div className='mt-4'>
                <ToggleController
                  control={control}
                  labelText={'Enable Member'}
                  name={'is_active'}
                  disabled={isFormDisable}
                />
              </div>
            </form>
          }
        </div>
      }
      modalFooter={
        <div className='flex justify-end gap-4'>
          <Button variant='tertiary' disabled={isFormDisable} onClick={() => onClose()}>
            Cancel
          </Button>
          <Button disabled={isFormDisable} onClick={() => handleSubmit(onSubmit)()}>
            {'Confirm'}
          </Button>
        </div>
      }
    />
  );
};

export default TeamMemberEditModal;
