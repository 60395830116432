import { FC } from 'react';
import BasicBadge from '../BasicBadge/BasicBadge';
import { Stage, Stages } from '../../../utils/constants';

export interface StageBadgeProps {
  role: Stage;
  className?: string;
  label: string;
}

const StageBadge: FC<StageBadgeProps> = props => {
  const className = Stages[props.role]?.className;
  return (
    <BasicBadge id={'stage-badge'} className={className + ' ' + props.className}>
      {props.label}
    </BasicBadge>
  );
};

export default StageBadge;
