import * as React from 'react';
import { toast } from 'react-toastify';

import { getErrorMessage } from '../../../utils/helpers/helperFunctions';

import { policyApi } from '../../../api/policies';
import { IPolicyResponse } from '../../../api/dtos/policies';

import { BasicModal, Button, Checkbox, ConfirmationModal, Input, Textarea } from '../../../ui';
import SingleSelect from '../../../components/ui/components/Select/SingleSelect';
import { IOption } from '../../../components/Filters/CustomSelect';

interface Props {
  isOpen: boolean;
  onClose: (boolean) => void;
  policyDetails: IPolicyResponse;
}

const EditPolicyDetailsModal: React.FC<Props> = ({ isOpen, onClose, policyDetails }) => {
  const [policyName, setPolicyName] = React.useState('');
  const [policyDescription, setPolicyDescription] = React.useState('');
  const [policyType, setPolicyType] = React.useState<IOption>(null);
  const [policyCategory, setPolicyCategory] = React.useState<IOption>(null);
  const [policyEnabled, setPolicyEnabled] = React.useState(true);
  const [policyEnabledConfirm, setPolicyEnabledConfirm] = React.useState(false);
  React.useEffect(() => {
    if (policyDetails) {
      resetData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [policyDetails]);

  const updatePolicy = async () => {
    try {
      const response = await policyApi.updatePolicy({
        id: String(policyDetails.id),
        name: policyName,
        description: policyDescription,
        type: policyDetails.type,
        category: policyDetails.category,
        is_enabled: policyEnabled,
      });

      if (response.status === 200) {
        toast.success('Policy Updated Successfully');

        onClose(true);
      }
    } catch (error) {
      toast.error(getErrorMessage(error));
    }
  };

  const resetData = () => {
    setPolicyName(policyDetails.name);
    setPolicyDescription(policyDetails.description);
    setPolicyType({ label: policyDetails.type_verbose, value: String(policyDetails.type) });
    setPolicyCategory({
      label: policyDetails.category_verbose,
      value: String(policyDetails.category),
    });
    setPolicyEnabled(policyDetails.is_enabled);
  };

  return (
    <>
      <BasicModal
        size='sm'
        open={isOpen}
        onClose={() => {
          if (policyEnabledConfirm === false) {
            onClose(false);
            resetData();
          }
        }}
        modalTitle='Edit Policy'
        modalBody={
          <div className='z-[100000]'>
            <form
              className='flex flex-col'
              onSubmit={e => {
                e.preventDefault();
                updatePolicy();
              }}
            >
              <Input
                id='policy-name'
                type='text'
                value={policyName}
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                onChange={(e: any) => setPolicyName(e.target.value)}
                placeholder='Enter Policy Name'
                labelText='Policy Name'
                className='mb-4'
                required
              />
              <Textarea
                id='policy-description'
                type='text'
                value={policyDescription}
                labelText='Policy Description'
                placeholder='Enter Policy Description'
                onChange={setPolicyDescription}
                className='mb-4'
                required
              />
              <div className='mb-4 grid grid-cols-2 gap-3'>
                <SingleSelect
                  value={policyType}
                  options={[]}
                  handleChange={setPolicyType}
                  label='Policy Type'
                  labelClassName='text-gray-800 text-sm'
                  isCloseHidden
                  disabled
                />
                <SingleSelect
                  value={policyCategory}
                  options={[]}
                  handleChange={setPolicyCategory}
                  label='Policy Category'
                  labelClassName='text-gray-800 text-sm'
                  isCloseHidden
                  disabled
                />
              </div>
              <Checkbox
                checked={policyEnabled}
                labelText={
                  <span className='text-sm font-medium leading-5 text-gray-700'>
                    Enable this Policy
                  </span>
                }
                onChange={e => {
                  if ((e.target as HTMLInputElement).checked === true) {
                    setPolicyEnabled((e.target as HTMLInputElement).checked);
                  } else {
                    setPolicyEnabledConfirm(true);
                  }
                }}
              />
              <p className='ml-6 mt-1 text-xs text-gray-500'>
                If you deactivate this policy, rules within this policy will no longer be run on
                transaction or address searches
              </p>
              <div className='mt-6 flex justify-end gap-2'>
                <Button
                  variant='tertiary'
                  onClick={() => {
                    onClose(false);
                    resetData();
                  }}
                >
                  Cancel
                </Button>
                <Button type='submit'>Save</Button>
              </div>
            </form>
          </div>
        }
      />
      <ConfirmationModal
        onConfirm={() => {
          setPolicyEnabled(false);
          setPolicyEnabledConfirm(false);
        }}
        open={!!policyEnabledConfirm}
        data={policyEnabledConfirm || null}
        onCancel={() => setPolicyEnabledConfirm(false)}
        title={'Confirm Edit Policy'}
        body={
          'Disabling this policy will cause all active alerts associated with the policy to become inactive. Are you sure you want to proceed?'
        }
        cancelButton='No'
      />
    </>
  );
};

export default EditPolicyDetailsModal;
