declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    zE: any;
  }
}

export const setIdentity = async (
  name: string,
  email: string,
  organization: string,
  zendesk_key: string
) => {
  if (email && zendesk_key) {
    const zenDeskScript = document.createElement('script');
    zenDeskScript.src = 'https://static.zdassets.com/ekr/snippet.js?key=' + zendesk_key;
    zenDeskScript.id = 'ze-snippet';
    document.body.appendChild(zenDeskScript);
    zenDeskScript.onload = () => {
      window?.zE('webWidget', 'hide');
      window.zE('webWidget:on', 'close', () => {
        window.zE('webWidget', 'hide');
      });
      window.zE('webWidget', 'identify', {
        name: name,
        email: email,
        organization: organization,
      });
      window.zE('webWidget', 'prefill', {
        name: {
          value: name,
          readOnly: true,
        },
        email: {
          value: email,
          readOnly: true,
        },
      });
    };
  }
};
