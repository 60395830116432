import { useRouter } from '../../modules/router/RouterProvider';
import MerkleLogoBlue from '@/assets/images/dashboard/merkle-logo-blue.svg';

interface ReportFooterProps {
  invoiceReport: boolean;
  children: React.ReactNode;
}
const ReportFooter: React.FC<ReportFooterProps> = ({ children, invoiceReport }) => {
  const { navigate } = useRouter();

  return (
    <footer
      id='footer'
      data-testid='footer'
      className='flex items-center justify-between border-t-2 pt-4 text-gray-600'
    >
      {children}
      <span>
        <span className='text-3xs'>
          {' '}
          <div className='flex'>
            <div
              onClick={() => navigate('dashboard')}
              id='brand-logo'
              data-testid='brand-logo'
              className='size-9 flex-row whitespace-pre rounded-md bg-transparent p-1.5 text-base font-medium text-black'
            >
              <img
                src={MerkleLogoBlue}
                alt='Merkle Logo'
                width={invoiceReport ? '48' : '15'}
                height={invoiceReport ? '48' : '15'}
              />
            </div>
            <div
              id='brand-text'
              data-testid='brand-text'
              className='flex rounded-md bg-transparent py-1 text-base font-medium text-black'
            >
              <span
                style={{
                  marginTop: invoiceReport ? '2px' : '0px',
                  fontSize: invoiceReport ? '80px' : '12px',
                }}
              >
                Merkle Science
              </span>
            </div>
          </div>
        </span>
      </span>
    </footer>
  );
};

export default ReportFooter;
