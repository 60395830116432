import { FC, useState } from 'react';
import { useRouter } from '../../modules/router/RouterProvider';
import {
  transactionApi,
  useTransactionGetAggregatedInputs,
  useTransactionGetTransaction,
} from '../../api/transaction';
import { useAlertGetAlerts } from '../../api/alert';
import { useCommentGetComments } from '../../api/comment';
import { classifierApi } from '../../api/classifier';
import { useQuery } from 'react-query';
import { flattenInfiniteQueryResult } from '../../utils/helpers/react-query.helper';
import Page from '../../components/pdf/Page';
import TransactionDetailsReport from '../../components/pdf/Transaction/TransactionDetailsReport';
import { isTokenCurrency } from '../../utils/helpers/currency';
import EntityDetailsReport from '../../components/pdf/Transaction/EntityDetailsReport';
import InternalTransactionsReport from '../../components/pdf/Transaction/InternalTransactionsReport';
import TokenTransfersReport from '../../components/pdf/Transaction/TokenTransfersReport';
import FlowAnalysis from '../../components/pdf/Address/FlowAnalysis';
import CommentsReport from '../../components/pdf/CommentsReport';
import CounterpartySummaryReport from '../../components/pdf/Address/CounterpartySummaryReport';
import AlertsReport from '../../components/pdf/Alerts/AlertsReport';
import ClassifiersReport from '../../components/pdf/ClassifiersReport';
import { useStagesListLiteGet } from '../../api/stages';
import { getColorMappingStageOptions } from '../../utils/helpers/stageOptionsColor';
import { IStageOptions } from '../dashboard/settings/Workspace/AssignmentWorkflow';
import StageBadge from '../../ui/components/Badges/StageBadge/StageBadge';

const TransactionReport: FC = () => {
  const { getParams, getQueryParams } = useRouter();
  const transactionId = Number(getParams().identifier || getParams().transactionId);
  const currency = Number(getQueryParams().currency);
  const entities = getQueryParams().entities?.split(',');
  const noEntity = !entities || entities.length === 0;
  const [stagesId, setStagesId] = useState({
    closed: [],
    in_progress: [],
    open: [],
  });
  const shouldShowSection = (entityName: string): boolean => {
    return noEntity || entities.includes(entityName);
  };
  const stageListQuery = useStagesListLiteGet(true, {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onSuccess: (data: any) => {
      const stagesIdObj = data?.data.results.reduce(
        (acc, item) => {
          if (item.category === 2 || item.category === 3) {
            acc.closed.push(item.id.toString());
          }
          if (item.category === 1) {
            acc.in_progress.push(item.id.toString());
          }
          if (item.category === 0) {
            acc.open.push(item.id.toString());
          }
          return acc;
        },
        { closed: [], in_progress: [], open: [] }
      );

      setStagesId(stagesIdObj);
    },
    enabled:
      shouldShowSection('closedAlerts') ||
      shouldShowSection('openAlerts') ||
      shouldShowSection('inProgressAlerts'),
  });
  const stageColorMap = getColorMappingStageOptions(stageListQuery?.data?.data?.results);

  const { data: transactionData } = useTransactionGetTransaction(transactionId);
  const isRipple = currency === 4;

  const isToken = isTokenCurrency(currency);

  const externalTransactionQuery = useQuery(
    ['transactionApi.getExternalTransaction', transactionId],
    () => transactionApi.getExternalTransaction({ id: transactionId }),
    {
      enabled: (isRipple || isTokenCurrency(currency)) && shouldShowSection('transactionDetails'),
    }
  );
  const externalTransactions = externalTransactionQuery?.data;
  const { data: tokenTransfers } = useQuery(
    ['transactionApi.getTokenTransfers', transactionId],
    () => transactionApi.getTokenTransfers({ id: transactionId }),
    {
      enabled: isToken && shouldShowSection('transactionDetails'),
    }
  );
  const { data: internalTransactions } = useQuery(
    ['transactionApi.getInternalTransactions', transactionId],
    () => transactionApi.getInternalTransactions({ id: transactionId }),
    {
      enabled: isToken && shouldShowSection('transactionDetails'),
    }
  );
  const { data: transactionInputs } = useQuery(
    ['transactionApi.getTransactionInputs', transactionId],
    () => transactionApi.getInputs({ id: transactionId, limit: 100 }),
    {
      enabled: !isToken && shouldShowSection('transactionDetails'),
    }
  );

  const { data: transactionOutputs } = useQuery(
    ['transactionApi.getTransactionOutputs', transactionId],
    () => transactionApi.getOutputs({ id: transactionId, limit: 100 }),
    {
      enabled: !isToken && shouldShowSection('transactionDetails'),
    }
  );

  const { data: aggregatedInputs } = useTransactionGetAggregatedInputs(transactionId, {
    enabled: shouldShowSection('flowAnalysis') || shouldShowSection('counterpartySummary'),
  });
  const { data: aggregatedOutputs } = useTransactionGetAggregatedInputs(transactionId, {
    enabled: shouldShowSection('flowAnalysis') || shouldShowSection('counterpartySummary'),
  });
  const { data: openAlertsData } = useAlertGetAlerts(
    { id: transactionId, type: 'transaction', status: [...stagesId.open] },
    {
      enabled: shouldShowSection('openAlerts'),
    }
  );
  const { data: inProgressAlertsData } = useAlertGetAlerts(
    {
      id: transactionId,
      type: 'transaction',
      status: [...stagesId.in_progress],
    },
    {
      enabled: shouldShowSection('inProgressAlerts'),
    }
  );
  const { data: closedAlertsData } = useAlertGetAlerts(
    {
      id: transactionId,
      type: 'transaction',
      status: [...stagesId.closed],
    },
    {
      enabled: shouldShowSection('closedAlerts'),
    }
  );
  const commentsQuery = useCommentGetComments(
    { id: transactionId, type: 'transaction' },
    {
      enabled: shouldShowSection('transactionComments'),
    }
  );
  const { data: classifiers } = useQuery(['classifierApi.getClassifiers'], () =>
    classifierApi.getClassifiers({ limit: 100, rule_type: 0 })
  );

  const [, openAlerts] = flattenInfiniteQueryResult(openAlertsData);
  const [, inProgressAlerts] = flattenInfiniteQueryResult(inProgressAlertsData);
  const [, closedAlerts] = flattenInfiniteQueryResult(closedAlertsData);
  const [, comments] = flattenInfiniteQueryResult(commentsQuery?.data);

  const showCurrencyTransaction = (): boolean => {
    const hasTransactionDetails = shouldShowSection('transactionDetails');
    const hasEthExternalTransactions =
      externalTransactions?.data?.input?.address || externalTransactions?.data?.output?.address;
    const hasNonTokenInputs = transactionInputs?.data?.results?.length;
    const hasNonTokenOutputs = transactionOutputs?.data?.results?.length;

    if (noEntity || hasTransactionDetails) {
      return !!(hasEthExternalTransactions || hasNonTokenInputs || hasNonTokenOutputs);
    }

    return false;
  };

  const TransactionFooter: FC = () => {
    return (
      <div className='text-3xs text-gray-500'>
        Transaction Report: {transactionData?.data?.identifier}
      </div>
    );
  };

  const getCustomOptionLabel = (option: IStageOptions) => {
    if (option.label === undefined) {
      return <StageBadge role={8} label='-NA-' />;
    }
    return <StageBadge role={stageColorMap[option?.id]} label={option?.label} />;
  };

  const isCounterpartyVisible = (): boolean => {
    return (
      (shouldShowSection('counterpartySummary') &&
        aggregatedInputs?.data?.counter_party_summary?.length &&
        aggregatedInputs?.data?.counter_party_summary.length > 0) ||
      (aggregatedOutputs?.data?.counter_party_summary &&
        aggregatedOutputs?.data?.counter_party_summary.length > 0)
    );
  };
  let part = 1;

  return (
    <div>
      {transactionData?.data && shouldShowSection('transactionDetails') && (
        <Page footer={<TransactionFooter />}>
          <TransactionDetailsReport transactionData={transactionData.data} part={part++} />
        </Page>
      )}
      {showCurrencyTransaction() && (
        <Page footer={<TransactionFooter />}>
          <EntityDetailsReport
            externalTransactions={externalTransactions?.data}
            nonTokenInputs={transactionInputs?.data?.results}
            nonTokenOutputs={transactionOutputs?.data?.results}
            transaction={transactionData?.data}
            part={part++}
          />
        </Page>
      )}
      {internalTransactions?.data?.results?.length > 0 && (
        <Page footer={<TransactionFooter />}>
          <InternalTransactionsReport
            internalTransactions={internalTransactions?.data?.results}
            transaction={transactionData?.data}
            part={part++}
          />
        </Page>
      )}
      {tokenTransfers?.data?.results?.length > 0 && (
        <Page footer={<TransactionFooter />}>
          <TokenTransfersReport
            tokenTransfers={tokenTransfers?.data?.results}
            transaction={transactionData?.data}
            part={part++}
          />
        </Page>
      )}
      {shouldShowSection('flowAnalysis') &&
        aggregatedInputs?.data?.counter_party_summary?.length &&
        aggregatedOutputs?.data?.counter_party_summary?.length && (
          <Page footer={<TransactionFooter />}>
            <FlowAnalysis
              inputs={aggregatedInputs?.data?.counter_party_summary}
              outputs={aggregatedOutputs?.data?.counter_party_summary}
              part={part++}
            />
          </Page>
        )}
      {isCounterpartyVisible() && (
        <Page footer={<TransactionFooter />}>
          <CounterpartySummaryReport
            incoming={aggregatedInputs?.data?.counter_party_summary}
            outgoing={aggregatedOutputs?.data?.counter_party_summary}
            part={part++}
          />
        </Page>
      )}
      {shouldShowSection('openAlerts') && openAlerts?.length > 0 && (
        <Page footer={<TransactionFooter />}>
          <AlertsReport
            type='transaction'
            alerts={openAlerts}
            title='Open Alerts'
            identifier={transactionData?.data?.identifier}
            part={part++}
            getCustomOptionLabel={getCustomOptionLabel}
          />
        </Page>
      )}
      {shouldShowSection('inProgressAlerts') && inProgressAlerts?.length > 0 && (
        <Page footer={<TransactionFooter />}>
          <AlertsReport
            type='transaction'
            alerts={inProgressAlerts}
            title='In Progress Alerts'
            identifier={transactionData?.data?.identifier}
            part={part++}
            getCustomOptionLabel={getCustomOptionLabel}
          />
        </Page>
      )}
      {shouldShowSection('closedAlerts') && closedAlerts?.length > 0 && (
        <Page footer={<TransactionFooter />}>
          <AlertsReport
            type='transaction'
            alerts={closedAlerts}
            title='Closed Alerts'
            identifier={transactionData?.data?.identifier}
            part={part++}
            getCustomOptionLabel={getCustomOptionLabel}
          />
        </Page>
      )}
      {shouldShowSection('transactionComments') && comments?.length > 0 && (
        <Page footer={<TransactionFooter />}>
          <CommentsReport
            type='transaction'
            comments={comments}
            identifier={transactionData?.data?.identifier}
            part={part++}
            isError={commentsQuery?.isError || comments?.[0] === undefined}
          />
        </Page>
      )}
      {classifiers?.data.results && (
        <Page footer={<TransactionFooter />}>
          <ClassifiersReport classifiers={classifiers?.data.results} type='transaction' />
        </Page>
      )}
    </div>
  );
};

export default TransactionReport;
