import * as React from 'react';
import classNames from 'classnames';
import { Download } from '@phosphor-icons/react';

import { formatDateShort } from '../../../utils/helpers/date';
import { getCurrencyById } from '../../../utils/helpers/currency';
import { RiskLevel } from '../../../utils/constants/constants';

import { Button } from '../../../ui';
import AppliedFilters from '../../../ui/components/Badges/AppliedFiltersBadge/AppliedFiltersBadge';

import { IFilters, IOption, defaultAddressListFilters } from './AddressListFilter';

interface Props {
  selectedText: string;
  count: number;
  filters: IFilters;
  setFilters: (filters: IFilters) => void;
  selectedAddresses: number[];
  setSelectedAddresses: (addresses: number[]) => void;
  isAllAddressSelected: boolean;
  setIsAllAddressSelected: (boolean) => void;
  selectAllAddresses: (boolean) => void;
  downloadSelectedAddresses: () => void;
  customTagsOptions?: IOption[];
}

const AddressListAppliedFilter: React.FC<Props> = ({
  selectedText,
  count,
  filters,
  setFilters,
  selectedAddresses,
  setSelectedAddresses,
  isAllAddressSelected,
  setIsAllAddressSelected,
  selectAllAddresses,
  downloadSelectedAddresses,
  customTagsOptions,
}) => {
  const isFilterApplied = React.useMemo(() => {
    if (filters.tag_type_verboses?.length) return true;
    if (filters.tag_name_verboses?.length) return true;
    if (filters.risk_level?.length) return true;
    if (filters.currency?.length) return true;
    if (filters.created_start_date) return true;
    if (filters.created_end_date) return true;
    if (filters.start_date) return true;
    if (filters.end_date) return true;
    if (filters.rule_name?.length) return true;
    if (filters.custom_tags?.length) return true;
    if (filters.is_included_in_case !== null) return true;
  }, [filters]);

  const resetFilter = () => {
    setFilters(defaultAddressListFilters);
  };

  return (
    <div
      className={classNames(
        'flex justify-stretch border-t-[1px] bg-gray-50',
        isFilterApplied || selectedAddresses?.length ? 'px-2 py-2.5' : ''
      )}
    >
      <div className='flex w-full items-center justify-between'>
        <div className='flex flex-wrap items-center'>
          {filters.start_date && (
            <AppliedFilters
              onRemove={() => {
                setFilters({
                  ...filters,
                  start_date: null,
                  end_date: null,
                });
              }}
            >
              Date Updated: {formatDateShort(filters.start_date)} -{' '}
              {formatDateShort(filters.end_date)}
            </AppliedFilters>
          )}
          {filters.created_end_date && (
            <AppliedFilters
              onRemove={() => {
                setFilters({
                  ...filters,
                  created_start_date: null,
                  created_end_date: null,
                });
              }}
            >
              Date Added: {formatDateShort(filters.created_start_date)} -{' '}
              {formatDateShort(filters.created_end_date)}
            </AppliedFilters>
          )}
          {filters.risk_level?.map((risk, i) => (
            <AppliedFilters
              key={i}
              onRemove={() => {
                setFilters({
                  ...filters,
                  risk_level: filters.risk_level.filter(r => r !== risk),
                });
              }}
            >
              Risk: {RiskLevel.find(item => item.value === risk).label}
            </AppliedFilters>
          ))}
          {filters.tag_type_verboses?.map((entityType, i) => (
            <AppliedFilters
              key={i}
              onRemove={() => {
                setFilters({
                  ...filters,
                  tag_type_verboses: filters.tag_type_verboses.filter(e => e !== entityType),
                });
              }}
            >
              Entity Type: {entityType}
            </AppliedFilters>
          ))}
          {filters.tag_name_verboses?.map((entityName, i) => (
            <AppliedFilters
              key={i}
              onRemove={() => {
                setFilters({
                  ...filters,
                  tag_name_verboses: filters.tag_name_verboses.filter(e => e !== entityName),
                });
              }}
            >
              Entity Name: {entityName}
            </AppliedFilters>
          ))}
          {filters.currency?.map((curr, i) => (
            <AppliedFilters
              key={i}
              onRemove={() => {
                setFilters({
                  ...filters,
                  currency: filters.currency.filter(c => c !== curr),
                });
              }}
            >
              Blockchain: {getCurrencyById(curr).label}
            </AppliedFilters>
          ))}
          {filters.rule_name?.map((rule_name, i) => (
            <AppliedFilters
              key={i}
              onRemove={() => {
                setFilters({
                  ...filters,
                  rule_name: filters.rule_name.filter(r => r !== rule_name),
                });
              }}
            >
              Rule Name: {rule_name}
            </AppliedFilters>
          ))}
          {filters.is_included_in_case !== null && (
            <AppliedFilters
              onRemove={() => {
                setFilters({
                  ...filters,
                  is_included_in_case: null,
                });
              }}
            >
              Included in Cases: {filters.is_included_in_case ? 'Yes' : 'No'}
            </AppliedFilters>
          )}
          {filters.custom_tags?.map((tag, i) => (
            <AppliedFilters
              key={i}
              onRemove={() => {
                setFilters({
                  ...filters,
                  custom_tags: filters.custom_tags.filter(r => r !== tag),
                });
              }}
            >
              Custom Tag: {customTagsOptions?.find(item => item.value === tag)?.label}
            </AppliedFilters>
          ))}
        </div>
        {isFilterApplied && (
          <div className='flex items-center'>
            <button
              id='addressList-reset'
              data-testid='addressList-reset'
              className='mr-2 text-2xs text-blue-600'
              onClick={() => resetFilter()}
            >
              Reset Filters
            </button>
          </div>
        )}
      </div>
      {selectedAddresses?.length > 0 && (
        <div className='flex min-w-[600px] items-center justify-end gap-3 pr-4 text-2xs'>
          <div></div>
          <div>{selectedText}</div>
          {!isAllAddressSelected && (
            <button
              id='addressList-selectAll'
              data-testid='addressList-selectAll'
              className='text-blue-500'
              onClick={() => {
                selectAllAddresses(true);
                setIsAllAddressSelected(true);
              }}
            >
              Select all {count} addresses
            </button>
          )}
          <div
            id='addressList-clear'
            data-testid='addressList-clear'
            className='cursor-pointer text-blue-500'
            onClick={() => {
              setSelectedAddresses([]);
              setIsAllAddressSelected(false);
            }}
          >
            Clear selection
          </div>

          <Button
            id='addressList-download'
            variant='tertiary'
            onClick={() => downloadSelectedAddresses()}
          >
            <Download size={17} />
          </Button>
        </div>
      )}
    </div>
  );
};

export default AddressListAppliedFilter;
