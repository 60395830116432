import { UseQueryOptions, useInfiniteQuery, useQuery } from 'react-query';
import { Api } from './api';
import {
  ICreatePolicyRequest,
  IPolicyListRequest,
  IPolicyListResponse,
  IPolicyResponse,
  IPolicyCryptoObjectsFlagged,
  IPolicyAlert,
  IPolicyRuleListRequest,
  IPolicyRulesTriggeredListResponse,
  IPolicyAuditTrailListRequest,
  IPolicyAuditTrailsListResponse,
  IPolicyRulesRequest,
  IPolicyRuleResponse,
  IRuleDetailsResponse,
  IPolicyRuleAuditTrailListRequest,
  IPolicyRuleAuditTrailsListResponse,
  IUpdateRuleDetails,
  ICreateRuleDetails,
} from './dtos/policies';
import { getNextPageParam } from '../utils/helpers/helperFunctions';
import { classifierApi } from './classifier';
import { IClassifierRequest } from './dtos/classifier';
import { convertBooleanToPy } from '../utils/helpers/apiHelpers';

export class PolicyApi extends Api {
  readonly baseUrl: string = '/risk/api/v4/policies';

  readonly getPolicyList = (params: IPolicyListRequest) => {
    const { offset = 0, limit = 25, is_enabled, type, q, ordering, category } = params;
    return this.http.get<IPolicyListResponse>(this.route('/'), {
      params: {
        offset,
        limit,
        is_enabled: convertBooleanToPy(is_enabled),
        type,
        q,
        ordering,
        category,
      },
    });
  };

  readonly getPolicyDetails = (id: string) => {
    return this.http.get<IPolicyResponse>(this.route(`/${id}/`));
  };

  readonly getPolicyCryptoObjectsFlagged = (id: string) => {
    return this.http.get<IPolicyCryptoObjectsFlagged>(this.route(`/${id}/crypto-objects-flagged/`));
  };

  readonly getPolicyRulesTriggered = (params: IPolicyRuleListRequest) => {
    const { offset = 0, limit = 25, id } = params;
    return this.http.get<IPolicyRulesTriggeredListResponse>(
      this.route(`/${id}/most-triggered-rules/`),
      {
        params: { offset, limit },
      }
    );
  };

  readonly getPolicyAlerts = (id: string) => {
    return this.http.get<IPolicyAlert[]>(this.route(`/${id}/alerts-triggered-over-time/`));
  };

  readonly getPolicyAuditTrail = (params: IPolicyAuditTrailListRequest) => {
    const { offset = 0, limit = 25, id } = params;
    return this.http.get<IPolicyAuditTrailsListResponse>(this.route(`/${id}/history/`), {
      params: { offset, limit },
    });
  };

  readonly createPolicy = (params: ICreatePolicyRequest) => {
    return this.http.post<IPolicyResponse>(this.route('/'), params);
  };

  readonly updatePolicy = (params: ICreatePolicyRequest) => {
    const { id } = params;
    return this.http.put<IPolicyResponse>(this.route(`/${id}/`), params);
  };

  readonly downloadRules = () => {
    return this.http.get(this.route('/download-rules/'));
  };

  readonly getPolicyRuleAlerts = (id: string, ruleId: string) => {
    return this.http.get<IPolicyAlert[]>(
      this.route(`/${id}/classifiers/${ruleId}/alerts-triggered-over-time/`)
    );
  };

  readonly getPolicyRules = (params: IPolicyRulesRequest) => {
    const { offset = 0, limit = 25, q, id, status, alert_level, risk_type } = params;
    return this.http.get<IPolicyRuleResponse>(this.route(`/${id}/classifiers/`), {
      params: {
        id,
        offset,
        limit,
        q,
        alert_level: alert_level.join(','),
        risk_type: risk_type.join(','),
        is_enabled: status.map(r => (r === 'live' ? 1 : 0)).join(','),
      },
    });
  };

  readonly getPolicyRuleDetails = (id: string, ruleId: string) => {
    return this.http.get<IRuleDetailsResponse>(this.route(`/${id}/classifiers/${ruleId}/`));
  };

  readonly createPolicyRuleDetails = (params: ICreateRuleDetails) => {
    return this.http.post<IRuleDetailsResponse>(
      this.route(`/${params.id}/classifiers/`),
      params.ruleDetails
    );
  };

  readonly updatePolicyRuleDetails = (params: IUpdateRuleDetails) => {
    return this.http.put<IRuleDetailsResponse>(
      this.route(`/${params.id}/classifiers/${params.ruleId}/`),
      params.ruleDetails
    );
  };

  readonly revertPolicyRule = (id: string, ruleId: string, versionId: number) => {
    return this.http.put<IRuleDetailsResponse>(this.route(`/${id}/classifiers/${ruleId}/revert/`), {
      version_id: versionId,
    });
  };

  readonly getPolicyRuleCryptoObjectsFlagged = (id: string, ruleId: string) => {
    return this.http.get<IPolicyCryptoObjectsFlagged>(
      this.route(`/${id}/classifiers/${ruleId}/crypto-objects-flagged/`)
    );
  };

  readonly getPolicyRuleAuditTrail = (params: IPolicyRuleAuditTrailListRequest) => {
    const { offset = 0, limit = 25, id, ruleId } = params;
    return this.http.get<IPolicyRuleAuditTrailsListResponse>(
      this.route(`/${id}/classifiers/${ruleId}/history/`),
      {
        params: { offset, limit },
      }
    );
  };
}

export const policyApi = new PolicyApi();

export const usePolicyGetList = (params?: IPolicyListRequest, options?: UseQueryOptions) => {
  const key = ['policyApi', 'getPolicyList', params];
  const result = useInfiniteQuery(
    key,
    ({ pageParam = 0 }) => policyApi.getPolicyList({ ...params, offset: pageParam }),
    {
      ...options,
      getNextPageParam,
    } as unknown
  );
  return result;
};

export const usePolicyGetDetails = (id?: string, options?: UseQueryOptions) => {
  const key = ['policyApi', 'getPolicyDetails'];
  const result = useQuery(key, () => policyApi.getPolicyDetails(id), {
    ...options,
  } as unknown);
  return result;
};

export const usePolicyGetAlerts = (id?: string, options?: UseQueryOptions) => {
  const key = ['policyApi', 'getPolicyAlerts'];
  const result = useQuery(key, () => policyApi.getPolicyAlerts(id), {
    ...options,
    getNextPageParam,
  } as unknown);
  return result;
};

export const usePolicyGetAuditTrail = (
  params?: IPolicyAuditTrailListRequest,
  options?: UseQueryOptions
) => {
  const key = ['policyApi', 'getPolicyAuditTrail', params];
  const result = useInfiniteQuery(
    key,
    ({ pageParam = 0 }) => policyApi.getPolicyAuditTrail({ ...params, offset: pageParam }),
    {
      ...options,
      getNextPageParam,
    } as unknown
  );
  return result;
};

export const usePolicyGetCryptoObjectsFlagged = (id?: string, options?: UseQueryOptions) => {
  const key = ['policyApi', 'getPolicyCryptoObjectsFlagged'];
  const result = useQuery(key, () => policyApi.getPolicyCryptoObjectsFlagged(id), {
    ...options,
    getNextPageParam,
  } as unknown);
  return result;
};

export const usePolicyGetRuleTriggeredList = (
  params?: IPolicyRuleListRequest,
  options?: UseQueryOptions
) => {
  const key = ['policyApi', 'getPolicyRulesTriggered', params];
  const result = useInfiniteQuery(
    key,
    ({ pageParam = 0 }) => policyApi.getPolicyRulesTriggered({ ...params, offset: pageParam }),
    {
      ...options,
      getNextPageParam,
    } as unknown
  );
  return result;
};

export const usePolicyGetRuleList = (params?: IClassifierRequest, options?: UseQueryOptions) => {
  const key = ['policyApi', 'getRuleList', params];
  const result = useInfiniteQuery(
    key,
    ({ pageParam = 0 }) => classifierApi.getClassifiers({ ...params, offset: pageParam }),
    {
      ...options,
      getNextPageParam,
    } as unknown
  );
  return result;
};

export const usePolicyGetPolicyRuleList = (
  params?: IPolicyRulesRequest,
  options?: UseQueryOptions
) => {
  const key = ['policyApi', 'getPolicyRules', params];
  const result = useInfiniteQuery(
    key,
    ({ pageParam = 0 }) => policyApi.getPolicyRules({ ...params, offset: pageParam }),
    {
      ...options,
      getNextPageParam,
    } as unknown
  );
  return result;
};

export const usePolicyRuleGetDetails = (id: string, ruleId: string, options?: UseQueryOptions) => {
  const key = ['policyApi', 'getPolicyRuleDetails'];
  const result = useQuery(key, () => policyApi.getPolicyRuleDetails(id, ruleId), {
    ...options,
  } as unknown);
  return result;
};

export const usePolicyRuleGetCryptoObjectsFlagged = (
  id: string,
  ruleId: string,
  options?: UseQueryOptions
) => {
  const key = ['policyApi', 'getPolicyRuleCryptoObjectsFlagged'];
  const result = useQuery(key, () => policyApi.getPolicyRuleCryptoObjectsFlagged(id, ruleId), {
    ...options,
    getNextPageParam,
  } as unknown);
  return result;
};

export const usePolicyRuleGetAlerts = (id: string, ruleId: string, options?: UseQueryOptions) => {
  const key = ['policyApi', 'getPolicyRuleAlerts', ruleId, id];
  const result = useQuery(key, () => policyApi.getPolicyRuleAlerts(id, ruleId), {
    ...options,
    getNextPageParam,
  } as unknown);
  return result;
};

export const usePolicyRuleGetAuditTrail = (
  params?: IPolicyRuleAuditTrailListRequest,
  options?: UseQueryOptions
) => {
  const key = ['policyApi', 'getPolicyRuleAuditTrail', params];
  const result = useInfiniteQuery(
    key,
    ({ pageParam = 0 }) => policyApi.getPolicyRuleAuditTrail({ ...params, offset: pageParam }),
    {
      ...options,
      getNextPageParam,
    } as unknown
  );
  return result;
};
