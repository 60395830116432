import { useRef } from 'react';
import { IPaginatedResponse, IPaginationMetaResponse } from '../api/dtos/common';
import { AxiosResponse } from 'axios';
import { UseInfiniteQueryResult } from 'react-query';
import {
  flattenInfiniteQueryResult,
  flattenInfiniteQueryResultMeta,
} from '../utils/helpers/react-query.helper';
import useIntersectionObserver from '../hooks/useInteractionObserver';
import CompassLoader from '../components/ui/components/Loader/CompassLoader';
import Loader from '@/assets/loader.gif';
import EmptyState from '../ui/components/States/Empty';
import Skeleton from 'react-loading-skeleton';

interface IPaginationProps<T> {
  query?: UseInfiniteQueryResult<AxiosResponse<IPaginatedResponse<T>, unknown>, unknown>;
  queryMeta?: UseInfiniteQueryResult<AxiosResponse<IPaginationMetaResponse<T>, unknown>, unknown>;
  queryData?: T[];
  className?: string;
  children?: (props: T, index?: number) => JSX.Element;
  loader?: 'compass' | 'skeleton';
  style?: React.CSSProperties;
  errorMessage?: string;
}

const Pagination = <T,>(props: IPaginationProps<T>) => {
  const {
    query,
    queryMeta,
    className,
    queryData,
    children,
    loader = 'skeleton',
    style,
    errorMessage,
  } = props;
  const currentQuery = query || queryMeta;

  const paginationRef = useRef<HTMLDivElement>(null);

  const onIntersect = () => {
    if (currentQuery.fetchNextPage) {
      currentQuery.fetchNextPage();
    }
  };
  useIntersectionObserver({
    target: paginationRef,
    onIntersect: onIntersect || null,
    enabled: !!currentQuery.hasNextPage,
  });
  if (!currentQuery) {
    return null;
  }
  if (currentQuery.isLoading) {
    return loader === 'skeleton' ? (
      <Skeleton
        height='20'
        count={10}
        containerClassName={`p-2 w-full h-full block ${className}`}
      />
    ) : (
      <CompassLoader />
    );
  }
  if (query?.isError || queryMeta?.isError)
    return (
      <span className='m-auto flex px-2 py-4 text-sm text-gray-500'>An error has occurred</span>
    );

  // If data is already provided through props, use it
  let data = [];
  if (queryData) {
    data = queryData;
  } else {
    [, data] = query
      ? flattenInfiniteQueryResult(query.data)
      : flattenInfiniteQueryResultMeta(queryMeta.data);
  }

  if (!data || data.length === 0) {
    return (
      <div className={className}>
        <EmptyState message={errorMessage} />
      </div>
    );
  }
  return (
    <div className={`overflow-auto ${className}`} style={style}>
      {data.map((item, index) => {
        return children(item, index);
      })}
      {!currentQuery.isFetchingNextPage && !currentQuery.isLoading && (
        <div
          ref={paginationRef}
          className={`p-2 ${!currentQuery.hasNextPage ? 'hidden' : ''}`}
        ></div>
      )}
      {currentQuery.isFetchingNextPage && (
        <div>
          <img src={Loader} alt='Loader' className='mx-auto h-16' />
        </div>
      )}
    </div>
  );
};

export default Pagination;
