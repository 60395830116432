import { FC, useEffect, useState } from 'react';
import { Button } from '@ui/index';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, useWatch } from 'react-hook-form';
import { IChangePasswordRequest } from '../../../api/dtos/auth';
import { authApi } from '../../../api/auth';
import { toast } from '../../../ui/components/Toast';
import { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import { IErrorResponse } from '../../../api/dtos/common';
import InputController from '../../../ui/components/Form/InputController';
import { hasNumber } from '../../../utils/helpers/common';
import { CheckCircle, XCircle } from '@phosphor-icons/react';
import { useRouter } from '../../../modules/router/RouterProvider';

const initialData: IChangePasswordRequest = {
  new_password: '',
  new_password_2: '',
};

const validations = Yup.object({
  new_password: Yup.string().label('New password').required(),
  new_password_2: Yup.mixed().label('Confirm new password').required(),
});

export type PasswordConditionName = 'chars>8' | 'numChars>=1' | 'new===new2';

export const passwordConditions = {
  'chars>8': {
    text: 'Should contain at least 8 characters.',
    satisfied: false,
  },
  'numChars>=1': {
    text: 'Should contain at least 1 numeric character.',
    satisfied: false,
  },
  'new===new2': {
    text: 'Passwords should match.',
    satisfied: false,
  },
};

const passwordConditionsOrder = ['chars>8', 'numChars>=1', 'new===new2'];

const ChangePasswordForm: FC = () => {
  const [currentPasswordConditions, setCurrentPasswordConditions] = useState(passwordConditions);
  const [isPasswordConditionsMatch, setIsPasswordConditionsMatch] = useState(false);
  const { navigate } = useRouter();
  const {
    control,
    handleSubmit,
    getValues,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: initialData,
    resolver: yupResolver(validations),
  });

  const newPassword = useWatch({
    control,
    name: 'new_password',
  });

  const newPassword2 = useWatch({
    control,
    name: 'new_password_2',
  });

  useEffect(() => {
    const newConditionStatuses = passwordConditions;

    if (newPassword && newPassword2) {
      newConditionStatuses['new===new2'].satisfied = newPassword === newPassword2;
    } else {
      newConditionStatuses['new===new2'].satisfied = false;
    }
    newConditionStatuses['chars>8'].satisfied = newPassword.length >= 8;
    newConditionStatuses['numChars>=1'].satisfied = hasNumber(newPassword);

    setCurrentPasswordConditions({ ...newConditionStatuses });

    const allSatisfied = Object.keys(newConditionStatuses).every(
      cond => newConditionStatuses[cond as PasswordConditionName].satisfied
    );

    if (allSatisfied) {
      setIsPasswordConditionsMatch(true);
    } else {
      setIsPasswordConditionsMatch(false);
    }
  }, [newPassword, newPassword2, getValues]);

  const { mutate } = useMutation(authApi.changePassword, {
    // Always refetch after error or success:
    onSuccess: () => {
      toast.success('Password changed successfully.');
      navigate('/accounts/logout');
    },
    onError: (error: AxiosError<IErrorResponse>) => {
      toast.error(error.response.data.detail);
    },
  });

  const onSubmit = (data: typeof initialData) => {
    mutate(data);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className='grid grid-cols-2 divide-x'>
        <div className='pr-4'>
          <InputController
            id='newPassword'
            name='new_password'
            type='password'
            labelText='New Password'
            placeholder='Enter your new password'
            className='mb-4'
            control={control}
          />
          <InputController
            id='newPassword2'
            name='new_password_2'
            type='password'
            labelText='Confirm New Password'
            placeholder='Re-enter your new password'
            className='mb-4'
            control={control}
          />
        </div>
        <div>
          {passwordConditionsOrder.map(name => (
            <p
              key={'conditions' + name}
              className='font-regular ml-4 mt-6 flex items-center text-sm text-gray-600'
            >
              {currentPasswordConditions[name as PasswordConditionName].satisfied ? (
                <CheckCircle className='mr-2 size-5 text-blue-500' weight='fill' />
              ) : (
                <XCircle className='mr-2 size-5 text-red-500' weight='fill' />
              )}
              <span>{currentPasswordConditions[name as PasswordConditionName].text}</span>
            </p>
          ))}
        </div>
      </div>
      <Button type='submit' disabled={isSubmitting || !isPasswordConditionsMatch}>
        Save Changes
      </Button>
    </form>
  );
};

export default ChangePasswordForm;
