import type { AxiosError, AxiosInstance, AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import axios from 'axios';
import { toString, trimStart, trimEnd } from 'lodash';
import { AppConfig } from '../config';
// import { ApiError } from './api-error';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const window: any;
function handleResponse(response: AxiosResponse) {
  return response;
}
// Set workspace in query params if not present
function workspaceQueryInterceptor(request: InternalAxiosRequestConfig) {
  let workspace = '';
  try {
    workspace =
      new URLSearchParams(window.location.search).get('workspace') ||
      JSON.parse(localStorage.getItem('workspace'));
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e);
  }
  const params = {
    ...(request.params ?? {}),
    workspace: request.params?.workspace ?? workspace,
  };
  return { ...request, params };
}

const handleError = (error: AxiosError) => {
  // const apiError = ApiError.fromAxiosError(error);
  return Promise.reject(error);
};

export const globalAxios = axios.create({
  baseURL: AppConfig.apiBaseUrl,
  headers: {
    'Accept-Language': '',
    // Global defaults
  },
});

globalAxios.interceptors.response.use(handleResponse, handleError);
globalAxios.interceptors.request.use(workspaceQueryInterceptor, handleError);

globalAxios.defaults.paramsSerializer = params => {
  // Serialize params to use %20 instead of + and avoid converting ":"
  return Object.keys(params)
    .filter(key => params[key] != null)
    .map(key => {
      const encodedValue = encodeURIComponent(params[key])
        .replace(/%3A/g, ':')
        .replace(/%25/g, '%');
      return `${encodeURIComponent(key)}=${encodedValue}`;
    })
    .join('&');
};

// Make it available while not on production for developers
!import.meta.env.PROD && typeof window !== 'undefined' && (window._globalAxios = globalAxios);

// https://levelup.gitconnected.com/enhance-your-http-request-with-axios-and-typescript-f52a6c6c2c8e
export abstract class Api {
  abstract readonly baseUrl: string;
  protected readonly http: AxiosInstance;

  constructor(axiosInstance?: AxiosInstance) {
    this.http = axiosInstance ?? globalAxios;
  }

  protected route(subpath: string | number) {
    return trimEnd(this.baseUrl, '/') + '/' + trimStart(toString(subpath), '/');
  }

  protected _get<T>(id: number) {
    return this.http.get<T>(this.route(id));
  }

  protected _all<T>() {
    return this.http.get<T>(this.route(''));
  }

  protected _delete<T>(id: number) {
    return this.http.delete<T>(this.route(id));
  }
}
