import classNames from 'classnames';
import { FC, useRef, useState } from 'react';
import { AxiosResponse } from 'axios';
import { UseInfiniteQueryResult, useMutation } from 'react-query';
import { isNumber } from 'lodash';
import { Question } from '@phosphor-icons/react';

import { useAuth } from '../../../modules/auth';

import saveBlobToFile from '../../../utils/helpers/saveBlobToFile';
import { useAddressGetTransactions, addressApi } from '../../../api/address';
import { dateToString, formatDateTime } from '../../../utils/helpers/date';
import { toast } from '../../../ui/components/Toast';
import { getTransactionTypeByValue } from '../../../utils/helpers/helperFunctions';
import { IAddressResponse, IAddressTransactionsResponse } from '../../../api/dtos/address';
import {
  buildInfiniteQueryTableProps,
  flattenInfiniteQueryResult,
} from '../../../utils/helpers/react-query.helper';

import IdentifierEllipsis from '../../ui/components/Currency/IdentifierEllipsis';
import EntityBadge from '../../ui/components/Badge/EntityBadge';
import AddressTransactionsAppliedFilters from './AddressTransactionsAppliedFilters';
import CurrencyValue from '../../ui/components/Currency/CurrencyValue';
import TransactionPopover from '../../../components/AddressTransaction/TransactionPopover';
import AddressTransactionsFilter, {
  IFilters,
  defaultStateFilters,
} from './AddressTransactionsFilter';
import Table, { ITableRow } from '../../../ui/components/Table/Table';
import { BasicBadge } from '../../../ui';
import { addressLiteApi, useAddressGetTransactionsLite } from '../../../api/addressPreview';
import Popover from '../../../ui/components/Popover/Popover';

interface IAddressTransactionsProps {
  address: IAddressResponse;
  isPreview?: boolean;
  defaultEntities?: string[];
  defaultEntitySubTypes?: string[];
  defaultEntityName?: string;
  startDate?: string;
  endDate?: string;
}

const AddressTransactions: FC<IAddressTransactionsProps> = ({
  address,
  isPreview,
  defaultEntities,
  defaultEntitySubTypes,
  defaultEntityName,
  startDate,
  endDate,
}) => {
  const { state } = useAuth();
  const [filters, setFilters] = useState({
    ...defaultStateFilters,
    transaction_start_date: startDate || null,
    transaction_end_date: endDate || null,
    tag_type_verboses: defaultEntities || [],
    tag_subtype_verboses: defaultEntitySubTypes || [],
    tag_name_verbose: defaultEntityName || '',
  } as IFilters);
  const [open, setOpen] = useState(false);
  const [currentId, setCurrentId] = useState<string>();
  const isAlertDetail = defaultEntities || defaultEntitySubTypes || defaultEntityName;

  const transactionQueryFull = useAddressGetTransactions(
    {
      address: address.id,
      filters: {
        ...filters,
        // Add default time value from address. (Added due to solana issue BE)
        transaction_start_date:
          filters.transaction_start_date ||
          dateToString(new Date(address.earliest_transaction_time)),
        transaction_end_date:
          filters.transaction_end_date || dateToString(new Date(address.latest_transaction_time)),
      },
    },
    {
      enabled: !isPreview,
    }
  );

  const transactionQueryLite = useAddressGetTransactionsLite(
    {
      address: address.identifier,
      currency: address.currency,
      filters: {
        ...filters,
        // Add default time value from address. (Added due to solana issue BE)
        transaction_start_date:
          filters.transaction_start_date ||
          dateToString(new Date(address.earliest_transaction_time)),
        transaction_end_date:
          filters.transaction_end_date || dateToString(new Date(address.latest_transaction_time)),
      },
    },
    {
      enabled: !!isPreview,
    }
  );

  const transactionQuery = isPreview ? transactionQueryLite : transactionQueryFull;

  const getRowsAndCount = (
    transactionsData: UseInfiniteQueryResult<AxiosResponse<IAddressTransactionsResponse>, unknown>
  ): [ITableRow[], number] => {
    const [transactionsCount, transactions] = flattenInfiniteQueryResult(transactionsData.data);
    if (transactions.every(t => !t) && !isNumber(transactionsCount)) return [null, null];
    return [
      transactionsCount
        ? transactions.map(transaction => {
            const mergedEntities = transaction.entities.map((entity, index) => ({
              tag_type_verbose: entity.tag_type_verbose,
              tag_name_verbose: transaction.entities_names[index]?.tag_type_verbose || '',
            }));
            return {
              id: transaction.transaction_id,
              data: [
                <IdentifierEllipsis
                  key={transaction.transaction_id}
                  identifier={transaction.transaction_id}
                  currency={address.currency}
                  isCreateCaseDisabled={true}
                  type='transactions'
                  copyable
                />,
                <div key={transaction.transaction_id}>
                  {transaction.token_symbol || address.currency_short}
                </div>,
                <div key={transaction.transaction_id}>
                  <BasicBadge className='bg-gray-100 text-2xs'>
                    {getTransactionTypeByValue(transaction.transaction_types)}
                  </BasicBadge>
                </div>,
                <CurrencyValue
                  key={transaction.transaction_id}
                  value={transaction.value}
                  className={transaction.direction === 1 ? 'text-green-600' : 'text-red-600'}
                  currency={transaction.token_symbol || address.currency_short}
                />,
                <CurrencyValue
                  key={transaction.transaction_id}
                  value={transaction.value_usd}
                  className={transaction.direction === 1 ? 'text-green-600' : 'text-red-600'}
                  currency='USD'
                />,
                <EntityBadge dot key={transaction.transaction_id} entities={mergedEntities} />,
                <div key={transaction.transaction_id}>
                  {/* * 100 because of Unix time */}
                  {formatDateTime(transaction.block_date_time * 1000, state.userProfile.timezone)}
                </div>,
              ],
              onClick() {
                if (filters.type === 'rewards' || transaction.transaction_id.startsWith('Reward'))
                  return;
                setOpen(true);
                setCurrentId(transaction.transaction_id);
              },
              // inset 6px 0px 0px 0px #f00

              className: classNames(
                'shadow-[inset_6px_0_0_0]',
                transaction.direction === 1 ? 'shadow-green-600' : 'shadow-red-600'
              ),
            };
          })
        : [],
      transactionsCount,
    ];
  };

  const toastId = useRef(null);
  const { mutate } = useMutation(
    isPreview ? addressLiteApi.exportTransactionsCSVLite : addressApi.exportTransactionsCSV,
    {
      onSuccess: data => {
        toast.update(toastId.current, {
          render: 'Exported transactions CSV successfully',
          type: 'success',
          autoClose: 3000,
          closeButton: true,
        });
        saveBlobToFile(data.data, `Merkle Science - Address - ${address.id} - Transactions.csv`);
      },
      onError: () => {
        toast.update(toastId.current, {
          render: 'Transactions CSV export failed. Please try again later.',
          type: 'error',
          autoClose: 3000,
          closeButton: true,
        });
      },
    }
  );

  const onExport = () => {
    toastId.current = toast.success('Your file is being prepared. Please wait for a few seconds', {
      autoClose: false,
      closeButton: false,
    });
    mutate({
      address: isPreview ? address.identifier : address.id,
      currency: address.currency,
      filters,
    });
  };

  const onApplyFilter = (filter: IFilters) => {
    setFilters(filter);
  };

  const [rows, transactionsCount] = getRowsAndCount(transactionQuery);

  const headerData = [
    'Transaction',
    'Token',
    'Type',
    'Value',
    'Value USD',
    'Entities Detected',
    'Date',
  ];

  return (
    <>
      <Table
        className='bg-white'
        title={'Transactions'}
        headerData={headerData}
        rows={rows || []}
        count={transactionsCount}
        border
        isLoading={transactionQuery.isLoading}
        onExport={onExport}
        filterComponent={
          <AddressTransactionsFilter filters={filters} onApply={onApplyFilter} address={address} />
        }
        appliedFilters={
          <AddressTransactionsAppliedFilters filters={filters} setFilters={onApplyFilter} />
        }
        heightOffset={120}
        errorMessage={
          transactionQuery?.isError
            ? 'Error in loading Transactions'
            : isAlertDetail
              ? 'No transactions available related to the current alert'
              : 'No transactions found.'
        }
        headerActionsLeft={
          <div className='flex items-center gap-1'>
            <Popover
              referenceContent={<Question size={16} className='text-gray-400' />}
              popoverContent={
                <div className='max-w-xs rounded-md border border-gray-200 bg-white p-2 text-2xs text-gray-800'>
                  There may be multiple rows for each transaction based on the nature of the
                  transaction and transfers.
                </div>
              }
              placement='right'
              className='ml-2'
            />
          </div>
        }
        {...buildInfiniteQueryTableProps(transactionQuery)}
      />
      <TransactionPopover
        id={currentId}
        currency={address.currency}
        open={open}
        setOpen={setOpen}
      />
    </>
  );
};

export default AddressTransactions;
