import { FC } from 'react';
import { AppliedFiltersBadge } from '../../ui';
import { format } from 'date-fns';

interface DateAppliedFilterProps {
  label: string;
  from: string;
  to: string;
  onRemove: () => void;
}

const DateAppliedFilter: FC<DateAppliedFilterProps> = props => {
  const { label, from, to, onRemove } = props;
  const formatDate = (date: string) => format(new Date(date), 'PP');
  if (!label || !from || !to) return null;

  return (
    <AppliedFiltersBadge onRemove={onRemove}>
      {label}: {formatDate(from)} - {formatDate(to)}
    </AppliedFiltersBadge>
  );
};

export default DateAppliedFilter;
