import React, { FC, ReactNode } from 'react';
import { BasicModal, Button } from '../../ui';
import SingleSelect from '../ui/components/Select/SingleSelect';
import { IOption } from '../Filters/CustomSelect';
import { UseInfiniteQueryResult } from 'react-query';
import { buildInfiniteQueryTableProps } from '../../utils/helpers/react-query.helper';

interface UpdateStageorAssigneeModalProps {
  open: boolean;
  bulkAction?: boolean;
  setOpen: (val: string) => void;
  optionsData: IOption[];
  selectedOption: IOption;
  setSelectedOption: (value: IOption) => void;
  handleSubmit: () => void;
  isLoading: boolean;
  currentOption?: IOption;
  setComment: (value: string) => void;
  isStageorAssignee: boolean;
  comment: string;
  getCustomOptionLabel: (value: IOption) => ReactNode;
  setSearch?: (value: string) => void;
  assignedToOptionsQuery?: UseInfiniteQueryResult;
}

const UpdateStageorAssigneeModal: FC<UpdateStageorAssigneeModalProps> = props => {
  const {
    open,
    bulkAction = false,
    setOpen,
    optionsData,
    selectedOption,
    setSelectedOption,
    handleSubmit,
    isLoading,
    currentOption,
    setComment,
    isStageorAssignee,
    comment,
    getCustomOptionLabel,
  } = props;

  const handleChange = (stage: IOption) => {
    setSelectedOption(stage);
  };

  const handleClose = () => {
    props?.setSearch('');
    setComment('');
    setOpen('');
    setSelectedOption(undefined);
  };

  return (
    <BasicModal
      open={open}
      size={bulkAction ? 'md' : 'md'}
      onClose={handleClose}
      modalTitle={`Update ${isStageorAssignee ? 'Status' : 'Assignee'}`}
      modalBody={
        <div className='flex flex-col gap-5 px-4 py-3 text-sm'>
          <div className='w-full gap-6'>
            {!bulkAction && (
              <div className='mb-2 flex w-full items-center gap-6'>
                <p className='w-1/5 text-gray-500'>
                  Current {`${isStageorAssignee ? 'Status' : 'Assignee'}`}
                </p>
                <div className='mt-1 flex grow items-center justify-between rounded-md border border-gray-200 bg-gray-50 p-2'>
                  {getCustomOptionLabel(currentOption)}
                </div>
              </div>
            )}
            <div className='flex w-full items-center gap-6'>
              <p className='w-1/5 text-gray-500'>
                Updated {`${isStageorAssignee ? 'Status' : 'Assignee'}`}
              </p>
              <SingleSelect
                options={optionsData}
                handleChange={handleChange}
                value={selectedOption}
                isCloseHidden={true}
                showRadioButton={true}
                isSearchable={!isStageorAssignee}
                getCustomLabel={getCustomOptionLabel}
                getCustomOptionLabel={isStageorAssignee && getCustomOptionLabel}
                parentClassName='flex-grow'
                {...buildInfiniteQueryTableProps(props?.assignedToOptionsQuery)}
                setSearch={props?.setSearch}
                filterOptions={() => true}
                asyncPaginate={!isStageorAssignee}
              />
            </div>
          </div>
          <div className='flex flex-col gap-2'>
            <p className='text-sm font-medium leading-5 text-gray-500'>Comment</p>
            <textarea
              name='alert-update'
              id='alert-update'
              data-testid='alert-update'
              placeholder='Enter your comment here'
              className='w-full rounded-md border-gray-400 text-sm text-gray-600'
              value={comment}
              onChange={e => setComment(e.target.value)}
              required
              rows={5}
            />
          </div>
        </div>
      }
      modalFooter={
        <div className='flex justify-end gap-4'>
          <Button
            variant='tertiary'
            disabled={isLoading || comment?.length === 0}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button disabled={isLoading || comment?.length === 0} onClick={handleSubmit}>
            Confirm
          </Button>
        </div>
      }
    />
  );
};

export default UpdateStageorAssigneeModal;
