import { FC, ReactNode } from 'react';
import BasicBadge from '../BasicBadge/BasicBadge';
import { Role, Roles } from '../../../utils/constants';

export interface RoleBadgeProps {
  role: Role;
  className?: string;
  label?: string | ReactNode;
}

const RoleBadge: FC<RoleBadgeProps> = props => {
  const className = Roles.find(r => r.value === props.role)?.className;
  return (
    <BasicBadge className={className + ' ' + props.className}>
      {props.label || Roles.find(r => r.value === props.role)?.label}
    </BasicBadge>
  );
};

export default RoleBadge;
