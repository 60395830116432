import { FC } from 'react';
import ReportTable from '../ReportTable';
import { ICaseTransactionResult } from '../../../api/dtos/case';
import { RiskBadge } from '../../../ui';
import CurrencyBadge from '../../ui/components/Badge/CurrencyBadge';

interface ITransactionListing {
  data: ICaseTransactionResult[];
  part: number;
}

const TransactionCaseList: FC<ITransactionListing> = ({ data, part }) => {
  if (!data) return null;

  const tableData = data.map((transactionItem: ICaseTransactionResult) => {
    const value = [
      {
        label: 'Transaction',
        value: <div className='break-words font-normal'>{transactionItem.identifier}</div>,
      },
      {
        label: 'Currency',
        value: (
          <div>
            <CurrencyBadge
              transparent
              key={transactionItem.identifier}
              currency={transactionItem.currency}
            />
          </div>
        ),
      },
      {
        label: 'Alert',
        value: <div className='font-normal'>{transactionItem.alerts}</div>,
      },
      {
        label: 'Risk Level',
        value: (
          <div>
            <RiskBadge risk={transactionItem.risk_level} />
          </div>
        ),
      },
      {
        label: 'Added by',
        value: <div className='font-normal'>{transactionItem.added_by_name}</div>,
      },
    ];

    return {
      label: transactionItem.id,
      value,
    };
  });
  return <ReportTable tableData={tableData} title={`Part ${part}: Transaction Listing`} />;
};

export default TransactionCaseList;
