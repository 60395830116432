import { Checkbox } from '../../../ui';
import { useRouter } from '../../../modules/router/RouterProvider';
import { useScreenApi } from '../../../utils/helpers/apiHelpers';
import { isCurrencyDeprecated } from '../../../utils/helpers/currency';
import CurrencyBadge from '../../../components/ui/components/Badge/CurrencyBadge';
import IdentifierEllipsis from '../../../components/ui/components/Currency/IdentifierEllipsis';
import CurrencyValue from '../../../components/ui/components/Currency/CurrencyValue';
import DigitalAssets from '../../../components/ui/components/Currency/DigitalAssets';
import RisksDetected from '../../../components/common/RisksDetected';
import CustomerAddressList from '../../../components/Address/AddressList/CustomerAddressList';
import { IAddressResponse } from '../../../../src/api/dtos/address';
import { tokenAccountError } from '../../../utils/constants/errors';
import { Date } from '../../../components/ui/components/Date';

interface IAddressData {
  addresses: IAddressResponse[];
  selectAllAddresses?: (force?: boolean) => void;
  selectedAddresses?: number[];
  selectAddress?: (id: number) => void;
  type?: string;
  setTokenModal?: (data: { identifier: string; currency: number }) => void;
}
export const useGetAddressData = ({
  addresses,
  selectAllAddresses,
  selectedAddresses,
  selectAddress,
  type = 'page',
  setTokenModal,
}: IAddressData) => {
  const { navigate } = useRouter();
  const screenApi = useScreenApi();

  return {
    headerData: [
      ...(type !== 'search'
        ? [
            <Checkbox
              key={0}
              className='w-0 rounded'
              onChange={() => selectAllAddresses()}
              checked={selectedAddresses.length === addresses.length && addresses.length !== 0}
            />,
          ]
        : []),
      'Address',
      'Digital Assets',
      'Customer ID',
      'Date Added',
      'Last Screened At',
      <div key={7} className='flex justify-end'>
        Received Amount
      </div>,
      'Risk Level',
      'Open Alerts',
    ],
    rowData: addresses
      ? addresses.map((address, index) => {
          return {
            id: address.identifier,
            currency: address.currency,
            customerId: address.customer_id,
            data: [
              ...(type !== 'search'
                ? [
                    <Checkbox
                      name='checkbox-selectAll'
                      key={address.id}
                      className='w-0 rounded'
                      onChange={e => {
                        e.stopPropagation();
                        selectAddress(address.id);
                      }}
                      checked={selectedAddresses.includes(address.id)}
                    />,
                  ]
                : []),
              <IdentifierEllipsis
                key={address.id}
                identifier={address.identifier}
                limit={16}
                className='text-blue-500'
                includedInCase={address.is_included_in_case}
                clickable
                copyable
              />,
              <div key={address.id} className='flex cursor-pointer gap-1'>
                {address.digital_assets?.length ? (
                  <DigitalAssets digitalAssets={address.digital_assets} />
                ) : (
                  <CurrencyBadge
                    key={index}
                    currency={address.currency}
                    label={address.currency_short}
                  />
                )}
              </div>,
              <CustomerAddressList key={address.id} address={address} />,
              <Date key={address.id} date={address.created_at} type='duration' />,
              <Date key={address.id} date={address.last_screened_time} type='duration' />,
              <div key={address.id} className='flex justify-end'>
                <CurrencyValue
                  key={7}
                  value={address.total_incoming_value_usd}
                  currency={'USD'}
                  dualTone
                />
              </div>,
              <RisksDetected
                key={address.id}
                risk={address.risk_level}
                risksDetected={address.risks_detected}
                grayScale={type === 'search'}
              />,
              <span key={address.id} id='open-alerts' data-testid='open-alerts'>
                {address.open_alerts}
              </span>,
            ],
            onClick: async () => {
              if (isCurrencyDeprecated(address.currency)) {
                navigate(`/addresses/${address.id}`);
              } else {
                const result = await screenApi({
                  identifier: address.identifier,
                  currency: address.currency,
                  entityType: 'addresses',
                  customer_id: address.customer_id,
                  type: address.type,
                });
                if (result?.error === tokenAccountError) {
                  setTokenModal({ identifier: result.account_owner, currency: address.currency });
                }
              }
            },
          };
        })
      : [],
  };
};
