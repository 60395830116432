import { FC } from 'react';
import { AppliedFiltersBadge } from '../../ui';

interface EntityAppliedFiltersProps {
  entity: string[];
  setEntity: (entity: string[]) => void;
  isCounterparty?: boolean;
}

const EntityAppliedFilters: FC<EntityAppliedFiltersProps> = props => {
  const { entity, setEntity, isCounterparty } = props;
  if (!entity) return null;

  const handleRemove = (r: string) => {
    const newEntities = entity
      .filter(e => e !== r)
      .map(e => {
        if (e.includes('->')) {
          return e.split('->')[1].trim();
        }
        return e;
      });
    setEntity(newEntities);
  };

  return (
    <>
      {entity.map(e => (
        <AppliedFiltersBadge key={e} onRemove={() => handleRemove(e)}>
          {!isCounterparty && (!e.includes('->') ? 'Entity: ' : 'Entity Subtype: ')}
          {e}
        </AppliedFiltersBadge>
      ))}
    </>
  );
};

export default EntityAppliedFilters;
