import { FC, useEffect, useMemo, useState } from 'react';
import { transactionApi } from '../../../../api/transaction';
import {
  getNextPageParamByOffset,
  useCachedInfiniteQuery,
} from '../../../../utils/helpers/react-query.helper';
import { Card } from '../../../ui/components/Card';
import { ITransactionResponse } from '../../../../api/dtos/transaction';
import Pagination from '../../../../containers/Pagination';
import TransactionEntity from './TransactionEntity';
import { ArrowRight } from '@phosphor-icons/react';
import CounterpartyFilter, {
  IFilters,
  defaultStateFilters,
} from '../../../Address/Overview/CounterpartyFilter';
import CounterpartyAppliedFilters from '../../../Address/Overview/CounterpartyFilter/CounterpartyAppliedFilters';
import { transactionLiteApi } from '../../../../api/transactionLite';

interface NonTokenTransactionProps {
  transaction: ITransactionResponse;
  setCustomerAddresses: (addresses: string[]) => void;
  defaultEntities?: string[];
  defaultEntitySubTypes?: string[];
  defaultEntityName?: string;
  popover?: boolean;
}

const NonTokenTransaction: FC<NonTokenTransactionProps> = props => {
  const {
    transaction,
    setCustomerAddresses,
    defaultEntities,
    defaultEntityName,
    defaultEntitySubTypes,
    popover,
  } = props;
  const [filters, setFilters] = useState<IFilters>(defaultStateFilters);

  useEffect(() => {
    if (defaultEntities) {
      setFilters(prev => ({ ...prev, entities: defaultEntities }));
    }
    if (defaultEntitySubTypes) {
      setFilters(prev => ({ ...prev, entitySubtypes: defaultEntitySubTypes }));
    }
    if (defaultEntityName) {
      setFilters(prev => ({ ...prev, entityName: defaultEntityName }));
    }
  }, [defaultEntities, defaultEntityName, defaultEntitySubTypes]);

  const mappedFilters = useMemo(() => {
    return {
      tag_type_verboses: filters.entities,
      tag_subtype_verboses: filters.entitySubtypes,
      tag_name_verbose: filters.entityName,
    };
  }, [filters]);

  const inputsQueryFull = useCachedInfiniteQuery(
    ['transactionDetailApi', 'getInputs', transaction.id, filters],
    ({ pageParam = 0 }) =>
      transactionApi.getInputs({ id: transaction.id, offset: pageParam, filters: mappedFilters }),
    {
      getNextPageParam: getNextPageParamByOffset,
      enabled: !popover,
    } as unknown
  );

  const outputsQueryFull = useCachedInfiniteQuery(
    ['transactionDetailApi', 'getOutputs', transaction.id, filters],
    ({ pageParam }) =>
      transactionApi.getOutputs({ id: transaction.id, offset: pageParam, filters: mappedFilters }),
    {
      getNextPageParam: getNextPageParamByOffset,
      enabled: !popover,
    } as unknown
  );

  const inputsQueryLite = useCachedInfiniteQuery(
    ['transactionLiteApi', 'getInputsLite', transaction.identifier, filters],
    ({ pageParam = 0 }) =>
      transactionLiteApi.getInputsLite({
        id: transaction.identifier,
        offset: pageParam,
        filters: mappedFilters,
        currency: transaction.currency,
      }),
    {
      getNextPageParam: getNextPageParamByOffset,
      enabled: !!popover,
    } as unknown
  );

  const outputsQueryLite = useCachedInfiniteQuery(
    ['transactionLiteApi', 'getOutputsLite', transaction.identifier, filters],
    ({ pageParam }) =>
      transactionLiteApi.getOutputsLite({
        id: transaction.identifier,
        offset: pageParam,
        filters: mappedFilters,
        currency: transaction.currency,
      }),
    {
      getNextPageParam: getNextPageParamByOffset,
      enabled: !!popover,
    } as unknown
  );
  const inputsQuery = !popover ? inputsQueryFull : inputsQueryLite;
  const outputsQuery = !popover ? outputsQueryFull : outputsQueryLite;

  useEffect(() => {
    const totalAddresses = () => {
      let output = [];
      if (outputsQuery.data) {
        output = outputsQuery.data.pages.flatMap(page =>
          page.data.results.map(result => result.address)
        );
      }
      return output;
    };
    setCustomerAddresses?.(totalAddresses());
  }, [outputsQuery.data, setCustomerAddresses]);

  const inputsCount = inputsQuery?.data?.pages?.map(d => d?.data).at(-1)?.count;
  const outputsCount = outputsQuery?.data?.pages?.map(d => d?.data).at(-1)?.count;

  return (
    <div>
      <Card
        title={`${transaction.currency_verbose} Transaction`}
        containerClassName='border'
        action={
          !!(defaultEntitySubTypes || defaultEntityName || defaultEntities) && (
            <CounterpartyFilter filters={filters} onApply={setFilters} />
          )
        }
        subHeader={
          <>
            <div className='grid grid-cols-12 border-b bg-gray-100 px-4 py-2 text-2xs text-gray-900'>
              <div className='col-span-7'>Senders ({inputsCount})</div>
              <div className='col-span-5'>Receivers ({outputsCount})</div>
            </div>
            <CounterpartyAppliedFilters filters={filters} setFilters={setFilters} />
          </>
        }
      >
        <div className='grid max-h-[400px] grid-cols-12 overflow-auto'>
          <Pagination query={inputsQuery} className='col-span-5 text-sm'>
            {entity => (
              <TransactionEntity
                key={`input-${entity.address}`}
                entity={entity}
                transaction={transaction}
              />
            )}
          </Pagination>
          <div className='col-span-2 mx-auto'>
            <ArrowRight size={32} weight='bold' color='#6B7280' />
          </div>
          <Pagination query={outputsQuery} className='col-span-5 text-sm'>
            {entity => (
              <TransactionEntity
                key={`output-${entity.address}`}
                entity={entity}
                transaction={transaction}
              />
            )}
          </Pagination>
        </div>
      </Card>
    </div>
  );
};

export default NonTokenTransaction;
