import { FC } from 'react';
import { Card } from '../ui/components/Card';
import Table from '../../ui/components/Table/Table';
import {
  useCustomerGetPofAddressSummary,
  useCustomerGetPofCustomerSummary,
} from '../../api/customer';
import {
  buildInfiniteQueryTableProps,
  flattenInfiniteQueryResult,
} from '../../utils/helpers/react-query.helper';
import CurrencyBadge from '../ui/components/Badge/CurrencyBadge';
import CurrencyValue from '../ui/components/Currency/CurrencyValue';
import { RiskBadge } from '../../ui';
import { Date } from '../ui/components/Date';
import IdentifierEllipsis from '../ui/components/Currency/IdentifierEllipsis';
import { Tooltip } from '../../ui/components/Tooltip';

interface IProofOfReservesProps {
  customerId: string;
}

const ProofOfReserves: FC<IProofOfReservesProps> = ({ customerId }) => {
  const { data: customerSummary } = useCustomerGetPofCustomerSummary(
    encodeURIComponent(customerId)
  );

  const customerAddressSummaryQuery = useCustomerGetPofAddressSummary(
    encodeURIComponent(customerId)
  );

  const headerData = [
    'Address',
    'Blockchain',
    <div key={'balance'} className='flex justify-end'>
      Balance (USD)
    </div>,
    <div key={'inflow'} className='flex justify-end'>
      Inflows (USD)
    </div>,
    <div key={'outflow'} className='flex justify-end'>
      Outflows (USD)
    </div>,
    'Risk level',
    'Date added',
    'Last updated',
  ];

  const [customerPofAddressesCount, customerPofAddressesData] = flattenInfiniteQueryResult(
    customerAddressSummaryQuery?.data
  );

  const rows = customerPofAddressesData?.map(address => {
    return {
      id: address.identifier,
      data: [
        <IdentifierEllipsis
          key={address.identifier}
          identifier={address.identifier}
          clickable
          copyable
          currency={address.currency}
          customerId={customerId}
          customerType={1}
          type='addresses'
        />,
        <CurrencyBadge key={address.identifier} currency={address.currency} />,
        <div key={address.identifier} className='flex justify-end'>
          <CurrencyValue currency={'USD'} value={address.balance_usd} dualTone />
        </div>,
        <div key={address.identifier} className='flex justify-end'>
          <CurrencyValue currency={'USD'} value={address.total_incoming_value_usd} dualTone />
        </div>,
        <div key={address.identifier} className='flex justify-end'>
          <CurrencyValue currency={'USD'} value={address.total_outgoing_value_usd} dualTone />
        </div>,
        <RiskBadge key={address.identifier} risk={address.risk_level} />,
        <Date key={address.identifier} date={address.date_added} type='duration' />,
        <Date key={address.identifier} date={address.last_updated_at} type='duration' />,
      ],
    };
  });
  return (
    <div>
      <Card
        title='Customer Summary'
        className='grid grid-cols-3 text-xs'
        containerClassName='border border-gray-200'
      >
        <div className='flex'>
          Total Balance (USD):{' '}
          <CurrencyValue
            className='ml-1'
            value={customerSummary?.data?.balance_usd}
            currency={'USD'}
          />
          <Tooltip
            className='bg-slate-300 text-gray-900'
            content='The balance amount may not match due to token price fluctuations'
            id='pof-tooltip'
            label='i'
          />
        </div>
        <div className='flex'>
          Total Inflows (USD):
          <CurrencyValue
            className='ml-1'
            value={customerSummary?.data?.total_incoming_value_usd}
            currency={'USD'}
          />
        </div>
        <div className='flex'>
          Total Outflows (USD):
          <CurrencyValue
            className='ml-1'
            value={customerSummary?.data?.total_outgoing_value_usd}
            currency={'USD'}
          />
        </div>
      </Card>
      <Table
        title=' '
        headerData={headerData}
        border
        className='mt-4'
        isCountVerbose
        rows={rows}
        count={customerPofAddressesCount}
        {...buildInfiniteQueryTableProps(customerAddressSummaryQuery)}
      />
    </div>
  );
};

export default ProofOfReserves;
