import classNames from 'classnames';
import { HTMLProps } from 'react';
import { FC, ReactNode } from 'react';
import { PageHeader } from '../components/ui/components/Header';

interface PageProps extends Omit<HTMLProps<HTMLDivElement>, 'action'> {
  title?: string;
  action?: ReactNode;
  containerClassName?: string;
}

const Page: FC<PageProps> = ({
  title,
  action,
  children,
  className,
  containerClassName,
  ...props
}) => {
  return (
    <div
      className={classNames('flex h-max flex-col px-6 py-4', containerClassName)}
      id='containerPage'
      data-testid='containerPage'
    >
      {(title || action) && (
        <PageHeader className='flex-none pb-4' heading={title}>
          {action}
        </PageHeader>
      )}
      <div className={classNames('flex-1', className)} {...props}>
        {children}
      </div>
    </div>
  );
};

export default Page;
