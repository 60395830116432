/* eslint-disable no-console */
import { Api } from './api';
import { useInfiniteQuery, UseQueryOptions } from 'react-query';
import { getNextPageParam } from '../utils/helpers/helperFunctions';
import { IExportUploadedFile, IUploadHistoryResponse } from './dtos/upload';

export class UploadApi extends Api {
  readonly baseUrl: string = '/upload/api/v4/files';

  readonly getFiles = ({ offset = 0, q }) => {
    return this.http.get<IUploadHistoryResponse>(this.route('/'), {
      params: { limit: 25, file_type: q, offset },
    });
  };

  readonly exportUploadedFile = (params: IExportUploadedFile) => {
    const { fileId, download_file_type } = params;

    return this.http.get(this.route(`${fileId}/download/`), {
      params: { download_file_type },
      responseType: 'blob',
    });
  };
  readonly uploadFile = params => {
    const formData = new FormData();

    const inputData = { file_type: params.file_type };
    formData.append('crypto_file', params.file);
    Object.keys(inputData).forEach(key => {
      formData.append(key, inputData[key]);
    });
    return this.http.post(this.route('/'), formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress(progressEvent) {
        const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);

        if (params.length - 1 === params.index && progress === 100) {
          params.setUploadProgress({});
        } else {
          params.setUploadProgress(prevProgress => ({
            ...prevProgress,
            [params.index]: progress,
          }));
        }
      },
    });
  };
}

export const uploadApi = new UploadApi();

export const useUpload = ({ query }: { query: number }, options?: UseQueryOptions) => {
  const key = ['UploadApi.getFiles', query];
  const result = useInfiniteQuery(
    key,
    ({ pageParam }) => uploadApi.getFiles({ offset: pageParam, q: query }),
    {
      getNextPageParam,
      ...options,
    } as unknown
  );
  return {
    key,
    ...result,
  };
};
