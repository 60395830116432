import { FC, useEffect, useRef, useState } from 'react';
import Tab from '../ui/components/Tab/Tab';
// import { flattenInfiniteQueryResultMeta } from '../../utils/helpers/react-query.helper';
import Pagination from '../../containers/Pagination';
import { IFilters, defaultStateFilters } from './AuditTrail/FilterAuditTrail';
import { UseInfiniteQueryResult, useMutation, useQueryClient } from 'react-query';
import classNames from 'classnames';
import FilterAuditTrail from './AuditTrail/FilterAuditTrail';
import AuditTrailAppliedFilters from './AuditTrail/AuditTrailAppliedFilters';
import Comment from './AuditTrail/Comment';
import { commentApi, useCommentGetComments } from '../../api/comment';
import Quill from '../ui/components/Quill';
import { IPostCommentsUser } from '../../api/dtos/comment';
import { AxiosResponse } from 'axios';
import { IPaginatedResponse } from '../../api/dtos/common';
import { toast } from 'react-toastify';
import { useReactToPrint } from 'react-to-print';
import { Button } from '../../ui/components/Button';
import ConfirmationModal from '../../ui/components/Modals/ConfirmationModal/ConfirmationModal';

interface AuditLogsProps {
  type: 'address' | 'transaction' | 'customer';
  id: string | number;
}

export interface IComment {
  id: number;
  type: number;
  body: string;
  comment_files: { file_key: string; file_name: string }[];
  commenter: string;
  created_at: string;
}

export interface ICommentGetResponse extends IPaginatedResponse<IComment> {}

const AuditLogs: FC<AuditLogsProps> = ({ type, id }) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const [filters, setFilters] = useState<IFilters>(defaultStateFilters);
  const [tab, setTab] = useState<number>(0);
  const [limit, setLimit] = useState<number>(undefined);
  const [loadingPdf, setLoadingPdf] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const toastId = useRef(null);
  const reactToPrintFn = useReactToPrint({
    contentRef,
    onAfterPrint() {
      setLoadingPdf(false);
      setLimit(undefined);
      toast.update(toastId.current, {
        render: 'Downloaded comments successfully',
        type: 'success',
        autoClose: 3000,
        closeButton: true,
      });
    },
    onPrintError() {
      toast.update(toastId.current, {
        render: 'Download failed. Please try again later.',
        type: 'error',
        autoClose: 3000,
        closeButton: true,
      });
      setLoadingPdf(false);
    },
  });
  const onSuccess = () => {
    if (loadingPdf && limit !== undefined)
      setTimeout(() => {
        reactToPrintFn();
      }, 1000);
  };
  const getCommentsAll = useCommentGetComments(
    {
      id: encodeURIComponent(id),
      filters,
      type,
      limit,
    },
    {
      onSuccess: onSuccess,
    }
  );
  const getCommentsNotes = useCommentGetComments(
    {
      id: encodeURIComponent(id),
      filters,
      type,
      tab_type: 6,
      limit,
    },
    {
      onSuccess: onSuccess,
    }
  );
  const getCommentsLineage = useCommentGetComments(
    {
      id: encodeURIComponent(id),
      filters,
      type,
      tab_type: 16,
      limit,
    },
    {
      onSuccess: onSuccess,
    }
  );

  const selectedQuery = [getCommentsAll, getCommentsNotes, getCommentsLineage][tab];
  const isLoading = getCommentsAll.isLoading && getCommentsLineage && getCommentsNotes.isLoading;
  const notShowSave = [
    getCommentsAll.isError,
    getCommentsNotes.isError,
    getCommentsLineage.isError,
  ][tab];

  useEffect(() => {
    if (!isNaN(limit)) selectedQuery.fetchNextPage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit]);

  const onChange = (tab: number) => {
    setTab(tab);
  };

  const onApplyFilter = (filters: IFilters) => {
    setFilters(filters);
  };

  const tabs = [
    { label: 'All' },
    {
      label: 'Notes',
    },
    {
      label: 'Lineage',
    },
  ];

  const queryClient = useQueryClient();

  const { mutate } = useMutation(commentApi.addComment, {
    onSuccess: () => {
      toast.success('Added Comment successfully');
      queryClient.invalidateQueries(['commentApi.getComments']);
    },
  });

  const onSubmit = (value: string, files: FileList, users: IPostCommentsUser[]) => {
    mutate({
      id,
      type,
      body: value,
      files,
      context: {
        users,
      },
    });
  };

  const renderPagination = (
    getComments: UseInfiniteQueryResult<AxiosResponse<ICommentGetResponse>>
  ) => (
    <>
      <Pagination
        query={getComments}
        className='flex max-h-screen flex-col-reverse print:h-full print:max-h-full'
        loader='compass'
      >
        {data => <Comment comment={data} key={data.id} />}
      </Pagination>
      {tab !== 2 && <Quill className='print:hidden' id={id} onSubmit={onSubmit} type={type} />}
    </>
  );

  const onConfirmModal = () => {
    setOpenModal(false);
    setLoadingPdf(true);
    toastId.current = toast.success('Your file is being prepared. Please wait for a few seconds', {
      autoClose: false,
      closeButton: false,
    });
    setLimit(500 - selectedQuery.data.pages.length * 25);
  };

  return (
    <div ref={contentRef}>
      <div className='hidden text-sm print:mb-3 print:block'>
        Note: This document includes up to 500 comments as per the download limit.
      </div>
      <div className={classNames('rounded-md border border-gray-200')}>
        <Tab
          values={tabs}
          type='primary'
          onChange={onChange}
          listClassName='border-b-2 p-4 rounded-t-md'
          inactiveClassName='text-gray-800/30 border-gray-200 cursor-not-allowed'
          panelClassName=''
          headerActions={
            <div className='flex gap-2'>
              {!isLoading && !notShowSave && (
                <Button
                  id='audit-pdfSave'
                  className='print:hidden'
                  variant='primary'
                  disabled={loadingPdf}
                  onClick={() => {
                    setOpenModal(true);
                  }}
                >
                  Save To PDF
                </Button>
              )}
              {tab === 0 && (
                <FilterAuditTrail filters={filters} onApply={onApplyFilter} type={type} />
              )}
            </div>
          }
          isLoading={isLoading}
          subHeader={
            tab === 0 && <AuditTrailAppliedFilters filters={filters} setFilters={setFilters} />
          }
        >
          {renderPagination(getCommentsAll)}
          {renderPagination(getCommentsNotes)}
          {renderPagination(getCommentsLineage)}
        </Tab>
      </div>
      <ConfirmationModal
        open={openModal}
        data={openModal || null}
        onCancel={() => {
          setLimit(undefined);
          setOpenModal(false);
        }}
        onConfirm={onConfirmModal}
        title='Download Comments'
        body='When downloading comments, please note that a maximum of 500 comments will be available for download. '
        confirmButton='Ok'
      />
    </div>
  );
};

export default AuditLogs;
