import { FC, PropsWithChildren } from 'react';
import { calculateVh } from '../utils/helpers/common';

interface LayoutPropsExtend {
  heightOffset?: number;
}
type LayoutProps = PropsWithChildren<LayoutPropsExtend>;

const Layout: FC<LayoutProps> = ({ children, heightOffset = 0 }) => {
  return (
    <div
      className='flex-1'
      style={{
        height: calculateVh(heightOffset),
        overflowY: 'scroll',
      }}
    >
      {children}
    </div>
  );
};

export default Layout;
