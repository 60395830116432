import classNames from 'classnames';
import { HTMLProps } from 'react';
import { ReactNode } from 'react';
import { FC } from 'react';

export interface CardProps extends Omit<HTMLProps<HTMLDivElement>, 'action'> {
  title: string;
  subtitle?: string;
  action?: ReactNode;
  subHeader?: ReactNode;
  containerClassName?: string;
}

const Card: FC<CardProps> = ({
  title,
  subtitle,
  action,
  className,
  containerClassName,
  subHeader,
  children,
  ...props
}) => {
  return (
    <div
      className={classNames(
        'flex w-full flex-col rounded-md bg-white shadow-sm',
        containerClassName
      )}
    >
      <div className='flex h-12 w-full flex-none items-center justify-between border-b px-4'>
        <div className='flex-1'>
          <p className='!mb-0 text-xs font-semibold text-gray-500'>{title}</p>
          {subtitle && <p className='text-2xs text-gray-400'>{subtitle}</p>}
        </div>
        {action && <div className='flex-none'>{action}</div>}
      </div>
      {subHeader}
      <div className={classNames('flex-1 p-4', className)} {...props}>
        {children}
      </div>
    </div>
  );
};

export default Card;
