import { FC } from 'react';
import { TabHeader } from '../../../../components/ui/components/Header';
import Table from '../../../../ui/components/Table/Table';
import { useWorkspacesGet } from '../../../../api/workspaces';
import { RoleBadge } from '../../../../ui';
import { Roles } from '../../../../ui/utils/constants';

const OrganizationStructure: FC = () => {
  const headerData = ['Name', 'Members', 'Risk Policies'];
  const { data, isLoading } = useWorkspacesGet();
  const rows = data?.data?.results.map(t => {
    return {
      id: t.slug,
      data: [
        t.name,
        <div key={t.slug}>
          {t.role_counts.map((role, i) => (
            <RoleBadge
              key={i}
              className='mr-1'
              role={Roles.find(r => r.label === role.role_verbose)?.value}
              label={
                <div className='flex items-center justify-evenly gap-1'>
                  <div>{`${role.role_verbose}s`}</div>
                  <div
                    className={`rounded-full text-white ${
                      Roles.find(r => r.label === role.role_verbose)?.border
                    } px-1.5 py-0 text-[10px]`}
                  >
                    {role.count}
                  </div>
                </div>
              }
            />
          ))}
        </div>,
        <span key={t.slug} className='ml-2'>
          {t.policy_count}
        </span>,
      ],
    };
  });
  return (
    <div className='h-full'>
      <TabHeader heading='Workspaces' />
      <div className='mt-5'>
        <Table
          border
          isHeaderVisible={false}
          headerData={headerData}
          rows={rows}
          isLoading={isLoading}
        />
      </div>
    </div>
  );
};

export default OrganizationStructure;
