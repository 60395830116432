import { FC, useState } from 'react';
import CustomSelect, { IOption } from './CustomSelect';
import { caseTypes, caseTypesCase } from '../common/AuditTrail/types';
interface CaseTypeFilterProps {
  value: string[];
  setValue: (types: string[]) => void;
  type: 'address' | 'transaction' | 'case' | 'customer';
}

const CaseTypeFilter: FC<CaseTypeFilterProps> = props => {
  const { value, setValue } = props;

  const filtersToRemove = {
    address: [0, 7, 8],
    transaction: [0, 4, 5, 7],
    customer: [0, 4, 5, 7, 8],
    case: [4, 5, 8],
  };

  const caseOptions =
    props.type === 'case'
      ? caseTypesCase
      : caseTypes
          .map(c => {
            return {
              value: caseTypes.indexOf(c).toString(),
              label: c,
            };
          })
          .filter(c => !filtersToRemove[props.type].includes(Number(c.value)));
  let selectedTypes = [];
  if (value) {
    const types = value;
    selectedTypes = caseOptions.filter(option => types.includes(option.value));
  }
  const [types, setTypes] = useState<IOption[]>(selectedTypes);

  const handleChange = (types: IOption[]) => {
    setTypes(types);
    const typeValues = types.map(type => type.value);
    setValue(typeValues);
  };

  return (
    <CustomSelect label='Type' options={caseOptions} handleChange={handleChange} value={types} />
  );
};

export default CaseTypeFilter;
