import { useQuery, UseQueryOptions } from 'react-query';
import { Api } from './api';
import {
  IDashboardAddressResponse,
  IDashboardAlertEntityResponse,
  IDashboardAlertResolutionRecordResponse,
  IDashboardAlertResponse,
  IDashboardCaseResponse,
  IDashboardEntitiesResponse,
  IDashboardRequest,
  IDashboardRuleResponse,
  IDashboardTransactionResponse,
} from './dtos/dashboard';
import { IMessageResponse, IStatusResponse } from './dtos/common';

export class DashboardApi extends Api {
  readonly baseUrl: string = '/risk/api/v4/dashboard';

  readonly getDashboardAddress = (params?: IDashboardRequest) => {
    return this.http.get<IDashboardAddressResponse>(this.route('addresses/'), { params });
  };

  readonly getDashboardTransaction = (params?: IDashboardRequest) => {
    return this.http.get<IDashboardTransactionResponse>(this.route('transactions/'), { params });
  };

  readonly getDashboardCustomer = (params?: IDashboardRequest) => {
    return this.http.get<IDashboardCaseResponse>(this.route('customers/'), { params });
  };

  readonly getDashboardEntities = (params?: IDashboardRequest) => {
    return this.http.get<IDashboardEntitiesResponse>(this.route('entities/'), { params });
  };

  readonly getDashboardCases = (params?: IDashboardRequest) => {
    return this.http.get<IDashboardCaseResponse>(this.route('cases/'), { params });
  };

  readonly getDashboardRules = (params?: IDashboardRequest) => {
    return this.http.get<IDashboardRuleResponse>(this.route('rules/'), { params });
  };

  readonly getDashboardAlerts = (params?: IDashboardRequest) => {
    return this.http.get<IDashboardAlertResponse>(this.route('alerts/'), { params });
  };

  readonly getDashboardAlertsEntity = (params?: IDashboardRequest) => {
    return this.http.get<IDashboardAlertEntityResponse>(this.route('alerts-entity/'), { params });
  };

  readonly getDashboardAlertsResolutionRecord = (params?: IDashboardRequest) => {
    return this.http.get<IDashboardAlertResolutionRecordResponse>(
      this.route('alerts-resolution-record/'),
      {
        params,
      }
    );
  };

  readonly getDashboardReport = (params?: IDashboardRequest) => {
    return this.http.get<IMessageResponse>(this.route('report/'), {
      params,
    });
  };

  readonly getDashboardResolvedAlertReport = (params?: IDashboardRequest) => {
    return this.http.get<IStatusResponse>(this.route('resolved-alert-report/'), {
      params,
    });
  };

  readonly getDashboardOpenedAlertReport = (params?: IDashboardRequest) => {
    return this.http.get<IStatusResponse>(this.route('opened-alert-report/'), {
      params,
    });
  };

  readonly getDashboardCreatedAlertReport = (params?: IDashboardRequest) => {
    return this.http.get<IStatusResponse>(this.route('created-alert-report/'), {
      params,
    });
  };
}

export const dashboardApi = new DashboardApi();
const formatParams = (params: IDashboardRequest) => {
  if (!params.refresh) {
    delete params.refresh;
  }
  return params;
};
export const useDashboardAddress = (params?: IDashboardRequest, options?: UseQueryOptions) => {
  return useQuery(
    ['dashboardApi.address', params?.start_date, params?.end_date],
    () => dashboardApi.getDashboardAddress(formatParams(params)),
    {
      ...options,
    } as unknown
  );
};

export const useDashboardTransaction = (params?: IDashboardRequest, options?: UseQueryOptions) => {
  return useQuery(
    ['dashboardApi.transaction', params?.start_date, params?.end_date],
    () => dashboardApi.getDashboardTransaction(formatParams(params)),
    {
      ...options,
    } as unknown
  );
};

export const useDashboardCustomer = (params?: IDashboardRequest, options?: UseQueryOptions) => {
  return useQuery(
    ['dashboardApi.customer', params?.start_date, params?.end_date],
    () => dashboardApi.getDashboardCustomer(formatParams(params)),
    {
      ...options,
    } as unknown
  );
};

export const useDashboardEntities = (params?: IDashboardRequest, options?: UseQueryOptions) => {
  return useQuery(
    ['dashboardApi.entities', params?.start_date, params?.end_date],
    () => dashboardApi.getDashboardEntities(formatParams(params)),
    {
      ...options,
    } as unknown
  );
};

export const useDashboardCases = (params?: IDashboardRequest, options?: UseQueryOptions) => {
  return useQuery(
    ['dashboardApi.cases', params?.start_date, params?.end_date],
    () => dashboardApi.getDashboardCases(formatParams(params)),
    {
      ...options,
    } as unknown
  );
};

export const useDashboardRules = (params?: IDashboardRequest, options?: UseQueryOptions) => {
  return useQuery(
    ['dashboardApi.rules', params?.start_date, params?.end_date],
    () => dashboardApi.getDashboardRules(formatParams(params)),
    {
      ...options,
    } as unknown
  );
};

export const useDashboardAlerts = (params?: IDashboardRequest, options?: UseQueryOptions) => {
  return useQuery(
    ['dashboardApi.alerts', params?.start_date, params?.end_date],
    () => dashboardApi.getDashboardAlerts(formatParams(params)),
    {
      ...options,
    } as unknown
  );
};

export const useDashboardAlertsEntity = (params?: IDashboardRequest, options?: UseQueryOptions) => {
  return useQuery(
    ['dashboardApi.alertsEntity', params?.start_date, params?.end_date],
    () => dashboardApi.getDashboardAlertsEntity(formatParams(params)),
    {
      ...options,
    } as unknown
  );
};

export const useDashboardAlertsResolutionRecord = (
  params?: IDashboardRequest,
  options?: UseQueryOptions
) => {
  return useQuery(
    ['dashboardApi.alertsResolutionRecord', params?.start_date, params?.end_date],
    () => dashboardApi.getDashboardAlertsResolutionRecord(formatParams(params)),
    {
      ...options,
    } as unknown
  );
};
