import React, { useEffect, useMemo, useRef } from 'react';
import { DayPicker } from 'react-day-picker';
import { Button } from '../../../ui';

interface Props {
  value: { from?: Date; to?: Date };
  handleChange: (value: { from?: Date; to?: Date }) => void;
  visible?: boolean;
  setVisible?: (visible: boolean) => void;
}

const CustomDayPicker: React.FC<Props> = ({ value, handleChange, visible, setVisible }) => {
  const [dateRange, setDateRange] = React.useState<{ from?: Date; to?: Date }>();

  const startDate = useRef(new Date(2010, 0, 1))?.current;
  const endDate = useRef(new Date())?.current;

  useEffect(() => {
    setDateRange(value);
  }, [value]);
  const disabledDays = useMemo(() => {
    return {
      after: new Date(),
    };
  }, []);
  const disabledDaysTo = useMemo(() => {
    return {
      before: dateRange?.from,
      after: new Date(),
    };
  }, [dateRange?.from]);

  const calendarRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (visible) {
      const handleClickOutside = (event: MouseEvent) => {
        const target = event.target as HTMLElement;
        const isOption =
          target.classList.contains('calendar-option') ||
          target.closest('.calendar-option') !== null;
        if (visible && calendarRef.current && !calendarRef.current.contains(target) && !isOption) {
          setVisible(false);
        }
      };

      document.addEventListener('click', handleClickOutside);

      return () => {
        document.removeEventListener('click', handleClickOutside);
      };
    }
  }, [setVisible, visible]);

  const onReset = e => {
    e.stopPropagation();
    e.preventDefault();
    setVisible(false);
    handleChange({ from: null, to: null });
  };
  const onApply = e => {
    e.stopPropagation();
    e.preventDefault();
    setVisible(false);
    handleChange(dateRange);
  };

  if (!visible) return null;

  return (
    <div ref={calendarRef}>
      <div className='flex border-b'>
        <DayPicker
          className='w-1/2 bg-white'
          captionLayout='dropdown-years'
          startMonth={startDate}
          endMonth={endDate}
          mode='single'
          selected={dateRange?.from}
          onSelect={date => setDateRange({ from: date, to: dateRange?.to })}
          disabled={disabledDays}
          fixedWeeks
          showOutsideDays
        />
        <DayPicker
          className='w-1/2 bg-white'
          captionLayout='dropdown-years'
          startMonth={startDate}
          endMonth={endDate}
          mode='single'
          selected={dateRange?.to}
          onSelect={date => setDateRange({ from: dateRange?.from, to: date })}
          disabled={disabledDaysTo}
          fixedWeeks
          showOutsideDays
        />
      </div>
      {/* Apply and reset */}
      <div className='flex justify-end bg-gray-50 p-1'>
        <Button variant='tertiary' className='mr-3' onClick={onReset}>
          Reset
        </Button>
        <Button variant='secondary' onClick={onApply}>
          Apply
        </Button>
      </div>
    </div>
  );
};

export default CustomDayPicker;
