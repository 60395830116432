import { Controller, ControllerProps, FieldPath, FieldValues } from 'react-hook-form';
import { Dropdown, DropdownProps } from '../Dropdown';

type DropdownControllerProps<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
> = Omit<DropdownProps, 'value' | 'onChange'> &
  Omit<ControllerProps<TFieldValues, TName>, 'render'>;

const DropdownController = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  name,
  rules,
  shouldUnregister,
  defaultValue,
  control,
  ...props
}: DropdownControllerProps<TFieldValues, TName>) => {
  return (
    <Controller
      name={name}
      rules={rules}
      shouldUnregister={shouldUnregister}
      defaultValue={defaultValue}
      control={control}
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      render={({ field: { ref, ...field }, fieldState }) => {
        return (
          <>
            <Dropdown {...field} {...props} error={fieldState?.error?.message} />
            {fieldState.error && (
              <p className='mt-2 text-sm text-red-600' id='email-error' data-testid='email-error'>
                {fieldState.error.message}
              </p>
            )}
          </>
        );
      }}
    ></Controller>
  );
};

export default DropdownController;
