import React, { FC } from 'react';
import Popover from '../../ui/components/Popover/Popover';
import { IRisksDetected } from '../../api/dtos/search';
import { RiskBadge } from '../../ui';
import { snakeCase, startCase } from 'lodash';
import { entityOptions } from '../../utils/constants/constants';

interface IRiskDetectedProps {
  risk: number;
  risksDetected: IRisksDetected[];
  isAddress?: boolean;
  grayScale?: boolean;
}

interface IRiskDetectedWithCount extends IRisksDetected {
  count: number;
}

const RisksDetected: FC<IRiskDetectedProps> = ({
  risk,
  risksDetected,
  isAddress = true,
  grayScale = false,
}) => {
  const association = risksDetected.filter(r => r.rule_category === 1);
  const behaviour = risksDetected.filter(r => r.rule_category === 2);
  const incoming = association.filter(r => r.risk_types.includes(1));
  const outgoing = association.filter(r => r.risk_types.includes(2));
  const direct = association.filter(r => r.risk_types.includes(3));

  const incomingValues = countValues(incoming);
  const outgoingValues = countValues(outgoing);
  const directValues = countValues(direct);
  const behaviourValues = countValues(behaviour);

  function countValues(values: IRisksDetected[]): IRiskDetectedWithCount[] {
    const counter = {};

    if (values.length < 10) return values.map(v => ({ ...v, count: 1 }));

    values.forEach(function (obj) {
      const key = JSON.stringify(obj);
      counter[key] = (counter[key] || 0) + 1;
    });

    return Object.keys(counter).map(key => ({
      ...JSON.parse(key),
      count: counter[key],
    }));
  }

  const getRuleName = (ruleName: string) => {
    if (entityOptions.some(e => snakeCase(e.value) === snakeCase(ruleName))) {
      return startCase(ruleName);
    }
    return ruleName;
  };

  const getRuleNameAndCount = (r: IRiskDetectedWithCount) => {
    if (r.count === 1) return getRuleName(r.rule_name);
    return `${getRuleName(r.rule_name)} (${r.count})`;
  };

  const Risks: FC<{ risks: IRiskDetectedWithCount[] }> = ({ risks }) => {
    return (
      <>
        {risks?.length ? (
          risks.map((r, index) => (
            <RiskBadge
              grayScale={grayScale}
              key={index}
              risk={r.risk_level}
              label={getRuleNameAndCount(r)}
            />
          ))
        ) : (
          <RiskBadge grayScale={grayScale} risk={0} />
        )}
      </>
    );
  };

  return risk ? (
    <div className='flex items-center text-xs'>
      <Popover
        className='w-[500px] border bg-white shadow-md'
        referenceContent={<RiskBadge grayScale={grayScale} risk={risk} />}
        popoverContent={
          <div>
            <div className='border-b p-2 py-4 text-sm font-semibold'>Risks Detected</div>
            {isAddress && (
              <div className='p-4'>
                <div className='mb-2 font-semibold'>Direct Risks</div>
                <Risks risks={directValues} />
              </div>
            )}
            <hr />
            <div className='p-4'>
              {isAddress && <div className='mb-2 font-semibold'>Association Risks</div>}
              <div className='grid grid-cols-2'>
                <div>
                  <div className='mb-2'>Incoming Risks</div>
                  <Risks risks={incomingValues} />
                </div>
                <div>
                  <div className='mb-2'>Outgoing Risks</div>
                  <Risks risks={outgoingValues} />
                </div>
              </div>
              {behaviourValues?.length > 0 && (
                <div>
                  <div className='mb-2 font-semibold'>Behaviour Risks</div>
                  <Risks risks={behaviourValues} />
                </div>
              )}
            </div>
          </div>
        }
      />
    </div>
  ) : (
    <RiskBadge grayScale={grayScale} risk={risk} />
  );
};

export default RisksDetected;
