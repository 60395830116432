import { FC } from 'react';
import { ILevels } from '../../api/dtos/dashboard';
import DashboardWidget from './DashboardWidget';
import { GoogleCharts } from 'google-charts';
import { createTooltip } from './WidgetGraphTooltip';
import React from 'react';

interface IAlertResolutionWidgetProps {
  resolutionData: ILevels;
  isLoading?: boolean;
  isPdf?: boolean;
}

const AlertResolutionWidget: FC<IAlertResolutionWidgetProps> = ({
  resolutionData = {},
  isLoading,
  isPdf,
}) => {
  const resolutionTimes = ['0 to 24 hours', '24 to 48 hours', '> 48 hours'];
  const resolutionDataArray: [key: string, value: number][] = Object.values(resolutionData).map(
    (r, i) => [resolutionTimes[i], r]
  );
  const totalCount = resolutionDataArray.reduce((sum, row) => sum + row[1], 0);
  const percentagesData: [key: string, value: number][] = resolutionDataArray.map(
    ([key, value]) => {
      return [key, Math.round((value / totalCount) * 100 * 1e2) / 1e4];
    }
  );

  const alertResolutionTimeChart = () => {
    const data = [];
    if (resolutionData) {
      percentagesData.forEach((p, i) => {
        data.push([
          p[0],
          p[1],
          '#F94144',
          `${(p[1] * 100).toFixed(2)}%`,
          createTooltip(p[0], resolutionDataArray[i][1], totalCount),
        ]);
      });
    }

    const headers: (string | { type?: string; role: string; p?: { html: boolean } })[] = [
      'Resolution Time',
      'Percentage',
      { role: 'style' },
      { role: 'annotation' },
      { type: 'string', role: 'tooltip', p: { html: true } },
    ];

    return [headers, ...data];
  };

  const options = {
    fontName:
      'Inter, ui-sans-serif, system-ui, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
    titleTextStyle: {
      bold: false,
      color: '#111827',
      fontSize: 16,
    },
    annotations: {
      textStyle: {
        fontSize: 12,
        color: '#fff',
      },
    },
    hAxis: {
      title: '',
      titleTextStyle: { color: '#6B7280' },
      textStyle: {
        color: '#6B7280',
        fontSize: 12,
      },
      gridlines: { color: '#E5E7EB', count: 5 },
    },
    vAxis: {
      gridlines: { color: '#E5E7EB' },
      format: 'percent',
    },
    legend: { position: 'none' },
    chartArea: {
      width: '78%',
      top: '10%',
      height: '80%',
    },
    tooltip: { isHtml: true },
    width: isPdf ? 450 : null,
  };
  const noData = !(resolutionDataArray?.length > 0);
  if (!noData) GoogleCharts.load(drawChart);

  const chartRef = React.useRef(null);
  function drawChart() {
    const data = GoogleCharts.api.visualization.arrayToDataTable(alertResolutionTimeChart());
    if (chartRef?.current) {
      const chart = new GoogleCharts.api.visualization.ColumnChart(chartRef.current);
      chart.draw(data, options);
    }
  }

  return (
    <DashboardWidget
      title='Alert Resolution Time'
      subtitle='In Selected Period'
      className='pb-0'
      containerClassName={!isPdf ? 'col-span-4' : 'col-span-6'}
      isLoading={isLoading}
      noData={noData}
      tooltipTitle='Alert Resolution Time'
      tooltipDescription='Provides a breakdown of the number of alerts that were resolved within 24 hours, between 24 and 48 hours, and greater than 48 hours in the selected period.'
    >
      <div id='alertResolutionChart' data-testid='alertResolutionChart' ref={chartRef} />
    </DashboardWidget>
  );
};

export default AlertResolutionWidget;
