import { FC } from 'react';
import { UseInfiniteQueryResult } from 'react-query';
import {
  ITransactionNonTokenResponse,
  ITransactionResponse,
} from '../../../../api/dtos/transaction';
import TransactionEntity from './TransactionEntity';
import { ArrowRight } from '@phosphor-icons/react';
import CompassLoader from '../../../ui/components/Loader/CompassLoader';
import { AxiosResponse } from 'axios';
import Pagination from '../../../../containers/Pagination';
import EmptyState from '../../../../ui/components/States/Empty';

interface ExternalTransactionProps {
  transaction: ITransactionResponse;
  inputsQuery: UseInfiniteQueryResult<AxiosResponse<ITransactionNonTokenResponse>>;
  outputsQuery: UseInfiniteQueryResult<AxiosResponse<ITransactionNonTokenResponse>>;
  isHidden?: boolean;
}

const ExternalTransaction: FC<ExternalTransactionProps> = ({
  transaction,
  inputsQuery,
  outputsQuery,
  isHidden = false,
}) => {
  if (isHidden) return <EmptyState />;
  if (inputsQuery.isLoading || outputsQuery.isLoading) {
    return <CompassLoader />;
  }

  if (inputsQuery.isError || outputsQuery.isError) {
    // Handle error state
    return <div>Error occurred while fetching transactions.</div>;
  }

  return (
    <div className='grid max-h-[400px] grid-cols-12 overflow-auto'>
      <Pagination query={inputsQuery} className='col-span-5 text-sm'>
        {entity => (
          <TransactionEntity
            key={`input-${entity.address}`}
            entity={entity}
            transaction={transaction}
          />
        )}
      </Pagination>
      <div className='col-span-2 mx-auto'>
        <ArrowRight size={32} weight='bold' color='#6B7280' />
      </div>
      <Pagination query={outputsQuery} className='col-span-5 text-sm'>
        {entity => (
          <TransactionEntity
            key={`output-${entity.address}`}
            entity={entity}
            transaction={transaction}
          />
        )}
      </Pagination>
    </div>
  );
};

export default ExternalTransaction;
