import { Field, Label, Switch } from '@headlessui/react';
import classNames from 'classnames';
import { FC } from 'react';

export interface ToggleProps {
  enabled: boolean;
  setEnabled: (enable: boolean) => void;
  labelText: string;
  className?: string;
  disabled?: boolean;
  noPadding?: boolean;
  labelClass?: string;
}

const Toggle: FC<ToggleProps> = props => {
  const {
    enabled,
    setEnabled,
    labelText,
    className,
    disabled,
    noPadding,
    labelClass,
    ...otherProps
  } = props;

  return (
    <Field as='div' className={classNames('flex items-center', !noPadding ? 'py-2' : '')}>
      <Switch
        id='toggle'
        data-testid='toggle'
        checked={enabled}
        onChange={setEnabled}
        className={classNames(
          enabled ? 'bg-blue-500' : 'bg-gray-200',
          'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-solid border-transparent p-0 transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2',
          className
        )}
        disabled={disabled}
        {...otherProps}
      >
        <span
          aria-hidden='true'
          className={classNames(
            enabled ? 'translate-x-5' : 'translate-x-0',
            'pointer-events-none my-auto inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
          )}
        />
      </Switch>
      <Label
        as='span'
        data-testid='toggle-label'
        className={classNames('ml-3 text-xs font-medium leading-default text-gray-600', labelClass)}
      >
        {labelText}
      </Label>
    </Field>
  );
};

export default Toggle;
