import { Gear, CircleNotch, CheckCircle, XCircle } from '@phosphor-icons/react';
import { FC } from 'react';

export const STATUS = {
  PENDING: 1,
  PROCESSING: 2,
  COMPLETE: 3,
  FAILED: 4,
};

const StatusIcon: FC<{ status: number }> = ({ status }) => {
  const statusConfig = {
    [STATUS.PENDING]: {
      icon: <CircleNotch size={16} className='mr-1 text-amber-500' weight='bold' />,
      text: 'Pending',
      color: 'text-amber-500',
    },
    [STATUS.PROCESSING]: {
      icon: <Gear size={16} className='mr-1 text-indigo-600' weight='bold' />,
      text: 'Processing',
      color: 'text-indigo-600',
    },
    [STATUS.COMPLETE]: {
      icon: <CheckCircle size={16} className='mr-1 text-emerald-600' weight='fill' />,
      text: 'Complete',
      color: 'text-emerald-600',
    },
    [STATUS.FAILED]: {
      icon: <XCircle size={16} className='mr-1 text-rose-600' weight='fill' />,
      text: 'Failed',
      color: 'text-rose-600',
    },
  };

  const config = statusConfig[status] || { icon: null, text: status.toString(), color: '' };

  return (
    <div className='flex items-center'>
      {config.icon}
      <span className={config.color}>{config.text}</span>
    </div>
  );
};

export default StatusIcon;
