import { FC } from 'react';
import CurrencyBadge from '../../ui/components/Badge/CurrencyBadge';
import { BasicBadge } from '../../../ui';
import Popover from '../../../ui/components/Popover/Popover';
import classNames from 'classnames';
import { getCurrencyById, isLiteCurrency } from '../../../utils/helpers/currency';

interface SupportedAssetsProps {
  blockchains: number[];
}

const CurrencyBadgeSupport: FC<{ currency: number }> = ({ currency }) => (
  <Popover
    referenceContent={
      <CurrencyBadge
        currency={currency}
        className={isLiteCurrency(currency) ? 'font-light' : 'font-medium'}
        label={getCurrencyById(currency).label + (isLiteCurrency(currency) ? ' (Lite)' : '')}
      />
    }
    popoverContent={isLiteCurrency(currency) ? 'Lite Coverage' : 'Full Coverage'}
    className='border bg-white p-2 shadow-md'
  />
);

const SupportedAssets: FC<SupportedAssetsProps> = ({ blockchains }) => {
  const limit = 4;
  blockchains.sort().sort((a, b) => Number(isLiteCurrency(a)) - Number(isLiteCurrency(b)));
  return (
    <div className='flex items-center'>
      <div className='flex'>
        {blockchains.slice(0, limit).map(b => (
          <CurrencyBadgeSupport key={b} currency={b} />
        ))}
        {blockchains.length > limit && (
          <Popover
            placement='bottom'
            referenceContent={
              <BasicBadge className='bg-gray-100'>{`+${blockchains.length - limit}`}</BasicBadge>
            }
            popoverContent={
              <div
                className={classNames(
                  'flex w-64 max-w-sm flex-wrap rounded-md bg-white p-2 shadow-lg'
                )}
              >
                {blockchains.slice(limit).map(asset => (
                  <CurrencyBadgeSupport key={asset} currency={asset} />
                ))}
              </div>
            }
          />
        )}
      </div>
    </div>
  );
};

export default SupportedAssets;
