import { Bezier } from 'bezier-js';
import {
  formatAmountAsString,
  truncateAddress,
} from '../../components/AddressTransaction/InvestigationTree/helper';

export const DEFAULT_NODE_REL_SIZE = 4;

export const colorsDark = {
  Exchange: '#8B5CF6',
  'Mining Pool': '#A78BFA',
  Service: '#6366F1',
  Darknet: '#BE185D',
  Gambling: '#6D28D9',
  'Coin Mixer': '#4C1D95',
  Scam: '#EC4899',
  Extortion: '#F9A8D4',
  Malware: '#F43F5E',
  Theft: '#FB7185',
  Donation: '#0369A1',
  'Smart Contract Platform': '#7DD3FC',
  Sanctions: '#831843',
  'High Risk Organization': '#BE123C',
  'Law Enforcement': '#0C4A6E',
  DeFi: '#0ea5e9',
  Rewards: '#6b6b6b',
  Fee: '#4a4a4a',
  Others: '#d3d3d3',
};

export const GRAPH_BACKGROUND_COLOR = '#F3F4F5';

// export const colorsLight = {
//   Exchange: '#4C1D95',
//   'Mining Pool': '#A78BFA',
//   Service: '#E0F2FE',
//   Darknet: '#BE185D',
//   Gambling: '#8B5CF6',
//   'Coin Mixer': '#6D28D9',
//   Scam: '#EC4899',
//   Extortion: '#F9A8D4',
//   Malware: '#F43F5E',
//   Theft: '#FB7185',
//   Donation: '#0369A1',
//   'Smart Contract Platform': '#7DD3FC',
//   Sanctions: '#831843',
//   'High Risk Organization': '#BE123C',
//   'Law Enforcement': '#0C4A6E',
//   DeFi: '#0ea5e9',
// }

export const colorsLight = colorsDark;
export const colorsDarkHex = Object.values(colorsDark);

export const colorDarkDefault = '#B2BFCF';
export const colorLightDefault = '#D3DDE9';

export const colorLinkIncoming = '#10B981';
export const colorLinkOutgoing = '#C050CA';

export const draw3DCubeProjection = (
  { x, y },
  ctx: CanvasRenderingContext2D,
  strokeColor: string,
  size = 15
) => {
  const scaledSizeX = size / 3;
  const scaledSizeY = (size * 2) / 3;

  const offsettedY = y - size / 6;

  const x1 = x + Math.sqrt(3) * scaledSizeX;
  const y1 = offsettedY - scaledSizeY / 4;
  const x2 = x;
  const y2 = offsettedY - scaledSizeY / 2;
  const x3 = x - Math.sqrt(3) * scaledSizeX;
  const y3 = y1;
  const x4 = x3;
  const y4 = y3 + scaledSizeY;
  const x5 = x;
  const y5 = offsettedY + scaledSizeY;
  const x6 = x1;
  const y6 = y1 + scaledSizeY;

  ctx.save();
  // Set the stroke color
  ctx.strokeStyle = strokeColor;
  // Set the fill color
  ctx.fillStyle = strokeColor;

  ctx.lineWidth = 3; //(3 * size) / 15;
  ctx.lineCap = 'butt';
  ctx.lineJoin = 'round';

  // Draw the left side filled face of the cube
  ctx.beginPath();
  ctx.moveTo(x, offsettedY);
  ctx.lineTo(x5, y5);
  ctx.lineTo(x4, y4);
  ctx.lineTo(x3, y3);
  ctx.lineTo(x, offsettedY);
  ctx.stroke();
  ctx.fill();
  ctx.closePath();

  // Draw the top face of the cube
  ctx.beginPath();
  ctx.moveTo(x, offsettedY);
  ctx.lineTo(x1, y1);
  ctx.lineTo(x2, y2);
  ctx.lineTo(x3, y3);
  ctx.stroke();
  ctx.closePath();

  // Draw the right side face of the cube
  ctx.beginPath();
  ctx.moveTo(x1, y1);
  ctx.lineTo(x6, y6);
  ctx.lineTo(x5, y5);
  ctx.stroke();
  ctx.closePath();

  ctx.restore();
};

export const drawNode = (
  node,
  ctx: CanvasRenderingContext2D,
  zoomLevel,
  graphData,
  txId,
  originType,
  mode = 'normal',
  highlightNodes = new Set(),
  isFiltersApplied = false
) => {
  const zoomCap = Math.min(0.5, 30 / graphData.nodes.length);

  const lineHeight = 14;
  const fontSize = 12;
  ctx.font = `900 ${fontSize}px Sans-Serif`;
  ctx['fontWeight'] = 'bold';
  ctx.textAlign = 'center';
  ctx.textBaseline = 'middle';
  ctx.fillStyle =
    '#12181f' +
    ((highlightNodes.size === 0 && !isFiltersApplied) ||
    highlightNodes.has(node.id) ||
    node.addresses.some(a => a?.toLowerCase() === txId?.toLowerCase())
      ? ''
      : '10');
  let positionY = node.y + 10;
  if (node.addresses.some(a => a?.toLowerCase() === txId?.toLowerCase())) {
    createOriginShape(node, ctx);
    positionY += lineHeight;
    if (zoomLevel > zoomCap) {
      ctx.fillText('Origin', node.x, positionY);
      positionY += lineHeight;
      ctx.font = `${fontSize}px Sans-Serif`;
      ctx.fillText(`${originType}: ${truncateAddress(node.node)}`, node.x, positionY);
    }
    node.nodeColor = '#000000';
  } else {
    if (node.tag_name_verbose || node.tag_type_verbose) {
      createKnownNodeShape(
        node,
        ctx,
        mode,
        highlightNodes,
        colorsDark[node.tag_type_verbose],
        isFiltersApplied
      );
    } else {
      createUnknownNodeShape(node, ctx, mode, highlightNodes, isFiltersApplied);
    }

    if (node.tag_name_verbose && zoomLevel > zoomCap) {
      ctx.font = `bold ${fontSize}px Sans-Serif`;
      positionY += lineHeight;
      ctx.fillText(node.tag_name_verbose, node.x, positionY);
    } else if (zoomLevel > zoomCap) {
      ctx.font = `${fontSize}px Sans-Serif`;
      positionY += lineHeight;
      ctx.fillText(truncateAddress(node.addresses[0]), node.x, positionY);
    }

    ctx.font = `${fontSize}px Sans-Serif`;
    if (node.tag_type_verbose && zoomLevel > zoomCap) {
      positionY += lineHeight;
      ctx.fillText(node.tag_type_verbose, node.x, positionY);
    }
  }
};

export const createKnownNodeShape = (
  node,
  ctx: CanvasRenderingContext2D,
  mode,
  highlightNodes,
  outerColor = '#0EA5E9',
  isFiltersApplied,
  size = 15
) => {
  createNodeShape(node, ctx, mode, highlightNodes, outerColor, size, isFiltersApplied);
};

export const createUnknownNodeShape = (
  node,
  ctx: CanvasRenderingContext2D,
  mode,
  highlightNodes,
  isFiltersApplied,
  size = 15
) => {
  createNodeShape(node, ctx, mode, highlightNodes, '#6C7280', size, isFiltersApplied);
};

export const createNodeShape = (
  { id, x, y, addresses },
  ctx,
  mode,
  highlightNodes,
  color,
  size = 15,
  isFiltersApplied = false
) => {
  const outerSize = size;
  const outerColor = color;
  if (mode === 'highlight') {
    ctx.save();
    ctx.beginPath();

    ctx.arc(x, y, outerSize * 1.5, 0, Math.PI * 2, true); // Outer circle
    ctx.fillStyle =
      outerColor +
      ((highlightNodes.size === 0 && !isFiltersApplied) || highlightNodes.has(id) ? '40' : '10');
    ctx.fill();

    ctx.restore();
  }
  ctx.save();

  ctx.beginPath();
  ctx.arc(x, y, outerSize, 0, Math.PI * 2, true); // Outer circle
  ctx.fillStyle =
    getOpaqueHexCode(outerColor, 40, GRAPH_BACKGROUND_COLOR) +
    ((highlightNodes.size === 0 && !isFiltersApplied) || highlightNodes.has(id) ? '' : '10');
  ctx.fill();

  if (addresses.length > 1) {
    ctx.strokeStyle =
      outerColor +
      ((highlightNodes.size === 0 && !isFiltersApplied) || highlightNodes.has(id) ? '' : '10');
    ctx.lineWidth = 2;
    ctx.stroke();
    ctx.restore();
  } else {
    ctx.restore();
    draw3DCubeProjection(
      { x, y },
      ctx,
      outerColor +
        ((highlightNodes.size === 0 && !isFiltersApplied) || highlightNodes.has(id) ? '' : '10')
    );
  }
};

export const createOriginShape = ({ x, y }, ctx: CanvasRenderingContext2D) => {
  ctx.save();
  ctx.beginPath();
  ctx.arc(x, y, 15, 0, Math.PI * 2, true); // inner circle
  ctx.fillStyle = '#1E1E1E';
  ctx.fill();
  ctx.restore();
};

export const drawLink = (
  link,
  ctx: CanvasRenderingContext2D,
  mode = 'normal',
  highlightLinks = new Set(),
  highlightNodes = new Set(),
  isFiltersApplied = false
) => {
  // right now we're only adding arrow and text to the existing link
  // thus `linkCanvasObjectMode` has to be `after` and not `replace`
  const start = link.source;
  const end = link.target;

  // ignore unbound links
  if (typeof start !== 'object' || typeof end !== 'object') return;

  drawArrow(link, ctx, start, end, mode, highlightLinks, highlightNodes, isFiltersApplied);

  if (isFiltersApplied && !highlightLinks.has(link)) return;

  // if (graph.zoom() >= 1.3) {
  drawTextForLink(link, ctx, start, end);
  // }
};

export const drawArrow = (
  link,
  ctx: CanvasRenderingContext2D,
  start,
  end,
  mode,
  highlightLinks,
  highlightNodes,
  isFiltersApplied = false
) => {
  ctx.save();
  // for triangle shape
  const ARROW_WH_RATIO = 0.8;
  const ARROW_VLEN_RATIO = 0;

  const arrowLength = link.width * 3 + 5;
  const startR = DEFAULT_NODE_REL_SIZE * 4;
  const endR = DEFAULT_NODE_REL_SIZE * 4;

  const arrowRelPos = 1;
  const arrowColor = link.color;
  const arrowHalfWidth = arrowLength / ARROW_WH_RATIO / 2;

  // Construct bezier for curved lines
  const bzLine =
    link.__controlPoints && new Bezier(start.x, start.y, ...link.__controlPoints, end.x, end.y);

  const getCoordsAlongLine = bzLine
    ? t => bzLine.get(t) // get position along bezier line
    : t => ({
        // straight line: interpolate linearly
        x: start.x + (end.x - start.x) * t || 0,
        y: start.y + (end.y - start.y) * t || 0,
      });

  const lineLen = bzLine
    ? bzLine.length()
    : Math.sqrt((end.x - start.x) ** 2 + (end.y - start.y) ** 2);

  const posAlongLine = startR + arrowLength + (lineLen - startR - endR - arrowLength) * arrowRelPos;

  const arrowHead = getCoordsAlongLine(posAlongLine / lineLen);
  const arrowTail = getCoordsAlongLine((posAlongLine - arrowLength) / lineLen);
  const arrowTailVertex = getCoordsAlongLine(
    (posAlongLine - arrowLength * (1 - ARROW_VLEN_RATIO)) / lineLen
  );

  const arrowTailAngle =
    Math.atan2(arrowHead.y - arrowTail.y, arrowHead.x - arrowTail.x) - Math.PI / 2;

  // For the pointy tip of the arrow, clear the link line
  ctx.save();
  ctx.beginPath();
  ctx.moveTo(arrowTailVertex.x, arrowTailVertex.y);
  ctx.lineTo(end.x, end.y);
  ctx.lineWidth = link.width * 2;
  ctx.strokeStyle =
    GRAPH_BACKGROUND_COLOR +
    ((highlightNodes.size === 0 && !isFiltersApplied) || highlightLinks.has(link) ? '' : '10');
  ctx.stroke();
  ctx.restore();

  // paint the arrow
  ctx.beginPath();
  ctx.moveTo(arrowHead.x, arrowHead.y);
  ctx.lineTo(
    arrowTail.x + arrowHalfWidth * Math.cos(arrowTailAngle),
    arrowTail.y + arrowHalfWidth * Math.sin(arrowTailAngle)
  );
  ctx.lineTo(arrowTailVertex.x, arrowTailVertex.y);
  ctx.lineTo(
    arrowTail.x - arrowHalfWidth * Math.cos(arrowTailAngle),
    arrowTail.y - arrowHalfWidth * Math.sin(arrowTailAngle)
  );
  ctx.fillStyle =
    arrowColor +
    ((highlightNodes.size === 0 && !isFiltersApplied) || highlightLinks.has(link)
      ? mode === 'highlight'
        ? ''
        : '80'
      : '10');
  ctx.fill();

  ctx.restore();
};

export const drawTextForLink = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  link: any,
  ctx: CanvasRenderingContext2D,
  start: { x: number; y: number },
  end: { x: number; y: number }
) => {
  const MAX_FONT_SIZE = 10;
  const LABEL_NODE_MARGIN = DEFAULT_NODE_REL_SIZE * 1.5;
  const label = `$${link.value}`;

  // calculate label positioning
  const textPos: { x: number; y: number } = {
    x: start.x + (end.x - start.x) / 2,
    y: start.y + (end.y - start.y) / 2,
  };

  const relLink: { x: number; y: number } = { x: end.x - start.x, y: end.y - start.y };

  const maxTextLength = Math.sqrt(relLink.x ** 2 + relLink.y ** 2) - LABEL_NODE_MARGIN * 2;

  let textAngle = Math.atan2(relLink.y, relLink.x);
  // maintain label vertical orientation for legibility
  if (textAngle > Math.PI / 2) {
    textAngle = -(Math.PI - textAngle);
  } else if (textAngle < -Math.PI / 2) {
    textAngle = -(-Math.PI - textAngle);
  }

  // estimate fontSize to fit in link length
  const font = 'Sans-Serif';
  let fontSize = MAX_FONT_SIZE;
  ctx.font = `${fontSize}px ${font}`;
  let textWidth = ctx.measureText(label).width;
  while (textWidth > maxTextLength && fontSize > 1) {
    fontSize--;
    ctx.font = `${fontSize}px ${font}`;
    textWidth = ctx.measureText(label).width;
  }

  const bckgDimensions = [textWidth, fontSize].map(n => n + fontSize * 0.2); // some padding

  // draw text label (with background rect)
  ctx.save();

  ctx.translate(textPos.x, textPos.y);
  ctx.rotate(textAngle);

  ctx.fillStyle = 'rgba(255, 255, 255, 0.9)';
  ctx.fillRect(
    -bckgDimensions[0] / 2,
    -bckgDimensions[1] / 2,
    bckgDimensions[0],
    bckgDimensions[1]
  );

  ctx.textAlign = 'center';
  ctx.textBaseline = 'middle';
  ctx.fillStyle = 'darkgrey';
  ctx.fillText(label, 0, 0);

  ctx.restore();
};

export const getLinkWidth = (linkVal, avgVal) => {
  if (!linkVal) {
    return 0.5;
  }

  if (linkVal >= avgVal) {
    if (linkVal <= (avgVal * 4) / 3) {
      return 4;
    } else if (linkVal <= (avgVal * 5) / 3) {
      return 5;
    } else {
      return 6;
    }
  } else if (linkVal >= (avgVal * 2) / 3) {
    return 3;
  } else if (linkVal <= (avgVal * 1) / 3) {
    return 2;
  } else {
    return 1;
  }
};

export const createLink = (item, source, target, linkColor, linkWidth = 1) => {
  return {
    id: source + '_' + target,
    source,
    target,
    value: formatAmountAsString(item.value, 2),
    depth: Number(item.depth),
    numberedValue: item.value,
    color: linkColor,
    width: linkWidth,
  };
};

export const getOpaqueHexCode = (
  colorHexCode: string,
  opacityPercentage: number,
  backgroundColorHexCode = '#F3F4F5'
): string => {
  // Parse the input hex code into its RGB components
  const red = parseInt(colorHexCode.slice(1, 3), 16);
  const green = parseInt(colorHexCode.slice(3, 5), 16);
  const blue = parseInt(colorHexCode.slice(5, 7), 16);

  // Parse the background color hex code into its RGB components
  const bgRed = parseInt(backgroundColorHexCode.slice(1, 3), 16);
  const bgGreen = parseInt(backgroundColorHexCode.slice(3, 5), 16);
  const bgBlue = parseInt(backgroundColorHexCode.slice(5, 7), 16);

  // Convert the opacity percentage to a decimal value
  const opacity = opacityPercentage / 100;

  // Calculate the new RGB values with the given opacity and background color
  const newRed = Math.round(red * opacity + bgRed * (1 - opacity));
  const newGreen = Math.round(green * opacity + bgGreen * (1 - opacity));
  const newBlue = Math.round(blue * opacity + bgBlue * (1 - opacity));

  // Convert the new RGB values back to a hex code
  const newHexCode = `#${newRed.toString(16).padStart(2, '0')}${newGreen
    .toString(16)
    .padStart(2, '0')}${newBlue.toString(16).padStart(2, '0')}`;

  // Return the new hex code
  return newHexCode;
};

// This is a very high resource consuming function, avoid using this in loop
export const findPath = (
  sourceNodeId: string,
  finalTargetNodeId: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  graphData: any
): string[] | null => {
  // Initialize an empty list to hold the path between the two nodes.
  const path = [];

  // to find path in just one direction
  const directionFlipped = sourceNodeId !== finalTargetNodeId && sourceNodeId[0] === '+';

  // Create a dictionary to keep track of the visited nodes.
  const visited: { [id: string]: boolean } = {};
  visited[sourceNodeId] = true;

  // Create a dictionary to keep track of the previous node in the path.
  const previous: { [id: string]: string | null } = {};
  previous[sourceNodeId] = null;

  // Create a queue and add the source node to it.
  const queue = [sourceNodeId];

  // Continue searching until the queue is empty.
  while (queue.length > 0) {
    // Dequeue the next node from the queue.
    const currentNodeId = queue.shift();

    // Check if the current node is the final target node.
    if (currentNodeId === finalTargetNodeId) {
      // Traverse the path backwards from the final target node to the source node.
      let nodeId = finalTargetNodeId;
      while (nodeId !== null) {
        path.unshift(nodeId);
        nodeId = previous[nodeId] || null;
      }
      return path;
    }

    // Loop over the links in the graph to find neighbors of the current node.
    for (const link of graphData.links) {
      if (link.source.id === currentNodeId && !visited[link.target.id] && !directionFlipped) {
        visited[link.target.id] = true;
        previous[link.target.id] = currentNodeId;
        queue.push(link.target.id);
      } else if (link.target.id === currentNodeId && !visited[link.source.id] && directionFlipped) {
        visited[link.source.id] = true;
        previous[link.source.id] = currentNodeId;
        queue.push(link.source.id);
      }
    }
  }

  // If the final target node is not found, return null.
  return null;
};

export const findAllPaths = (
  sourceNodeId: string,
  finalTargetNodeId: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  graphData: any
): string[] => {
  const paths: string[][] = []; // Array to store all paths found

  const findPathsRecursively = (currentNodeId: string, currentPath: string[]): void => {
    // Add the current node to the current path
    const updatedPath = [...currentPath, currentNodeId];

    // Check if the current node is the final target node
    if (currentNodeId === finalTargetNodeId) {
      // Add the current path to the paths array
      paths.push(updatedPath);
      return;
    }

    // Check if the direction is flipped
    const directionFlipped = currentNodeId !== finalTargetNodeId && currentNodeId[0] === '+';

    // Loop over the links in the graph to find neighbors of the current node
    for (const link of graphData.links) {
      if (
        link.source.id === currentNodeId &&
        !updatedPath.includes(link.target.id) &&
        !directionFlipped
      ) {
        findPathsRecursively(link.target.id, updatedPath);
      } else if (
        link.target.id === currentNodeId &&
        !updatedPath.includes(link.source.id) &&
        directionFlipped
      ) {
        findPathsRecursively(link.source.id, updatedPath);
      }
    }
  };

  // Start the recursive search from the source node
  findPathsRecursively(sourceNodeId, []);

  // Flatten the array of paths into a single array and filter out duplicates
  const uniquePaths = paths.flat();
  return [...new Set(uniquePaths)];
};
