import { FC } from 'react';
import { AppliedFiltersBadge } from '../../ui';
import { RiskLevel } from '../../utils/constants/constants';

interface RiskAppliedFilterProps {
  risks: number[];
  setRisks: (risk: number[]) => void;
}

const RiskAppliedFilter: FC<RiskAppliedFilterProps> = props => {
  const { risks, setRisks } = props;
  if (!risks) return null;

  const handleRemove = (risk: number) => {
    setRisks(risks.filter(r => r !== risk));
  };

  const getBadgeLabel = (risk: number) => RiskLevel.find(r => r.value === risk).label;

  return (
    <>
      {risks.map(r => (
        <AppliedFiltersBadge key={r} onRemove={() => handleRemove(r)}>
          Risk: {getBadgeLabel(r)}
        </AppliedFiltersBadge>
      ))}
    </>
  );
};

export default RiskAppliedFilter;
