import Cookies from 'js-cookie';

interface CookieProps {
  name: string;
  value: string;
  cookieOptions?: Cookies.CookieAttributes;
}

export const createCookie = ({ name, value, cookieOptions }: CookieProps) => {
  const options = {
    secure: true,
    path: '/',
    ...cookieOptions,
  };
  Cookies.set(name, value, options);
};

export const setTimezone = timezone => {
  createCookie({
    name: process.env.TZ,
    value: timezone,
  });
};

export const getTimezone = () => {
  return Cookies.get(process.env.TZ);
};

export const getOrganizationInfo = () => {
  return Cookies.get('organizationInfo');
};

export const removeOrganizationInfo = () => {
  const parts = window.location.hostname.split('.');
  parts.shift();
  const upperleveldomain = parts.join('.');
  const options = {
    secure: true,
    path: '/',
    domain: upperleveldomain,
  };
  Cookies.remove('organizationInfo', options);
};

export const setOrganizationInfo = organizationID => {
  removeOrganizationInfo();
  const parts = window.location.hostname.split('.');
  parts.shift();
  const upperleveldomain = parts.join('.');
  const options = {
    secure: true,
    path: '/',
    domain: upperleveldomain,
  };
  Cookies.set('organizationInfo', organizationID, options);
};

export const setUserEmail = email => {
  createCookie({
    name: 'email',
    value: email,
  });
};

export const getUserEmail = () => {
  return Cookies.get('email');
};

export const removeUserEmail = () => {
  Cookies.remove('email');
};

export const getLanguage = () => {
  return Cookies.get('i18n_redirected');
};

export const setCaptchaFailed = () => {
  createCookie({
    name: 'captchaFailed',
    value: new Date().getTime().toString(),
  });
};

export const getCaptchaFailed = () => {
  return Cookies.get('captchaFailed');
};

export const setTokenCookie = token => {
  createCookie({
    name: 'token',
    value: token,
    cookieOptions: {
      SameSite: 'strict',
    },
  });
};
