import { Dialog, DialogPanel } from '@headlessui/react';
import { FC, useState, useEffect } from 'react';
import { CloudArrowDown, Link as LinkIcon, Spinner } from '@phosphor-icons/react';
import Table from '../../../ui/components/Table/Table';
import { useTheme } from '../../../utils/helpers/theme';
import { downloadManagerApi, useDownloadGetDownloads } from '../../../api/downloadManager';
import {
  buildInfiniteQueryTableProps,
  flattenInfiniteQueryResult,
} from '../../../utils/helpers/react-query.helper';
import { formatDateAgo } from '../../../utils/helpers/date';
import { useMutation } from 'react-query';
import StatusIcon, { STATUS } from './StatusIcon';
import CopyToClipboardBtn from '../../../ui/components/Copy/Copy';
import { toast } from 'react-toastify';
import { useRouter } from '../../../modules/router/RouterProvider';

interface DownloadManagerProps {}

const DownloadManager: FC<DownloadManagerProps> = () => {
  const { themeColor } = useTheme();
  const [open, setOpen] = useState<boolean>(false);
  const [loadingDownloadId, setLoadingDownloadId] = useState<string>('');
  const { getQueryParams, navigate } = useRouter();
  const downloadsQuery = useDownloadGetDownloads(
    {},
    {
      enabled: open,
    }
  );

  const [, downloads] = flattenInfiniteQueryResult(downloadsQuery?.data);

  const headerData = ['Description', 'Status', 'Requested By', 'Created At', 'Request ID', 'Link'];

  useEffect(() => {
    const { showDownloadManager } = getQueryParams();
    if (showDownloadManager === 'true') {
      setOpen(true);
      localStorage.setItem('showDownloadManager', 'true');
    }
  }, [getQueryParams]);

  const handleClose = () => {
    setOpen(false);
    localStorage.setItem('showDownloadManager', 'false');
    const currentParams = getQueryParams();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { showDownloadManager, ...restParams } = currentParams;
    const { pathname } = window.location;
    navigate(pathname, restParams);
  };

  const { mutate: requestDownload } = useMutation(downloadManagerApi.getDownloadLink, {
    onSuccess: async data => {
      if (data?.data.download_url) {
        window.open(data?.data.download_url, '_blank');
      }
      setLoadingDownloadId('');
    },
    onError: () => {
      toast.error('Failed to get download link');
      setLoadingDownloadId('');
    },
  });

  const getDownloadLink = (status: number, id: string) => {
    if (status !== STATUS.COMPLETE) {
      return <span className='text-gray-400'>-</span>;
    }

    if (loadingDownloadId === id) {
      return (
        <div className='flex w-full items-center justify-center py-2'>
          <Spinner size={18} weight='bold' className='animate-spin' />
        </div>
      );
    }

    return (
      <div
        className='cursor-pointer rounded-md border border-gray-200 py-2 pl-3 pr-2'
        onClick={() => {
          setLoadingDownloadId(id);
          requestDownload(id);
        }}
      >
        <LinkIcon
          id={`link-${id}`}
          data-testid={`link-${id}`}
          className='cursor-pointer text-gray-800'
          size={18}
          weight='bold'
        />
      </div>
    );
  };

  const truncateId = (id: string) => {
    if (id.length <= 14) return id;
    return `${id.slice(0, 7)}...${id.slice(-7)}`;
  };

  const rows =
    downloads?.map(download => ({
      id: download.id,
      data: [
        <div key={`description-${download.id}`} title={download.title}>
          {download.title.length > 50 ? `${download.title.substring(0, 50)}...` : download.title}
        </div>,
        <div key={`status-${download.id}`}>
          <StatusIcon status={download.status} />
        </div>,
        <div key={`requestedBy-${download.id}`}>{download.requested_by}</div>,
        <div key={`createdAt-${download.id}`}>{formatDateAgo(download.created_at)}</div>,
        <div key={`requestId-${download.id}`} className='flex items-center gap-2'>
          <span className='font-mono'>{truncateId(download.id)}</span>
          <CopyToClipboardBtn text={download.id} size={14} />
        </div>,
        <div key={`link-${download.id}`} className='text-center'>
          {getDownloadLink(download.status, download.id)}
        </div>,
      ],
    })) || [];

  const handleOpen = () => {
    setOpen(true);
    localStorage.setItem('showDownloadManager', 'true');
    const currentParams = getQueryParams();
    const { pathname } = window.location;
    navigate(pathname, {
      ...currentParams,
      showDownloadManager: 'true',
    });
  };

  return (
    <div
      id='header-downloadManager'
      data-testid='header-downloadManager'
      style={{ backgroundColor: themeColor().secondary }}
      className='my-px mr-3 flex cursor-pointer items-center rounded-lg px-3 text-white outline-1 hover:outline hover:outline-white focus:outline-none'
      onClick={handleOpen}
    >
      <div>
        <CloudArrowDown size={20} color='#fff' />
      </div>
      <Dialog open={open} onClose={handleClose} className='relative z-50'>
        <div className='fixed inset-0 bg-black/50' aria-hidden='true' />
        <div className='fixed right-[20px] top-[40px] flex w-[1000px] rounded-xl pt-2'>
          <DialogPanel className='max-h-[calc(100vh-55px)] w-full overflow-auto rounded-xl bg-white'>
            <div>
              <Table
                title='Download Manager'
                headerData={headerData}
                rows={rows}
                height={450}
                isSelectable
                headerWidths={[30, 5, 20, 10, 15, 5]}
                fontClass='text-[11px]'
                showRefresh
                {...buildInfiniteQueryTableProps(downloadsQuery)}
              />
            </div>
          </DialogPanel>
        </div>
      </Dialog>
    </div>
  );
};

export default DownloadManager;
