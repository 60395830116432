import { Plus } from '@phosphor-icons/react';
import Select from 'react-select';
import { IOption } from '../../Filters/CustomSelect';
import { useEffect, useRef, useState } from 'react';
import { customTagsApi } from '../../../api/settings/customTags';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { debounce } from 'lodash';
import { ConfirmationModal } from '../../../ui';
import { addressApi } from '../../../api/address';
import { IAddressCustomTag, IAddressResponse } from '../../../api/dtos/address';
import CustomTagBadge from '../../ui/components/Badge/CustomTagBadge';
import { toast } from 'react-toastify';
import classNames from 'classnames';

interface AddressCustomTagsProps {
  address: IAddressResponse;
}

const AddressCustomTags = (props: AddressCustomTagsProps) => {
  const { address } = props;
  const [inputText, setInputText] = useState('');
  const [searchText, setSearchText] = useState('');

  const [value, setValue] = useState<IOption>(null);
  const [selectedTag, setSelectedTag] = useState<IAddressCustomTag>(address?.custom_tags?.[0]);
  const [menuOpen, setMenuOpen] = useState(false);
  const [showModal, setShowModal] = useState<'add' | 'remove'>(null);

  const { data: tags, isLoading } = useQuery(['customTagApi.search', searchText], () =>
    customTagsApi.searchCustomTags(searchText)
  );

  useEffect(() => {
    setSelectedTag(address?.custom_tags?.[0]);
  }, [address]);

  const { mutate: updateTag } = useMutation(addressApi.updateCustomTag, {
    onSuccess: data => {
      toast.success(data.data.message);
    },
  });

  const queryClient = useQueryClient();

  const optionsList = tags?.data?.results.map(t => ({
    label: t.name,
    value: t.id.toString(),
  }));

  const debouncedSetSearchText = useRef(
    debounce((nextValue: string) => setSearchText(nextValue), 500)
  ).current;

  const handleInputChange = (value: string) => {
    setInputText(value);
    debouncedSetSearchText(value);
  };

  const noOptionsMessage = () => {
    if (isLoading) {
      return 'Loading...';
    }
    return 'No results found';
  };

  const Placeholder = () =>
    menuOpen ? null : (
      <span className='flex cursor-pointer text-blue-600'>
        <Plus className='mr-2' size={16} />
        <span>Add a tag</span>
      </span>
    );

  const onRemoveCustomTag = () => {
    setShowModal('remove');
    setValue(null);
  };

  const onChange = (option: IOption) => {
    setShowModal('add');
    setValue(option);
  };

  const onConfirmModal = () => {
    updateTag(
      { id: address.id, custom_tag_ids: value ? [parseInt(value.value)] : [] },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(['addressApi.getAddress', address.id]);
          setSelectedTag(value ? { id: parseInt(value.value), name: value.label, type: 0 } : null);
        },
      }
    );
    setShowModal(null);
  };

  return (
    <div>
      {selectedTag ? (
        <CustomTagBadge
          type={selectedTag.type}
          label={selectedTag?.name}
          onRemove={onRemoveCustomTag}
        />
      ) : (
        <Select
          id='add-tag'
          options={optionsList || []}
          styles={{
            input: baseStyle => ({ ...baseStyle, 'input:focus': { boxShadow: 'none' } }),
            control: baseStyle => ({
              ...baseStyle,
              minHeight: 'none',
            }),
          }}
          components={{
            IndicatorSeparator: () => null,
            DropdownIndicator: () => null,
            Placeholder,
          }}
          classNames={{
            control: () => '!border-0 !bg-transparent',
            valueContainer: () => '!flex !p-0',
            option: ({ isFocused }) => classNames(isFocused && '!bg-blue-500 !text-white'),
          }}
          onMenuOpen={() => setMenuOpen(true)}
          onMenuClose={() => setMenuOpen(false)}
          isClearable={true}
          inputValue={inputText}
          value={value}
          onInputChange={handleInputChange}
          isLoading={!!searchText && isLoading}
          filterOption={null}
          noOptionsMessage={noOptionsMessage}
          onChange={onChange}
        />
      )}
      <ConfirmationModal
        open={!!showModal}
        data={showModal || null}
        onCancel={() => {
          setValue(null);
          setShowModal(null);
        }}
        onConfirm={onConfirmModal}
        title={showModal === 'add' ? 'Custom tag addition warning' : 'Custom tag removal warning'}
        body={
          showModal === 'add'
            ? 'After adding a custom tag, only the rules created for this custom tag will be run on this address moving forward'
            : 'After removing a custom tag, only the rules created without a custom tag will be run on this address moving forward'
        }
        confirmButton={showModal === 'add' ? 'Add Tag' : 'Remove Tag'}
      />
    </div>
  );
};

export default AddressCustomTags;
