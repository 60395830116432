import { Api } from '../api';
import {
  IInvestigatedTxnDetailsPayload,
  IInvestigatedTxnsResponse,
  IInvestigationTreeGetRequestPayload,
  IInvestigationTreeResponse,
} from '../dtos/investigationTree';

export class InvestigationTreeApi extends Api {
  readonly baseUrl: string = '/risk/api/v4/';

  readonly getInvestigationTree = (payload: IInvestigationTreeGetRequestPayload) => {
    const { id, originType, isPreview, currency } = payload;
    const route = isPreview
      ? `${currency}/${originType === 'addresses' ? 'address-preview' : 'transaction-preview'}/${id}/investigation-tree/`
      : `${originType}/${id}/investigation-tree/`;
    return this.http.get<IInvestigationTreeResponse>(this.route(route));
  };

  readonly fetchInvestigatedTxnDetails = (payload: IInvestigatedTxnDetailsPayload) => {
    const { currency, sender, receiver, offset, limit } = payload;
    return this.http.post<IInvestigatedTxnsResponse>(
      this.route(
        `transactions/investigated-transactions/${currency}/?limit=${limit}&offset=${offset}`
      ),
      { sender, receiver }
    );
  };

  readonly fetchInvestigatedTxnDetailsLite = (payload: IInvestigatedTxnDetailsPayload) => {
    const { currency, sender, receiver, offset, limit } = payload;
    return this.http.post<IInvestigatedTxnsResponse>(
      this.route(
        `${currency}/transaction-preview/investigated-transactions/?limit=${limit}&offset=${offset}`
      ),
      { sender, receiver }
    );
  };
}

export const investigationTreeApi = new InvestigationTreeApi();
