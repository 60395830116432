/* eslint-disable tailwindcss/enforces-shorthand */
import { FC, useState } from 'react';
import { DotBadge, BasicBadge } from '../../../../ui';
import { getEntityColor } from '../../../../utils/helpers/entity';
import { Popover, PopoverPanel } from '@headlessui/react';

interface IEntity {
  tag_type_verbose?: string;
  tag_name_verbose?: string;
}
export interface EntityBadgeProps {
  entities?: IEntity[];
  entity?: IEntity;
  dot?: boolean;
  fixedWidth?: boolean;
}

const EntityBadge: FC<EntityBadgeProps> = ({
  entities,
  entity,
  dot = false,
  fixedWidth = false,
}) => {
  const multiple = Array.isArray(entities) && entities.length > 1;
  const firstEntity = entities?.at(0) || entity;
  const [popover, setPopover] = useState(false);
  if (!multiple && !firstEntity) {
    return <div>No Entities Detected</div>;
  }

  return (
    <div className='flex'>
      {dot ? (
        <DotBadge
          dotColor={getEntityColor(firstEntity.tag_type_verbose).bgColor}
          content={firstEntity.tag_name_verbose || firstEntity.tag_type_verbose}
          fixedWidth={fixedWidth}
        />
      ) : (
        <BasicBadge
          className={`${getEntityColor(firstEntity.tag_type_verbose).bgColor} ${
            getEntityColor(firstEntity.tag_type_verbose).fontColor
          } px-0.5 py-0.5`}
        >
          <div className='mx-1'>{firstEntity.tag_name_verbose || firstEntity.tag_type_verbose}</div>
        </BasicBadge>
      )}
      {multiple && (
        <Popover>
          <div className='relative'>
            <BasicBadge className='ml-1 bg-gray-100 px-0.5 py-0.5'>
              <div
                className='mx-1'
                onMouseEnter={() => setPopover(true)}
                onMouseLeave={() => setPopover(false)}
              >
                +{entities.length - 1}
              </div>
            </BasicBadge>
            {popover && (
              <PopoverPanel static className='absolute z-10 flex flex-col bg-white p-3 shadow'>
                {entities.slice(1).map((e, index) => (
                  <div className='mb-1' key={index}>
                    <DotBadge
                      dotColor={getEntityColor(e.tag_type_verbose).bgColor}
                      content={e.tag_name_verbose}
                    />
                  </div>
                ))}
              </PopoverPanel>
            )}
          </div>
        </Popover>
      )}
    </div>
  );
};

export default EntityBadge;
