import Select from 'react-select/creatable';
import { IOption } from '../../../Filters/CustomSelect';
import { IndicatorsContainerProps, MultiValueRemoveProps, components } from 'react-select';
import classNames from 'classnames';
import { CaretDown, X } from '@phosphor-icons/react';

interface SelectCreatableProps {
  label?: string;
  options: string[];
  setOptions: (options: string[]) => void;
  formatCreateLabel?: (input: string) => string;
  placeholder?: string;
}

const SelectCreatable = ({
  options,
  setOptions,
  label,
  formatCreateLabel,
  placeholder,
}: SelectCreatableProps) => {
  const mappedOptions = options?.length
    ? options.map(option => ({ value: option, label: option }))
    : [];
  const IndicatorsContainer = (props: IndicatorsContainerProps) => {
    return (
      <components.IndicatorsContainer {...props} className='mr-2.5'>
        <CaretDown weight='bold' size={16} className={classNames('text-blue-300')} />
      </components.IndicatorsContainer>
    );
  };
  const MultiValueRemove = (props: MultiValueRemoveProps) => {
    return (
      <components.MultiValueRemove {...props}>
        <X className='text-gray-500' />
      </components.MultiValueRemove>
    );
  };
  return (
    <>
      <label data-testid='select-creatable-label' className='text-sm font-medium text-gray-500'>
        {label}
      </label>
      <Select
        // Removes input border on focus
        styles={{
          input: baseStyle => ({ ...baseStyle, 'input:focus': { boxShadow: 'none' } }),
        }}
        components={{
          IndicatorsContainer,
          MultiValueRemove,
        }}
        classNames={{
          option: ({ isFocused }) => classNames(isFocused && '!bg-blue-500 !text-white'),
          placeholder: () => 'text-sm',
          multiValue: () => '!rounded-xl !bg-gray-100',
        }}
        value={mappedOptions}
        options={mappedOptions}
        placeholder={placeholder}
        isMulti
        hideSelectedOptions={false}
        onChange={(options: IOption[]) => setOptions(options.map(option => option.value))}
        formatCreateLabel={formatCreateLabel}
      />
    </>
  );
};

export default SelectCreatable;
