import { FC } from 'react';
import { IAggregatedResult } from '../../../api/dtos/address';
import DonutChartReport from '../DonutChartReport';
import EntitiesLegend from '../../AddressTransaction/EntitiesLegend';

interface IFlowAnalysisProps {
  inputs: IAggregatedResult[];
  outputs: IAggregatedResult[];
  part?: number;
}

const FlowAnalysis: FC<IFlowAnalysisProps> = props => {
  const { inputs, outputs, part } = props;

  const getEntities = () => {
    const entities: string[] = [];

    inputs?.forEach(item => {
      if (item.tag_type_verbose !== null && !entities.includes(item.tag_type_verbose)) {
        entities.push(item.tag_type_verbose);
      }
    });

    outputs?.forEach(item => {
      if (item.tag_type_verbose !== null && !entities.includes(item.tag_type_verbose)) {
        entities.push(item.tag_type_verbose);
      }
    });

    return entities;
  };

  return (
    <div className='w-full rounded-sm border'>
      <div className='rounded-t-sm border-b bg-gray-200 px-3 py-2.5 text-2xs font-bold'>
        Part {part}: Flow Analysis
      </div>
      <DonutChartReport inputs={inputs} outputs={outputs} group='type' id='pieChartReport' />
      <EntitiesLegend className='mb-8 ml-12' entities={getEntities()} />
    </div>
  );
};
export default FlowAnalysis;
