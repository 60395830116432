import { FC, useEffect, useState } from 'react';
import AssociatedCases from '../../../components/AddressTransaction/AssociatedCases';
import CompassLoader from '../../../components/ui/components/Loader/CompassLoader';
import DetailPage from '../../../layouts/DetailPage';
import TransactionDetailsMetadata from '../../../components/Transaction/TransactionMetadata/TransactionMetadata';
import Tab from '../../../components/ui/components/Tab/Tab';
import { useTransactionGetTransaction } from '../../../api/transaction';
import Overview from '../../../components/Transaction/Overview/Overview';
import { useRouter } from '../../../modules/router/RouterProvider';
import AuditLogs from '../../../components/common/AuditLogs';
import classNames from 'classnames';
interface TransactionDetailsProps {
  id?: string;
}

const TransactionDetails: FC<TransactionDetailsProps> = () => {
  const { getParams, getQueryParams } = useRouter();

  const transactionId = getParams().identifier || getParams().transactionId;
  const alertId = getQueryParams().alert;

  const { data: response, isLoading: detailLoading } = useTransactionGetTransaction(
    Number(transactionId),
    {
      useErrorBoundary: true,
    }
  );

  const [alerts, setAlerts] = useState(0);
  const [customerAddresses, setCustomerAddresses] = useState([]);
  const [alertDetails, setAlertDetails] = useState<number>(null);

  useEffect(() => {
    if (alertId) {
      setAlertDetails(Number(alertId));
    }
  }, [alertId, setAlertDetails]);

  if (detailLoading) {
    return <CompassLoader />;
  }
  const tabs = [
    { label: 'Overview' },
    {
      label: 'Associated Cases',
      count: response?.data?.associated_cases?.length,
      hidden: !response?.data?.associated_cases?.length,
    },
    {
      label: 'Audit Logs',
    },
  ];

  return (
    <DetailPage alertDetails={alertDetails}>
      <TransactionDetailsMetadata
        transaction={response.data}
        alerts={alerts}
        customerAddresses={customerAddresses}
      />
      <div className=''>
        <Tab
          values={tabs}
          type='secondary'
          listClassName={classNames('border-b border-gray-200 py-2 px-4 sticky z-30 top-0', {
            hidden: !!alertDetails,
          })}
          panelClassName='p-4'
        >
          <Overview
            transaction={response.data}
            setAlerts={setAlerts}
            setCustomerAddresses={setCustomerAddresses}
            alertDetails={alertDetails}
            setAlertDetails={setAlertDetails}
          />
          <AssociatedCases associatedCases={response.data.associated_cases} />
          <AuditLogs type='transaction' id={response.data.id} />
        </Tab>
      </div>
    </DetailPage>
  );
};

export default TransactionDetails;
