import { FC, useState } from 'react';
import { FolderPlus } from '@phosphor-icons/react';
import { BasicModal, Button, RadioButton } from '../../../ui';
import { useForm } from 'react-hook-form';
import { caseApi } from '../../../api/case';
import RadioButtonsController from '../../../ui/components/Form/RadioButtonsController';
import ExistingCaseDropdown from './ExistingCaseDropdownController';
import { useWatch } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import InputController from '../../../ui/components/Form/InputController';
import { useMutation, useQueryClient } from 'react-query';
// import { useNavigate } from 'react-router-dom';
// TODO: Use from library directly
import { IAddressResponse } from '../../../api/dtos/address';
import { IAddEntitiesResponse } from '../../../api/dtos/case';
import { AxiosError } from 'axios';
import { useRouter } from '../../../modules/router/RouterProvider';
import { ITransactionResponse } from '../../../api/dtos/transaction';
import { ICustomerResponse } from '../../../api/dtos/customer';

interface AddToCaseProps {
  address?: IAddressResponse;
  transaction?: ITransactionResponse;
  customer?: ICustomerResponse;
  refCase?: {
    entity: number;
    case: number;
  };
}

export const initialData = {
  case_type: 'new',
  case_name: '',
  case_id: '',
};

const AddToCase: FC<AddToCaseProps> = ({ address, transaction, customer, refCase }) => {
  const data = address || transaction;
  const id = data?.id;

  const queryClient = useQueryClient();

  const transactionAddress = transaction ? transaction.address : null;
  const [open, setOpen] = useState(false);

  const isRefCase = refCase?.case && refCase?.entity;

  // const navigate = useNavigate();
  const { navigate } = useRouter();

  const validations = Yup.object({
    case_type: Yup.string().label('Case type').required(),
    case_name: Yup.string()
      .label('Case id')
      .when('case_type', {
        is: 'new',
        then: schema => schema.required(),
      }),
    case_id: Yup.string()
      .label('Case id')
      .when('case_type', {
        is: 'existing',
        then: schema => schema.required(),
      }),
  });
  const { control, handleSubmit, setError } = useForm({
    defaultValues: initialData,
    resolver: yupResolver(validations),
  });

  const caseType = useWatch({ control, name: 'case_type' });

  const { mutate: archiveEntity } = useMutation(caseApi.archiveEntity, {
    onSuccess: () => {
      navigate(`/cases/${refCase.case}`);
    },
  });

  const onRemoveFromCase = () => {
    archiveEntity({
      type: address ? 'address' : transaction ? 'transaction' : 'customer',
      caseId: refCase.case,
      entityId: refCase.entity,
    });
  };

  const ModalBody: FC = () => {
    const caseId = useWatch({ control, name: 'case_id' });

    const onSuccess = (id: string | number) => {
      if (address) {
        queryClient.invalidateQueries(['addressApi.getAddress', address.id]);
      } else if (transaction) {
        queryClient.invalidateQueries(['transactionApi.getTransaction', transaction.id]);
      } else if (customer) {
        queryClient.invalidateQueries([
          'customerApi',
          customer.customer_id,
          'customerApi.getCustomer',
        ]);
      }

      navigate(`/cases/${id || caseId}`);
      setOpen(false);
    };

    const { mutate: addAddress } = useMutation(caseApi.addAddress, {
      onSuccess: () => onSuccess(caseId),
    });

    const { mutate: addTransaction } = useMutation(caseApi.addTransaction, {
      onSuccess: () => onSuccess(caseId),
    });

    const { mutate: addCustomer } = useMutation(caseApi.addCustomer, {
      onSuccess: () => onSuccess(caseId),
    });

    const { mutate: createCase } = useMutation(caseApi.createCase, {
      onSuccess: data => {
        onSuccess(data.data.id);
      },
      onError: (error: AxiosError<IAddEntitiesResponse>) => {
        setError('case_name', { message: error.response.data.name });
      },
    });

    const onSubmit = (data: typeof initialData) => {
      if (address) {
        const { identifier, currency, customer_id, type } = address;

        const addresses = [{ identifier, currency, ...(customer_id && { customer_id, type }) }];
        caseType === 'existing'
          ? addAddress({ case_id: Number(data.case_id), addresses })
          : createCase({ name: data.case_name, addresses });
      } else if (transaction) {
        const { identifier, currency, customer_id, type } = transaction;
        const transactions = [
          {
            identifier,
            currency,
            ...(customer_id && { customer_id, type, address: transactionAddress }),
          },
        ];
        caseType === 'existing'
          ? addTransaction({ case_id: Number(data.case_id), transactions })
          : createCase({ name: data.case_name, transactions });
      } else if (customer) {
        const { customer_id } = customer;
        const customers = [{ customer_id }];
        caseType === 'existing'
          ? addCustomer({ case_id: Number(data.case_id), customers })
          : createCase({ name: data.case_name, customers });
      }
    };

    return (
      <form onSubmit={handleSubmit(onSubmit)} className=''>
        <div className='mb-4'>
          <RadioButtonsController name='case_type' control={control}>
            <RadioButton value='new' labelText='New case' />
            <RadioButton value='existing' labelText='Existing case' />
          </RadioButtonsController>
          <div className='mb-6' />
          <label
            data-testid='enter-case-name'
            className='mb-2 inline-block text-base font-medium text-gray-700'
          >
            Enter Case Name
          </label>

          {caseType === 'existing' ? (
            <ExistingCaseDropdown control={control} />
          ) : (
            <div className='flex flex-col'>
              <InputController
                control={control}
                name='case_name'
                id={`case_name_${id}_${caseType}_${address ? 'address' : 'transaction'}}`}
                type='text'
                placeholder='Case name'
              />
            </div>
          )}
        </div>
        <div className='flex justify-end'>
          <div className='flex justify-end'>
            <Button id='modal-cancel' variant='tertiary' onClick={() => setOpen(false)}>
              Cancel
            </Button>
            <Button id='modal-confirm' type='submit' variant='primary' className='ml-2'>
              {caseType === 'existing' ? 'Add to Case' : 'Create Case'}
            </Button>
          </div>
        </div>
      </form>
    );
  };

  return (
    <>
      {isRefCase ? (
        <Button
          id='case-remove'
          variant='tertiary'
          className='my-auto py-1.5 text-sm font-medium'
          onClick={onRemoveFromCase}
        >
          <FolderPlus size={12} className='ml-[-12px] text-white' />
          Remove from Case
        </Button>
      ) : (
        <Button id='modal-add' variant='primary' onClick={() => setOpen(true)} className='my-auto'>
          <FolderPlus size={16} className='mr-2' />
          Add to Case
        </Button>
      )}
      <BasicModal
        open={open}
        onClose={() => setOpen(false)}
        modalTitle={`Add ${address ? 'Address' : 'Transaction'} to Case`}
        size='md'
        modalBody={<ModalBody />}
        modalFooter={null}
        customFooterClass='hidden'
      />
    </>
  );
};

export default AddToCase;
