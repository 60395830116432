import * as Sentry from '@sentry/react';
import { AxiosError } from 'axios';
import { getErrorMessage } from '../utils/helpers/helperFunctions';

const modulePatterns = {
  AddrLst: [
    '^/risk/api/v4/addresses/$',
    '/risk/api/v4/addresses/direct-tag-data/name-verboses',
    '/risk/api/v4/addresses/update-alerts',
    '/risk/api/v4/addresses/export',
    '/risk/api/v4/address-comments',
  ],
  AddrDtl: [
    '/risk/api/v4/addresses/\\d+$',
    '/risk/api/v4/addresses/\\d+/$',
    '/risk/api/v4/addresses/\\d+/main-balance',
    '/risk/api/v4/addresses/\\d+/token-summary',
    '/risk/api/v4/addresses/\\d+/attribution-timestamp',
    '/risk/api/v4/addresses/\\d+/tag-aggregated-incoming-values',
    '/risk/api/v4/addresses/\\d+/tag-aggregated-outgoing-values',
    '/risk/api/v4/addresses/\\d+/transactions',
    '/risk/api/v4/addresses/\\d+/transactions/export',
    '/risk/api/v4/addresses/\\d+/transactions/direct-export',
    '/risk/api/v4/addresses/\\d+/[^/]+-transactions',
    '/risk/api/v4/addresses/\\d+/[^/]+-transactions/export',
    '/risk/api/v4/addresses/\\d+/sentiment-summary',
    '/risk/api/v4/addresses/\\d+/sentiments',
    '/risk/api/v4/addresses/\\d+/report',
    '/risk/api/v4/addresses/\\d+/monitoring',
    '/risk/api/v4/addresses/\\d+/update-custom-tags',
  ],
  CustAddr: [
    '/risk/api/v4/customers/\\d+/deposit-addresses',
    '/risk/api/v4/customers/\\d+/withdrawal-addresses',
  ],
  Settings: [
    '/risk/api/v4/custom-tagged-addresses',
    '/risk/api/v4/custom-tagged-addresses/\\d+/archive',
    '/risk/api/v4/custom-tagged-addresses/export',
    '/risk/api/v4/custom-monitored-addresses',
    '/risk/api/v4/custom-monitored-addresses/archive',
    '/risk/api/v4/token-monitoring-addresses',
    '/profile/api/v2/algo-summary',
    '/risk/api/v4/monitoring-ignored-addresses',
    '/risk/api/v4/custom-monitored-entities',
    '/risk/api/v4/custom-monitored-entities/\\d+',
    '/risk/api/v4/custom-monitored-entities/addresses',
    '/profile/api/v1/team-members/me',
    '/profile/api/v2/team-members',
    '/profile/api/v2/workflow-stages',
  ],
  TrnLst: [
    '^/risk/api/v4/transactions/$',
    '/risk/api/v4/transactions/export',
    '/risk/api/v4/transactions/update-alerts',
  ],
  TrnDtl: [
    '/risk/api/v4/transactions/\\d+$',
    '/risk/api/v4/transactions/\\d+/$',
    '/risk/api/v4/transactions/\\d+/aggregated-inputs',
    '/risk/api/v4/transactions/\\d+/aggregated-outputs',
    '/risk/api/v4/transactions/\\d+/inputs',
    '/risk/api/v4/transactions/\\d+/outputs',
    '/risk/api/v4/transactions/\\d+/external-transaction',
    '/risk/api/v4/transactions/\\d+/internal-transactions',
    '/risk/api/v4/transactions/\\d+/token-transfers',
    '/risk/api/v4/transactions/\\d+/report',
  ],
  CustLst: [
    '^/risk/api/v4/customers/$',
    '/risk/api/v4/customers/export',
    '/risk/api/v4/customers/update-alerts',
  ],
  CustDtl: [
    '/risk/api/v4/customers/[^/]+$',
    '/balances',
    '/deposit-addresses',
    '/withdrawal-addresses',
    '/any-addresses',
    '/deposits-tag-aggregates',
    '/withdrawals-tag-aggregates',
    '/deposits',
    '/withdrawals',
    '/sentiment_summary',
    '/sentiments',
    '/pof-customer-summary',
    '/pof-address-summary',
    '/report',
  ],
  AddrPrev: [
    '/risk/api/v4/\\d+/address-preview/[^/]+$',
    '/risk/api/v4/\\d+/address-preview/[^/]+/$',
    '/risk/api/v4/\\d+/address-preview/[^/]+/token-summary',
    '/risk/api/v4/\\d+/address-preview/[^/]+/main-balance',
    '/risk/api/v4/\\d+/address-preview/[^/]+/attribution-timestamp',
    '/risk/api/v4/\\d+/address-preview/[^/]+/tag-aggregated-incoming-values',
    '/risk/api/v4/\\d+/address-preview/[^/]+/tag-aggregated-outgoing-values',
    '/risk/api/v4/\\d+/address-preview/[^/]+/transactions',
    '/risk/api/v4/\\d+/address-preview/[^/]+/transactions/direct-export',
  ],
  TxnPrev: [
    '^/risk/api/v4/\\d+/transaction-preview/[^/]+/?$',
    '/risk/api/v4/\\d+/transaction-preview/[^/]+/aggregated-inputs',
    '/risk/api/v4/\\d+/transaction-preview/[^/]+/aggregated-outputs',
    '/risk/api/v4/\\d+/transaction-preview/[^/]+/inputs',
    '/risk/api/v4/\\d+/transaction-preview/[^/]+/outputs',
    '/risk/api/v4/\\d+/transaction-preview/[^/]+/internal-transactions',
    '/risk/api/v4/\\d+/transaction-preview/[^/]+/token-transfers',
  ],
  Search: ['/risk/api/v4/search', '/risk/api/v4/search/suggest', '/risk/api/v4/search/customers'],
  Workflow: [
    '/risk/api/v4/[^/]+/workspace-assignment-counts',
    '/profile/api/v2/workspaces/workflow-stages',
    '/profile/api/v2/workspaces/members',
  ],
  Dashboard: [
    '/risk/api/v4/dashboard/addresses',
    '/risk/api/v4/dashboard/transactions',
    '/risk/api/v4/dashboard/customers',
    '/risk/api/v4/dashboard/entities',
    '/risk/api/v4/dashboard/cases',
    '/risk/api/v4/dashboard/rules',
    '/risk/api/v4/dashboard/alerts',
    '/risk/api/v4/dashboard/alerts-entity',
    '/risk/api/v4/dashboard/alerts-resolution-record',
    '/risk/api/v4/dashboard/report',
    '/risk/api/v4/dashboard/resolved-alert-report',
    '/risk/api/v4/dashboard/opened-alert-report',
    '/risk/api/v4/dashboard/created-alert-report',
  ],
  Auth: [
    '^/auth0/api/organization/?$',
    '/auth0/api/logout',
    '/auth0/api/change-password',
    '/auth0/api/permissions',
    '/auth0/api/permissions/',
    '^/account-permissions/?$',
    '^/account-permissions$',
  ],
  Profile: [
    '/profile/api/v1/team-members/me',
    '/profile/api/v1/team-members/me/notification-subscriptions',
    '/profile/api/v1/team-members/me/autocreate-case-alert-levels',
    '/profile/api/v1/keys',
    '/profile/api/v1/keys/\\d+',
    '/profile/api/v1/case-settings/assignment-type',
    '/profile/api/v1/case-settings/mandatory-approval',
    '/profile/api/v1/case-settings/users',
    '/profile/api/v1/case-settings/users/\\d+',
  ],
  Download: ['/download'],
  AccountPermissions: ['^/account-permissions/?$', '^/account-permissions$'],
  HealthCheck: ['/api/v4/health-check'],
  Integration: [
    '^/integrations/?$',
    '/integrations/[^/]+$',
    '/integrations/webhook-audit-trail',
    '/integrations/fireblocks',
    '/integrations/fireblocks/unfreeze_transaction/\\d+',
    '/integrations/slack-auth-redirect',
    '/risk/api/v4/webhook-tests',
    '/risk/api/v4/webhook-tests/',
  ],
  Billing: [
    '/profile/billing/plan',
    '/profile/billing/details',
    '/subscriptions/customer/[^/]+/subscription/[^/]+/invoice',
    '/subscriptions/customer/[^/]+/subscription',
    '/profile/plan',
    '/profile/credit-usage',
    '/profile/credit-usage-nonunique',
  ],
  Workspaces: [
    '/profile/api/v2/workspaces',
    '/profile/api/v2/workspaces/lite',
    '/profile/api/v2/workspaces/members',
    '/profile/api/v2/workspaces/workflow-stages',
  ],
  Classifier: ['/risk/api/v4/classifiers'],
  Stages: ['/profile/api/v2/workflow-stages/lite', '/profile/api/v2/workflow-stages'],
  Case: [
    '^/resolutioncenter/api/v4/cases/?$',
    '/resolutioncenter/api/v4/cases/\\d+$',
    '/resolutioncenter/api/v4/cases/\\d+/$',
    '/resolutioncenter/api/v4/cases/bulk',
    '/resolutioncenter/api/v4/cases/export',
    '/resolutioncenter/api/v4/cases/update-alerts',
    '/resolutioncenter/api/v4/cases/\\d+/addresses',
    '/resolutioncenter/api/v4/cases/\\d+/transactions',
    '/resolutioncenter/api/v4/cases/\\d+/customers',
    '/resolutioncenter/api/v4/cases/\\d+/report',
    '/resolutioncenter/api/v4/cases/\\d+/comments',
    '/resolutioncenter/api/v4/cases/\\d+/audit-trail',
  ],
  Policy: [
    '^/risk/api/v4/policies/?$',
    '/risk/api/v4/policies/\\d+$',
    '/risk/api/v4/policies/\\d+/$',
    '/risk/api/v4/policies/\\d+/crypto-objects-flagged',
    '/risk/api/v4/policies/\\d+/most-triggered-rules',
    '/risk/api/v4/policies/\\d+/alerts-triggered-over-time',
    '/risk/api/v4/policies/\\d+/history',
    '/risk/api/v4/policies/download-rules',
    '/risk/api/v4/policies/\\d+/classifiers$',
    '/risk/api/v4/policies/\\d+/classifiers/$',
    '/risk/api/v4/policies/\\d+/classifiers/\\d+$',
    '/risk/api/v4/policies/\\d+/classifiers/\\d+/$',
    '/risk/api/v4/policies/\\d+/classifiers/\\d+/revert',
    '/risk/api/v4/policies/\\d+/classifiers/\\d+/crypto-objects-flagged',
    '/risk/api/v4/policies/\\d+/classifiers/\\d+/alerts-triggered-over-time',
    '/risk/api/v4/policies/\\d+/classifiers/\\d+/history',
  ],
  InvestigationTree: [
    '/risk/api/v4/[^/]+/\\d+/investigation-tree',
    '/risk/api/v4/transactions/investigated-transactions/[^/]+',
    '/risk/api/v4/\\d+/address-preview/[^/]+/investigation-tree',
    '/risk/api/v4/\\d+/transaction-preview/[^/]+/investigation-tree',
    '/risk/api/v4/[^/]+/transaction-preview/investigated-transactions',
  ],
  Comment: [
    '/resolutioncenter/api/v4/[^/]+/[^/]+/comments/?$',
    '/resolutioncenter/api/v4/[^/]+/[^/]+/comments/suggest/?$',
  ],
  Alert: [
    '/resolutioncenter/api/v4/alerts',
    '/resolutioncenter/api/v4/alerts/\\d+/cycles',
    '/resolutioncenter/api/v4/alerts/\\d+/items',
    '/resolutioncenter/api/v4/alerts/update-alerts',
    '/resolutioncenter/api/v4/[^/]+/\\d+/alerts',
    '/resolutioncenter/api/v4/[^/]+/\\d+/alerts/\\d+',
    '/resolutioncenter/api/v4/[^/]+/\\d+/alerts/\\d+/cycles',
    '/resolutioncenter/api/v4/[^/]+/\\d+/alerts/\\d+/monitoring',
  ],
  DigitalAssets: ['/risk/api/v4/digital-assets', '/risk/api/v4/digital-assets/download'],
  MonitoringHealth: ['/risk/api/v4/addresses/rescreening-addresses'],
  Upload: ['/upload/api/v4/files', '/upload/api/v4/files/\\d+/download'],
  CustomTags: [
    '/risk/api/v4/custom-tags',
    '/risk/api/v4/custom-tags/\\d+',
    '/risk/api/v4/custom-tags/export',
  ],
  TeamMembers: [
    '/profile/api/v2/team-members',
    '/profile/api/v2/team-members/\\d+',
    '/profile/api/v2/team-members/suggest',
    '/profile/api/v2/team-members/bulk-invite-workspace',
  ],
  MonitoringPreferences: [
    '/profile/api/v1/team-members/me/continuous-monitoring-settings',
    '/profile/api/v1/team-members/me/autoresolve-alert-levels',
  ],

  ApiKeys: ['/profile/api/v1/team-members/me/api-keys'],
};

export const getModuleAndComponent = (url: string): string => {
  for (const [module, patterns] of Object.entries(modulePatterns)) {
    if (patterns.some(pattern => new RegExp(pattern).test(url))) {
      return module;
    }
  }

  return 'Unknown/Component';
};

export const handleCacheError = (
  error: AxiosError,
  variables: unknown,
  errorType: 'queryCache' | 'mutationCache'
) => {
  try {
    Sentry.withScope(scope => {
      const moduleInfo = getModuleAndComponent(error?.config?.url || '');
      const statusCode = error?.response?.status || 'unknown_status';
      const simplifiedRoute = error?.config?.url?.split('?')[0];
      const errorMessage = getErrorMessage(error) || 'Unknown Err';

      // Format: Module | Method StatusCode | Route | Error Message
      const errorName = `${moduleInfo} | ${error?.config?.method} ${statusCode} | ${simplifiedRoute} | ${errorMessage}`;

      scope.setExtra('variables', variables);
      scope.setExtra('endpoint', error?.config?.url);
      scope.setExtra('requestMethod', error?.config?.method);
      scope.setLevel('error');
      scope.setTag('errorType', errorType);
      scope.setTag('statusCode', statusCode.toString());
      scope.setTag('module', moduleInfo.split('/')[0]);

      const errorEvent = new Error(errorMessage);
      errorEvent.name = errorName;
      errorEvent.stack = error.stack;

      Sentry.captureException(errorEvent, {
        fingerprint: [
          moduleInfo,
          error?.config?.method || 'unknown_method',
          statusCode.toString(),
          error?.config?.url?.split('?')[0] || 'unknown_url',
        ],
        extra: {
          originalError: error,
          errorResponse: error?.response?.data,
          timestamp: new Date().toISOString(),
          fullUrl: error?.config?.url,
        },
      });
    });
  } catch {
    Sentry.captureException(error);
  }
};
