import { FC, ReactNode, useState } from 'react';
import Sidebar from '../../ui/components/Sidebar';
import { Button } from '../../../ui';
import { ClockCounterClockwise } from '@phosphor-icons/react';
import Quill from '../../ui/components/Quill';
import Comment from './Comment';
import { useMutation, useQueryClient } from 'react-query';
import FilterAuditTrail, { IFilters, defaultStateFilters } from './FilterAuditTrail';
import AuditTrailAppliedFilters from './AuditTrailAppliedFilters';
import { IAlert, IAlertItem } from '../../../api/dtos/alert';
import { useCommentGetComments, commentApi } from '../../../api/comment';
import Pagination from '../../../containers/Pagination';
import { IPostCommentsUser } from '../../../api/dtos/comment';
import { toast } from 'react-toastify';

interface IAuditTrailProps {
  id: number | string;
  alert?: IAlert;
  alertItem?: IAlertItem;
  buttonIcon?: ReactNode;
  type: 'address' | 'transaction' | 'customer' | 'alert';
  nonApiType: 'address' | 'transaction' | 'customer';
}

const AuditTrail: FC<IAuditTrailProps> = props => {
  const { id, alert, buttonIcon, alertItem, type, nonApiType } = props;
  const [open, setOpen] = useState(false);
  const [filters, setFilters] = useState<IFilters>(defaultStateFilters);
  const getComments = useCommentGetComments(
    {
      id,
      filters: { ...filters },
      type,
    },
    { enabled: open }
  );
  const queryClient = useQueryClient();

  const { mutate } = useMutation(commentApi.addComment, {
    onSuccess: () => {
      toast.success('Added Comment succesfully');
      queryClient.invalidateQueries(['commentApi.getComments']);
    },
  });

  const onSubmit = (value: string, files: FileList, users: IPostCommentsUser[]) => {
    mutate({
      id,
      type,
      body: value,
      files,
      context: {
        users,
        ...(alert && {
          alerts: [{ rule_name: alert.alert_body, id: alert.id, start_position: 0, length: 0 }],
        }),
        ...(alertItem && {
          alert_items: [{ id: alertItem.identifier, start_position: 0, length: 0 }],
        }),
      },
    });
  };

  const onApplyFilter = (filters: IFilters) => {
    setFilters(filters);
  };

  return (
    <>
      <Button
        id='auditTrail'
        variant='tertiary'
        className='my-auto ml-2 px-3'
        onClick={() => setOpen(true)}
      >
        {buttonIcon || <ClockCounterClockwise className='' size={16} />}
      </Button>
      <Sidebar
        title='Audit Trail'
        filter={<FilterAuditTrail filters={filters} onApply={onApplyFilter} type={nonApiType} />}
        isOpen={open}
        onClose={() => setOpen(false)}
      >
        <div className='flex h-full flex-col justify-between'>
          <AuditTrailAppliedFilters
            filters={filters}
            setFilters={setFilters}
            alert={alert}
            alertItem={alertItem}
          />
          <Pagination
            query={getComments}
            className='mt-auto flex flex-col-reverse'
            loader='compass'
          >
            {data => <Comment comment={data} key={data.id} />}
          </Pagination>
          <Quill id={id} onSubmit={onSubmit} type={nonApiType} />
        </div>
      </Sidebar>
    </>
  );
};

export default AuditTrail;
