import { FC, useState } from 'react';
import { CaretDown, DownloadSimple } from '@phosphor-icons/react';
import SimpleDropdown from '../../ui/components/Dropdown/SimpleDropdown';
import Button from '../../../ui/components/Button/Button';
import { DropdownOption } from '../../../ui';
import { StyledDropzone } from './DropSection';
import { toast } from 'react-toastify';

interface IDropZone {
  file_type: number;
  onSuccess: () => void;
}

const DropZone: FC<IDropZone> = ({ file_type, onSuccess }) => {
  const [dropdownValue, setDropdownValue] = useState({ label: 'CSV', value: 'CSV', id: 'csv' });
  const onChangeDropdown = value => {
    setDropdownValue(value);
  };
  const handleDownload = async () => {
    try {
      const fileName = `samples_${file_type === 0 ? 'addresses' : 'transactions'}_fileupload.${dropdownValue.id}`;
      const response = await fetch(`/samples/${fileName}`);

      if (!response.ok) {
        throw new Error('Download failed');
      }

      const blob = await response.blob();

      const url = window.URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = url;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();

      window.URL.revokeObjectURL(url);
      document.body.removeChild(link);

      toast.success(
        `Sample ${file_type === 0 ? 'addresses' : 'transactions'} template downloaded successfully!`
      );
    } catch {
      toast.error('Download failed. Please try again.');
    }
  };
  const moreDropdownOptions: DropdownOption[] = [
    { label: 'CSV', value: 'CSV', id: 'csv' },
    { label: 'XLS', value: 'XLS', id: 'xls' },
  ];
  return (
    <div className='m-4'>
      <StyledDropzone file_type={file_type} onSuccess={onSuccess} />
      <div className='mt-5 flex justify-between'>
        <p className='mt-2 text-xs leading-4 text-gray-500'>
          Choose a format to download template:
        </p>
        <div className='flex'>
          <SimpleDropdown
            id='fileUploads-fileType'
            value={null}
            onChange={onChangeDropdown}
            placeholder={
              <Button variant='tertiary' className='px-3'>
                {dropdownValue.value}
                <CaretDown size={12} color='#D1D5DB' className='ml-1' />
              </Button>
            }
            options={moreDropdownOptions}
          />
          <Button
            id='fileUploads-template'
            variant='secondary'
            className='ml-4 focus:outline-0'
            onClick={handleDownload}
          >
            <DownloadSimple size={16} />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default DropZone;
