import { useInfiniteQuery, useQuery, UseQueryOptions } from 'react-query';
import { Api } from './api';
import { IPaginationQueryParams } from './dtos/common';
import {
  IIntegrationCreateRequest,
  IIntegrationFireblocksLogsResponse,
  IIntegrationPaginatedResponse,
  IIntegrationResult,
  IIntegrationUpdateRequest,
  IIntegrationWebhookAuditTrailResponse,
  ISlackAuthPayload,
  ITestWebhookRequest,
} from './dtos/integration';
import { getNextPageParam } from '../utils/helpers/helperFunctions';

export class IntegrationApi extends Api {
  readonly baseUrl: string = '/integrations/';

  readonly getIntegrations = (request: IPaginationQueryParams) => {
    const { offset = 0, page = 1, limit = 20 } = request;
    return this.http.get<IIntegrationPaginatedResponse>(this.route('/'), {
      params: {
        offset,
        page,
        limit,
      },
    });
  };

  readonly createIntegration = (request: IIntegrationCreateRequest) => {
    return this.http.post<IIntegrationResult>(this.route('/'), request);
  };

  readonly updateIntegration = (request: IIntegrationUpdateRequest) => {
    const { id, ...requestBody } = request;
    return this.http.put<IIntegrationResult>(this.route(`${id}/`), requestBody);
  };

  readonly getWebhookAuditTrail = (request: IPaginationQueryParams) => {
    const { offset = 0, limit = 20 } = request;
    return this.http.get<IIntegrationWebhookAuditTrailResponse>(
      this.route('webhook-audit-trail/'),
      {
        params: {
          offset,
          limit,
        },
      }
    );
  };

  readonly getFireblocksLogs = (request: IPaginationQueryParams) => {
    const { offset = 0, limit = 20 } = request;
    return this.http.get<IIntegrationFireblocksLogsResponse>(this.route('fireblocks/'), {
      params: {
        offset,
        limit,
      },
    });
  };

  readonly testWebhook = (request: ITestWebhookRequest) => {
    return this.http.post('risk/api/v4/webhook-tests/', request);
  };

  readonly unfreezeFireblocksTransaction = (id: number) => {
    return this.http.post(this.route(`fireblocks/unfreeze_transaction/${id}/`));
  };

  readonly slackAuthRedirect = (request: ISlackAuthPayload) => {
    return this.http.post(this.route('slack-auth-redirect/'), request);
  };
}

export const integrationApi = new IntegrationApi();

export const useIntegrationsGet = (options?: UseQueryOptions) => {
  const key = ['integrationApi', 'getIntegrations'];
  const result = useQuery(
    key,
    () =>
      integrationApi.getIntegrations({
        limit: 30,
      }),
    { ...options } as unknown
  );

  return {
    key,
    ...result,
  };
};

export const useWebhookAuditTrailGet = (options?: UseQueryOptions) => {
  const key = ['integrationApi', 'getWebhookAuditTrail'];
  const result = useInfiniteQuery(
    key,
    ({ pageParam }) => integrationApi.getWebhookAuditTrail({ offset: pageParam }),
    {
      ...options,
      getNextPageParam,
    } as unknown
  );
  return result;
};

export const useFireblocksLogsGet = (options?: UseQueryOptions) => {
  const key = ['integrationApi', 'getFireblocksLogs'];
  const result = useInfiniteQuery(
    key,
    ({ pageParam }) => integrationApi.getFireblocksLogs({ offset: pageParam }),
    {
      ...options,
      getNextPageParam,
    } as unknown
  );
  return result;
};
