import { FC } from 'react';
import ReportTable from '../ReportTable';
import { ICaseAddressResult } from '../../../api/dtos/case';
import { RiskBadge } from '../../../ui';
import CurrencyBadge from '../../ui/components/Badge/CurrencyBadge';
import { getCurrencyById } from '../../../utils/helpers/currency';

interface ICaseListing {
  data: ICaseAddressResult[];
  part: number;
}
const AddressCaseList: FC<ICaseListing> = ({ data, part }) => {
  if (!data) return null;

  const tableData = data.map((addressItem: ICaseAddressResult) => {
    const value = [
      {
        label: 'Address',
        value: <div className='break-words font-normal'>{addressItem.identifier}</div>,
      },
      {
        label: 'Currency',
        value: (
          <CurrencyBadge transparent key={addressItem.identifier} currency={addressItem.currency} />
        ),
      },
      {
        label: 'Received Amount',
        value: (
          <div className='flex gap-1'>
            <span className='font-normal'>{addressItem.received_amount}</span>
            <span>{getCurrencyById(addressItem.currency).currency_short}</span>
          </div>
        ),
      },
      {
        label: 'Alert',
        value: <div className='font-normal'>{addressItem.alerts}</div>,
      },
      {
        label: 'Risk Level',
        value: (
          <div>
            <RiskBadge risk={addressItem.risk_level} />
          </div>
        ),
      },
    ];

    return {
      label: addressItem.id,
      value,
    };
  });
  return <ReportTable tableData={tableData} title={`Part ${part}: Address Listing`} />;
};

export default AddressCaseList;
