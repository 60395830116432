import { FC } from 'react';
import { useRoutes, Navigate, useSearchParams } from 'react-router-dom';
import { inAppContainer } from '../containers/AppContainer';
import { inGuestContainer } from '../containers/GuestContainer';
import AppShell from '../layouts/AppShell';
import Layout from '../layouts/Layout';
import Dashboard from '../views/dashboard/Dashboard';
import Login from '../views/login/Login';
import ApiKeys from '../views/dashboard/settings/Workspace/ApiKeys';
import EmailNotifications from '../views/dashboard/settings/User/EmailNotifications';
import Profile from '../views/dashboard/settings/User/Profile';
import Theme from '../views/theme/Theme';
import BillingUsage from '../views/dashboard/settings/Organization/BillingUsage';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import AddressDetails from '../views/dashboard/addresses/AddressDetails';
import TransactionDetails from '../views/dashboard/transactions/TransactionDetails';

// should get from doppler
const REACT_APP_RECAPTCHA_SITE_KEY = import.meta.env.VITE_RECAPTCHA_SITE_KEY;
// import CasePreference from '../views/dashboard/settings/CasePreference';
import MonitoringPreference from '../views/dashboard/settings/Workspace/MonitoringPreference';
import CasePreference from '../views/dashboard/settings/Workspace/CasePreference';
import CustomTags from '../views/dashboard/settings/Workspace/CustomTags';
import AddressReport from '../views/pdf/AddressReport';
import TransactionReport from '../views/pdf/TransactionReport';
import CustomerDetails from '../views/dashboard/customers/CustomerDetails';
// import AlgoSettings from '../views/dashboard/settings/Workspace/AlgoSettings';
import CustomerReport from '../views/pdf/CustomerReport';
import CaseDetails from '../views/dashboard/cases/CaseDetails';
import AddressList from '../views/dashboard/addresses/AddressList';
import TransactionList from '../views/dashboard/transactions/TransactionList';
import CustomerList from '../views/dashboard/customers/CustomerList';
import CaseList from '../views/dashboard/cases/CaseList';
import Integrations from '../views/dashboard/settings/Integrations/Integrations';
import IntegrationDetails from '../views/dashboard/settings/Integrations/IntegrationDetails';
import OrganizationSettings from '../views/dashboard/settings/Organization';
import OrganizationMembers from '../views/dashboard/settings/Organization/Members';
import OrganizationWorkspaces from '../views/dashboard/settings/Organization/Workspaces';
import WorkspaceSettings from '../views/dashboard/settings/Workspace';
import WorkspaceMembers from '../views/dashboard/settings/Workspace/Members';
import PolicyList from '../components/RiskPolicy/PolicyList';
import RuleList from '../components/RiskPolicy/RuleList';
import PolicyDetails from '../components/RiskPolicy/PolicyDetails';
import UserSettings from '../views/dashboard/settings/User';
import RuleCreate from '../components/RiskPolicy/RuleCreate';
import RuleDetails from '../components/RiskPolicy/RuleDetails';
import SearchPage from '../views/search/SearchPage';
import SmartContractWatchlist from '../components/Settings/Monitoring/SmartContractWatchlist';
import ErrorPage from '../components/ErrorPage/ErrorPage';
import Logout from '../views/login/Logout';
import ProductGuide from '../views/docs/ProductGuide';
import ApiDocumentation from '../views/docs/ApiDocumentation';
import AlertList from '../views/dashboard/alerts/AlertList';
import { wrapUseRoutes } from '@sentry/react';
import CustomLists from '../views/dashboard/settings/Workspace/CustomLists';
import LoginComplete from '../views/login/LoginComplete';
import SlackConnect from '../views/dashboard/settings/Integrations/SlackConnect';
import DownloadWithKey from '../views/dashboard/DownloadWithKey';
import CaseReport from '../views/pdf/CaseReport';

export const AppRoutes: FC = () => {
  const useSentryRoutes = wrapUseRoutes(useRoutes);
  const PreserveParamsNavigate: FC<{ to: string }> = ({ to }) => {
    const [searchParams] = useSearchParams();
    const search = searchParams.toString();
    const targetPath = search ? `${to}?${search}` : to;
    return <Navigate to={targetPath} replace={true} />;
  };

  const routes = useSentryRoutes([
    {
      path: '/accounts',
      children: [
        {
          path: 'login',
          children: [
            {
              index: true,
              element: inGuestContainer(
                <Layout heightOffset={-54.9}>
                  <GoogleReCaptchaProvider reCaptchaKey={REACT_APP_RECAPTCHA_SITE_KEY}>
                    <Login />
                  </GoogleReCaptchaProvider>
                </Layout>
              ),
            },
            {
              path: 'complete',
              element: <LoginComplete />,
            },
          ],
        },
        {
          path: 'logout',
          element: inAppContainer(<AppShell />),
          children: [
            {
              index: true,
              element: <Logout />,
            },
          ],
        },
      ],
    },
    {
      path: 'theme',
      element: <Theme />,
    },
    {
      path: '/',
      element: <PreserveParamsNavigate to='dashboard' />,
    },
    {
      path: '/search',
      element: inAppContainer(<AppShell isSearchPage={true} />),
      children: [
        {
          index: true,
          element: <SearchPage />,
        },
      ],
    },
    {
      path: 'dashboard',
      element: inAppContainer(<AppShell />),
      children: [
        {
          index: true,
          element: <Dashboard />,
        },
        {
          path: 'slack',
          children: [
            {
              path: 'connect',
              element: <SlackConnect />,
            },
          ],
        },
      ],
    },
    {
      path: 'addresses',
      element: inAppContainer(<AppShell />),
      children: [
        {
          index: true,
          element: <AddressList />,
        },
        {
          path: ':addressId',
          children: [
            {
              index: true,
              element: <AddressDetails />,
            },
          ],
        },
      ],
    },
    {
      path: 'transactions',
      element: inAppContainer(<AppShell />),
      children: [
        {
          index: true,
          element: <TransactionList />,
        },
        {
          path: ':transactionId',
          children: [
            {
              index: true,
              element: <TransactionDetails />,
            },
          ],
        },
      ],
    },
    {
      path: 'customers',
      element: inAppContainer(<AppShell />),
      children: [
        {
          index: true,
          element: <CustomerList />,
        },
        {
          path: ':customerId',
          children: [
            {
              index: true,
              element: <CustomerDetails />,
            },
          ],
        },
      ],
    },
    {
      path: 'alerts',
      element: inAppContainer(<AppShell />),
      children: [
        {
          index: true,
          element: <AlertList />,
        },
      ],
    },
    {
      path: 'cases',
      element: inAppContainer(<AppShell />),
      children: [
        {
          index: true,
          element: <CaseList />,
        },
        {
          path: ':caseId',
          children: [
            {
              index: true,
              element: <CaseDetails />,
            },
          ],
        },
      ],
    },
    {
      path: 'settings',
      element: inAppContainer(<AppShell />),
      children: [
        {
          path: 'organisation',
          element: <OrganizationSettings />,
          children: [
            {
              index: true,
              element: <Navigate to='members' replace={true} />,
            },
            {
              path: 'members',
              element: <OrganizationMembers />,
            },
            {
              path: 'workspaces',
              element: <OrganizationWorkspaces />,
            },
            {
              path: 'usage',
              element: <BillingUsage />,
            },
            {
              path: 'current-plan',
              element: <Navigate to='/' replace />,
            },
          ],
        },
        {
          path: 'workspace',
          element: <WorkspaceSettings />,
          children: [
            {
              index: true,
              element: <Navigate to='members' replace={true} />,
            },
            {
              path: 'api-keys',
              element: <ApiKeys />,
            },
            {
              path: 'integrations',
              children: [
                {
                  index: true,
                  element: <Integrations />,
                },
                {
                  path: ':integrationType',
                  element: <IntegrationDetails />,
                },
              ],
            },
            {
              path: 'members',
              element: <WorkspaceMembers />,
            },
            {
              path: 'workflow',
              element: <Navigate to='/' replace />,
            },
            {
              path: 'case',
              element: <CasePreference />,
            },
            {
              path: 'custom-tags',
              element: <CustomTags />,
            },
            {
              path: 'policies',
              children: [
                {
                  index: true,
                  element: <PolicyList />,
                },
                {
                  path: ':policyId',
                  children: [
                    {
                      index: true,
                      element: <PolicyDetails />,
                    },
                    {
                      path: 'rules/add',
                      element: <RuleCreate />,
                    },
                    {
                      path: 'rules/:ruleId',
                      element: <RuleDetails />,
                    },
                  ],
                },
              ],
            },
            {
              path: 'rules',
              element: <RuleList />,
            },
            {
              path: 'config',
              element: <MonitoringPreference />,
            },
            {
              path: 'smart-contract-watchlist',
              element: <SmartContractWatchlist />,
            },
            {
              path: 'algo-settings',
              element: <Navigate to='/' replace />,
            },
            {
              path: 'custom-lists',
              element: <CustomLists />,
            },
            {
              path: 'whitelist-blacklist',
              element: <Navigate to='/settings/workspace/custom-lists' replace />,
            },
          ],
        },
        {
          path: 'user',
          element: <UserSettings />,
          children: [
            {
              path: 'profile',
              element: <Profile />,
            },
            {
              path: 'notifications',
              element: <EmailNotifications />,
            },
          ],
        },
      ],
    },
    {
      path: 'download',
      element: inAppContainer(<AppShell />),
      children: [
        {
          index: true,
          element: <DownloadWithKey />,
        },
      ],
    },
    {
      path: 'slack',
      element: inAppContainer(<AppShell />),
      children: [
        {
          path: 'connect',
          element: <SlackConnect />,
        },
      ],
    },
    {
      path: 'docs',
      children: [
        {
          path: 'guide',
          element: <ProductGuide />,
        },
        {
          path: 'api',
          element: <ApiDocumentation />,
        },
      ],
    },
    {
      path: 'pdf',
      children: [
        {
          path: 'dashboard-report/',
          element: <Dashboard isPdf />,
        },
        {
          path: 'address-report/:addressId',
          element: <AddressReport />,
        },
        {
          path: 'transaction-report/:transactionId',
          element: <TransactionReport />,
        },
        {
          path: 'customer-report/:customerId',
          element: <CustomerReport />,
        },
        {
          path: 'case-report/:caseId',
          element: <CaseReport />,
        },
      ],
    },
    {
      path: '*',
      element: inAppContainer(<ErrorPage message='This page could not be found' />),
    },
  ]);

  return routes;
};

export default AppRoutes;
