import { CheckCircle } from '@phosphor-icons/react';
import { FC } from 'react';
import BasicBadge from '../BasicBadge/BasicBadge';
import classNames from 'classnames';

interface StatusBadgeProps {
  isActive: boolean;
  className?: string;
}

const ScreeningStatusBadge: FC<StatusBadgeProps> = ({ isActive, className }) => {
  const badgeStyles = isActive ? 'bg-green-100 text-green-900' : 'bg-gray-100 text-gray-900';
  const badgeText = isActive ? 'Screened' : 'Unscreened';
  const iconColor = isActive ? 'text-green-500' : 'text-gray-500';

  return (
    <BasicBadge
      iconStart={<CheckCircle className={`mr-2 ${iconColor}`} size={12} weight='fill' />}
      className={classNames(badgeStyles, className)}
    >
      {badgeText}
    </BasicBadge>
  );
};

export default ScreeningStatusBadge;
