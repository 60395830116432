import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { policyApi } from '../../api/policies';
import { BasicModal, Button } from '../../ui';
import { Plus } from '@phosphor-icons/react';
import { useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import InputController from '../../ui/components/Form/InputController';
import TextareaController from '../../ui/components/Form/TextareaController';
import DropdownController from '../../ui/components/Form/DropdownController';
import CheckboxController from '../../ui/components/Form/CheckboxController';
import { useRouter } from '../../modules/router/RouterProvider';

const initialData = {
  name: '',
  description: '',
  type: { label: 'Transaction', value: 0 },
  category: { label: 'Source of Funds', value: 1 },
  is_enabled: true,
};

const AddPolicy = () => {
  const [modal, setModal] = useState(false);
  const { navigate } = useRouter();
  const queryClient = useQueryClient();

  const { mutate: createPolicy, isLoading } = useMutation(policyApi.createPolicy, {
    onSuccess: data => {
      toast.success('Policy created successfully');
      queryClient.invalidateQueries(['policyApi']);
      navigate(`/settings/workspace/policies/${data?.data?.id}`);
    },
    onError: () => {
      toast.error('Policy creation failed. Please try again later.');
    },
  });

  const validations = Yup.object({
    name: Yup.string().required('Required'),
    description: Yup.string().required('Required'),
    type: Yup.object().required('Required'),
    category: Yup.object().required('Required'),
    is_enabled: Yup.boolean().required('Required'),
  });

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: initialData,
    resolver: yupResolver(validations),
  });

  const type = useWatch({ control, name: 'type' });

  const isSubmitDisabled = () => {
    return isSubmitting || isLoading;
  };

  const onSubmit = (req: typeof initialData) => {
    createPolicy({
      name: req.name,
      description: req.description,
      type: req.type.value,
      category: req.category.value,
      is_enabled: req.is_enabled,
    });

    setModal(false);
  };

  const typeOptions = [
    { label: 'Address', value: 1 },
    { label: 'Transaction', value: 0 },
    { label: 'Customer', value: 2 },
  ];

  const ruleCategories = [
    {
      label: 'Source of Funds',
      value: 1,
    },
    {
      label: 'Behaviour',
      value: 2,
    },
    {
      label: 'Source of Funds Advanced',
      value: 3,
    },
  ];

  const categoryOptions =
    (type as { value: number }).value === 0 ? ruleCategories.splice(0, 1) : ruleCategories;

  return (
    <>
      <Button id='policy-add' onClick={() => setModal(true)} className='mr-1' iconStart={<Plus />}>
        Add policy
      </Button>
      <BasicModal
        modalTitle='Policy'
        modalBody={
          <div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <InputController
                control={control}
                name='name'
                labelText='Policy Name'
                placeholder='Enter policy name'
                className='mb-3'
                id={'name'}
                type={'text'}
              />
              <TextareaController
                control={control}
                name='description'
                labelText='Policy Description'
                placeholder='Enter description'
                className='mb-3'
                id={'description'}
                type={'text'}
              />
              <div className='flex'>
                <div className='mr-4 w-1/2'>
                  <DropdownController
                    control={control}
                    name='type'
                    labelText='Policy Type'
                    options={typeOptions}
                  />
                </div>
                <div className='w-1/2'>
                  <DropdownController
                    control={control}
                    name='category'
                    labelText='Policy Category'
                    options={categoryOptions}
                  />
                </div>
              </div>
              <CheckboxController
                control={control}
                name='is_enabled'
                labelText='Enable this Policy'
                className='my-3'
              />
              <div className='ml-6 text-sm text-gray-500'>
                If you deactivate this policy, rules within this policy will no longer be run on
                transaction or address searches
              </div>
            </form>
          </div>
        }
        modalFooter={
          <div className='flex justify-end'>
            <Button
              id='cancel'
              onClick={() => setModal(false)}
              className='mr-2'
              variant='tertiary'
              disabled={isSubmitting}
            >
              Cancel
            </Button>
            <Button
              id='policy-submit'
              onClick={() => handleSubmit(onSubmit)()}
              variant='primary'
              disabled={isSubmitDisabled()}
            >
              {isLoading ? 'Loading...' : 'Add Policy'}
            </Button>
          </div>
        }
        open={modal}
        onClose={() => setModal(false)}
      />
    </>
  );
};

export default AddPolicy;
