import { FC } from 'react';
import { BasicModal, CopyBtn } from '../../../ui';
import Table from '../../../ui/components/Table/Table';
import { useWebhookAuditTrailGet } from '../../../api/integration';
import {
  buildInfiniteQueryTableProps,
  flattenInfiniteQueryResult,
} from '../../../utils/helpers/react-query.helper';
import { formatDateTime } from '../../../utils/helpers/date';
import { useAuth } from '../../../modules/auth';

interface WebhookLogsProps {
  modal: boolean;
  setModal: (value: boolean) => void;
}

const WebhookLogs: FC<WebhookLogsProps> = ({ modal, setModal }) => {
  const webhookLogsQuery = useWebhookAuditTrailGet({ enabled: modal });

  const { state } = useAuth();
  const headerData = ['Webhook Endpoint', 'Entity Type', 'Response Code', 'Created'];
  const [, webhookLogs] = flattenInfiniteQueryResult(webhookLogsQuery?.data);
  const rows = webhookLogs?.map((w, i) => ({
    id: i,
    data: [
      <div className='flex' key={i}>
        {w.endpoint}
        <CopyBtn text={w.endpoint} />
      </div>,
      <a
        key={i}
        href={w.payload.object.url}
        className='text-blue-500'
        target='_blank'
        rel='noreferrer '
      >
        {w.payload.object.entity_type}
      </a>,
      w.response_code,
      formatDateTime(w.created_at, state.userProfile.timezone),
    ],
  }));
  return (
    <BasicModal
      open={modal}
      onClose={() => setModal(false)}
      modalTitle='Webhook Logs'
      customBodyClass=' '
      modalBody={
        <Table
          rows={rows}
          headerData={headerData}
          isHeaderVisible={false}
          headerWidths={[50, 13, 13, 23]}
          {...buildInfiniteQueryTableProps(webhookLogsQuery)}
        />
      }
    />
  );
};

export default WebhookLogs;
