import { BasicModal, Button, Input } from '../../ui';
import { getTypeApi } from '../../utils/helpers/apiHelpers';
import { startCase } from 'lodash';
import Tab from '../ui/components/Tab/Tab';
import { useEffect, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { caseApi } from '../../api/case';
import { toast } from 'react-toastify';
import isEmpty from 'lodash/isEmpty';
import classNames from 'classnames';
import { useAuth } from '../../modules/auth';
import CurrencyDropdown from '../ui/components/Select/CurrencyDropdown';
import { IOption } from '../Filters/CustomSelect';

interface ICaseEntitiesModalProps {
  caseId: number;
  isOpen: boolean;
  onClose: () => void;
  type?: 'address' | 'transaction' | 'customer';
}

const CaseAddEntitiesModal: React.FC<ICaseEntitiesModalProps> = props => {
  const { caseId, isOpen, onClose, type } = props;
  const { state } = useAuth();

  const queryClient = useQueryClient();

  interface IEntity {
    identifier: string;
    currency: IOption;
  }

  const defaultEntity = {
    identifier: '',
    currency: null,
  };

  const [tab, setTab] = useState(0);

  useEffect(() => {
    const defaultTabIndex = () => {
      if (type === 'address') return 1;
      if (type === 'transaction') return 2;
      return 0;
    };
    setTab(defaultTabIndex());
  }, [type]);

  const [customers, setCustomers] = useState<string[]>(['']);
  const [addresses, setAddresses] = useState<IEntity[]>([{ ...defaultEntity }]);
  const [transactions, setTransactions] = useState<IEntity[]>([{ ...defaultEntity }]);
  const [invalidCustomers, setInvalidCustomers] = useState<string[]>([]);
  const [invalidAddresses, setInvalidAddresses] = useState<boolean[]>([]);
  const [invalidTransactions, setInvalidTransactions] = useState<boolean[]>([]);

  const getErrorMessage = () => {
    if (tab === 0 && invalidCustomers.length) {
      return 'Customer ID is invalid';
    }
    if (tab === 1 && invalidAddresses.length) {
      return 'Address is invalid for the selected currency';
    }
    if (tab === 2 && invalidTransactions.length) {
      return 'Transaction hash is invalid for the selected currency';
    }
  };

  const onError = error => {
    if (error.response.data.customers) {
      setInvalidCustomers(error.response.data.customers?.map(c => c.customer_id));
    }
    if (error.response.data.addresses) {
      setInvalidAddresses(error.response.data.addresses.map(a => !isEmpty(a)));
    }
    if (error.response.data.transactions) {
      setInvalidTransactions(error.response.data.transactions.map(t => !isEmpty(t)));
    }
  };

  const onSuccess = () => {
    onClose();
    queryClient.invalidateQueries(['caseApi', caseId]);

    toast.success('Added Successfully');
    setCustomers(['']);
    setAddresses([{ ...defaultEntity }]);
    setTransactions([{ ...defaultEntity }]);

    setInvalidCustomers([]);
    setInvalidAddresses([]);
    setInvalidTransactions([]);
  };

  const { mutate: addCustomer } = useMutation(caseApi.addCustomer, {
    onError,
    onSuccess,
  });

  const { mutate: addAddress } = useMutation(caseApi.addAddress, {
    onError,
    onSuccess,
  });

  const { mutate: addTransaction } = useMutation(caseApi.addTransaction, {
    onError,
    onSuccess,
  });

  const tabs = [
    {
      label: 'Customers',
      value: 'customers',
      hidden: !state?.userProfile?.can_access_customers,
    },
    {
      label: 'Addresses',
      value: 'addresses',
    },
    {
      label: 'Transactions',
      value: 'transactions',
    },
  ];

  const onChangeCustomer = (e, i) => {
    const input = (e.target as HTMLInputElement).value;

    setCustomers(prev => {
      prev[i] = input;
      return [...prev, ...(i === customers.length - 1 ? [''] : [])];
    });
  };

  const onChangeAddressCurrency = (value, i) => {
    setAddresses(prev => {
      prev[i].currency = value;
      return [...prev];
    });
  };

  const onChangeAddressIdentifier = (e, i) => {
    const input = (e.target as HTMLInputElement).value;

    setAddresses(prev => {
      prev[i].identifier = input;
      return [...prev, ...(i === addresses.length - 1 ? [{ ...defaultEntity }] : [])];
    });
  };

  const onChangeTransactionCurrency = (value, i) => {
    setTransactions(prev => {
      prev[i].currency = value;
      return [...prev];
    });
  };

  const onChangeTransactionIdentifier = (e, i) => {
    const input = (e.target as HTMLInputElement).value;

    setTransactions(prev => {
      prev[i].identifier = input;
      return [...prev, ...(i === transactions.length - 1 ? [{ ...defaultEntity }] : [])];
    });
  };

  const tabType = tabs[tab].label;

  const onConfirm = () => {
    if (tab === 0) {
      if (state?.userProfile?.can_access_customers) {
        addCustomer({
          case_id: caseId,
          customers: customers.filter(c => c?.length).map(c => ({ customer_id: c })),
        });
      }
    }
    if (tab === 1) {
      addAddress({
        case_id: caseId,
        addresses: addresses
          .filter(a => a.identifier?.length)
          .map(a => ({ identifier: a.identifier, currency: Number(a.currency?.value || 0) })),
      });
    }
    if (tab === 2) {
      addTransaction({
        case_id: caseId,
        transactions: transactions
          .filter(t => t.identifier?.length)
          .map(t => ({ identifier: t.identifier, currency: Number(t.currency?.value || 0) })),
      });
    }
  };

  const modalTitle = `Add ${type ? startCase(getTypeApi(type)) : 'Entities'} to case`;

  const handleClose = () => {
    setCustomers(['']);
    setAddresses([{ ...defaultEntity }]);
    setTransactions([{ ...defaultEntity }]);

    setInvalidCustomers([]);
    setInvalidAddresses([]);
    setInvalidTransactions([]);

    onClose();
  };

  return (
    <div>
      <BasicModal
        open={isOpen}
        onClose={handleClose}
        modalTitle={modalTitle}
        modalBody={
          <div>
            <Tab
              defaultIndex={tab}
              values={tabs}
              onChange={tab => setTab(tab)}
              type={'tertiary'}
              className={classNames(type ? 'hidden' : '', 'bg-white')}
              listClassName={classNames('text-sm -mx-4 px-4', {
                'border-b': !type,
              })}
              panelClassName='py-4'
            >
              <div>
                {customers.map((customer, i) => (
                  <Input
                    error={invalidCustomers.includes(customer)}
                    key={i}
                    id={'customer-id'}
                    className='mb-2'
                    type={'text'}
                    value={customer}
                    placeholder={'Enter Customer ID' + customers[i]}
                    onChange={e => onChangeCustomer(e, i)}
                  />
                ))}
              </div>
              <div>
                {addresses.map((address, i) => (
                  <div
                    key={i}
                    className={classNames('mb-2 flex rounded-md border p-0.5', {
                      'border-red-400': invalidAddresses[i],
                    })}
                  >
                    <CurrencyDropdown
                      currency={address.currency}
                      setCurrency={value => onChangeAddressCurrency(value, i)}
                    />

                    <Input
                      id={'identifier'}
                      error={invalidAddresses[i]}
                      type={'text'}
                      name={'identifier'}
                      className='my-auto ml-1 w-full'
                      inputClassNames='border-0'
                      placeholder='Enter Address'
                      value={address.identifier}
                      onChange={e => onChangeAddressIdentifier(e, i)}
                    />
                  </div>
                ))}
              </div>
              <div>
                {transactions.map((transaction, i) => (
                  <div
                    key={i}
                    className={classNames('mb-2 flex rounded-md border p-0.5', {
                      'border-red-400': invalidTransactions[i],
                    })}
                  >
                    <CurrencyDropdown
                      currency={transaction.currency}
                      setCurrency={value => onChangeTransactionCurrency(value, i)}
                    />
                    <Input
                      id={'identifier'}
                      error={invalidTransactions[i]}
                      type={'text'}
                      name={'identifier'}
                      className='my-auto ml-1 w-full'
                      inputClassNames='border-0'
                      placeholder='Enter Transaction Hash'
                      value={transaction.identifier}
                      onChange={e => onChangeTransactionIdentifier(e, i)}
                    />
                  </div>
                ))}
              </div>
            </Tab>
            <div className='mt-4 flex justify-between'>
              <div className='text-xs text-red-600'>{getErrorMessage()}</div>
              <div>
                <Button variant='tertiary' onClick={onClose} className='mr-2'>
                  Cancel
                </Button>
                <Button onClick={onConfirm} variant='primary'>
                  Add {tabType}
                </Button>
              </div>
            </div>
          </div>
        }
      />
    </div>
  );
};

export default CaseAddEntitiesModal;
