import classNames from 'classnames';
import { Children, cloneElement, FC, HTMLProps, ReactElement, ReactNode } from 'react';

export type RadioButtonsProps = Pick<
  HTMLProps<HTMLInputElement>,
  'onChange' | 'disabled' | 'onClick'
> & {
  selected?: string | number;
  className?: string;
  name: string;
  children?: ReactNode | ReactNode[];
};

interface RadioButtonProps {
  index?: number;
  name?: string;
  value?: string | number;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  onClick?: (e: React.MouseEvent<HTMLInputElement>) => void;
  selected?: boolean;
}

export const RadioButtons: FC<RadioButtonsProps> = props => {
  const { className, name, onChange, disabled, children, selected, onClick } = props;
  const arrayChildren = Children.toArray(children);

  return (
    <div
      id='radio-buttons'
      data-testid='radio-buttons'
      className={classNames('flex gap-4', className)}
    >
      {Children.map(arrayChildren, (child: ReactElement<RadioButtonProps>, index: number) => {
        return cloneElement(child, {
          index,
          name,
          onChange,
          disabled,
          onClick,
          selected: selected == null ? false : child.props.value == selected,
        });
      })}
    </div>
  );
};

export default RadioButtons;
