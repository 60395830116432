import { FC } from 'react';
import { Link } from 'react-router-dom';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ThemeProps {}

const Theme: FC<ThemeProps> = () => {
  return (
    <div className='flex h-full items-center justify-center'>
      <div className='flex flex-col space-y-6'>
        <p className='text-6xl font-mono font-extrabold text-blue-500'>Hello World</p>
        <div>
          <Link
            to='/login'
            className='flex items-center justify-center rounded-md border border-transparent bg-indigo-600 p-4 text-base font-medium text-white hover:bg-indigo-700 md:px-10 md:py-4 md:text-lg'
          >
            Go to login
          </Link>
        </div>
        <div>
          <Link
            to='/settings'
            className='flex items-center justify-center rounded-md border border-transparent bg-indigo-600 p-4 text-base font-medium text-white hover:bg-indigo-700 md:px-10 md:py-4 md:text-lg'
          >
            Go to settings
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Theme;
