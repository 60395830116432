import CustomSelect, { IOption } from './CustomSelect';

import { FC, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { classifierApi } from '../../api/classifier';
import { uniq } from 'lodash';
import SingleSelect from '../ui/components/Select/SingleSelect';

interface RuleNameFilterProps {
  value: string[];
  setValue: (rules: string[]) => void;
  type: 'address' | 'transaction' | 'customer' | 'all';
  isSingle?: boolean;
}

const RuleNameFilter: FC<RuleNameFilterProps> = props => {
  const { value, setValue, type, isSingle } = props;
  const [ruleNames, setRuleNames] = useState<IOption[]>([]);
  const rule_type = type === 'all' ? null : type === 'transaction' ? 0 : 1;
  const { data: ruleNamesData, isLoading } = useQuery(['ruleName', type], () =>
    classifierApi.getClassifiers({ rule_type, limit: 500 })
  );
  useEffect(() => {
    if (ruleNamesData) {
      const ruleNamesList = uniq(ruleNamesData.data.results.map(r => r.rule_name));
      setRuleNames(
        ruleNamesList.map(r => {
          return {
            value: r,
            label: r,
          };
        })
      );
    }
  }, [ruleNamesData]);

  let selectedRules = [];
  if (value && value.length) {
    selectedRules = value.map(r => {
      return {
        value: r,
        label: r,
      };
    });
  }
  const [rules, setRules] = useState<IOption[]>(selectedRules);

  const handleChange = (rules: IOption[]) => {
    setValue(rules.map(r => r.value));
    setRules(rules);
  };

  return isSingle ? (
    <SingleSelect
      label='Rule Name'
      placeholder='Select Rule Name'
      options={ruleNames}
      isLoading={isLoading}
      value={rules[0]}
      handleChange={rule => handleChange(rule ? [rule] : [])}
      isSearchable
    />
  ) : (
    <CustomSelect
      label='Rule Name'
      placeholder='Select Rule Name'
      options={ruleNames}
      handleChange={handleChange}
      isLoading={isLoading}
      value={rules}
    />
  );
};

export default RuleNameFilter;
