/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import { useLocalStorage } from 'react-use';
import {
  CheckCircle,
  DotsThreeOutlineVertical,
  Download,
  Plus,
  FolderPlus,
} from '@phosphor-icons/react';
import { useRouter } from '../../../modules/router/RouterProvider';
import { useAuth } from '../../../modules/auth';

import { Button, DropdownOption } from '../../../ui';
import { toast } from '../../../ui/components/Toast';
import Table, { ITableRow } from '../../../ui/components/Table/Table';
import AddToCaseModal from '../../../components/common/AddToCaseModal';
import AddCustomerModal from '../../../components/Customer/CustomerList/AddCustomerModal';
import CustomerListExportModal from '../../../components/Customer/CustomerList/CustomerListExportModal';
import CustomerListFilter, {
  IFilters,
  defaultCustomerListFilters,
} from '../../../components/Customer/CustomerList/CustomerListFilter';
import CustomerListAppliedFilter from '../../../components/Customer/CustomerList/CustomerListAppliedFilter';
import SimpleDropdown from '../../../components/ui/components/Dropdown/SimpleDropdown';

import {
  buildInfiniteQueryTableProps,
  flattenInfiniteQueryResult,
} from '../../../utils/helpers/react-query.helper';
import { customerApi, useCustomerGetCustomerList } from '../../../api/customer';

import { useWorkspace } from '../../../utils/helpers/common';
import { useGetCustomerData } from './CustomerData';
import { isEqual } from 'lodash';
import ResolveMultipleAlertsModal from '../../../components/common/ResolveMultipleAlertsModal';

const CustomerList: React.FC = () => {
  const { state } = useAuth();
  const { getQueryParams, navigate } = useRouter();
  const {
    deposit_address,
    risk_level,
    currencies,
    created_start_date,
    created_end_date,
    start_date,
    end_date,
    is_included_in_case,
    rule_name,
  } = getQueryParams();
  const workspace = useWorkspace();
  const [filterStorage, setFilterStorage] = useLocalStorage(
    `compass.storage.customerFilters.${workspace.slug}`,
    null
  );
  const [filters, setFilters] = React.useState<IFilters>({
    deposit_address: deposit_address && deposit_address !== 'null' ? deposit_address : null,
    risk_level: risk_level ? risk_level.split(',').map(Number) : [],
    currencies: currencies ? currencies.split(',').map(Number) : [],
    created_start_date:
      created_start_date && created_start_date !== 'null' ? created_start_date : null,
    created_end_date: created_end_date && created_end_date !== 'null' ? created_end_date : null,
    start_date: start_date && start_date !== 'null' ? start_date : null,
    end_date: end_date && end_date !== 'null' ? end_date : null,
    is_included_in_case:
      is_included_in_case === null || is_included_in_case === undefined
        ? null
        : Boolean(is_included_in_case),
    rule_name: rule_name ? rule_name.split(',') : [],
  });
  const [isAllCustomerSelected, setIsAllCustomerSelected] = React.useState(false);
  const [selectedCustomers, setSelectedCustomers] = React.useState<number[]>([]);
  const [isExportCustomersModalOpen, setIsExportCustomersModalOpen] = React.useState(false);
  const [isAddCustomersModalOpen, setIsAddCustomersModalOpen] = React.useState(false);
  const [isAddToCaseModalOpen, setIsAddToCaseModalOpen] = React.useState(false);
  const [openAlertsModal, setAlertsModal] = React.useState(false);
  const selectedDropdownOptions = [
    {
      label: 'Update Open Alerts',
      value: 'alerts',
      iconStart: <CheckCircle size={17} />,
    },
    {
      label: 'Add to Case',
      value: 'case',
      iconStart: <FolderPlus size={17} />,
    },
  ];

  const customerQueryAll = useCustomerGetCustomerList({ offset: 0, filters });

  const [allCustomersCount, allCustomersData] = flattenInfiniteQueryResult(customerQueryAll?.data);
  const isLoading = customerQueryAll.isLoading;

  React.useEffect(() => {
    if (!customerQueryAll.isLoading) {
      const localStorageFilters = filterStorage
        ? formatLocalStorageValue(filterStorage)
        : defaultCustomerListFilters;
      setFilters(localStorageFilters);
      onChange(localStorageFilters);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerQueryAll.isLoading]);

  React.useEffect(() => {
    const localStorageFilters = filterStorage
      ? formatLocalStorageValue(filterStorage)
      : defaultCustomerListFilters;

    workspace.workspaces.forEach(w => {
      if (!localStorage.getItem(`compass.storage.customerFilters.${w.slug}`)) {
        localStorage.setItem(
          `compass.storage.customerFilters.${w.slug}`,
          JSON.stringify(defaultCustomerListFilters)
        );
      }
    });

    if (isEqual(filters, defaultCustomerListFilters)) {
      setFilters(localStorageFilters);
      syncFilters(localStorageFilters);
    } else {
      setFilterStorage(filters);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (state?.userProfile.email) {
      const newSearchParams = new URLSearchParams();
      if (filterStorage) {
        setFilters(filterStorage);
        Object.keys(filterStorage).forEach(key => {
          if (
            filterStorage[key] !== null &&
            (!Array.isArray(filterStorage[key]) || filterStorage[key].length !== 0)
          ) {
            newSearchParams.set(key, filterStorage[key].toString());
          }
        });
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterStorage]);

  const syncFilters = (newFilters: IFilters) => {
    if (state?.userProfile.email) {
      const newSearchParams = new URLSearchParams();
      Object.keys(newFilters).forEach(key => {
        if (
          newFilters[key] !== null &&
          (!Array.isArray(newFilters[key]) || newFilters[key].length !== 0)
        ) {
          newSearchParams.set(key, newFilters[key]?.toString());
        }
      });
      setFilterStorage(newFilters);
      navigate('/customers', Object.fromEntries(newSearchParams));
    }
  };

  const formatLocalStorageValue = (unformattedFilter: IFilters): IFilters => {
    const val = {};
    Object.keys(unformattedFilter).forEach(key => {
      if (key === 'workflow_stage') val[key] = undefined;
      else if (unformattedFilter[key] === 'null') {
        val[key] = null;
      } else {
        val[key] = unformattedFilter[key];
      }
    });
    return val as IFilters;
  };

  const handleApply = (newFilters: IFilters) => {
    setFilters(newFilters);
    syncFilters(newFilters);
  };

  const onReset = () => {
    setFilters(defaultCustomerListFilters);
    syncFilters(defaultCustomerListFilters);
  };

  const selectCustomer = id => {
    if (selectedCustomers?.includes(id)) {
      setSelectedCustomers(selectedCustomers.filter(i => i !== id));
    } else {
      setSelectedCustomers([...selectedCustomers, id]);
    }
  };

  const selectAllCustomers = (force = false) => {
    if (force) {
      setSelectedCustomers(allCustomersData.map(customer => customer.id));
    } else {
      if (selectedCustomers.length === allCustomersData.length) {
        setSelectedCustomers([]);
        setIsAllCustomerSelected(false);
      } else {
        setSelectedCustomers(allCustomersData.map(customer => customer.id));
      }
    }
  };

  const downloadSelectedCustomers = async () => {
    if (isAllCustomerSelected) {
      const res = await customerApi.exportCustomerList({
        ids: isAllCustomerSelected ? [] : selectedCustomers,
        filters,
      });
      toast.success(res.data.message);
    } else {
      const res = await customerApi.exportCustomerList({
        ids: selectedCustomers,
      });
      toast.success(res.data.message);
    }
  };

  const onChange = (newFilters?: IFilters) => {
    setSelectedCustomers([]);
    setIsAllCustomerSelected(false);
    if (newFilters) {
      syncFilters(newFilters);
    } else {
      syncFilters(filters);
    }
  };

  const onChangeSelectedDropdown = (value: DropdownOption) => {
    if (value.value === 'alerts') {
      setAlertsModal(true);
    } else if (value.value === 'case') {
      setIsAddToCaseModalOpen(true);
    }
  };

  const customerData = useGetCustomerData({
    selectAllCustomers,
    selectedCustomers,
    customers: allCustomersData,
    selectCustomer,
  });

  const headerData: (string | JSX.Element)[] = customerData.headerData;
  const rows: ITableRow[] = customerData.rowData;

  return (
    <>
      <Table
        title='Customers'
        headerData={headerData}
        rows={rows}
        count={`Showing ${rows?.length} of ${allCustomersCount ?? 0} results`}
        isLoading={isLoading}
        filterComponent={
          <>
            {selectedCustomers.length > 0 && (
              <SimpleDropdown
                id='customerList-menu'
                value={null}
                onChange={onChangeSelectedDropdown}
                placeholder={
                  <Button variant='tertiary' className='my-1 px-3'>
                    <DotsThreeOutlineVertical size={16} weight='fill' />
                  </Button>
                }
                options={selectedDropdownOptions}
              />
            )}
            {selectedCustomers.length === 0 && (
              <Button
                id='customerList-export'
                variant='tertiary'
                iconStart={<Download size={17} />}
                onClick={() => setIsExportCustomersModalOpen(true)}
              >
                Export
              </Button>
            )}
            <CustomerListFilter
              filters={filters}
              onApply={handleApply}
              onReset={onReset}
              disabled={selectedCustomers.length > 0}
            />
            <Button
              id='customerList-addCustomer'
              iconStart={<Plus size={17} />}
              onClick={() => setIsAddCustomersModalOpen(true)}
              disabled={selectedCustomers.length > 0}
            >
              Add Customer
            </Button>
          </>
        }
        appliedFilters={
          <CustomerListAppliedFilter
            count={allCustomersCount}
            filters={filters}
            setFilters={handleApply}
            selectedCustomers={selectedCustomers}
            setSelectedCustomers={setSelectedCustomers}
            isAllCustomerSelected={isAllCustomerSelected}
            setIsAllCustomerSelected={setIsAllCustomerSelected}
            selectAllCustomers={selectAllCustomers}
            downloadSelectedCustomers={downloadSelectedCustomers}
            selectedText={
              isAllCustomerSelected
                ? `${allCustomersCount} customers selected.`
                : selectedCustomers.length === allCustomersData.length
                  ? `All ${selectedCustomers.length} customers in this page are selected.`
                  : `${selectedCustomers.length} ${
                      selectedCustomers.length > 1 ? 'customers' : 'customer'
                    } selected.`
            }
          />
        }
        heightOffset={25.5}
        isSelectable
        {...buildInfiniteQueryTableProps(customerQueryAll)}
      />

      <CustomerListExportModal
        isOpen={isExportCustomersModalOpen}
        onClose={() => setIsExportCustomersModalOpen(false)}
        downloadSelectedCustomers={downloadSelectedCustomers}
      />
      <ResolveMultipleAlertsModal
        type='customer'
        isOpen={openAlertsModal}
        isAllEntitiesSelected={isAllCustomerSelected}
        selectedEntities={selectedCustomers}
        refetchList={customerQueryAll.refetch}
        onClose={() => {
          setAlertsModal(false);
        }}
        selectedTab={3}
        filters={filters}
        setSelectedEntities={setSelectedCustomers}
        setIsAllEntitiesSelected={setIsAllCustomerSelected}
      />
      <AddCustomerModal
        isOpen={isAddCustomersModalOpen}
        onClose={() => setIsAddCustomersModalOpen(false)}
      />
      <AddToCaseModal
        type='customer'
        isOpen={isAddToCaseModalOpen}
        onClose={() => setIsAddToCaseModalOpen(false)}
        entities={selectedCustomers.map(customer => {
          let data;
          allCustomersData.forEach(add => {
            if (add.id === customer) {
              data = {
                customer_id: add.customer_id,
              };
            }
          });
          return data;
        })}
        isAllEntitiesSelected={isAllCustomerSelected}
        selectedTab={3}
        filters={filters}
      />
    </>
  );
};

export default CustomerList;
