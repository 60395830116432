import React from 'react';
import TabComponent from './TabComponent';
import { useAuth } from '../../modules/auth';

interface Props {
  isSearchPage?: boolean;
  addressesCount?: number;
  transactionsCount?: number;
  casesCount?: number;
  customersCount?: number;
  sanctionedAddressesCount?: number;
  sanctionedTransactionsCount?: number;
}
const DashboardTabs: React.FC<Props> = props => {
  const { state } = useAuth();
  const { isSearchPage = false } = props;
  return (
    <div className={`h-[54.6px] w-full ${state?.isTabVisible ? '' : 'hidden'}`}>
      <div className='flex flex-row justify-center bg-blue-50'>
        <div className='flex'>
          {!props.isSearchPage && <TabComponent type='dashboard' />}
          {!props.isSearchPage && <TabComponent type='alerts' isSearchPage={isSearchPage} />}
          {(!props.isSearchPage ||
            props.addressesCount > 0 ||
            props.sanctionedAddressesCount > 0) && (
            <TabComponent
              type='addresses'
              count={props.addressesCount}
              isSearchPage={isSearchPage}
            />
          )}
          {(!props.isSearchPage ||
            props.transactionsCount > 0 ||
            props.sanctionedTransactionsCount > 0) && (
            <TabComponent
              type='transactions'
              count={props.transactionsCount}
              isSearchPage={isSearchPage}
            />
          )}
          {(!props.isSearchPage || props.customersCount > 0) && (
            <TabComponent
              type='customers'
              count={props.customersCount}
              isSearchPage={isSearchPage}
            />
          )}
          {(!props.isSearchPage || props.casesCount > 0) && (
            <TabComponent type='cases' count={props.casesCount} isSearchPage={isSearchPage} />
          )}
        </div>
      </div>
    </div>
  );
};

export default DashboardTabs;
