import { FC, ReactNode } from 'react';

export interface BasicBadgeProps {
  id?: string;
  className?: string;
  iconStart?: ReactNode;
  iconEnd?: ReactNode;
  onClickIcon?: () => void;
  children: string | ReactNode;
}

const BasicBadge: FC<BasicBadgeProps> = props => {
  const { iconStart, iconEnd, className = '', children, id } = props;
  let defaultClassName = 'inline-flex content-center items-center text-xs rounded-xl font-medium';
  if (className.indexOf('px') < 0) {
    defaultClassName = defaultClassName + ' px-2.5';
  }
  if (className.indexOf('py') < 0) {
    defaultClassName = defaultClassName + ' py-0.5';
  }
  return (
    <span id={id} data-testid={id} className={`${defaultClassName} ${className}`}>
      {iconStart}
      {children}
      {iconEnd}
    </span>
  );
};

export default BasicBadge;
