import { FC, ReactNode } from 'react';
import BasicBadge from '../BasicBadge/BasicBadge';

export interface UserBadgeProps {
  className?: string;
  label?: string;
  iconStart?: ReactNode;
}

const UserBadge: FC<UserBadgeProps> = props => {
  return (
    <BasicBadge
      id={'user-badge'}
      iconStart={props.iconStart}
      className={'gap-1' + ' ' + props.className + ' ' + props.className}
    >
      {props.label}
    </BasicBadge>
  );
};

export default UserBadge;
