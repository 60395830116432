export function getPercentageValue(
  total: number,
  previous: number
): { percent: string; value: number } {
  const change = total - previous;
  const result = (change * 100) / previous || 0;

  return {
    percent: isFinite(result) ? Number(result.toFixed(2)).toFixed(2) + '%' : 'NA',
    value: result,
  };
}
