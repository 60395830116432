import { FC, useEffect } from 'react';
import { IUserProfileUpdateRequest } from '../../../api/dtos/profile';
import timezones from '../../../ui/utils/timezones';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import { profileApi, useUserProfileGet } from '../../../api/profile';
import { toast } from '../../../ui/components/Toast';
import { AxiosError } from 'axios';
import { IErrorResponse } from '../../../api/dtos/common';
import { Button, DropdownOption } from '@ui/index';
import InputController from '../../../ui/components/Form/InputController';
import DropdownController from '../../../ui/components/Form/DropdownController';
import Skeleton from 'react-loading-skeleton';

const initialData: Omit<IUserProfileUpdateRequest, 'timezone'> & { timezone?: DropdownOption } = {
  first_name: '',
  last_name: '',
  language: '',
};

interface UserProfileFormProps {}

const validations = Yup.object({
  first_name: Yup.string().label('First name').required().max(100),
  last_name: Yup.string().label('Last name').required().max(100),
  timezone: Yup.mixed().label('Timezone').required(),
  language: Yup.string().label('Language'),
});

const UserProfileForm: FC<UserProfileFormProps> = () => {
  const queryClient = useQueryClient();
  const { data: response, isLoading, key: qKey } = useUserProfileGet();

  const {
    control,
    handleSubmit,
    setValue,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: initialData,
    resolver: yupResolver(validations),
  });

  useEffect(() => {
    if (response?.data?.first_name == null) {
      return;
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    setValue('first_name', response.data.first_name);
    setValue('last_name', response.data.last_name);
    setValue(
      'timezone',
      timezones.find(t => t.value === response.data.timezone)
    );
    setValue('language', response.data.language);
  }, [setValue, response]);

  const { mutate } = useMutation(profileApi.update, {
    // Always refetch after error or success:
    onSettled: (_data, error) => {
      if (error) {
        toast.error('Error updating profile');
      } else {
        toast.success('Profile updated successfully');
      }

      queryClient.invalidateQueries(qKey);
    },
    onError: (error: AxiosError<IErrorResponse>) => {
      toast.error(error.response.data.detail);
    },
  });

  const onSubmit = (data: typeof initialData) => {
    mutate({
      ...data,
      timezone: data.timezone?.value as string,
    });
  };

  return (
    // TODO Here we can have loader or skeleton
    <>
      {isLoading && (
        <div className='w-1/2'>
          {' '}
          <Skeleton count={2} height={15} />
        </div>
      )}
      {!isLoading && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className='mb-4 grid grid-cols-4 gap-8'>
            <InputController
              id='firstName'
              type='text'
              name='first_name'
              labelText='First Name'
              placeholder='First Name'
              control={control}
            />

            <InputController
              id='lastName'
              name='last_name'
              type='text'
              labelText='Last Name'
              placeholder='Last Name'
              control={control}
            />
          </div>
          <div className='mb-4 grid grid-cols-2 gap-8'>
            <DropdownController
              name='timezone'
              labelText='Timezone'
              options={timezones}
              customClass={'cursor-pointer pr-10 pl-3'}
              optionsCustomClass={'pl-8 cursor-pointer'}
              control={control}
            />
          </div>
          <Button type='submit' disabled={isSubmitting || isLoading}>
            Save Changes
          </Button>
        </form>
      )}
    </>
  );
};

export default UserProfileForm;
