import { FC } from 'react';
import BasicBadge from '../../../../ui/components/Badges/BasicBadge/BasicBadge';
import { RiskLevel } from '../../../../utils/constants/constants';
import { Warning, Info, DotsThreeOutline } from '@phosphor-icons/react';
import bars1 from '@/assets/icons/bars-1.svg';
import bars2 from '@/assets/icons/bars-2.svg';
import bars3 from '@/assets/icons/bars-3.svg';

export interface RiskBadgeProps {
  risk: number;
  count?: number;
  className?: string;
  label?: string;
  noLabel?: boolean;
  grayScale?: boolean;
}

const riskIcons = {
  0: <DotsThreeOutline size={16} weight='fill' />,
  1: <Info size={16} weight='fill' className='text-amber-400' />,
  2: <img src={bars1} alt='risk' width={16} />,
  3: <img src={bars2} alt='risk' width={16} />,
  4: <img src={bars3} alt='risk' width={16} />,
  5: <Warning size={16} weight='fill' />,
};

const RiskBadge: FC<RiskBadgeProps> = ({
  risk,
  count,
  className,
  label,
  noLabel,
  grayScale = false,
}) => {
  const currentRisk = RiskLevel.find(item => item.value === risk);
  if (!currentRisk) return null;
  return (
    <BasicBadge
      className={`${currentRisk.bgColor} ${currentRisk.textColor} ${grayScale ? 'grayscale' : ''} ${className} px-2 py-px text-2xs leading-4`}
      iconStart={riskIcons[risk]}
    >
      {!noLabel && (
        <div className='mx-1' id={'risk-badge'} data-testid='risk-badge'>
          {label || currentRisk.label}
          {!!count && count > 1 && <span className={'rounded-xl py-0.5'}> x{count} </span>}
        </div>
      )}
    </BasicBadge>
  );
};

export default RiskBadge;
