import { UseQueryOptions, useQuery } from 'react-query';
import { Api } from '../api';
import {
  IUserMonitoringPreferenceResponse,
  IUserMonitoringPreferenceContinuousMonitoringResponse,
  IUserMonitoringPreferenceContinuousMonitoringUpdateResponse,
  IContinuousMonitoringUpdateRequest,
} from '../dtos/settings/monitoringPreferences';

export class MonitoringPreferencesApi extends Api {
  readonly baseUrl = '/profile/api/v1/team-members/me/';
  readonly getMonitoringPreference = () => {
    return this.http.get<IUserMonitoringPreferenceResponse>(
      this.route('autoresolve-alert-levels/')
    );
  };
  readonly getContinuousMonitoringSettings = () => {
    return this.http.get<IUserMonitoringPreferenceContinuousMonitoringResponse>(
      this.route('continuous-monitoring-settings/')
    );
  };
  readonly updateContinuousMonitoringSettings = (request: IContinuousMonitoringUpdateRequest) => {
    return this.http.put<IUserMonitoringPreferenceContinuousMonitoringUpdateResponse>(
      this.route('continuous-monitoring-settings/'),
      request
    );
  };
  readonly updateAutoResolveAlerts = (request: { levels: number[] }) => {
    return this.http.put<IUserMonitoringPreferenceResponse>(
      this.route('autoresolve-alert-levels/'),
      request
    );
  };
}

export const monitoringPreferencesApi = new MonitoringPreferencesApi();

export const useUserContinuousMonitoringLevelGet = (options?: UseQueryOptions) => {
  const key = 'ProfileApi.getContinuousMonitoring';
  const result = useQuery(key, monitoringPreferencesApi.getContinuousMonitoringSettings, {
    ...options,
  } as unknown);

  return {
    key,
    ...result,
  };
};

export const useUserMonitoringPreferenceGet = (options?: UseQueryOptions) => {
  const key = 'ProfileApi.getMonitoringPreference';
  const result = useQuery(key, monitoringPreferencesApi.getMonitoringPreference, {
    ...options,
  } as unknown);

  return {
    key,
    ...result,
  };
};
