import { Controller, ControllerProps, FieldPath, FieldValues } from 'react-hook-form';
import { Toggle, ToggleProps } from '../Toggle';

type ToggleControllerProps<TFieldValues, TName extends FieldPath<TFieldValues>> = Omit<
  ToggleProps,
  'enabled' | 'setEnabled'
> &
  Omit<ControllerProps<TFieldValues, TName>, 'render'>;

const ToggleController = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  name,
  rules,
  shouldUnregister,
  defaultValue,
  control,
  ...props
}: ToggleControllerProps<TFieldValues, TName>) => {
  return (
    <Controller
      name={name}
      rules={rules}
      shouldUnregister={shouldUnregister}
      defaultValue={defaultValue}
      control={control}
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      render={({ field: { onChange, value } }) => {
        return <Toggle {...props} enabled={value} setEnabled={onChange} />;
      }}
    ></Controller>
  );
};

export default ToggleController;
