import { Controller, ControllerProps, FieldPath, FieldValues } from 'react-hook-form';
import { Input, InputProps } from '../Input';

type InputControllerProps<TFieldValues, TName extends FieldPath<TFieldValues>> = InputProps &
  Omit<ControllerProps<TFieldValues, TName>, 'render'>;

const InputController = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  name,
  rules,
  shouldUnregister,
  defaultValue,
  control,
  ...inputProps
}: InputControllerProps<TFieldValues, TName>) => {
  return (
    <Controller
      name={name}
      rules={rules}
      shouldUnregister={shouldUnregister}
      defaultValue={defaultValue}
      control={control}
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      render={({ field: { ref, ...field }, fieldState }) => {
        return <Input {...field} {...inputProps} error={fieldState?.error?.message} />;
      }}
    ></Controller>
  );
};

export default InputController;
