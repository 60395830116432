import { useQuery, UseQueryOptions } from 'react-query';
import { Api } from './api';
import { IPaginationQueryParams } from './dtos/common';
import {
  IUserApiKey,
  IUserApiKeysResponse,
  IUserApiKeyUpdateRequest,
  IUserApprovalFlowResponse,
  IUserApprovalFlowUpdateRequest,
  IUserAutoCreateCasesResponse,
  IUserAutoCreateCasesUpdateRequest,
  IUserCaseApproverAssignmentResponse,
  IUserCaseApproverAssignmentUpdateRequest,
  IUserCaseUsersResponse,
  IUserCaseAssignmentResponse,
  IUserCaseAssignmentUpdateRequest,
  IUserNotificationSubscriptionResponse,
  IUserNotificationSubscriptionUpdateRequest,
  IUserProfileResponse,
  IUserProfileUpdateRequest,
  IUserCaseUserRequest,
} from './dtos/profile';

export class ProfileApi extends Api {
  readonly baseUrl: string = '/profile/api/v1/';

  readonly get = () => {
    return this.http.get<IUserProfileResponse>(this.route('team-members/me/'));
  };

  readonly update = (request: IUserProfileUpdateRequest) => {
    return this.http.put<IUserProfileResponse>(this.route('team-members/me/'), request);
  };

  readonly notificationSubscriptions = () => {
    return this.http.get<IUserNotificationSubscriptionResponse>(
      this.route('team-members/me/notification-subscriptions/')
    );
  };

  readonly updateNotificationSubscriptions = (
    request: IUserNotificationSubscriptionUpdateRequest
  ) => {
    return this.http.put<IUserNotificationSubscriptionResponse>(
      this.route('team-members/me/notification-subscriptions/'),
      request
    );
  };

  readonly getApiKeys = (request: IPaginationQueryParams) => {
    const { offset = 0, page = 1, limit = 20 } = request;
    return this.http.get<IUserApiKeysResponse>(this.route('keys/'), {
      params: {
        offset,
        page,
        limit,
      },
    });
  };

  readonly generateApiKey = () => {
    return this.http.post<IUserApiKey>(this.route('keys/'));
  };

  readonly updateApiKey = (id: number, request: IUserApiKeyUpdateRequest) => {
    return this.http.put<IUserApiKey>(this.route(`keys/${id}/`), request);
  };

  readonly updateCaseAssignment = (request: IUserCaseAssignmentUpdateRequest) => {
    return this.http.put<IUserCaseAssignmentResponse>(
      this.route('case-settings/assignment-type/'),
      request
    );
  };
  readonly updateApprovalFlow = (request: IUserApprovalFlowUpdateRequest) => {
    return this.http.put<IUserApprovalFlowResponse>(
      this.route('case-settings/mandatory-approval/'),
      request
    );
  };
  readonly updateCaseApproverAssignment = (request: IUserCaseApproverAssignmentUpdateRequest) => {
    return this.http.put<IUserCaseApproverAssignmentResponse>(
      this.route('case-settings/assignment-type/'),
      request
    );
  };
  readonly updateAutoCreateCases = (request: IUserAutoCreateCasesUpdateRequest) => {
    return this.http.put<IUserAutoCreateCasesResponse>(
      this.route('team-members/me/autocreate-case-alert-levels/'),
      request
    );
  };
  readonly updateCaseApprovers = (request: { id: number; payload: { case_role: number } }) => {
    return this.http.put(this.route(`case-settings/users/${request.id}/`), request.payload);
  };

  readonly getCaseAssignment = () => {
    return this.http.get<IUserCaseAssignmentResponse>(this.route('case-settings/assignment-type/'));
  };
  readonly getApprovalFlow = () => {
    return this.http.get<IUserApprovalFlowResponse>(
      this.route('case-settings/mandatory-approval/')
    );
  };
  readonly getCaseApproverAssignment = () => {
    return this.http.get<IUserCaseApproverAssignmentResponse>(
      this.route('case-settings/assignment-type/')
    );
  };
  readonly getCaseUsers = (params?: IUserCaseUserRequest) => {
    return this.http.get<IUserCaseUsersResponse>(this.route('case-settings/users/'), {
      params,
    });
  };
  readonly getAutoCreateCases = () => {
    return this.http.get<IUserAutoCreateCasesResponse>(
      this.route('team-members/me/autocreate-case-alert-levels/')
    );
  };
}

export const profileApi = new ProfileApi();

export const useUserProfileGet = (options?: UseQueryOptions) => {
  const key = 'ProfileApi.get';
  const result = useQuery(key, profileApi.get, { ...options } as unknown);

  return {
    key,
    ...result,
  };
};

export const useUserNotificationSubscriptionsGet = (options?: UseQueryOptions) => {
  const key = 'ProfileApi.notificationSubscriptions';
  const result = useQuery(key, profileApi.notificationSubscriptions, { ...options } as unknown);

  return {
    key,
    ...result,
  };
};

export const useUserCaseAssignmentGet = (options?: UseQueryOptions) => {
  const key = 'ProfileApi.getCaseAssignment';
  const result = useQuery(key, profileApi.getCaseAssignment, { ...options } as unknown);

  return {
    key,
    ...result,
  };
};
export const useUserApprovalFlowGet = (options?: UseQueryOptions) => {
  const key = 'ProfileApi.getApprovalFlow';
  const result = useQuery(key, profileApi.getApprovalFlow, { ...options } as unknown);

  return {
    key,
    ...result,
  };
};
export const useUserCaseApproverAssignmentGet = (options?: UseQueryOptions) => {
  const key = 'ProfileApi.getCaseApproverAssignment';
  const result = useQuery(key, profileApi.getCaseApproverAssignment, { ...options } as unknown);

  return {
    key,
    ...result,
  };
};
export const useUserCaseUserGet = (params?: IUserCaseUserRequest, options?: UseQueryOptions) => {
  const key = ['ProfileApi.getCaseUsers', params?.case_role];
  const result = useQuery(
    key,
    () => profileApi.getCaseUsers({ case_role: params?.case_role, is_active: params?.is_active }),
    {
      ...options,
    } as unknown
  );

  return {
    key,
    ...result,
  };
};
export const useUserAutoCreateCasesGet = (options?: UseQueryOptions) => {
  const key = 'ProfileApi.getAutoCreateCases';
  const result = useQuery(key, profileApi.getAutoCreateCases, { ...options } as unknown);

  return {
    key,
    ...result,
  };
};
