import { FC } from 'react';
import { IAlert } from '../../../api/dtos/alert';
import AlertReport from './AlertReport';
import { IStageOptions } from '../../../components/common/Alerts/Alert/AlertButtonPanel/AlertButtonPanel';

interface IAlertReportProps {
  title: string;
  identifier: string;
  alerts: IAlert[];
  part?: number;
  type: 'address' | 'transaction' | 'customer';
  getCustomOptionLabel: (val: IStageOptions) => JSX.Element;
}

const AlertsReport: FC<IAlertReportProps> = props => {
  const { title, alerts, identifier, type, part, getCustomOptionLabel } = props;

  return (
    <div className='mb-4 rounded-b-sm'>
      <div className='rounded-t-sm border border-gray-200 bg-gray-200 px-3 py-2.5 text-xs font-bold'>
        Part {part}: {title}
        <div className='text-2xs font-normal text-gray-500'>
          Alerts triggered for the {type} {identifier}
        </div>
      </div>
      {alerts.map((alert, index) => (
        <AlertReport key={index} alert={alert} getCustomOptionLabel={getCustomOptionLabel} />
      ))}
    </div>
  );
};

export default AlertsReport;
