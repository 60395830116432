import { useRouter } from '../../modules/router/RouterProvider';
import CustomLink from '../ui/components/CustomLink';

interface TabComponent {
  type: string;
  count?: number;
  isSearchPage?: boolean;
}

const TabComponent: React.FC<TabComponent> = ({ type, count, isSearchPage = false }) => {
  const { getQueryParams } = useRouter();
  const { type: currentType, q, workspace } = getQueryParams();
  const params = isSearchPage
    ? {
        q,
        type,
        workspace,
      }
    : {};
  return (
    <div>
      <CustomLink
        to={!isSearchPage ? `/${type}` : '/search'}
        params={params}
        className={`${
          (isSearchPage ? type === currentType : location.pathname.split('/')[1] === type)
            ? 'border-b-2 border-blue-500 bg-blue-100 text-blue-800'
            : 'border-b-2 border-blue-50 text-gray-500 hover:text-blue-800'
        } flex items-center p-4 text-sm font-medium uppercase tracking-wide`}
      >
        <span data-testid={`${type}-tab`}>{type}</span>
        {isSearchPage && count !== 0 && (
          <span
            className={`${(isSearchPage ? type === currentType : location.pathname === `/${type}`) ? '' : 'bg-gray-200'} ml-1 rounded-2xl px-2 text-[12px] font-medium uppercase tracking-wide`}
          >
            {count}
          </span>
        )}
      </CustomLink>
    </div>
  );
};

export default TabComponent;
