import React, { useEffect, useState } from 'react';
import { Transition } from '@headlessui/react';
import { CaretRight } from '@phosphor-icons/react';
import classNames from 'classnames';

interface ExpandableProps {
  title: React.ReactNode;
  subtitle?: React.ReactNode;
  content: React.ReactNode;
  className?: string;
  isExpanded?: boolean;
}

const Expandable: React.FC<ExpandableProps> = ({
  title,
  subtitle,
  content,
  className,
  isExpanded: isExpandedProp,
}) => {
  const [isExpanded, setIsExpanded] = useState(!!isExpandedProp);

  useEffect(() => {
    setIsExpanded(!!isExpandedProp);
  }, [isExpandedProp]);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className={classNames('-z-10', className)}>
      <div onClick={toggleExpand} className='flex cursor-pointer items-center'>
        <CaretRight
          size={14}
          className={`${isExpanded ? 'rotate-90' : 'rotate-0'} mr-1 text-gray-500`}
          weight='bold'
        />
        {title}
      </div>
      <div className='ml-[18px] text-sm'>{subtitle}</div>
      <Transition
        as='div'
        show={isExpanded}
        enter='transition-all duration-300'
        enterFrom='opacity-0 max-h-0'
        enterTo='opacity-100 max-h-screen'
        leave='transition-all duration-300'
        leaveFrom='opacity-100 max-h-screen'
        leaveTo='opacity-0 max-h-0'
      >
        <div className='ml-5 mt-1'>{content}</div>
      </Transition>
    </div>
  );
};

export default Expandable;
