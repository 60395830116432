import { FC } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { BasicModal, Button } from '../../../ui';
import InputController from '../../../ui/components/Form/InputController';
import { getErrorMessage } from '../../../utils/helpers/helperFunctions';
import { AxiosError } from 'axios';
import { ICaseResponse } from '../../../api/dtos/case';
import { caseApi } from '../../../api/case';

interface EditCaseNameModalProps {
  caseData?: ICaseResponse;
  open: boolean;
  setOpen: (open: boolean) => void;
}

const EditCaseNameModal: FC<EditCaseNameModalProps> = ({ caseData, open, setOpen }) => {
  const queryClient = useQueryClient();

  const { mutate } = useMutation(caseApi.updateCase, {
    onSuccess: () => {
      queryClient.invalidateQueries(['caseApi', caseData.id]);
      toast.success('Case Name edited successfully.');
      setOpen(false);
    },
    onError: (error: AxiosError) => {
      toast.error(getErrorMessage(error));
    },
  });

  const validations = Yup.object({
    case_name: Yup.string().label('Case Name').required(),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validations),
    defaultValues: {
      case_name: '',
    },
  });

  const onClose = () => {
    setOpen(false);
  };

  const onConfirm = () => {
    handleSubmit((data: { case_name: string }) => {
      mutate({ caseId: caseData?.id, caseName: data.case_name });
    })();
  };

  const caseName = useWatch({ control, name: 'case_name' });

  return (
    <BasicModal
      open={open}
      onClose={onClose}
      modalTitle={'Edit Case Name'}
      modalBody={
        <InputController
          labelText='Case Name'
          control={control}
          name='case_name'
          label='Case Name'
          placeholder='Enter Case Name'
          error={errors.case_name?.message}
          id={'rename-case'}
          type={'text'}
        />
      }
      modalFooter={
        <div className='flex flex-row justify-end gap-4'>
          <Button variant='tertiary' onClick={onClose}>
            Cancel
          </Button>
          <Button variant='primary' onClick={onConfirm} disabled={!caseName?.length}>
            Save Name
          </Button>
        </div>
      }
    />
  );
};

export default EditCaseNameModal;
