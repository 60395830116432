import React from 'react';
import { formatDateTime } from '../../../utils/helpers/date';
import { File, Paperclip } from '@phosphor-icons/react';
import { downloadApi } from '../../../api/download';
import { useMutation } from 'react-query';
import saveBlobToFile from '../../../utils/helpers/saveBlobToFile';
import { toast } from '../../../ui/components/Toast';
import { useAuth } from '../../../modules/auth';
import styles from '../../common/AuditTrail/Comment.module.scss';
import classNames from 'classnames';
import { CommentResult } from '../../../api/dtos/comment';
import { ellipsis } from '../../../utils/helpers/helperFunctions';
import { useScreenApi } from '../../../utils/helpers/apiHelpers';
import { useRouter } from '../../../modules/router/RouterProvider';

interface CaseCommentProps {
  comment: CommentResult;
}

const CaseComment: React.FC<CaseCommentProps> = ({ comment }) => {
  const {
    state: {
      userProfile: { timezone },
    },
  } = useAuth();

  const { mutateAsync } = useMutation(downloadApi.downloadFile);

  const onClickFile = async (file: { file_key: string; file_name: string }) => {
    try {
      const result = await mutateAsync({ key: file.file_key });
      saveBlobToFile(result.data, file.file_name);
    } catch (error) {
      toast.error(error);
    }
  };

  const commentEntityType = () => {
    if (comment.context?.address) {
      return 'Address';
    }
    if (comment.context?.transaction) {
      return 'Transaction';
    }
    if (comment.context?.customer) {
      return 'Customer';
    }
    return '';
  };

  const commentsEntityId = () => {
    if (comment.context?.address) {
      return comment.context.address.identifier;
    }
    if (comment.context?.transaction) {
      return comment.context.transaction.identifier;
    }
    if (comment.context?.customer) {
      return comment.context.customer.customer_id;
    }
    return '';
  };
  const screenApi = useScreenApi();
  const { navigate } = useRouter();

  const onClickEntity = () => {
    if (commentEntityType() === 'Address') {
      screenApi({
        identifier: commentsEntityId(),
        currency: comment.context.address.currency,
        isCreateCaseDisabled: true,
        entityType: 'addresses',
        customer_id: comment.context.address.customer_id,
      });
    } else if (commentEntityType() === 'Transaction') {
      screenApi({
        identifier: commentsEntityId(),
        currency: comment.context.transaction.currency,
        isCreateCaseDisabled: true,
        entityType: 'transactions',
        type: comment.context.transaction.type,
        address: comment.context.transaction.deposit_address,
        customer_id: comment.context.transaction.customer_id,
      });
    } else if (commentEntityType() === 'Customer') {
      navigate(`/customers/${encodeURIComponent(commentsEntityId())}`);
    }
  };

  return (
    <div className='my-3 border-b-2 border-gray-100 px-3'>
      <div className='flex flex-col text-sm'>
        <div className='font-semibold text-gray-600'>{comment.commenter}</div>
        <div className='mt-2 flex w-fit items-center gap-2 rounded-xl bg-gray-100 px-2 py-0.5'>
          <Paperclip size={16} />
          <span className='text-xs'>4</span>
        </div>
      </div>
      <div
        dangerouslySetInnerHTML={{ __html: comment.body }}
        className={classNames('my-2 text-sm font-normal capitalize', styles.container)}
      ></div>
      <div className='flex justify-between text-xs'>
        <div className='flex text-xs'>
          <div className='text-gray-400'>{commentEntityType()}</div>
          <div
            className='ml-1 cursor-pointer text-xs text-gray-800 underline hover:text-blue-600'
            onClick={onClickEntity}
          >
            {ellipsis(commentsEntityId(), 12)}
          </div>
        </div>
        <div className='text-gray-400'>{formatDateTime(comment.created_at, timezone)}</div>
      </div>
      <div className='mb-2 flex flex-row'>
        {comment.comment_files.map(file => (
          <label
            data-testid={`file-${file.file_key}`}
            key={file.file_key}
            className='mr-1 flex cursor-pointer rounded-lg border border-gray-300 px-1 text-sm'
            onClick={() => onClickFile(file)}
          >
            <File className='mx-1 my-auto text-gray-500' weight='fill' />
            {file.file_name}
          </label>
        ))}
      </div>
    </div>
  );
};

export default CaseComment;
