import classNames from 'classnames';
import CompassLoader from './CompassLoader';

interface ScreeningLoaderProps {
  height?: string;
  isHidden?: boolean;
}

const ScreeningLoader: React.FC<ScreeningLoaderProps> = ({
  height = '100vh',
  isHidden = false,
}) => {
  return (
    <div
      className={classNames('fixed right-0 top-0 z-[100000] h-full w-full bg-black/60', {
        hidden: isHidden,
      })}
      style={{ height }}
    >
      <div className='flex h-full items-center justify-center'>
        <div className='flex flex-col items-center justify-center'>
          <CompassLoader size={400} />
          <p className='text-base text-white'>Please wait, while we screen for risk</p>
        </div>
      </div>
    </div>
  );
};

export default ScreeningLoader;
