import { FC } from 'react';
import { BasicModal } from '../../ui';
import Table from '../../ui/components/Table/Table';
import { useHealthCheckGet } from '../../api/header/healthCheck';
import { formatDuration, intervalToDuration } from 'date-fns';
import { formatDateTime } from '../../utils/helpers/date';
import { useAuth } from '../../modules/auth';

interface ChainSyncModalProps {
  open: boolean;
  onClose: () => void;
}

const ChainSyncModal: FC<ChainSyncModalProps> = ({ open, onClose }) => {
  const { state } = useAuth();

  const headerData = ['Blockchain', 'Latest Block', 'Last Updated', 'Last Synced Block Timestamp'];
  const { data, isLoading } = useHealthCheckGet({ enabled: open });
  const rows = data?.data?.statuses_by_blockchain?.map(status => {
    const dur = intervalToDuration({ start: new Date(status.last_updated_at), end: new Date() });
    const maxDur = Object.entries(dur).filter(e => e[1] > 0)[0];
    const durationText =
      formatDuration({
        [maxDur[0]]: maxDur[1],
      }) + ' ago';
    return {
      id: status.blockchain,
      data: [
        status.blockchain_verbose,
        status.last_synced_block,
        durationText,
        <span key={status.blockchain} className='text-gray-500'>
          {formatDateTime(status.last_synced_block_timestamp, state?.userProfile?.timezone)}
        </span>,
      ],
    };
  });

  return (
    <BasicModal
      open={open}
      onClose={onClose}
      modalTitle='CHAIN SYNCHRONIZATION'
      customBodyClass='mb-1'
      modalBody={
        <Table
          isLoading={isLoading}
          isHeaderVisible={false}
          height={500}
          headerData={headerData}
          rows={rows}
        />
      }
    />
  );
};

export default ChainSyncModal;
