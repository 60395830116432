import { useMutation } from 'react-query';
import { policyApi } from '../../api/policies';
import { toast } from 'react-toastify';
import saveBlobToFile from '../../utils/helpers/saveBlobToFile';
import { Button } from '../../ui';
import { Export } from '@phosphor-icons/react';

const ExportRules = () => {
  const { mutate: exportRules, isLoading } = useMutation(policyApi.downloadRules, {
    onSuccess: data => {
      toast.success('Exported rules CSV successfully');
      saveBlobToFile(data.data, 'rules.csv');
    },
    onError: () => {
      toast.error('Rules export failed. Please try again later.');
    },
  });

  const handleExport = () => {
    exportRules();
  };

  return (
    <Button
      id='rules-export'
      onClick={handleExport}
      variant='tertiary'
      className='mr-1 !border !border-gray-300 !bg-white !text-black'
      iconStart={<Export />}
      disabled={isLoading}
    >
      Export
    </Button>
  );
};

export default ExportRules;
