import { FC, useEffect, useState } from 'react';
import { FilterPanel } from '../../FilterPanel';
import CurrencyFilter from '../../Filters/CurrencyFilter';
import UserFilter from '../../Filters/UserFilter';
interface MonitoringTableFilterProps {
  filters?: IFilters;
  onApply: (filters: IFilters) => void;
}

export interface IFilters {
  currency: number[];
  added_by: number[];
}

export const defaultStateFilters: IFilters = {
  currency: [],
  added_by: [],
};

const MonitoringTableFilter: FC<MonitoringTableFilterProps> = ({ filters, onApply }) => {
  const [currency, setCurrency] = useState<number[]>([]);
  const [addedBy, setAddedBy] = useState<number[]>([]);

  const handleApply = () => {
    const newFilters = {
      currency,
      added_by: addedBy,
    };
    onApply(newFilters);
  };

  const onReset = () => {
    setCurrency([]);
    setAddedBy([]);
    onApply(defaultStateFilters);
  };

  useEffect(() => {
    if (filters) {
      setCurrency(filters.currency);
      setAddedBy(filters.added_by);
    }
  }, [filters]);

  return (
    <FilterPanel
      id='monitoringList-filter'
      onApply={handleApply}
      onReset={onReset}
      panelClass='-top-5'
    >
      <CurrencyFilter value={currency} setValue={setCurrency} />
      <UserFilter value={addedBy} setValue={setAddedBy} />
    </FilterPanel>
  );
};

export default MonitoringTableFilter;
