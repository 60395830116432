import { FC } from 'react';
import AppliedFilters from '../../AppliedFilters';
import { IFilters, defaultStateFilters } from './DigitalAssetsFilter';
import { isEmpty, every } from 'lodash';
import CurrencyAppliedFilters from '../../AppliedFilters/CurrencyAppliedFilters';

interface DigitalAssetsAppliedFiltersProps {
  filters: IFilters;
  setFilters: (filters: IFilters) => void;
}

const DigitalAssetsAppliedFilters: FC<DigitalAssetsAppliedFiltersProps> = props => {
  const { filters, setFilters } = props;
  if (every(filters, v => isEmpty(v))) return null;

  return (
    <AppliedFilters
      onResetAll={() => {
        setFilters(defaultStateFilters);
      }}
    >
      <CurrencyAppliedFilters
        currencies={filters.blockchains}
        setCurrencies={blockchains => {
          setFilters({ ...filters, blockchains });
        }}
      />
    </AppliedFilters>
  );
};

export default DigitalAssetsAppliedFilters;
