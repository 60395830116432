import { FC, useEffect, useState } from 'react';
import { FilterPanel } from '../../FilterPanel';
import RiskFilter from '../../Filters/RiskFilter';
import RiskTypeFilter from '../../../components/Filters/RiskTypeFilter';
import CustomSelect, { IOption } from '../../../components/Filters/CustomSelect';
import PolicyStatusBadge from '../../../ui/components/Badges/StatusBadge/PolicyStatusBadge';

export interface IFilters {
  alert_level: number[];
  risk_type: number[];
  status: string[];
}

export const defaultStateFilters: IFilters = {
  alert_level: [],
  risk_type: [],
  status: ['live'],
};

export const ruleStatusOptions = [
  { label: 'Live', value: 'live' },
  { label: 'Deactivated', value: 'deactivated' },
];

interface RuleListFilterProps {
  filters?: IFilters;
  onApply: (filters: IFilters) => void;
}

const PolicyRuleListFilter: FC<RuleListFilterProps> = ({ filters, onApply }) => {
  const [riskLevel, setRiskLevel] = useState<number[]>([]);
  const [riskType, setRiskType] = useState<number[]>([]);
  const [status, setStatus] = useState<string[]>([]);
  const selectedStatus: IOption[] = [];
  if (status?.length > 0) {
    status.map(r =>
      selectedStatus.push({
        value: r,
        label: r === 'live' ? 'Live' : 'Deactivated',
      })
    );
  }
  const handleApply = () => {
    const newFilters = {
      alert_level: riskLevel,
      risk_type: riskType,
      status: status,
    };
    onApply(newFilters);
  };

  const onReset = () => {
    setRiskLevel([]);
    setRiskType([]);
    setStatus([]);
    onApply({
      risk_type: [],
      alert_level: [],
      status: [],
    });
  };

  useEffect(() => {
    setRiskLevel(filters.alert_level);
    setRiskType(filters.risk_type);
    setStatus(filters.status);
  }, [filters]);

  const handleChange = (values: IOption[]) => {
    setStatus(values.map(r => r.value));
  };

  const getCustomLabel = (option: IOption) => {
    return (
      <PolicyStatusBadge key={option.value} isActive={option.value === 'live' ? true : false} />
    );
  };

  return (
    <FilterPanel id='rule-filter' onApply={handleApply} onReset={onReset}>
      <RiskFilter value={riskLevel} setValue={setRiskLevel} />
      <RiskTypeFilter value={riskType} setValue={setRiskType} />
      <CustomSelect
        options={ruleStatusOptions}
        handleChange={handleChange}
        value={selectedStatus}
        label='Status'
        getCustomLabel={getCustomLabel}
      />
    </FilterPanel>
  );
};

export default PolicyRuleListFilter;
