import { FC, useEffect, useState } from 'react';
import { FilterPanel } from '../../FilterPanel';
import EntityFilter from '../../Filters/EntityFilter';
import CalendarFilter, { IDateRangeString } from '../../Filters/CalendarFilter';
import { SelectCreatable } from '../../ui/components/Select';
import { Search } from '../../ui/components/Search';
import TransactionTypeFilter from '../../Filters/TransactionTypeFilter';
import TransactionDirectionFilter from '../../Filters/TransactionDirectionFilter';
import { IAddressResponse } from '../../../api/dtos/address';
import TokenSymbolFilter from './TokenSymbolFilter';
import EntitySubtypeFilter from '../../Filters/EntitySubtypeFilter';
interface AddressTransactionsFilterProps {
  address: IAddressResponse;
  filters?: IFilters;
  onApply: (filters: IFilters) => void;
}

export interface IFilters {
  addresses: string[];
  tag_name_verbose: string;
  tag_type_verboses: string[];
  tag_subtype_verboses?: string[];
  transaction_start_date: string;
  transaction_end_date: string;
  type: string;
  token_symbol: string[];
  transaction_direction: string | null;
}

export const defaultStateFilters: IFilters = {
  addresses: [],
  tag_name_verbose: '',
  tag_type_verboses: [],
  tag_subtype_verboses: [],
  transaction_start_date: '',
  transaction_end_date: '',
  type: '',
  token_symbol: [],
  transaction_direction: null,
};

const AddressTransactionsFilter: FC<AddressTransactionsFilterProps> = ({
  filters,
  onApply,
  address,
}) => {
  const [addresses, setAddresses] = useState<string[]>([]);
  const [entityType, setEntityType] = useState<string[]>([]);
  const [entitySubType, setEntitySubType] = useState<string[]>([]);
  const [entityName, setEntityName] = useState<string>('');
  const [dateRange, setDateRange] = useState<IDateRangeString>();
  const [transactionType, setTransactionType] = useState<string>('');
  const [tokenSymbol, setTokenSymbol] = useState<string[]>([]);
  const [txnDirection, setTxnDirection] = useState<string | null>(null);

  const handleApply = () => {
    const newFilters = {
      addresses: addresses,
      tag_name_verbose: entityName,
      tag_type_verboses: entityType,
      tag_subtype_verboses: entitySubType,
      transaction_start_date: dateRange?.from,
      transaction_end_date: dateRange?.to,
      type: transactionType,
      token_symbol: tokenSymbol,
      transaction_direction: txnDirection,
    };
    onApply(newFilters);
  };

  const onReset = () => {
    setAddresses([]);
    setEntityName('');
    setEntityType([]);
    setEntitySubType([]);
    setDateRange(null);
    setTransactionType('');
    setTokenSymbol([]);
    setTxnDirection(null);
    onApply(defaultStateFilters);
  };

  useEffect(() => {
    setAddresses(filters.addresses);
    setEntityName(filters.tag_name_verbose);
    setEntityType(filters.tag_type_verboses);
    setEntitySubType(filters.tag_subtype_verboses);
    setDateRange({
      from: filters.transaction_start_date,
      to: filters.transaction_end_date,
    });
    setTransactionType(filters.type);
    setTokenSymbol(filters.token_symbol);
    setTxnDirection(filters.transaction_direction);
  }, [filters]);

  return (
    <FilterPanel id='transactions-filter' onApply={handleApply} onReset={onReset}>
      <TransactionTypeFilter value={transactionType} setValue={setTransactionType} />
      <Search
        label='Entity name'
        placeholder='Enter Entity Name ...'
        value={entityName}
        setValue={setEntityName}
      />
      <SelectCreatable
        formatCreateLabel={input => `Add ${input}`}
        label='Addresses'
        options={addresses}
        setOptions={setAddresses}
      />
      <CalendarFilter value={dateRange} setValue={setDateRange} label='Transaction Date' />
      <EntityFilter value={entityType} setValue={setEntityType} />
      <EntitySubtypeFilter value={entitySubType} setValue={setEntitySubType} />
      {address?.digital_assets?.length > 2 && (
        <TokenSymbolFilter
          value={tokenSymbol}
          setValue={setTokenSymbol}
          tokens={address.digital_assets}
          currency_short={address.currency_short}
        />
      )}
      <TransactionDirectionFilter value={txnDirection} setValue={setTxnDirection} />
    </FilterPanel>
  );
};

export default AddressTransactionsFilter;
