/* eslint-disable quotes */
import React, { useRef, useEffect, useMemo } from 'react';
import ErrorImage from '@/assets/images/error.svg';
import { FallbackProps } from 'react-error-boundary';
import { getUserEmail, removeOrganizationInfo } from '../../utils/helpers/cookies';
import { Header } from '../Header';
import { Button } from '../../ui';
import { ArrowCircleLeft } from '@phosphor-icons/react/dist/ssr';
import { useRouter } from '../../modules/router/RouterProvider';
import { getErrorMessage } from '../../utils/helpers/helperFunctions';
import { useAuth } from '../../modules/auth';
import { tokenAccountError } from '../../utils/constants/errors';
import IdentifierEllipsis from '../ui/components/Currency/IdentifierEllipsis';
import { useLocation } from 'react-router-dom';

const SUPPORT_EMAIL = 'support@merklescience.com';

interface IErrorPage extends Partial<FallbackProps> {
  message?: string;
}

const ErrorPage: React.FC<IErrorPage> = ({ message, error, resetErrorBoundary }) => {
  const errorStatusCode = error?.response?.status;
  const errorMessage = message || getErrorMessage(error);
  const { navigate } = useRouter();
  const { state } = useAuth();
  const { pathname } = useLocation();
  const originalPathname = useRef(pathname);

  useEffect(() => {
    if (pathname !== originalPathname.current) {
      resetErrorBoundary?.();
    }
  }, [pathname, resetErrorBoundary]);

  const getError = useMemo(() => {
    let title = errorMessage || 'An error occurred';
    let description;

    switch (true) {
      case errorStatusCode === 401 && errorMessage === 'Your Billing Plan Has Expired':
        description = (
          <p className='mb-6 text-base text-gray-500'>
            Your Billing Plan has expired. Please renew it for continued access. If you have any
            questions, please contact{' '}
            <a href={`mailto:${SUPPORT_EMAIL}`} className='text-blue-600'>
              {SUPPORT_EMAIL}
            </a>{' '}
            or your account manager.
          </p>
        );
        break;
      case errorStatusCode === 403 && errorMessage === "You don't have access to the product":
        description = (
          <p className='mb-6 text-base text-gray-500'>
            {"You don't have access to the product, please contact "}
            <a href={`mailto:${SUPPORT_EMAIL}`} className='text-blue-600'>
              {SUPPORT_EMAIL}
            </a>{' '}
            or your account manager.
          </p>
        );
        break;
      case errorStatusCode === 403:
        description = (
          <p className='mb-6 text-base text-gray-500'>
            If you have any questions, please contact{' '}
            <a href={`mailto:${SUPPORT_EMAIL}`} className='text-blue-600'>
              {SUPPORT_EMAIL}
            </a>{' '}
            or your account manager.
          </p>
        );
        break;
      default:
        description = (
          <p className='mb-6 text-base text-gray-500'>
            We encountered a <span className='font-semibold'>{errorStatusCode}</span> error. You can
            mail us at{' '}
            <a href={`mailto:${SUPPORT_EMAIL}`} className='text-blue-600'>
              {SUPPORT_EMAIL}
            </a>{' '}
            to report the error.
          </p>
        );
    }

    if (errorStatusCode === 404) {
      title = 'This page could not be found';
    }
    if (title === tokenAccountError) {
      description = (
        <div className='mt-2 flex text-gray-600'>
          Owner Address:
          <IdentifierEllipsis
            onClick={() => {
              navigate('/dashboard');
              resetErrorBoundary?.();
            }}
            identifier={error?.response?.data?.account_owner}
            currency={error?.response?.data?.currency}
            type='addresses'
            limit={50}
            className='ml-2 text-blue-500'
            clickable
            copyable
          />
        </div>
      );
    }

    return { title, description };
  }, [errorStatusCode, errorMessage, error, navigate, resetErrorBoundary]);

  const onLogout = () => {
    resetErrorBoundary?.();
    removeOrganizationInfo();
    navigate('/accounts/logout', { clear: 'true' });
  };

  const onGoHome = () => {
    resetErrorBoundary?.();
    if (!state.access) onLogout();
    else {
      navigate('/dashboard');
    }
  };

  const isLogoutVisible = errorStatusCode === 401 || errorStatusCode === 403;
  const isHomeVisible =
    !isLogoutVisible &&
    !["You don't have access to the product", 'No workspaces found for the user.'].includes(
      errorMessage
    );

  return (
    <div>
      {!isLogoutVisible && <Header />}
      <div className='flex items-center justify-center overflow-auto text-base'>
        <div className='flex max-w-[575px] flex-col items-center'>
          <img src={ErrorImage} width='370' className='mx-auto mb-6' alt='Error' />
          <div className='mb-3 text-center text-[24px] font-semibold text-gray-800'>
            {getError.title}
          </div>
          <div className='border-b pb-6 text-center text-base text-gray-500'>
            {getError.description}
          </div>
          <div className='my-2 grid grid-cols-2 text-xs text-gray-700'>
            <div className='mr-2 justify-self-end'>
              {getUserEmail() || state?.userProfile?.email}
            </div>
            <div>
              <span className='mr-2'>|</span>
              {window.location.hostname}
            </div>
          </div>
          {isLogoutVisible && (
            <Button variant='tertiary' onClick={onLogout}>
              <ArrowCircleLeft className='mr-2' weight='bold' size={20} /> Logout
            </Button>
          )}
          {isHomeVisible && <Button onClick={onGoHome}>Back to home</Button>}
        </div>
      </div>
    </div>
  );
};

export default ErrorPage;
