import { useState, FC, useCallback } from 'react';
import ReactQuill from 'react-quill-new';
// Update this with react-quill if they updated to quill v3 (currently looks unmaintained)
// https://github.com/zenoamaro/react-quill
import 'react-quill-new/dist/quill.snow.css';
import './react-quill.scss';
import { Button } from '../../../../ui';
import FileAttach from '../../../common/AuditTrail/FileAttach';
import 'quill-mention/autoregister';
import { IPostCommentsUser } from '../../../../api/dtos/comment';
import { commentApi } from '../../../../api/comment';
import { useMutation } from 'react-query';

import classNames from 'classnames';
import { profileApi } from '../../../../api/profile';

interface IQuillProps {
  onSubmit?: (value: string, files: FileList, users?: IPostCommentsUser[]) => void;
  type: 'address' | 'transaction' | 'customer' | 'caseUsers';
  isResolveAlert?: boolean;
  id: string | number;
  showBottomButtons?: boolean;
  setComment?: (value: string) => void;
  className?: string;
}

const Quill: FC<IQuillProps> = props => {
  const {
    onSubmit,
    isResolveAlert,
    type,
    id,
    showBottomButtons = true,
    setComment,
    className,
  } = props;

  const [value, setValue] = useState('');
  const [formattedValue, setFormattedValue] = useState('');
  const [files, setFiles] = useState<FileList>();
  const [users, setUsers] = useState<IPostCommentsUser[]>([]);

  const { mutateAsync: suggestUsers } = useMutation(commentApi.suggestCommentUsers, {
    mutationKey: 'suggestUsers',
  });

  const { mutateAsync: getCaseUsers } = useMutation(profileApi.getCaseUsers, {
    mutationKey: 'getCaseUsers',
  });

  const getUsers = useCallback(
    async ({
      type,
      q,
      id,
    }: {
      type: 'address' | 'transaction' | 'customer' | 'caseUsers';
      q: string;
      id: string | number;
    }) => {
      if (type === 'caseUsers') {
        const { data } = await getCaseUsers({ q });
        return data?.results;
      } else {
        const { data } = await suggestUsers({ type, q, id });
        return data;
      }
    },
    [getCaseUsers, suggestUsers]
  );

  const handleSubmit = () => {
    const usersWithIndex = users.map(user => ({
      ...user,
      start_position: formattedValue.indexOf(`@${user.value}`),
    }));
    if (onSubmit) {
      onSubmit(formattedValue, files, usersWithIndex);
    }
    setValue('');
    setFormattedValue('');
    setFiles(null);
  };

  const formatQuillText = useCallback((str: string) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(str, 'text/html');

    let output = '';
    for (const node of doc.body.childNodes) {
      const childNodeElement = node as Element;
      if (childNodeElement.tagName === 'SPAN' && childNodeElement.classList.contains('mention')) {
        const username = `@${childNodeElement.getAttribute('data-value')} `;
        output += username;
      } else {
        output += childNodeElement?.outerHTML || childNodeElement?.nodeValue || '';
      }
    }

    return `<p>${output}</p>`;
  }, []);

  const onChange = (value: string) => {
    setValue(value);
    const formattedOutput = formatQuillText(value);
    setFormattedValue(formattedOutput);
    if (setComment) {
      if (value === '<p><br></p>') {
        setComment('');
      } else {
        setComment(formattedOutput);
      }
    }
  };

  return (
    <div className={`w-full ${className}`}>
      <ReactQuill
        value={value}
        onChange={onChange}
        theme='snow'
        className={classNames(isResolveAlert ? 'mx-4' : '')}
        placeholder='Enter your comment here'
        modules={{
          toolbar: isResolveAlert
            ? null
            : [
                [{ header: [false, 1, 2, 3, 4, 5, 6] }],
                ['bold', 'italic', 'underline'],
                [{ list: 'ordered' }, { list: 'bullet' }],
              ],
          mention: {
            allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
            mentionDenotationChars: ['@'],
            source: useCallback(async (searchTerm, renderList) => {
              // if (searchTerm) {
              const values = await await getUsers({ type, q: searchTerm, id });

              renderList(values, searchTerm);
              // }
              // eslint-disable-next-line react-hooks/exhaustive-deps
            }, []),
            renderLoading: useCallback(() => {
              const element = document.createElement('div');
              element.classList.add('shadow-lg', 'p-2');
              const imgElement = document.createElement('img');
              imgElement.src = '/loader.gif';
              imgElement.height = 50;
              imgElement.alt = 'Loading...';
              imgElement.className = 'mx-auto h-16';
              element.append(imgElement);
              return element;
            }, []),
            onSelect: useCallback((item, insertItem) => {
              setUsers(prev => [
                ...prev,
                {
                  id: item.id,
                  value: item.value,
                  start_position: 0,
                  length: item.value.length + 1,
                },
              ]);
              insertItem(item);
            }, []),
          },
        }}
      />
      {showBottomButtons && (
        <div
          className={classNames(
            'flex flex-row',
            isResolveAlert ? 'mt-2 border-t bg-gray-50' : 'bg-blue-50'
          )}
        >
          {/* File attach */}
          {!isResolveAlert && <FileAttach files={files} onUpload={files => setFiles(files)} />}
          <Button
            id='commentSubmit'
            variant='primary'
            className={classNames('m-2 p-1 px-2', { 'ml-auto text-sm': isResolveAlert })}
            onClick={handleSubmit}
          >
            {isResolveAlert ? 'Resolve' : 'Add Comment'}
          </Button>
        </div>
      )}
    </div>
  );
};

export default Quill;
