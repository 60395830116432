import * as React from 'react';
import classNames from 'classnames';
import { Download } from '@phosphor-icons/react';

import { formatDateShort } from '../../../utils/helpers/date';
import { getCurrencyById } from '../../../utils/helpers/currency';
import { RiskLevel } from '../../../utils/constants/constants';

import { Button } from '../../../ui';
import AppliedFilters from '../../../ui/components/Badges/AppliedFiltersBadge/AppliedFiltersBadge';

import { IFilters, defaultCustomerListFilters } from './CustomerListFilter';

interface Props {
  selectedText: string;
  count: number;
  filters: IFilters;
  setFilters: (filters: IFilters) => void;
  selectedCustomers: number[];
  setSelectedCustomers: (customers: number[]) => void;
  isAllCustomerSelected: boolean;
  setIsAllCustomerSelected: (boolean) => void;
  selectAllCustomers: (boolean) => void;
  downloadSelectedCustomers: () => void;
}

const CustomerListAppliedFilter: React.FC<Props> = ({
  selectedText,
  count,
  filters,
  setFilters,
  selectedCustomers,
  setSelectedCustomers,
  isAllCustomerSelected,
  setIsAllCustomerSelected,
  selectAllCustomers,
  downloadSelectedCustomers,
}) => {
  const isFilterApplied = React.useMemo(() => {
    if (filters.deposit_address) return true;
    if (filters.risk_level?.length) return true;
    if (filters.currencies?.length) return true;
    if (filters.created_start_date) return true;
    if (filters.created_end_date) return true;
    if (filters.start_date) return true;
    if (filters.end_date) return true;
    if (filters.rule_name?.length) return true;
    if (filters.is_included_in_case !== null) return true;
  }, [filters]);

  const resetFilter = () => {
    setFilters(defaultCustomerListFilters);
  };

  return (
    <div
      className={classNames(
        'flex justify-stretch border-t-[1px] bg-gray-50',
        isFilterApplied || selectedCustomers?.length ? 'px-2 py-2.5' : ''
      )}
    >
      <div className='flex w-full items-center justify-between'>
        <div className='flex flex-wrap items-center'>
          {filters.start_date && (
            <AppliedFilters
              onRemove={() => {
                setFilters({
                  ...filters,
                  start_date: null,
                  end_date: null,
                });
              }}
            >
              Date Updated: {formatDateShort(filters.start_date)} -{' '}
              {formatDateShort(filters.end_date)}
            </AppliedFilters>
          )}
          {filters.created_end_date && (
            <AppliedFilters
              onRemove={() => {
                setFilters({
                  ...filters,
                  created_start_date: null,
                  created_end_date: null,
                });
              }}
            >
              Date Added: {formatDateShort(filters.created_start_date)} -{' '}
              {formatDateShort(filters.created_end_date)}
            </AppliedFilters>
          )}
          {filters.risk_level?.map((risk, i) => (
            <AppliedFilters
              key={i}
              onRemove={() => {
                setFilters({
                  ...filters,
                  risk_level: filters.risk_level.filter(r => r !== risk),
                });
              }}
            >
              Risk: {RiskLevel.find(item => item.value === risk).label}
            </AppliedFilters>
          ))}
          {filters.currencies?.map((curr, i) => (
            <AppliedFilters
              key={i}
              onRemove={() => {
                setFilters({
                  ...filters,
                  currencies: filters.currencies.filter(c => c !== curr),
                });
              }}
            >
              Blockchain: {getCurrencyById(curr).label}
            </AppliedFilters>
          ))}
          {filters.rule_name?.map((rule_name, i) => (
            <AppliedFilters
              key={i}
              onRemove={() => {
                setFilters({
                  ...filters,
                  rule_name: filters.rule_name.filter(r => r !== rule_name),
                });
              }}
            >
              Rule Name: {rule_name}
            </AppliedFilters>
          ))}
          {filters.is_included_in_case !== null && (
            <AppliedFilters
              onRemove={() => {
                setFilters({
                  ...filters,
                  is_included_in_case: null,
                });
              }}
            >
              Included in Cases: {filters.is_included_in_case ? 'Yes' : 'No'}
            </AppliedFilters>
          )}
          {filters.deposit_address && (
            <AppliedFilters
              onRemove={() => {
                setFilters({
                  ...filters,
                  deposit_address: '',
                });
              }}
            >
              Address: {filters.deposit_address}
            </AppliedFilters>
          )}
        </div>
        {isFilterApplied && (
          <div className='flex items-center'>
            <button
              id='customerList-resetFilter'
              data-testid='customerList-resetFilter'
              className='mr-2 text-2xs text-blue-600'
              onClick={() => resetFilter()}
            >
              Reset Filters
            </button>
          </div>
        )}
      </div>
      {selectedCustomers?.length > 0 && (
        <div className='ml-auto flex min-w-[900px] items-center justify-end gap-3 pr-4 text-2xs'>
          <div></div>
          <div>{selectedText}</div>
          {!isAllCustomerSelected && (
            <button
              id='customerList-selectAll'
              data-testid='customerList-selectAll'
              className='text-blue-500'
              onClick={() => {
                selectAllCustomers(true);
                setIsAllCustomerSelected(true);
              }}
            >
              Select all {count} customers
            </button>
          )}
          <button
            id='customerList-clear'
            data-testid='customerList-clear'
            className='text-blue-500'
            onClick={() => {
              setSelectedCustomers([]);
              setIsAllCustomerSelected(false);
            }}
          >
            Clear selection
          </button>
          <Button
            id='customerList-download'
            variant='tertiary'
            onClick={() => downloadSelectedCustomers()}
          >
            <Download size={17} />
          </Button>
        </div>
      )}
    </div>
  );
};

export default CustomerListAppliedFilter;
