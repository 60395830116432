import { ArrowDown, ArrowUp } from '@phosphor-icons/react';
import React, { FC } from 'react';
import { getPercentageValue } from '../../utils/helpers/dashboard';
import classNames from 'classnames';
import WidgetTooltip from './WidgetTooltip';
import Skeleton from 'react-loading-skeleton';
import CustomLink from '../ui/components/CustomLink';

interface ICounterWidgetProps {
  title: React.ReactNode;
  value: number;
  oldValue?: number;
  isLoading?: boolean;
  tooltipTitle?: string;
  tooltipDescription?: string;
  action?: React.ReactNode;
  isPdf?: boolean;
  link?: {
    to?: string;
    params?: Record<string, string>;
  };
}

const CounterWidget: FC<ICounterWidgetProps> = ({
  title,
  value,
  oldValue,
  isLoading,
  tooltipTitle,
  tooltipDescription,
  action,
  isPdf,
  link,
}) => {
  const percentValue = getPercentageValue(value, oldValue);

  return (
    <div
      className={classNames(
        'flex flex-col rounded-2xl border bg-white p-5 text-gray-900 shadow-sm',
        {
          'col-span-4': isPdf,
        }
      )}
    >
      <CustomLink to={link?.to} params={link?.params} className='group'>
        <div className='flex justify-between'>
          <p className='mb-6 text-base font-semibold uppercase'>{title}</p>
          <div
            className='flex'
            onClick={e => {
              e.stopPropagation();
              e.preventDefault();
            }}
          >
            {action}
            <WidgetTooltip title={tooltipTitle} description={tooltipDescription} />
          </div>
        </div>
        {isLoading ? (
          <Skeleton count={2} height={29} />
        ) : (
          <p className='text-[30px] font-semibold'>
            <div className={link ? 'group-hover:text-blue-600 group-hover:underline' : ''}>
              {value?.toLocaleString() ?? '-'}
            </div>
            {oldValue != null && (
              <div className='flex text-sm'>
                {percentValue.percent !== 'NA' &&
                  (percentValue.value > 0 ? (
                    <ArrowUp size={20} className='text-emerald-500' weight='bold' />
                  ) : (
                    <ArrowDown size={20} className='text-red-500' weight='bold' />
                  ))}
                <span
                  className={classNames(
                    percentValue.value > 0
                      ? 'text-emerald-700'
                      : percentValue.value < 0
                        ? 'text-red-700'
                        : 'text-gray-900',
                    'ml-1 mr-2'
                  )}
                >
                  {percentValue.percent}
                </span>
                <span className='text-gray-600'>since previous period</span>
              </div>
            )}
          </p>
        )}
      </CustomLink>
    </div>
  );
};

export default CounterWidget;
