import { useMutation } from 'react-query';
import { useRouter } from '../../modules/router/RouterProvider';
import { useEffectOnce } from 'react-use';
import saveBlobToFile from '../../utils/helpers/saveBlobToFile';
import { downloadApi } from '../../api/download';

const DownloadWithKey = () => {
  const { getQueryParams, navigate } = useRouter();
  const { key } = getQueryParams();
  const { mutate } = useMutation(downloadApi.downloadFile, {
    onSuccess: data => {
      saveBlobToFile(data.data, key);
      navigate('dashboard');
    },
  });
  useEffectOnce(() => {
    mutate({ key });
  });
  return null;
};

export default DownloadWithKey;
