import { FC, useRef } from 'react';

import { ITopRule } from '../../api/dtos/dashboard';
import DashboardWidget from './DashboardWidget';
import { createTooltip } from './WidgetGraphTooltip';
import { GoogleCharts } from 'google-charts';
import React from 'react';

interface IRulesTriggeredWidgetProps {
  rules: ITopRule[];
  isLoading?: boolean;
  isPdf?: boolean;
}

const RulesTriggeredWidget: FC<IRulesTriggeredWidgetProps> = ({ rules, isLoading, isPdf }) => {
  const options = {
    fontName:
      'Inter, ui-sans-serif, system-ui, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
    annotations: {
      textStyle: {
        fontSize: 12,
      },
    },

    legend: 'none',
    tooltip: {
      isHtml: true,
    },
    chartArea: {
      width: '78%',
      top: '5%',
      height: '90%',
      right: '2%',
    },
    hAxis: {
      minValue: 0,
      gridlines: {
        minSpacing: 50,
      },
    },
    height: 530,
    width: isPdf ? 900 : '100%',
  };

  const total = rules?.reduce((sum, row) => sum + row.count, 0);

  const chartData = () => {
    const chartData = [];
    if (chartData) {
      rules?.slice(0, 10).map(p => {
        chartData.push([
          p.rule_name,
          p.count,
          p.count.toLocaleString(),
          createTooltip(p.rule_name, p.count, total),
        ]);
      });
    }

    const headers: (string | { type?: string; role: string; p?: { html: boolean } })[] = [
      'Rule Name',
      'Count',
      { type: 'string', role: 'annotation' },
      { type: 'string', role: 'tooltip', p: { html: true } },
    ];

    return [headers, ...chartData];
  };
  const noData = !(rules?.length > 0);
  if (!noData) GoogleCharts.load(drawChart);

  const chartRef = useRef<HTMLDivElement>(null);
  function drawChart() {
    const data = GoogleCharts.api.visualization.arrayToDataTable(chartData());
    if (chartRef?.current) {
      const chart = new GoogleCharts.api.visualization.BarChart(chartRef.current);
      chart.draw(data, options);
    }
  }

  return (
    <DashboardWidget
      title='Top Rules Triggered'
      subtitle='In Selected Period'
      className='flex-none py-0'
      containerClassName={!isPdf ? 'col-span-8 row-span-2' : 'col-span-12'}
      widgetHeader={<></>}
      isLoading={isLoading}
      noData={noData}
    >
      <div ref={chartRef} id='ruleTriggeredChart' data-testid='ruleTriggeredChart' />
    </DashboardWidget>
  );
};

export default RulesTriggeredWidget;
