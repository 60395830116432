import { UseQueryOptions } from 'react-query';
import { Api } from './api';
import {
  ICommentsResponse,
  IGetCommentsRequest,
  IPostCommentsRequest,
  ISuggestCommentUsersRequest,
  ISuggestCommentUsersResponse,
} from './dtos/comment';
import { getTypeApi } from '../utils/helpers/apiHelpers';
import { getNextPageParam } from '../utils/helpers/helperFunctions';
import { useCachedInfiniteQuery } from '../utils/helpers/react-query.helper';
export class CommentApi extends Api {
  readonly baseUrl: string = '/resolutioncenter/api/v4';

  readonly getComments = (params: IGetCommentsRequest) => {
    const { id, tab_type, offset, limit } = params;
    const { start_date, end_date, type, alert, alert_item, start_time, end_time } =
      params.filters ?? {};
    if (tab_type) {
      return this.http.get<ICommentsResponse>(
        this.route(`${getTypeApi(params.type)}/${id}/comments/`),
        {
          params: {
            start_date,
            end_date,
            start_time,
            end_time,
            type: tab_type,
            alert,
            alert_item,
            offset,
            limit,
          },
        }
      );
    }
    return this.http.get<ICommentsResponse>(
      this.route(`${getTypeApi(params.type)}/${id}/comments/`),
      {
        params: {
          start_date,
          end_date,
          start_time,
          end_time,
          type,
          alert,
          alert_item,
          offset,
          limit,
        },
      }
    );
  };

  readonly suggestCommentUsers = (params: ISuggestCommentUsersRequest) => {
    const { id, q, type } = params;

    return this.http.get<ISuggestCommentUsersResponse>(
      this.route(`${getTypeApi(type)}/${id}/comments/suggest/`),
      {
        params: { q, entity: 'user' },
      }
    );
  };

  readonly addComment = (params: IPostCommentsRequest) => {
    const { id, type, body, files = [], context = [] } = params;
    const formData = new FormData();
    formData.append('body', body);
    // add all files to formData
    for (let i = 0; i < files?.length; i++) {
      formData.append('files', files[i]);
    }
    formData.append('context', JSON.stringify(context));
    let encodedId = id;
    if (type === 'customer') {
      encodedId = encodeURIComponent(id);
    }

    return this.http.post(this.route(`${getTypeApi(type)}/${encodedId}/comments/`), formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  };
}

export const commentApi = new CommentApi();

export const useCommentGetComments = (params: IGetCommentsRequest, options?: UseQueryOptions) => {
  const key = ['commentApi.getComments', params.id, params.type, params?.tab_type, params?.filters];
  const result = useCachedInfiniteQuery(
    key,
    ({ pageParam = 0 }) => commentApi.getComments({ ...params, offset: pageParam }),
    {
      ...options,
      getNextPageParam,
    } as unknown
  );
  return result;
};
