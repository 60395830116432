import * as React from 'react';
import CalendarFilter, { IDateRangeString } from '../../../../components/Filters/CalendarFilter';
import { FilterPanel } from '../../../../components/FilterPanel';

export interface IOption {
  value: string;
  label: string;
}

export interface IFilters {
  start_date: string;
  end_date: string;
}

export const defaultBillingFilters: IFilters = {
  start_date: null,
  end_date: null,
};

interface Props {
  filters: IFilters;
  onApply: (any) => void;
}

const BillingFilter: React.FC<Props> = ({ filters, onApply }) => {
  const [dateAdded, setDateAdded] = React.useState<IDateRangeString>();

  const handleApply = () => {
    const newFilters: IFilters = {
      start_date: dateAdded.from,
      end_date: dateAdded.to,
    };
    onApply(newFilters);
  };

  const onReset = () => {
    setDateAdded(null);
    onApply(defaultBillingFilters);
  };

  React.useEffect(() => {
    setDateAdded({
      from: filters.start_date,
      to: filters.end_date,
    });
  }, [filters]);

  return (
    <FilterPanel id='usage-filter' onApply={handleApply} onReset={onReset}>
      <CalendarFilter value={dateAdded} setValue={setDateAdded} label='Date' />
    </FilterPanel>
  );
};

export default BillingFilter;
