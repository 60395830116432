import { FC } from 'react';
import { AppliedFiltersBadge } from '../../ui';

interface BasicAppliedFilterBadge {
  label: string;
  value: string[];
  setValue: (value: string[]) => void;
  getCustomValue?: (value: string) => string;
  single?: boolean;
}

const BasicAppliedFilterBadge: FC<BasicAppliedFilterBadge> = props => {
  const { label, value, setValue, single = false } = props;
  if (!label || !value || !value?.length) return null;
  if (single && !value[0]) return null;

  const handleRemove = entity => {
    const newValues = value.filter(e => e !== entity);
    setValue(newValues);
  };
  return (
    <>
      {value.map(v => (
        <AppliedFiltersBadge key={v} onRemove={() => handleRemove(v)}>
          {label}: {props.getCustomValue ? props.getCustomValue(v) : v}
        </AppliedFiltersBadge>
      ))}
    </>
  );
};

export default BasicAppliedFilterBadge;
