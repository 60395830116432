import { useInfiniteQuery, UseQueryOptions } from 'react-query';
import { Api } from '../api';
import {
  ICustomTagCreateRequest,
  ICustomTagResult,
  ICustomTagsGetRequest,
  ICustomTagsGetResponse,
  ICustomTagToggleRequest,
  ICustomTagUpdateRequest,
} from '../dtos/settings/customTags';
import { getNextPageParam } from '../../utils/helpers/helperFunctions';

export class CustomTagsApi extends Api {
  readonly baseUrl: string = '/risk/api/v4/custom-tags/';

  readonly getCustomTags = (params: ICustomTagsGetRequest) => {
    const { q = '', offset = 0, lite, is_active, is_in_custom_list, limit } = params;
    return this.http.get<ICustomTagsGetResponse>(this.route(''), {
      params: {
        q,
        offset,
        lite,
        is_active,
        is_in_custom_list,
        limit,
      },
    });
  };

  readonly createCustomTag = (payload: ICustomTagCreateRequest) => {
    return this.http.post<ICustomTagResult>(this.route(''), payload);
  };

  readonly updateCustomTag = (request: ICustomTagUpdateRequest) => {
    return this.http.patch<ICustomTagResult>(this.route(`${request.id}/`), request.payload);
  };

  readonly toggleCustomTag = (payload: ICustomTagToggleRequest) => {
    const { id, isActive, is_in_custom_list = false } = payload;
    return this.http.patch<ICustomTagResult>(this.route(`${id}/`), {
      is_active: isActive,
      is_in_custom_list,
    });
  };

  readonly exportCustomTags = () => {
    return this.http.get(this.route('export/'));
  };

  readonly searchCustomTags = (q: string) => {
    return this.http.get<ICustomTagsGetResponse>(this.route(''), {
      params: {
        q,
        lite: true,
        is_active: true,
      },
    });
  };
}

export const customTagsApi = new CustomTagsApi();

export const useCustomListGetCustomList = (
  params: ICustomTagsGetRequest,
  options?: UseQueryOptions
) => {
  const key = ['customTagsApi.getCustomLists', params.is_in_custom_list, params?.q];
  const result = useInfiniteQuery(
    key,
    ({ pageParam = 0 }) => customTagsApi.getCustomTags({ ...params, offset: pageParam }),
    {
      ...options,
      getNextPageParam,
    } as unknown
  );

  return result;
};

export const useCustomTagsGetCustomTags = (
  params: ICustomTagsGetRequest,
  options?: UseQueryOptions
) => {
  const key = ['customTagsApi.getAddresses', params.is_in_custom_list, params?.q];

  const result = useInfiniteQuery(
    key,
    ({ pageParam = 0 }) => customTagsApi.getCustomTags({ ...params, offset: pageParam }),
    {
      ...options,
      getNextPageParam,
    } as unknown
  );

  return result;
};
