import { FC } from 'react';
import AppliedFilters, { BasicAppliedFilter } from '../../AppliedFilters';
import { IFilters, defaultStateFilters } from './FilterAuditTrail';
import DateAppliedFilter from '../../AppliedFilters/DateAppliedFilter';
import { every, isEmpty } from 'lodash';
import { caseTypes } from './types';
import { IAlert, IAlertItem } from '../../../api/dtos/alert';

interface AuditTrailAppliedFiltersProps {
  filters: IFilters;
  setFilters: (filters: IFilters) => void;
  alert?: IAlert;
  alertItem?: IAlertItem;
}

const AuditTrailAppliedFilters: FC<AuditTrailAppliedFiltersProps> = props => {
  const { filters, setFilters, alert, alertItem } = props;

  const getAlertName = () => {
    if (!alert) return '';
    const alertName = alert?.alert_body;
    const alertItemName = alertItem?.identifier;
    return `rule: "${alertName}"${alertItemName ? `, alert: ${alertItemName}` : ''}`;
  };
  return (
    <div>
      {getAlertName() && <div className='rounded border border-gray-300 p-1'>{getAlertName()}</div>}
      {!every(filters, v => isEmpty(v)) && (
        <AppliedFilters
          onResetAll={() => {
            setFilters(defaultStateFilters);
          }}
        >
          <BasicAppliedFilter
            label='Type'
            value={filters.type}
            setValue={v => {
              setFilters({ ...filters, type: v });
            }}
            getCustomValue={v => caseTypes[v]}
          />
          <DateAppliedFilter
            label='Date Created'
            from={filters.start_date}
            to={filters.end_date}
            onRemove={() => setFilters({ ...filters, start_date: '', end_date: '' })}
          />
        </AppliedFilters>
      )}
    </div>
  );
};

export default AuditTrailAppliedFilters;
