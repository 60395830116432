import { capitalize } from 'lodash';
import { FC, useEffect, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { customerApi } from '../../../api/customer';
import { ConfirmationModal } from '../../../ui';
import { ICustomerAddressesResult } from '../../../api/dtos/customer';

interface ICustomerAddressArchiveModalProps {
  id: number;
  archiveAddress: ICustomerAddressesResult;
  setArchiveAddress: (address: ICustomerAddressesResult) => void;
  identifier: string;
  customerId: string;
  type: 'deposit' | 'withdrawal' | 'any';
  setIsArchiveLoading: (isLoading: boolean) => void;
}

const CustomerAddressArchiveModal: FC<ICustomerAddressArchiveModalProps> = props => {
  const {
    identifier,
    archiveAddress,
    setArchiveAddress,
    id,
    customerId,
    type,
    setIsArchiveLoading,
  } = props;
  const queryClient = useQueryClient();

  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (archiveAddress) {
      setOpen(true);
    }
  }, [archiveAddress]);

  const onSuccess = () => {
    toast.success(`This ${type === 'any' ? '' : capitalize(type)} Address has been Archived.`);
    setArchiveAddress(null);
    queryClient.invalidateQueries(['customerApi', customerId]);
  };
  const { mutate: archiveDepositAddress, isLoading: isArchiveDepositLoading } = useMutation(
    customerApi.archiveDepositAddress,
    { onSuccess }
  );
  const { mutate: archiveWithdrawalAddress, isLoading: isArchiveWithdrawalLoading } = useMutation(
    customerApi.archiveWithdrawalAddress,
    {
      onSuccess,
    }
  );
  const { mutate: archiveAnyAddress, isLoading: isArchiveAnyLoading } = useMutation(
    customerApi.archiveAnyAddress,
    {
      onSuccess,
    }
  );

  useEffect(() => {
    setIsArchiveLoading(
      isArchiveDepositLoading || isArchiveWithdrawalLoading || isArchiveAnyLoading
    );
  }, [
    isArchiveAnyLoading,
    isArchiveDepositLoading,
    isArchiveWithdrawalLoading,
    setIsArchiveLoading,
  ]);

  const ModalBody: FC = () => {
    return (
      <div>
        Are you sure you want to archive this {type} address
        <strong className='break-words'> {identifier || id}</strong>?
        <p>
          Archived {type} addresses will not appear in the list of {type} addresses for this
          customer.
        </p>
        <p className='mt-4'>
          Additionally, changes to customer balances and alerts created by this deposit address for
          this customer will be modified to reflect the removal of this deposit address.
        </p>
      </div>
    );
  };

  const onConfirm = () => {
    const encodedCustomerId = encodeURIComponent(customerId);
    if (type === 'deposit') {
      archiveDepositAddress({ customerId: encodedCustomerId, address: id });
    }
    if (type === 'withdrawal') {
      archiveWithdrawalAddress({ customerId: encodedCustomerId, address: id });
    }
    if (type === 'any') {
      archiveAnyAddress({ customerId: encodedCustomerId, address: id });
    }
    setOpen(null);
  };

  const onCancel = () => {
    setOpen(null);
    setArchiveAddress(null);
  };

  return (
    <ConfirmationModal
      open={!!open}
      data={open || null}
      onCancel={onCancel}
      onConfirm={onConfirm}
      title={`Archive this ${capitalize(type)}`}
      body={<ModalBody />}
    />
  );
};

export default CustomerAddressArchiveModal;
