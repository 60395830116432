import * as React from 'react';

import { getErrorMessage } from '../../../utils/helpers/helperFunctions';
import { useRouter } from '../../../modules/router/RouterProvider';

import { caseApi } from '../../../api/case';

import { BasicModal, Button } from '../../../ui';
import { toast } from '../../../ui/components/Toast';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import InputController from '../../../ui/components/Form/InputController';
import SingleSelectController from '../../ui/components/Select/SingleSelectController';
import { IOption } from '../../Filters/CustomSelect';

interface Props {
  isOpen: boolean;
  onClose: () => void;

  caseUsers: IOption[];
}

export const initialData = {
  name: '',
  assigned_to: null as IOption,
};

const AddCaseModal: React.FC<Props> = ({ isOpen, onClose, caseUsers }) => {
  const { navigate } = useRouter();

  const validations = Yup.object({
    name: Yup.string().label('Case Name').required(),
    assigned_to: Yup.object().label('Assigned To').nullable(),
  });

  const { control, handleSubmit, setError } = useForm({
    defaultValues: initialData,
    resolver: yupResolver(validations),
  });

  const onSubmit = async (data: typeof initialData) => {
    try {
      const res = await caseApi.createCase({
        name: data.name,
        assigned_to: Number(data?.assigned_to?.value),
        add_assignee_payload: true,
      });

      if (res.status === 201) {
        toast.success('Case Created Successfully');

        navigate(`/cases/${res.data.id}`);

        onClose();
      }
    } catch (error) {
      if (error.response.data.name) {
        setError('name', { message: getErrorMessage(error) });
      } else {
        toast.error(getErrorMessage(error));
      }
    }
  };

  return (
    <BasicModal
      size='sm'
      open={isOpen}
      onClose={onClose}
      modalTitle='Create Case'
      modalBody={
        <div className='z-[100000]'>
          <form className='flex flex-col' onSubmit={handleSubmit(onSubmit)}>
            <InputController
              name='name'
              control={control}
              id='customer-id'
              type='text'
              labelText='Case Name'
              placeholder='Enter Case Name'
              required
            />
            <div className='my-2.5' />
            <label
              data-testid='assign-to'
              className={'text-sm font-medium leading-default text-gray-800'}
            >
              Assign To
            </label>
            <SingleSelectController control={control} name='assigned_to' options={caseUsers} />
            <div className='mt-4 flex justify-end gap-2'>
              <Button variant='tertiary' onClick={onClose}>
                Cancel
              </Button>
              <Button type='submit'>Add Case</Button>
            </div>
          </form>
        </div>
      }
    />
  );
};

export default AddCaseModal;
