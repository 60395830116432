import { FC, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useModalData } from '../../../hooks/useModalData';
import { BasicBadge, Button, Checkbox, RoleBadge, StatusBadge } from '../../../ui';
import Table from '../../../ui/components/Table/Table';
import {
  buildInfiniteQueryTableProps,
  flattenInfiniteQueryResult,
} from '../../../utils/helpers/react-query.helper';
import { useWorkspaceMembersGet } from '../../../api/workspaces';
import { useTeamMembersGet } from '../../../api/settings/teamMembers';
import { ITeamMember } from '../../../api/dtos/settings/teamMembers';
import TeamMemberEditModal from './TeamMemberEditModal';
import TeamMemberInviteModal from './TeamMemberInviteModal';
import { IWorkspaceMembersResult } from '../../../api/dtos/workspaces';
import { Search } from '../../ui/components/Search';
import { useWorkspace } from '../../../utils/helpers/common';
import { Plus } from '@phosphor-icons/react';
import Tab from '../../ui/components/Tab/Tab';
import Popover from '../../../ui/components/Popover/Popover';
import classNames from 'classnames';
import { useDebounce } from 'use-debounce';

interface TeamMembersTableProps {
  type: 'organisation' | 'workspace';
}

const TeamMembersTable: FC<TeamMembersTableProps> = ({ type }) => {
  const queryClient = useQueryClient();
  // TODO: Update in future
  const isSelectHidden = true;

  const {
    state: teamMember,
    open,
    close,
    isOpen,
  } = useModalData<ITeamMember | IWorkspaceMembersResult>();
  const workspace = useWorkspace();

  const [selected, setSelected] = useState<string[]>([]);
  const [inviteType, setInviteType] = useState<'single' | 'bulk'>('single');
  const [search, setSearch] = useState<string>('');
  const [debouncedSearch] = useDebounce(search, 300);

  const [tab, setTab] = useState<number>(0);

  const workspaceMembersQuery = useWorkspaceMembersGet(
    { workspace: workspace.slug, q: debouncedSearch, is_active: tab === 2 ? null : tab === 0 },
    {
      enabled: type === 'workspace',
    }
  );
  const orgMemberQuery = useTeamMembersGet(
    { q: debouncedSearch, is_active: tab === 2 ? null : tab === 0 },
    { enabled: type === 'organisation' }
  );
  const queryResult = type === 'organisation' ? orgMemberQuery : workspaceMembersQuery;
  const headerData = [...(!isSelectHidden ? [''] : []), 'Name', 'Email', 'Role', 'Status', ''];

  const [, results] =
    type === 'organisation'
      ? flattenInfiniteQueryResult(orgMemberQuery.data)
      : flattenInfiniteQueryResult(workspaceMembersQuery.data);

  const onClose = (refetch?: false) => {
    close();
    if (refetch) {
      queryClient.invalidateQueries(['TeamMembersApi', 'getTeamMembers']);
      queryClient.invalidateQueries(['WorkspacesApi', 'getWorkspaceMembers']);
    }
  };

  const onClickCheckbox = (email: string) => {
    if (selected.includes(email)) {
      setSelected(selected.filter(s => s !== email));
    } else {
      setSelected([...selected, email]);
    }
  };

  const rows = results?.map((t: ITeamMember | IWorkspaceMembersResult) => {
    const isActive = 'user_is_active' in t ? t.user_is_active && t.is_active : t.is_active;
    return {
      id: t.id,
      data: [
        ...(!isSelectHidden
          ? [
              <Checkbox
                key={t.id}
                checked={selected.includes(t.email)}
                onClick={() => onClickCheckbox(t.email)}
              />,
            ]
          : []),
        `${t.first_name} ${t.last_name}`,
        t.email,
        'memberships' in t ? (
          <>
            {t.memberships.slice(0, 2).map((m, i) => (
              <RoleBadge
                key={`${t.id} ${i}`}
                role={m.role}
                className='mr-1'
                label={`${m.role_verbose} - ${m.workspace}`}
              />
            ))}
            {t.memberships.length > 2 && (
              <Popover
                placement='bottom'
                referenceContent={
                  <BasicBadge className='bg-gray-100'>{`+${t.memberships.length - 2}`}</BasicBadge>
                }
                popoverContent={
                  <div
                    className={classNames(
                      'flex w-64 max-w-sm flex-wrap rounded-md bg-white p-2 shadow-lg'
                    )}
                  >
                    {t.memberships.slice(2).map((m, i) => (
                      <RoleBadge
                        key={`${t.id} ${i}`}
                        role={m.role}
                        className='mr-1'
                        label={`${m.role_verbose} - ${m.workspace}`}
                      />
                    ))}
                  </div>
                }
              />
            )}
          </>
        ) : (
          <RoleBadge key={t.id} role={t.role} label={t.role_verbose} />
        ),
        <StatusBadge key={t.id} isActive={isActive} />,
        <span key={t.id}>
          <Button
            id={type === 'workspace' ? 'Edit' : t.is_active ? 'disable' : 'enable'}
            disabled={'user_is_active' in t ? !t.user_is_active : false}
            variant='tertiary'
            buttonSize='sm'
            className='!px-2 !py-0 leading-5'
            onClick={() => {
              open(t);
            }}
          >
            {type === 'workspace' ? 'Edit' : t.is_active ? 'Disable' : 'Enable'}
          </Button>
        </span>,
      ],
    };
  });

  return (
    <>
      <div className='rounded-lg border'>
        <Table
          title='MEMBERS'
          appliedFilters={
            !!selected.length && (
              <div className='flex justify-between border-t p-3 text-xs font-semibold text-gray-500'>
                <div className='font-semibold text-gray-500'>
                  {selected.length} members in this page are selected
                </div>
                <Button
                  variant='secondary'
                  onClick={() => {
                    open(null);
                    setInviteType('bulk');
                  }}
                >
                  Invite to workspace
                </Button>
              </div>
            )
          }
          headerData={headerData}
          rows={rows}
          fontClass='text-sm'
          {...buildInfiniteQueryTableProps(queryResult)}
          headerActionsLeft={
            <Tab
              values={[{ label: 'Active' }, { label: 'Inactive' }, { label: 'All' }]}
              type={'primary'}
              onChange={setTab}
            />
          }
          headerActions={
            <div className='flex'>
              <Search
                id='members-search'
                className='mr-4'
                minChars={1}
                value={search}
                setValue={setSearch}
              />
              <Button
                id='members-invite'
                className='text-sm'
                iconStart={<Plus weight='bold' size={18} />}
                onClick={() => {
                  open(null);
                  setInviteType('single');
                }}
              >
                Invite Member
              </Button>
            </div>
          }
        />
      </div>

      <TeamMemberEditModal
        type={type}
        teamMember={teamMember}
        open={teamMember && isOpen}
        onClose={onClose}
        workspace={workspace}
      />

      <TeamMemberInviteModal
        type={type}
        inviteType={inviteType}
        open={!teamMember && isOpen}
        onClose={onClose}
        emails={selected}
      />
    </>
  );
};

export default TeamMembersTable;
