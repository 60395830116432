import * as React from 'react';
import Skeleton from 'react-loading-skeleton';

import classNames from 'classnames';

import { Button } from '../../ui';
import { useRouter } from '../../modules/router/RouterProvider';
import { flattenInfiniteQueryResult } from '../../utils/helpers/react-query.helper';
import {
  usePolicyGetDetails,
  usePolicyGetCryptoObjectsFlagged,
  usePolicyGetAlerts,
  usePolicyGetRuleTriggeredList,
} from '../../api/policies';
import { IPolicyRulesTriggeredResponse } from '../../api/dtos/policies';

import PolicyOverview from './PolicyDetails/PolicyOverview';
import PolicyRuleList from './PolicyDetails/PolicyRuleList';
import AuditTrail from './PolicyDetails/AuditTrail';
import EditPolicyDetailsModal from './PolicyDetails/EditPolicyDetailsModal';

const PolicyDetails: React.FC = () => {
  const { getParams, navigate } = useRouter();
  const params = getParams();

  const {
    isLoading,
    data: detailsData,
    refetch,
  } = usePolicyGetDetails(params.policyId, { useErrorBoundary: true });
  const policyDetails = detailsData?.data;

  const { isLoading: isLoadingPolicyCryptoObjects, data: policyCryptoObjects } =
    usePolicyGetCryptoObjectsFlagged(params.policyId);
  const policyCryptoObjectsFlagged = policyCryptoObjects?.data;

  const { isLoading: isLoadingPolicyAlert, data: policyAlertData } = usePolicyGetAlerts(
    params.policyId
  );
  const policyAlerts = policyAlertData?.data;

  const policyGetTriggeredRulesQuery = usePolicyGetRuleTriggeredList({
    id: params.policyId,
  });
  const response = flattenInfiniteQueryResult(policyGetTriggeredRulesQuery.data);
  const policyRules = response[1];
  const policyRulesCount = response[0];
  const [activeTab, setActiveTab] = React.useState(0);

  const [isEditModalOpen, setIsEditModalOpen] = React.useState(false);

  const renderActiveTab = () => {
    switch (activeTab) {
      case 0:
        return (
          <PolicyOverview
            policyDetails={policyDetails}
            isLoadingPolicyCryptoObjects={isLoadingPolicyCryptoObjects}
            policyCryptoObjectsFlagged={policyCryptoObjectsFlagged}
            isLoadingPolicyRulesList={policyGetTriggeredRulesQuery.isLoading}
            policyRules={policyRules as IPolicyRulesTriggeredResponse[]}
            isLoadingPolicyAlert={isLoadingPolicyAlert}
            policyAlerts={policyAlerts}
            policyGetTriggeredRulesQuery={policyGetTriggeredRulesQuery}
            policyRulesCount={policyRulesCount}
          />
        );
      case 1:
        return <PolicyRuleList />;
      case 2:
        return <AuditTrail policyType={policyDetails.type_verbose} />;
    }
  };

  if (isLoading) {
    return <Skeleton count={3} height={30} />;
  } else {
    return (
      <>
        <div className='mb-4 flex gap-2 text-sm font-semibold shadow'>
          <div
            id='policy-all'
            data-testid='policy-all'
            className='cursor-pointer border-b-4 border-white px-5 py-3.5'
            onClick={() => navigate('/settings/workspace/policies/')}
          >
            All Risk Policies
          </div>
          <div className='cursor-pointer border-b-4 border-white py-3.5'>{'>'}</div>
          <div className='cursor-pointer border-b-4 border-blue-600 px-3 py-3.5'>
            {policyDetails.name}
          </div>
        </div>
        <div className='shadow'>
          <div className='flex gap-2 px-3 text-sm font-semibold shadow-sm'>
            <div
              id='policy-overview'
              data-testid='policy-overview'
              onClick={() => setActiveTab(0)}
              className={classNames(
                activeTab === 0 ? 'border-blue-600' : 'border-white',
                'cursor-pointer border-b-4 px-3 py-4'
              )}
            >
              Policy Overview
            </div>
            <div
              id='policy-ruleList'
              data-testid='policy-ruleList'
              onClick={() => setActiveTab(1)}
              className={classNames(
                activeTab === 1 ? 'border-blue-600' : 'border-white',
                'cursor-pointer border-b-4 px-3 py-4'
              )}
            >
              Rule List
            </div>
            <div
              id='policy-auditTrail'
              data-testid='policy-auditTrail'
              onClick={() => setActiveTab(2)}
              className={classNames(
                activeTab === 2 ? 'border-blue-600' : 'border-white',
                'cursor-pointer border-b-4 px-3 py-4'
              )}
            >
              Audit Trail
            </div>
            {activeTab === 0 && (
              <div className='ml-auto pt-3.5'>
                <Button
                  id='policy-edit'
                  type='button'
                  buttonSize='sm'
                  variant='primary'
                  onClick={() => setIsEditModalOpen(true)}
                >
                  Edit Policy Details
                </Button>
              </div>
            )}
          </div>

          {renderActiveTab()}

          <EditPolicyDetailsModal
            isOpen={isEditModalOpen}
            onClose={isRefetch => {
              setIsEditModalOpen(false);
              if (isRefetch) refetch();
            }}
            policyDetails={policyDetails}
          />
        </div>
      </>
    );
  }
};

export default PolicyDetails;
