import { FC } from 'react';
import AppliedFilters, { BasicAppliedFilter } from '../../AppliedFilters';
import { IFilters, defaultStateFilters } from './CustomerTransactionsFilter';
import EntityAppliedFilters from '../../AppliedFilters/EntityAppliedFilters';
import DateAppliedFilter from '../../AppliedFilters/DateAppliedFilter';
import { isEmpty, every } from 'lodash';
import RiskAppliedFilter from '../../AppliedFilters/RiskAppliedFilter';
import { getEntityTypeBySubType } from '../../../utils/helpers/entity';

interface CustomerTransactionsAppliedFiltersProps {
  filters: IFilters;
  setFilters: (filters: IFilters) => void;
}

const CustomerTransactionsAppliedFilters: FC<CustomerTransactionsAppliedFiltersProps> = props => {
  const { filters, setFilters } = props;
  if (every(filters, v => isEmpty(v))) return null;
  return (
    <AppliedFilters
      onResetAll={() => {
        setFilters(defaultStateFilters);
      }}
    >
      <EntityAppliedFilters
        entity={filters.tag_type_verboses}
        setEntity={e => {
          setFilters({ ...filters, tag_type_verboses: e });
        }}
      />
      <EntityAppliedFilters
        entity={filters.tag_subtype_verboses?.map(e => `${getEntityTypeBySubType(e)} -> ${e}`)}
        setEntity={e => {
          setFilters({ ...filters, tag_subtype_verboses: e });
        }}
      />
      <BasicAppliedFilter
        label='Entity Name'
        value={filters.tag_name_verboses}
        setValue={v => {
          setFilters({ ...filters, tag_name_verboses: v });
        }}
      />
      <RiskAppliedFilter
        risks={filters.risk_level}
        setRisks={r => {
          setFilters({ ...filters, risk_level: r });
        }}
      />
      <DateAppliedFilter
        label='Transaction Date'
        from={filters.transaction_start_date}
        to={filters.transaction_end_date}
        onRemove={() =>
          setFilters({ ...filters, transaction_start_date: '', transaction_end_date: '' })
        }
      />
    </AppliedFilters>
  );
};

export default CustomerTransactionsAppliedFilters;
