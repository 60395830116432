const ApiDocumentation = () => {
  return (
    <iframe
      className='size-full'
      src='https://docs.merklescience.com/reference'
      title='API Reference'
    ></iframe>
  );
};

export default ApiDocumentation;
