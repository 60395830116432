import React, { FC } from 'react';
import { CaretRight } from '@phosphor-icons/react';
import { ellipsis } from '../../../../utils/helpers/helperFunctions';
import { useRouter } from '../../../../modules/router/RouterProvider';
// import { useNavigate } from 'react-router-dom';

interface BreadcrumbItem {
  label: string;
  to?: string;
}

interface BreadcrumbsProps {
  items: BreadcrumbItem[];
  className?: string;
}

const Breadcrumb: FC<BreadcrumbsProps> = ({ items, className }) => {
  // const navigate = useNavigate();
  const { navigate } = useRouter();

  const onclick = (item: BreadcrumbItem) => {
    if (item.to) {
      navigate(item.to);
    }
  };

  return (
    <div className={className}>
      <div className='flex flex-wrap'>
        {items.map((item, index) => (
          <>
            <div
              key={index}
              className='flex cursor-pointer items-center rounded-md border bg-white p-1 text-xs font-medium shadow'
            >
              <div onClick={() => onclick(item)}> {ellipsis(item.label, 14)}</div>
            </div>
            {index !== items.length - 1 && (
              <CaretRight size={20} className='my-auto text-gray-300' />
            )}
          </>
        ))}
      </div>
    </div>
  );
};

export default Breadcrumb;
