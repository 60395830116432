import { useInfiniteQuery, useQuery, UseQueryOptions } from 'react-query';
import { Api } from '../api';
import {
  ICustomAddressesGetRequest,
  ICustomAddressesGetResponse,
  ICustomAddressesPostRequest,
  IAddressMutateResponse,
} from '../dtos/settings/customList';
import { getNextPageParam } from '../../utils/helpers/helperFunctions';

export class CustomListApi extends Api {
  readonly baseUrl: string = '/risk/api/v4/custom-tagged-addresses/';

  readonly getAddresses = (params: ICustomAddressesGetRequest) => {
    const { custom_tag_id, q = '', offset = 0 } = params;
    return this.http.get<ICustomAddressesGetResponse>(this.route(''), {
      params: {
        q,
        custom_tag_id,
        offset,
      },
    });
  };

  readonly postAddresses = (params: ICustomAddressesPostRequest) => {
    const { custom_tag_id, addresses } = params;
    return this.http.post<IAddressMutateResponse>(this.route(''), {
      addresses,
      custom_tag_id,
    });
  };

  readonly archiveAddress = ({ id }: { id: number }) => {
    return this.http.delete<IAddressMutateResponse>(this.route(`${id}/archive/`));
  };

  readonly exportAddresses = ({ custom_tag_id }: { custom_tag_id: number }) => {
    return this.http.get<IAddressMutateResponse>(this.route('export/'), {
      params: {
        custom_tag_id,
      },
    });
  };
}

export const customListApi = new CustomListApi();

export const useCustomListGetAddresses = (
  params: ICustomAddressesGetRequest,
  options?: UseQueryOptions
) => {
  const key = ['customListApi.getAddresses', params?.q, params?.custom_tag_id];
  const result = useInfiniteQuery(
    key,
    ({ pageParam = 0 }) => customListApi.getAddresses({ ...params, offset: pageParam }),
    {
      ...options,
      getNextPageParam,
    } as unknown
  );
  return { key, ...result };
};

export const useCustomListExportAddresses = (custom_tag_id: number, options?: UseQueryOptions) => {
  const key = ['customListApi.exportAddresses', custom_tag_id];
  const result = useQuery(key, () => customListApi.exportAddresses({ custom_tag_id }), {
    ...options,
  } as unknown);

  return result;
};
