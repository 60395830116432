import { FC } from 'react';
import BasicBadge from '../BasicBadge/BasicBadge';
import { X } from '@phosphor-icons/react';

interface AppliedFiltersProps {
  children: React.ReactNode;
  onRemove?: () => void;
}

const AppliedFilters: FC<AppliedFiltersProps> = ({ children, onRemove }) => {
  return (
    <BasicBadge
      className='my-1.5 mr-2 bg-blue-100 text-blue-800'
      iconEnd={
        <X
          id='filter-remove'
          data-testid='filter-remove'
          size={14}
          className='ml-1 cursor-pointer text-blue-400'
          onClick={onRemove}
        />
      }
    >
      {children}
    </BasicBadge>
  );
};

export default AppliedFilters;
